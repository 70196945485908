import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import { WebApimanager } from "../../../WebApiManager";
import { useRecoilState } from "recoil";
import { siteId } from "../../../atoms";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

// Function to calculate due days
const calculateDueDays = (expectedDate, releasedDate = null) => {
  if (!expectedDate) return "-";

  const dateToCompare = releasedDate ? new Date(releasedDate) : new Date();
  const expectedDateObj = new Date(expectedDate);
  const differenceInTime = expectedDateObj.getTime() - dateToCompare.getTime();
  const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24)); // convert time difference to days

  return differenceInDays;
};

const RfiChart = (props) => {
  let webApi = new WebApimanager();
  const [site] = useRecoilState(siteId); // Using site state
  const [drawingList, setDrawingList] = useState([]);

  const fetchData = async () => {
    try {
      const response = await webApi.get(
        props.type === "architectToRo"
          ? `api/architectureToRoRequested?siteId=${site}`
          : `api/roToSiteLevelRequested/site?siteId=${site}`
      );
      console.log("Fetch drawing Based on site", response.data.data);
      setDrawingList(response.data.data || []);
    } catch (error) {
      console.error("Error fetching drawing data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [site]); // Dependency array includes `site` to refetch if `site` changes

  // Initialize counters for consultants
  const consultantCounts = {};

  // Process the data
  drawingList.forEach((register) => {
    const consultant = register.drawingId?.designDrawingConsultant?.role || "";

    // Initialize consultant in the counts object
    if (!consultantCounts[consultant]) {
      consultantCounts[consultant] = { onTime: 0, delay: 0, total: 0 };
    }

    // Calculate due days and categorize
    const dueDays = calculateDueDays(
      register.expectedDate,
      register.releasedDate
    );
    if (dueDays > 0) {
      consultantCounts[consultant].onTime++;
    } else {
      consultantCounts[consultant].delay++;
    }
    consultantCounts[consultant].total++;
  });

  // Prepare data for Chart.js
  const chartData = {
    labels: Object.keys(consultantCounts),
    datasets: [
      {
        label: "On Time",
        data: Object.values(consultantCounts).map(
          (counts) => (counts.onTime / counts.total) * 100
        ),
        backgroundColor: "#0075A4",
        barThickness: 15, // Adjust the bar thickness
        maxBarThickness: 20, // Maximum bar thickness
        categoryPercentage: 0.5, // Adjust this to control spacing between bars
        barPercentage: 0.8, // Adjust this to control the width of the bars
      },
      {
        label: "Delay",
        data: Object.values(consultantCounts).map(
          (counts) => (counts.delay / counts.total) * 100
        ),
        backgroundColor: "#72ADCF",
        barThickness: 15, // Adjust the bar thickness
        maxBarThickness: 20, // Maximum bar thickness
        categoryPercentage: 0.5, // Adjust this to control spacing between bars
        barPercentage: 0.8, // Adjust this to control the width of the bars
      },
    ],
  };

  return (
    <div style={{ height: "400px" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "10px",
          height: "300px",
        }}
      >
        <div style={{ color: "var(--primary-color)", marginBottom: "10px" }}>
          RFI Receivables on Time
        </div>
        <Bar
          data={chartData}
          options={{
            indexAxis: "y", // Interchange X and Y axes
            responsive: true,
            maintainAspectRatio: false, // Ensures the chart uses the specified height
            plugins: {
              legend: {
                display: false,
                position: "top",
                labels: {
                  usePointStyle: true,
                },
              },
              tooltip: {
                callbacks: {
                  label: function (tooltipItem) {
                    const consultant = tooltipItem.label; // Get consultant name from the y-axis label
                    const datasetLabel = tooltipItem.dataset.label; // Get whether it's "On Time" or "Delay"
                    const counts = consultantCounts[consultant]; // Fetch the respective consultant's counts
                    const value =
                      datasetLabel === "On Time" ? counts.onTime : counts.delay; // Determine the value

                    return `${datasetLabel}: ${value} drawings`; // Return actual count of drawings
                  },
                },
              },
            },
            scales: {
              x: {
                stacked: false, // Set to false to display bars side by side
                title: {
                  display: true,
                  text: "Percentage of Drawings",
                },
                min: 0,
                max: 100,
                ticks: {
                  stepSize: 10,
                  callback: function (value) {
                    return `${value}%`;
                  },
                },
              },
              y: {
                stacked: false, // Set to false to display bars side by side
                title: {
                  display: true,
                  text: "Consultants",
                },
                grid: {
                  drawBorder: false, // Optional: hides grid lines to improve appearance
                },
              },
            },
          }}
        />

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "5px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "20px",
            }}
          >
            <div
              style={{
                width: "15px",
                height: "15px",
                borderRadius: "50%",
                backgroundColor: "#0075A4",
                marginRight: "5px",
              }}
            ></div>
            <span>On Time</span>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: "15px",
                height: "15px",
                borderRadius: "50%",
                backgroundColor: "#72ADCF",
                marginRight: "5px",
              }}
            ></div>
            <span>Delay</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RfiChart;
