import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Viewer from "./viewer";
import CloseIcon from "@mui/icons-material/Close";
import { userInfo } from "../../atoms";
import { useRecoilState } from "recoil";
import SignIn from "../SignIn/SignIn";
const DwgViewer = () => {
  const [camera, setCamera] = useState(null);
  const [selectedIds, setSelectedIds] = useState([]);
  const [urn, setUrn] = useState("");
  const [token, setToken] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const [currentUserInfo, setCurrentUserInfo] = useRecoilState(userInfo);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const urnFromParams = queryParams.get("urn");
    const tokenFromParams = queryParams.get("token");

    console.log("URN from params:", urnFromParams);
    console.log("Token from params:", tokenFromParams);

    setUrn(urnFromParams);
    setToken(tokenFromParams);
  }, [location]);

  const handleCameraChange = ({ camera }) => {
    setCamera(camera.getWorldPosition());
  };

  const handleSelectionChange = ({ ids }) => {
    setSelectedIds(ids);
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <div>
      {!currentUserInfo._id ? (
        <p style={{ textAlign: "center", marginTop: "10px" }}>
          Please login the page <SignIn />
        </p>
      ) : (
        <>
          <div style={{ marginTop: "30px", marginLeft: "16px" }}>
            <CloseIcon
              onClick={handleBackClick}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div
            style={{
              width: "100%",
              height: "65vh",
              position: "relative",
              overflow: "hidden",
            }}
          >
            {urn && token ? (
              <Viewer
                urn={urn}
                token={token}
                onCameraChange={handleCameraChange}
                onSelectionChange={handleSelectionChange}
              />
            ) : (
              <div>No URN or token provided</div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default DwgViewer;
