import React, { useState, useEffect } from 'react';
import { WebApimanager } from '../../WebApiManager';
import { useRecoilState } from "recoil";
import { siteId } from '../../atoms';

const Task = () => {
    // const [siteList, setSiteList] = useState([]);
    // const [selectedSite, setSelectedSite] = useState('');
    const [workSequences, setWorkSequences] = useState([]);
    const [unitsData, setUnitsData] = useState({});
    const [openedItems, setOpenedItems] = useState([]);
    const [completedUnits, setCompletedUnits] = useState([]);
    const [updatedFields, setUpdatedFields] = useState([]);
    const [savedData, setSavedData] = useState([]);
    const [site, setSite] = useRecoilState(siteId)
    let webApi = new WebApimanager();

    // useEffect(() => {
    //     const fetchAllSites = async () => {
    //         try {
    //             const response = await new WebApimanager().get("api/sites/AllSites");
    //             if (response.data && Array.isArray(response.data.sites)) {
    //                 setSiteList(response.data.sites);
    //             } else {
    //                 console.error("Unexpected data format:", response.data);
    //             }
    //         } catch (error) {
    //             console.error("There was an error fetching the site list!", error);
    //         }
    //     };
    //     fetchAllSites();
    // }, []);

    // const handleSiteChange = async (e) => {
    //     const site = e.target.value;
    //     try {
    //         const response = await new WebApimanager().get(`api/WorkSequence/site/${site}`);
    //         if (response.data && Array.isArray(response.data.data.workSequences)) {
    //             setWorkSequences(response.data.data.workSequences);
    //             // Clear units data when site changes
    //             setUnitsData({});
    //         } else {
    //             console.error("Unexpected data format:", response.data);
    //         }
    //     } catch (error) {
    //         console.error("There was an error fetching the work sequences!", error);
    //     }
    // };

    const handleAccordionToggle = (sequenceId) => {
        if (openedItems.includes(sequenceId)) {
            setOpenedItems(prevItems => prevItems.filter(item => item !== sequenceId));
        } else {
            setOpenedItems(prevItems => [...prevItems, sequenceId]);
            fetchUnits(sequenceId);
        }
    };

    useEffect(()=>{
        console.log(unitsData)
    },[unitsData])

    const fetchUnits = async (sequenceId) => {
        try {
            const response = await new WebApimanager().get(`api/sites/UnitDetails/${site}`);
            if (response.data && Array.isArray(response.data.data.units)) {
                setUnitsData(prevUnitsData => ({
                    ...prevUnitsData,
                    [sequenceId]: response.data.data.units.map(unit => ({
                        name: unit.name,
                        unitId: unit._id,
                        _id: unit._id,
                        duration: null,
                        startDate: null,
                        endDate: null
                    }))
                }));
            } else {
                console.error(`Unexpected data format for sequenceId ${sequenceId}:`, response.data);
            }
        } catch (error) {
            console.error(`There was an error fetching units for sequenceId ${sequenceId}!`, error);
        }
    };

    const handleInputChange = (sequenceId, unitId, field, value) => {
        // Update unitsData state
        setUnitsData(prevUnitsData => ({
            ...prevUnitsData,
            [sequenceId]: prevUnitsData[sequenceId].map(unit => {
                if (unit._id === unitId) {
                    return {
                        ...unit,
                        [field]: value
                    };
                }
                return unit;
            }).map(unit => {
                if (field === 'duration' && unit.startDate && unit.duration && !unit.endDate) {
                    unit.endDate = new Date(unit.startDate);
                    unit.endDate.setDate(unit.endDate.getDate() + parseInt(unit.duration));
                }
                if (field === 'startDate' && unit.endDate && unit.startDate && !unit.duration) {
                    unit.duration = Math.ceil((new Date(unit.endDate) - new Date(unit.startDate)) / (1000 * 60 * 60 * 24));
                }
                if (field === 'endDate' && unit.startDate && unit.endDate && !unit.duration) {
                    unit.duration = Math.ceil((new Date(unit.endDate) - new Date(unit.startDate)) / (1000 * 60 * 60 * 24));
                }
                return unit;
            })
        }));
    
        // Update updatedFields state with the latest data
        setUpdatedFields(prevFields => {
            // Filter out previous entries for the same workSequenceId and unitId
            const filteredFields = prevFields.filter(field => !(field.workSequenceId === sequenceId && field.unitId === unitId));
    
            // Add the updated unit data to the state if duration is '2'
            if (value === '2') {
                const updatedUnitData = {
                    workSequenceId: sequenceId,
                    unitId: unitId,
                    ...unitsData[sequenceId].find(unit => unit._id === unitId),
                    [field]: value
                };
                return [...filteredFields, updatedUnitData];
            } else {
                return filteredFields;
            }
        });
    
        // Check if all fields are filled for the unit
        const updatedUnits = unitsData[sequenceId].map(unit => {
            if (unit._id === unitId) {
                return {
                    ...unit,
                    [field]: value
                };
            }
            return unit;
        });
    
        const isCompleted = updatedUnits.every(unit =>
            unit.duration !== null && unit.startDate !== null && unit.endDate !== null
        );
    
        if (isCompleted && !completedUnits.includes(unitId)) {
            setCompletedUnits(prevCompletedUnits => [...prevCompletedUnits, unitId]);
        } else if (!isCompleted && completedUnits.includes(unitId)) {
            setCompletedUnits(prevCompletedUnits => prevCompletedUnits.filter(id => id !== unitId));
        }
    };
    

    const handleSubmit = () => {
        const filteredData = updatedFields.filter(field => 
            field.startDate !== null && field.endDate !== null && field.duration !== null
        );
        const newData = filteredData.map(field => ({
            workSequenceId: field.workSequenceId,
            unitId: field.unitId,
            startDate: field.startDate,
            endDate: field.endDate,
            duration: field.duration
        }));

        setSavedData(newData);
        console.log('Saved Data:', newData);
    };

    const submitData = () => {
        const sendToBackend = []

        Object.entries(unitsData).forEach(([sequenceId, units])=>{
           units.forEach((unit)=>{
            if(unit.startDate && unit.endDate && unit.duration){
                console.log(unit)
                sendToBackend.push({
                    workSequenceId: sequenceId,
                    unitId: unit._id,
                    startDate: unit.startDate,
                    endDate: unit.endDate,
                    duration: unit.duration
                })
            }
          })
        })

        webApi.post("api/Task", sendToBackend)
          .then((response) => {
            console.log('Form data stored successfully:', response.data);
            // Reload the page or perform any other action as needed
            window.location.reload();
          })
          .catch((error) => {
            console.error('Error storing form data:', error);
            // Handle error as needed
          });
      
    };

    return (
        <div>
            <div className='flex gap-5'>
                {/* <div>
                    <label className='font-bold py-2'>
                        Select Sites
                        <select className='flex justify-center items-center px-5' onChange={handleSiteChange} value={selectedSite}>
                            <option value="">Select Sites</option>
                            {siteList.map((site) => (
                                <option key={site._id} value={site._id}>{site.siteName}</option>
                            ))}
                        </select>
                    </label>
                </div> */}
            </div>
            <div id="accordion-collapse" data-accordion="collapse">
                {workSequences.map((sequence, index) => (
                    <AccordionItem
                        key={index}
                        sequence={sequence}
                        index={index}
                        units={unitsData[sequence._id]}
                        onToggleAccordion={handleAccordionToggle}
                        onInputChange={handleInputChange}
                        isOpen={openedItems.includes(sequence._id)}
                    />
                ))}
            </div>
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4" onClick={handleSubmit}>
                Submit Updated Fields
            </button>
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4" onClick={submitData}>
                Submit
            </button>
        </div>
    );
};

const AccordionItem = ({ sequence, index, units, onToggleAccordion, onInputChange, isOpen }) => {
    return (
        <div className='mt-3'>
            <h2 id={`accordion-collapse-heading-${index}`}>
                <button
                    type="button"
                    className={`flex items-center justify-between w-full p-3 rounded-md font-normal rtl:text-right text-gray-500 border ${isOpen ? 'border-b-0 ' : 'border-gray-200'} hover:bg-gray-100 gap-3`}
                    aria-expanded={isOpen}
                    aria-controls={`accordion-collapse-body-${index}`}
                    onClick={() => onToggleAccordion(sequence._id)}
                >
                    <span className='font-bold text-[15px]'>{sequence.name || `Work Sequence ${sequence._id}`}</span>
                    <svg data-accordion-icon className={`w-3 h-3 ${isOpen ? 'rotate-180' : ''} shrink-0`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                    </svg>
                </button>
            </h2>
            <div id={`accordion-collapse-body-${index}`} className={isOpen ? '' : 'hidden'} aria-labelledby={`accordion-collapse-heading-${index}`}>
                <div className={`p-2 border ${isOpen ? 'border-t-0' : 'border-gray-200'}`}>
                    {units ? (
                        <table className="w-full">
                            <thead>
                                <tr>
                                    <th>Unit Name</th>
                                    <th>Duration</th>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {units.map(unit => (
                                    <tr key={unit._id} className={unit.duration && unit.startDate && unit.endDate ? 'bg-green-100' : ''}>
                                        <td>{unit.name}</td>
                                        <td>
                                            <input
                                                type="text"
                                                value={unit.duration || ''}
                                                onChange={(e) => onInputChange(sequence._id, unit._id, 'duration', e.target.value)}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="date"
                                                value={unit.startDate || ''}
                                                onChange={(e) => onInputChange(sequence._id, unit._id, 'startDate', e.target.value)}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="date"
                                                value={unit.endDate || ''}
                                                onChange={(e) => onInputChange(sequence._id, unit._id, 'endDate', e.target.value)}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : 'Loading units...'}
                </div>
            </div>
        </div>
    );
};

export default Task;
