import React, { useState } from "react";
import { Dropdown } from "react-bootstrap"; // Using Bootstrap for dropdown
import "./users.css";
function CustomDropdown({ options, handleInputChange, errors }) {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleSelect = (eventKey) => {
    const option = options.find((opt) => opt.value === eventKey);
    setSelectedOption(option);

    handleInputChange({
      target: {
        name: "reportingEmpIdName",
        value: option.value,
      },
    });
  };

  // Extract text from option.label for display purposes
  const getLabelText = (option) => {
    if (option && option.label && option.label.props) {
      return option.label.props.children[1].props.children;
    }

    return option ? option.label : "Enter Employee id"; // Fallback if the structure is different
  };

  return (
    <div>
      <label
        htmlFor="reportingEmpIdName"
        className="fs-10 fw-bold text-start userlableName"
      >
        Reporting Employee ID / Name
        <a style={{ color: "red" }}>*</a>
      </label>
      <br />
      <Dropdown
        onSelect={handleSelect}
        style={{ width: "81%", height: "40px" }}
      >
        <Dropdown.Toggle
          id="dropdown-basic"
          style={{
            color: "black",
            backgroundColor: "#F3F3F3",
            width: "100%",
            textAlign: "left",
            height: "70px",
            border: " 1px solid rgba(151, 151, 151, 0.25)",
          }}
          className="no-caret"
        >
          {getLabelText(selectedOption)}
        </Dropdown.Toggle>

        <Dropdown.Menu
          style={{
            width: "100%",
            zIndex: 99999,
            maxHeight: "200px",
            overflowY: "auto",
          }}
        >
          {options.map((option) => (
            <Dropdown.Item
              key={option.value}
              eventKey={option.value}
              style={{ width: "100%", zIndex: 99999 }} // Added z-index to the items
            >
              {/* Only render the items with user icons */}
              {option.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <span style={{ color: "red" }}>{errors.reportingEmpIdName}</span>
    </div>
  );
}

export default CustomDropdown;
