import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar/Header";
import HeroSection from "./HeroSection";
import { useRecoilState } from "recoil";
import { siteId } from '../../atoms';
import SelectSite from '../../Utilities/SelectSite';

const Tracker = ({ ratio }) => {

  const [site, setSite] = useRecoilState(siteId)

  
  

  return (
<>
<div>
     
      {site === '' ?
      <>
       <SelectSite />
      </>
       : 
<>
    <HeroSection currentPoints={"10"} totalPoints={"100"}/>
    </>
}
</div>
    </>
  );
};
export default Tracker;

