import React, { useState, useEffect } from "react";
import { TableComponent, Searchbar } from "../../Widgets/CommonWidgets";
import { WebApimanager } from "../../WebApiManager";
import { siteId, userInfo } from "../../atoms";
import { useRecoilValue } from "recoil";
import { ButtonBlue } from "../../Widgets/Buttons";
import swal from "sweetalert";
import CreateNotification from "./CreateNotification";
import { Loader } from "../../Widgets/notificationFeedbacks";
import SelectSite from "../../Utilities/SelectSite";
import { useRecoilState } from "recoil";
import TableWrapper from "../../Utilities/TableWrapper";
import * as Icon from "react-bootstrap-icons";
import { Tabs, Tab, Typography, Box } from "@mui/material";

const Notifications = () => {
  const webApi = new WebApimanager();
  const currUserInfo = useRecoilValue(userInfo);
  const [tableData, setTableData] = useState([]);
  const [notificationsData, setNotificationsData] = useState([]);
  const [openNotifForm, setOpenNotifForm] = useState(false);
  const [showNotifs, setNotifs] = useState({});
  const [selectedRowIndex, setSelectedRowIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState("received");
  const [site, setSite] = useRecoilState(siteId);
  const [searchTerm, setSearchTerm] = useState("");
  const notifTabs = [
    { label: "Received", nickName: "received" },
    { label: "Sent", nickName: "sent" },
  ];

  useEffect(() => {
    gettingAfterComposing();
  }, [activeMenuItem]);

  const gettingAfterComposing = () => {
    if (activeMenuItem === "received") {
      getNotifications();
    } else {
      getSentNotifications();
    }
  };
  const getNotifications = () => {
    setIsLoading(true);
    webApi
      .get(`api/mail?type=received`)
      .then((res) => {
        setIsLoading(false);
        if (res.data.status === "success") {
          const receivedMail = res.data.data.receivedMail || [];
          setTableData(receivedMail);
          setNotifs(receivedMail[0] || {});
          setSelectedRowIndex(0);
        } else {
          console.error("Failure", res.data.errormessage || "Unexpected error");
        }
      })
      .catch(handleApiError);
  };

  const getSentNotifications = () => {
    setIsLoading(true);
    webApi
      .get(`api/mail?type=sent`)
      .then((res) => {
        setIsLoading(false);
        if (res.data.status === "success") {
          const sentMail = res.data.data.sentMail || [];
          setTableData(sentMail);
          setNotifs(sentMail[0] || {});
          setSelectedRowIndex(0);
        } else {
          console.error("Failure", res.data.errormessage || "Unexpected error");
        }
      })
      .catch(handleApiError);
  };

  const handleApiError = (error) => {
    const errorMessage =
      error.customErrorMessage ||
      "Oops! Unable to get the Notifications, Please try again later.";
    swal("Error", errorMessage, "error");
  };
  const notifTableActions = [
    {
      label: <Icon.Trash size={20} />,
      onClick: (row) => deleteNotifications(row),
    },
  ];

  const handleNotificationClick = (row, index) => {
    console.log("read", row._id);
    setNotifs(row);
    setSelectedRowIndex(index);
    if (!row.isRead) {
      webApi
        .put(`api/mail/read/${row._id}`)
        .then((res) => {
          if (res.data.status === "success") {
            gettingAfterComposing();
          } else {
            console.error(
              "Failure",
              res.data.errormessage || "Unexpected error"
            );
          }
        })
        .catch(handleApiError);
    }
  };

  // const deleteNotifications = (notif) => {
  //   console.log("Deleting notification:", notif); // Debug log
  //   console.log('delete',notif._id)
  //   swal({
  //     title: "Are you sure?",
  //     text: "You want to delete this Notification!",
  //     icon: "warning",
  //     buttons: ["Cancel", "Yes, delete it!"],
  //     dangerMode: true,
  //   }).then((confirmed) => {
  //     if (confirmed) {
  //       webApi.delete(`api/mail/delete/${notif._id}`)
  //         .then((res) => {
  //           if (res.data.status === "success") {
  //             gettingAfterComposing();
  //             swal("Success", "Notification deleted successfully", "success");
  //           } else {
  //             swal("Failure", res.data.errormessage || "Unexpected error", "error");
  //           }
  //         })
  //         .catch(handleApiError); // Handles API errors
  //     }
  //   });
  // };
  const deleteNotifications = (notif) => {
    console.log("Deleting notification:", notif); // Debug log
    console.log("delete", notif._id);

    swal({
      title: "Are you sure?",
      text: "You want to delete this Notification!",
      icon: "warning",
      buttons: ["Cancel", "Yes, delete it!"],
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        webApi
          .delete(`api/mail/delete/${notif._id}`)
          .then((res) => {
            console.log("DELETE Response:", res); // Log DELETE response

            // Check for specific status code
            if (res.status === 204) {
              gettingAfterComposing(); // Refresh notifications
              swal("Success", "Notification deleted successfully", "success");
            } else {
              // Handle unexpected status codes
              console.error(
                "Delete Error:",
                res.data.errormessage || "Unexpected error during deletion"
              );
              swal(
                "Failure",
                res.data.errormessage || "Unexpected error during deletion",
                "error"
              );
            }
          })
          .catch((error) => {
            console.error("Error:", error); // Log error
            swal(
              "Failure",
              "Unexpected error occurred during deletion",
              "error"
            ); // Generic error message
          });
      }
    });
  };

  const handleTabClick = (e, tabNickName) => {
    e.preventDefault();
    setActiveMenuItem(tabNickName);
    setSearchTerm("");
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  const filteredData = tableData.filter((row) => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    const matchesSubject = row.subject
      ?.toLowerCase()
      .includes(lowerCaseSearchTerm);
    const matchesMessage = row.message
      ?.toLowerCase()
      .includes(lowerCaseSearchTerm);
    const matchesSentBy =
      `${row.sentBy?.firstName?.toLowerCase()} ${row.sentBy?.lastName?.toLowerCase()}`.includes(
        lowerCaseSearchTerm
      );
    const matchesSentTo =
      `${row.sentTo?.firstName?.toLowerCase()} ${row.sentTo?.lastName?.toLowerCase()}`.includes(
        lowerCaseSearchTerm
      );

    return matchesSubject || matchesMessage || matchesSentBy || matchesSentTo;
  });

  return (
    <>
      <div>
        {site === "" ? (
          <>
            <SelectSite />
          </>
        ) : (
          <>
            {openNotifForm && (
              <CreateNotification
                trigger={openNotifForm}
                setTrigger={setOpenNotifForm}
                getAllNotifs={gettingAfterComposing}
              />
            )}

            <Loader open={isLoading} />

            <div className="m-[60px]">
              {/* <span className="flex justify-center items-center bg-[rgb(228,115,49)] text-white font-semibold text-center rounded-lg h-12">
          Notifications
        </span> */}
              <TableWrapper
                children={
                  <>
                    <div
                      style={{
                        display: "flex",
                        marginLeft: "5px",
                        marginRight: "5px",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <h3
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "20px",
                        }}
                      >
                        Notification
                      </h3>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div
                          style={{
                            position: "relative",
                            width: "250px",
                            paddingLeft: "5px",
                            height: "50px",
                            right: "10px",
                          }}
                        >
                          <input
                            placeholder="Search Notifications"
                            value={searchTerm}
                            onChange={handleSearch}
                            style={{
                              paddingLeft: "20px",
                              borderRadius: "6px",
                              border: "1px solid var(--primary-color)",
                              width: "250px",
                              height: "40px",
                            }}
                          />
                          <Icon.Search
                            style={{
                              position: "absolute",
                              right: "-3px",
                              top: "40%",
                              transform: "translateY(-50%)",
                              marginRight: "4px",
                              color: "#ACACAC",
                            }}
                          />
                        </div>
                        <div>
                          <ButtonBlue
                            id="compose"
                            btnName="compose"
                            dataCy="compose"
                            name="Compose"
                            onClick={() => setOpenNotifForm(true)}
                            className="flex justify-center items-center rounded-1g h-10 mr-3 px-3 w-auto text-white font-bold bg-red-600 text-sm hover:bg-red-700 focus:bg-red-600 border-transparent"
                          />
                        </div>
                      </div>
                    </div>

                    <section className="flex justify-around items-center h-10 font-semibold text-gray-600 cursor-pointer border-blue-52 w-52">
                      {notifTabs.map((tab, index) => (
                        <button
                          key={index}
                          id={tab.nickName}
                          name={tab.nickName}
                          data-cy={tab.nickName}
                          style={{
                            textTransform: "none",
                            color: "#131313",
                            fontWeight: "bold",
                            borderBottom:
                              activeMenuItem === tab.nickName
                                ? "2px solid #e47331"
                                : "none",
                            padding: "8px 16px",
                            cursor: "pointer",
                            outline: "none",
                            transition: "background-color 0.3s, color 0.3s",
                            whiteSpace: "nowrap",
                          }}
                          onClick={(e) => handleTabClick(e, tab.nickName)}
                        >
                          {tab.label}
                        </button>
                      ))}
                    </section>

                    <div>
                      <TableComponent
                        tableId="NotificationsTable"
                        data={searchTerm ? filteredData : tableData}
                        columns={[
                          {
                            key: "from",
                            header:
                              activeMenuItem === "received" ? "From" : "To",
                            render: (row) => {
                              const user =
                                activeMenuItem === "received"
                                  ? row.sentBy
                                  : row.sentTo;
                              return `${user?.firstName || ""} ${
                                user?.lastName || ""
                              }`;
                            },
                            getCellStyles: (row) =>
                              row.isRead ? {} : { fontWeight: "600" },
                          },
                          {
                            key: "subject",
                            header: "Subject",
                            getCellStyles: (row) =>
                              row.isRead ? {} : { fontWeight: "600" },
                          },
                          {
                            key: "message",
                            header: "Message",
                            getCellStyles: (row) =>
                              row.isRead ? {} : { fontWeight: "600" },
                          },
                        ]}
                        selectedRowIndex={selectedRowIndex}
                        dataOnClick={(row, index) =>
                          handleNotificationClick(row, index)
                        }
                        actionHeader="Actions"
                        actions={notifTableActions}
                        initialSort={{ key: "date", direction: "desc" }}
                        rowStyles={(row, index) => ({
                          backgroundColor:
                            index % 2 === 0
                              ? "var(--table-oddrow-color)"
                              : "var(--table-evenrow-color)",
                        })}
                      />

                      {showNotifs && (
                        <section className="m-2 mt-4 rounded-lg p-5 bg-slate-100 border border-gray-200">
                          <p className="text-gray-500">
                            Subject:{" "}
                            <span className="text-black">
                              {showNotifs.subject || "-"}
                            </span>
                          </p>
                          <p className="text-gray-500">
                            Message:{" "}
                            <span className="text-black">
                              {showNotifs.message || "-"}
                            </span>
                          </p>
                          <p className="text-gray-500">
                            {activeMenuItem === "received"
                              ? "Sent To"
                              : "Sent By"}
                            :
                            <span className="text-black">
                              {activeMenuItem === "received"
                                ? showNotifs.sentTo
                                  ? `${showNotifs.sentTo.firstName || "-"} ${
                                      showNotifs.sentTo.lastName || "-"
                                    }`
                                  : "-"
                                : showNotifs.sentBy
                                ? `${showNotifs.sentBy.firstName || "-"} ${
                                    showNotifs.sentBy.lastName || "-"
                                  }`
                                : "-"}
                            </span>
                          </p>
                        </section>
                      )}
                    </div>
                  </>
                }
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Notifications;
