import React, { useState, useEffect, useCallback } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Nav, Tab } from "react-bootstrap";
import Navbar from "../Navbar/Header";
import PnmTable from "./PnmTable";
import * as Icon from "react-bootstrap-icons";
import { Dialog, DialogContent } from "@mui/material";
import { WebApimanager } from "../../WebApiManager";
import { RxCross1 } from "react-icons/rx";
import { FaAngleDown } from "react-icons/fa6";
import { FaAngleUp } from "react-icons/fa6";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import AssetCodeCreation from "./AssetCodeCreation";
import Tabs from "./Tabs";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddnewPnm from "./AddnewPnm/AddnewPnm";
import { useRecoilState } from "recoil";
import { siteId } from "../../atoms";
import SelectSite from "../../Utilities/SelectSite";
import Footer from "../Footer/Footer";
import CustomTabs from "./Tabs";
import TabButton from "./PNMAssets/TabButtons";
import styles from "../PNM/PnmTable.module.css";

const Pnm = () => {
  const webApi = new WebApimanager();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showMachineryDetails, setShowMachineryDetails] = useState(false);
  const [showVehicleDetails, setShowVehicleDetails] = useState(false);
  const [showPowerToolsDetails, setShowPowerToolsDetails] = useState(false);
  const [showDistributionBoxDetails, setShowDistributionBoxDetails] =
    useState(false);
  const [site, setSite] = useRecoilState(siteId);

  const [formData, setFormData] = useState({
    sCode: "",
    subCode: "",
    Name: "",
    brandName: "",
    fuelornot: "",
    type: "",
    ownorHire: "",
    preServiceDate: "",
    nextServiceDate: "",
  });
  const [errors, setErrors] = useState({});
  const [PnmList, setPnmList] = useState([]);
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = () => {
    let Valid = true;
    const newErrors = {};
    if (!formData.sCode.trim()) {
      newErrors.sCode = "SCode  is required";
      Valid = false;
    }
    if (!formData.subCode.trim()) {
      newErrors.subCode = "SubCode  is required";
      Valid = false;
    }
    if (!formData.Name.trim()) {
      newErrors.Name = "Name is required";
      Valid = false;
    }
    if (!formData.brandName.trim()) {
      newErrors.brandName = "Brand Name is required";
      Valid = false;
    }
    if (!formData.fuelornot.trim()) {
      newErrors.fuelornot = "Fuel Or Not Field is required";
      Valid = false;
    }
    if (!formData.type.trim()) {
      newErrors.type = "Type Field is required";
      Valid = false;
    }
    if (!formData.ownorHire.trim()) {
      newErrors.ownorHire = "Own Or Hire Field is required";
      Valid = false;
    }
    if (!formData.preServiceDate.trim()) {
      newErrors.preServiceDate = "PreService Date is required";
      Valid = false;
    }
    if (!formData.nextServiceDate.trim()) {
      newErrors.nextServiceDate = "NextService Date is required";
      Valid = false;
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    }
    if (Valid) {
      setApiProcessing({ loader: true, message: "Submitting..." });
      const requestPayload = {
        SCode: formData.sCode,
        SubCode: formData.subCode,
        Name: formData.Name,
        BrandName: formData.brandName,
        FuelOrNot: formData.fuelornot,
        Type: formData.type,
        OwnOrHire: formData.ownorHire,
        PreService: formData.preServiceDate,
        NextService: formData.nextServiceDate,
      };

      webApi
        .post("api/pnm", requestPayload)
        .then((response) => {
          setOpen(false);
          setApiProcessing({ loader: false, message: "" });
        })
        .catch((error) => {
          console.error("There was an error!", error);
          setApiProcessing({ loader: false, message: "" });
        });
    } else {
      const newPnmItem = { ...formData };
      setPnmList([...PnmList, newPnmItem]);
      setFormData({
        sCode: "",
        subCode: "",
        Name: "",
        brandName: "",
        fuelornot: "",
        type: "",
        ownorHire: "",
        preServiceDate: "",
        nextServiceDate: "",
      });
      setErrors({});
      setOpen(false);
      window.location.reload();
    }
  };

  useEffect(() => {
    webApi
      .get("api/pnm")
      .then((response) => {
        if (response.data && Array.isArray(response.data.data.machinary)) {
          setData(response.data.data.machinary);
        } else {
          setError("Unexpected data format");
          console.error("Unexpected data format:", response.data);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
        setError("There was an error fetching the data");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  // Your existing state variables and functions...

  // Function to handle search input change
  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const [selectedTab, setSelectedTab] = useState("Own");
  useEffect(() => {
    const filtered = data.filter((item) =>
      `${item.scode}
  ${item.subCode}
  ${item.Name}
  ${item.brandName}
  ${item.fuelornot}
  ${item.type}
  ${item.ownorHire}
    ${new Date(item.preServiceDate).toLocaleDateString()}
    ${new Date(item.nextServiceDateServiceDate).toLocaleDateString()}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
    setFilteredData(filtered);
  }, [data, searchTerm]);
  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (newTabNumber) => {
    setCurrentTab(newTabNumber); // Update the state with the new tab number
  };
  return (
    <>
      <div className="bg-[#F3F3F3]">
        {site === "" ? (
          <>
            <SelectSite />
          </>
        ) : (
          <>
            <Tabs onTabChange={handleTabChange} />
            {/* Own-hire */}
            {currentTab == 0 && (
              <div style={{ marginTop: "30px" }}>
                {/* Dialog starts */}
                <Dialog
                  open={open}
                  onClose={handleClose}
                  maxWidth="md"
                  fullWidth
                  PaperProps={{
                    style: { borderRadius: 20, fontFamily: "Poppins" },
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      margin: "0px",
                    }}
                  >
                    {" "}
                    <p
                      style={{
                        marginLeft: "25px",
                        fontSize: "25px",
                        marginTop: "20px",
                      }}
                    >
                      Add PNM List
                    </p>
                    <RxCross1
                      size={20}
                      style={{
                        marginLeft: "auto",
                        cursor: "pointer",
                        marginRight: "25px",
                      }}
                      onClick={handleClose}
                    />
                  </div>
                  <DialogContent
                    style={{
                      padding: "25px",
                      minWidth: "300px",
                      minHeight: "300px",
                    }}
                  >
                    <section
                      className="grid gap-y-6 w-5/6 mb-4 md:w-11/12 mt-4"
                      style={{ marginLeft: "80px", marginRight: "80px" }}
                    >
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                          gap: "20px",
                        }}
                      >
                        <div>
                          <label
                            htmlFor="sCode"
                            className="fs-10 fw-bold text-start py-2"
                          >
                            SCode
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            id="sCode"
                            name="sCode"
                            value={formData.sCode}
                            onChange={handleChange}
                            placeholder="Enter your SCode"
                            className="form-control fs-12"
                            style={{ width: "100%" }}
                          />
                          {errors.sCode && (
                            <p style={{ color: "red", fontSize: "12px" }}>
                              {errors.sCode}
                            </p>
                          )}
                        </div>
                        <div>
                          <label
                            htmlFor="subCode"
                            className="fs-10 fw-bold text-start py-2"
                          >
                            SubCode
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            id="subCode"
                            name="subCode"
                            value={formData.subCode}
                            onChange={handleChange}
                            placeholder="Enter your SubCode"
                            className="form-control fs-12"
                            style={{ width: "100%" }}
                          />
                          {errors.subCode && (
                            <p style={{ color: "red", fontSize: "12px" }}>
                              {errors.subCode}
                            </p>
                          )}
                        </div>

                        <div>
                          <label
                            htmlFor="Name"
                            className="fs-10 fw-bold text-start py-2"
                          >
                            Name
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            id="Name"
                            name="Name"
                            value={formData.Name}
                            onChange={handleChange}
                            placeholder="Enter your Machinery Name"
                            className="form-control fs-12"
                            style={{ width: "100%" }}
                          />
                          {errors.Name && (
                            <p style={{ color: "red", fontSize: "12px" }}>
                              {errors.Name}
                            </p>
                          )}
                        </div>
                        <div>
                          <label
                            htmlFor="brandName"
                            className="fs-10 fw-bold text-start py-2"
                          >
                            Brand Name
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            id="brandName"
                            name="brandName"
                            value={formData.brandName}
                            onChange={handleChange}
                            placeholder="Enter your Brand Name"
                            className="form-control fs-12"
                            style={{ width: "100%" }}
                          />
                          {errors.brandName && (
                            <p style={{ color: "red", fontSize: "12px" }}>
                              {errors.brandName}
                            </p>
                          )}
                        </div>
                        <div>
                          <label
                            htmlFor="fuelornot"
                            className="fs-10 fw-bold text-start py-2"
                          >
                            Fuel/Not
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            type="text"
                            id="fuelornot"
                            name="fuelornot"
                            value={formData.machineryName}
                            onChange={handleChange}
                            className="form-control fs-12"
                            style={{ width: "100%" }}
                          >
                            <option value="">Select A Option</option>
                            <option value="Fuel"> Fuel</option>
                            <option value="NotFuel">Not Fuel</option>
                          </select>
                          {errors.fuelornot && (
                            <p style={{ color: "red", fontSize: "12px" }}>
                              {errors.fuelornot}
                            </p>
                          )}
                        </div>
                        <div>
                          <label
                            htmlFor="type"
                            className="fs-10 fw-bold text-start py-2"
                          >
                            Type
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            type="text"
                            id="type"
                            name="type"
                            value={formData.type}
                            onChange={handleChange}
                            className="form-control fs-12"
                            style={{ width: "100%" }}
                          >
                            <option value="">Select A Option</option>
                            <option value="Machinery"> Machinery</option>
                            <option value="Vechile">Vechile</option>
                            <option value="HandTools">Hand Tools</option>
                          </select>
                          {errors.type && (
                            <p style={{ color: "red", fontSize: "12px" }}>
                              {errors.type}
                            </p>
                          )}
                        </div>

                        <div>
                          <label
                            htmlFor="ownorHire"
                            className="fs-10 fw-bold text-start py-2"
                          >
                            Own/Hire
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            type="text"
                            id="ownorHiire"
                            name="ownorHire"
                            value={formData.ownorHire}
                            onChange={handleChange}
                            className="form-control fs-12"
                            style={{ width: "100%" }}
                          >
                            <option value="">Select A Option</option>
                            <option value="Own"> Own</option>
                            <option value="Hire">Hire</option>
                          </select>
                          {errors.ownorHire && (
                            <p style={{ color: "red", fontSize: "12px" }}>
                              {errors.ownorHire}
                            </p>
                          )}
                        </div>
                        <div>
                          <label
                            htmlFor="preServiceDate"
                            className="fs-10 fw-bold text-start py-2"
                          >
                            PreService Date
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="date"
                            id="preServiceDate"
                            name="preServiceDate"
                            value={formData.preServiceDate}
                            onChange={handleChange}
                            className="form-control fs-12"
                            style={{ width: "100%" }}
                          />
                          {errors.preServiceDate && (
                            <p style={{ color: "red", fontSize: "12px" }}>
                              {errors.preServiceDate}
                            </p>
                          )}
                        </div>
                        <div>
                          <label
                            htmlFor="nextServiceDate"
                            className="fs-10 fw-bold text-start py-2"
                          >
                            NextService Date
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="date"
                            id="nextServiceDate"
                            name="nextServiceDate"
                            value={formData.nextServiceDate}
                            onChange={handleChange}
                            className="form-control fs-12"
                            style={{ width: "100%" }}
                          />
                          {errors.nextServiceDate && (
                            <p style={{ color: "red", fontSize: "12px" }}>
                              {errors.nextServiceDate}
                            </p>
                          )}
                        </div>

                        <div
                          style={{
                            marginTop: "45px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <button
                            style={{
                              padding: "8px",
                              borderRadius: "5px",
                              border: "1px solid lightgray",
                              backgroundColor: "transparent",
                              width: "100px",
                            }}
                            onMouseOver={(e) =>
                              (e.target.style.backgroundColor = "lightgray")
                            }
                            onMouseOut={(e) =>
                              (e.target.style.backgroundColor = "transparent")
                            }
                            onClick={handleClose}
                          >
                            Cancel
                          </button>
                          <button
                            style={{
                              marginLeft: "50px",
                              padding: "10px",
                              borderRadius: "5px",
                              width: "100px",
                            }}
                            className="btn btn-primary"
                            onClick={handleSubmit}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </section>
                  </DialogContent>
                </Dialog>
                {/* Dialog Ends */}
                <div></div>
                <div>
                  <Tab.Container id="tabs-example" defaultActiveKey="first">
                    <div>
                      <div className={styles.selectTabs}>
                        {/* <Nav className="justify-content-center" style={{background:'none !important'}}>
                        <Nav.Item style={{ width: '45%' }}>
                        <Nav.Link eventKey="first" className={styles.hireButton}>Own</Nav.Link>
                        </Nav.Item>
                        <Nav.Item style={{ width: '45%' }}>
                        <Nav.Link eventKey="second" className={styles.hireButton}>Hire</Nav.Link>
                          </Nav.Item>
                        </Nav> */}
                        <div>
                          <TabButton
                            buttonText="Own"
                            selected={selectedTab === "Own"}
                            onClick={() => setSelectedTab("Own")}
                          />
                          <TabButton
                            buttonText="Hire"
                            selected={selectedTab === "Hire"}
                            onClick={() => setSelectedTab("Hire")}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        ></div>
                      </div>
                    </div>
                    <div className={styles.tablesContainer}>
                      <section className={styles.container}>
                        <header className={styles.header}>
                          <h3 className={styles.title}>Machinery</h3>
                          {/* <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/015433747a12472c8a1b493d80bd60f61e5268ebf5cd9df94f229c4bf46e7e4b?apiKey=860967788028437b8a0095d5a96bdd20&&apiKey=860967788028437b8a0095d5a96bdd20"
                            className={styles.icon}
                            alt=""
                          /> */}
                          {!showMachineryDetails ? (
                            <FaAngleDown
                              className=" cursor-pointer"
                              onClick={() =>
                                setShowMachineryDetails((prev) => !prev)
                              }
                            />
                          ) : (
                            <FaAngleUp
                              className=" cursor-pointer"
                              onClick={() =>
                                setShowMachineryDetails((prev) => !prev)
                              }
                            />
                          )}
                        </header>
                        {showMachineryDetails && (
                          <PnmTable filterByOwnOrHire="own" filterByType="hh" />
                        )}
                      </section>
                      <section className={styles.container}>
                        <header className={styles.header}>
                          <h3 className={styles.title}>Vehicle</h3>
                          {/* <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/015433747a12472c8a1b493d80bd60f61e5268ebf5cd9df94f229c4bf46e7e4b?apiKey=860967788028437b8a0095d5a96bdd20&&apiKey=860967788028437b8a0095d5a96bdd20"
                            className={styles.icon}
                            alt=""
                          /> */}
                          {!showVehicleDetails ? (
                            <FaAngleDown
                              className=" cursor-pointer"
                              onClick={() =>
                                setShowVehicleDetails((prev) => !prev)
                              }
                            />
                          ) : (
                            <FaAngleUp
                              className=" cursor-pointer"
                              onClick={() =>
                                setShowVehicleDetails((prev) => !prev)
                              }
                            />
                          )}
                        </header>
                        {showVehicleDetails && (
                          <PnmTable filterByOwnOrHire="own" filterByType="hh" />
                        )}
                      </section>
                      <section className={styles.container}>
                        <header className={styles.header}>
                          <h3 className={styles.title}>Power Tools</h3>
                          {/* <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/015433747a12472c8a1b493d80bd60f61e5268ebf5cd9df94f229c4bf46e7e4b?apiKey=860967788028437b8a0095d5a96bdd20&&apiKey=860967788028437b8a0095d5a96bdd20"
                            className={styles.icon}
                            alt=""
                          /> */}

                          {!showPowerToolsDetails ? (
                            <FaAngleDown
                              className=" cursor-pointer"
                              onClick={() =>
                                setShowPowerToolsDetails((prev) => !prev)
                              }
                            />
                          ) : (
                            <FaAngleUp
                              className=" cursor-pointer"
                              onClick={() =>
                                setShowPowerToolsDetails((prev) => !prev)
                              }
                            />
                          )}
                        </header>
                        {showPowerToolsDetails && (
                          <PnmTable filterByOwnOrHire="own" filterByType="hh" />
                        )}
                      </section>
                      <section className={styles.container}>
                        <header className={styles.header}>
                          <h3 className={styles.title}>Distribution Box</h3>
                          {/* <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/015433747a12472c8a1b493d80bd60f61e5268ebf5cd9df94f229c4bf46e7e4b?apiKey=860967788028437b8a0095d5a96bdd20&&apiKey=860967788028437b8a0095d5a96bdd20"
                            className={styles.icon}
                            alt=""
                          /> */}
                          {!showDistributionBoxDetails ? (
                            <FaAngleDown
                              className=" cursor-pointer"
                              onClick={() =>
                                setShowDistributionBoxDetails((prev) => !prev)
                              }
                            />
                          ) : (
                            <FaAngleUp
                              className=" cursor-pointer"
                              onClick={() =>
                                setShowDistributionBoxDetails((prev) => !prev)
                              }
                            />
                          )}
                        </header>
                        {showDistributionBoxDetails && (
                          <PnmTable filterByOwnOrHire="own" filterByType="hh" />
                        )}
                      </section>
                    </div>
                  </Tab.Container>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};
export default Pnm;
