import logo from '../../Images/TMLOGO.png'
import React, { useState , useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import { BG_COLOUR, Button_COLOUR } from '../../Utilities/Constants';
import { useRecoilState } from "recoil";
import { userInfo,profileImageUrl, siteId } from '../../atoms';
import NavbarTile from './NavbarTile';
import { MdNotifications } from 'react-icons/md';
import { useNavigate } from 'react-router-dom'; 
import AdminNavbarTile from './AdminNavbarTile';
import { WebApimanager } from '../../WebApiManager';

const Navbar = () => {
  let webApi = new WebApimanager();
  const [activeItem, setActiveItem] = useState(null);
  const location = useLocation();
  const [currentUserInfo, setCurrentUserInfo] = useRecoilState(userInfo); 
  const [profileImage, setProfileImage] = useRecoilState(profileImageUrl);
  const [userRole, setuserRole] = useState(currentUserInfo.role || ""); 
  const [showDropdown, setShowDropdown] = useState(false); 
  // const userInfoState = useRecoilValue(userInfo); // Get the entire userInfo atom
  const [site, setSite] = useRecoilState(siteId);
  const navigate = useNavigate();


  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await webApi.get("api/notification");
        console.log("API response:", response);
  
        if (response.data && Array.isArray(response.data.data.notifications)) {
          // Extract unread notifications count
          const unreadNotifications = response.data.data.notifications.filter(notification => !notification.isRead);
          setUnreadCount(unreadNotifications.length);
          console.log('unread',unreadCount);
        } else {
          console.error('Failed to fetch notifications:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };
  
    fetchNotifications();
  }, []);
  

   
  useEffect(() => {
    console.log('User Info State:', currentUserInfo); // Log the entire userInfo state
    console.log('User Role:', userRole);
    const pageName = location.pathname.substring(1);
    setActiveItem(pageName);
  }, [location.pathname]);
  const handleItemClick = (itemName) => {
    setActiveItem(itemName === activeItem ? null : itemName);
  };
  const handleProfileImageClick = () => {
    setShowDropdown(!showDropdown);
  };
  const containerStyle = {
    position: 'relative',
  };

  const dropdownStyle = {
    position: 'absolute',
    top: '100%',
    left: '-80px', 
    backgroundColor: '#fff',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    border: '1px solid #ddd',
    borderRadius: '5px',
    padding: '4px',
    width: '120px',
    display: showDropdown ? 'block' : 'none',
  };

  const dropdownItemStyle = {
    padding: '8px',
    cursor: 'pointer',
  };

  const dropdownItemHoverStyle = {
    backgroundColor: '#f5f5f5',
  };
  const handleNotificationClick = () => {
    navigate('/systemnotifications'); // Adjust the path as needed
  };

  const handleLogout = () => {

  
  setTimeout(() => {
    localStorage.removeItem('recoil-persist:userInfo');
    console.log('Local storage after reset:', localStorage.getItem('recoil-persist:userInfo'));

    // Navigate to home after a short delay
    navigate('/');
  }, 100);
};
  
  return(
    <>
    <nav style={{ background:'#4D4D4D', position: 'fixed', zIndex: '10', width: '100%', height: '50px', padding: '12px', display: 'flex', justifyContent: 'end', gap: '30px', paddingLeft: '60px', paddingRight: '60px' }}>
       <AdminNavbarTile path={'planner'} title={'Planner'} activeItem={activeItem} handleItemClick={handleItemClick} />
       <AdminNavbarTile path={'pnm'} title={'P&M'} activeItem={activeItem} handleItemClick={handleItemClick} />
       <AdminNavbarTile path={'hr'} title={'HR'}  activeItem={activeItem} handleItemClick={handleItemClick}/>
       <AdminNavbarTile title={'EHS'} activeItem={activeItem} handleItemClick={handleItemClick}/>
       <AdminNavbarTile title={'QS'} activeItem={activeItem} handleItemClick={handleItemClick}/>
       <AdminNavbarTile title={'QA/QC'} activeItem={activeItem} handleItemClick={handleItemClick}/>
       <AdminNavbarTile title={'Admin'} activeItem={activeItem} handleItemClick={handleItemClick}/>
       <AdminNavbarTile title={'Store'} activeItem={activeItem} handleItemClick={handleItemClick}/>
    </nav>
    <nav className="flex justify-between items-center" style={{ background:'white', position: 'fixed', zIndex: '10', width: '100%', top: '50px', padding: '6px 60px' }}>
      <a href="tracker" id="tracker" className="flex gap-2 items-center no-underline focus:outline-none">
        <img src={logo} className="h-12 object-cover max-w-18 max-h-12" alt="logo" />
        {/* <span className="text-lg font-bold text-gray-800"> Borcelle Constructions</span> */}
      </a>
       <div id="nav-menu" className= "lg:flex gap-[40px] no-underline focus:outline-none"  >
            <NavbarTile title={'Dashboard'} path={'dashboard'} activeItem={activeItem} handleItemClick={handleItemClick} />
            <NavbarTile title={'WorkStatus'} path={'tracker'} activeItem={activeItem} handleItemClick={handleItemClick} />
            {/* <NavbarTile title={'Task Tracker'} path={'task-tracker'} activeItem={activeItem} handleItemClick={handleItemClick} /> */}
            {userRole !== 'HR' && (<NavbarTile title={'Checklist'} path={'checklist'} activeItem={activeItem} handleItemClick={handleItemClick} /> )}
            {/* {userRole !== 'Engineer' && ( */}
                  <NavbarTile title={'Drawing'} path={'drawing'} activeItem={activeItem} handleItemClick={handleItemClick} /> 
                  {/* )} */}
            {/* <NavbarTile title={'Planner'} path={'planner'} activeItem={activeItem} handleItemClick={handleItemClick} /> */}
            {/* <NavbarTile title={'P&M'} path={'pnm'} activeItem={activeItem} handleItemClick={handleItemClick} /> */}
            {/* <NavbarTile title={'Users'} path={'users'} activeItem={activeItem} handleItemClick={handleItemClick} /> */}
            <NavbarTile title={'Sites'} path={'sites'} activeItem={activeItem} handleItemClick={handleItemClick} />
            <NavbarTile title={'Space'} path={'space'} activeItem={activeItem} handleItemClick={handleItemClick} />
            <NavbarTile title={'Users'} path={'users'} activeItem={activeItem} handleItemClick={handleItemClick} />
            <NavbarTile title={'Mails'} path={'notifications'} activeItem={activeItem} handleItemClick={handleItemClick} />
            <div style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
  <div style={{ position: 'relative' }}>
    <MdNotifications size={24} color="black" onClick={handleNotificationClick} />
    {unreadCount > 0 && (
      <div
        style={{
          position: 'absolute',
          top: '-5px',
          right: '-10px',
          background: 'var(--primary-color)',
          color: 'white',
          borderRadius: '50%',
          width: '18px',
          height: '18px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '12px',
        }}
      >
        {unreadCount}
      </div>
    )}
  </div>
              <div
                className="relative"
                style={containerStyle}
                onClick={handleProfileImageClick}
                onMouseEnter={() => setShowDropdown(true)}
                onMouseLeave={() => setShowDropdown(false)}
              >
              <img
                      src={
                        profileImage ||
                        "logo/Avatar.png"
                      }
                  height="30px"
                  width="30px"
                  alt="Profile"
                />
                
              {showDropdown && (
                <div style={dropdownStyle}>
                  <div style={dropdownItemStyle} onMouseEnter={(e) => e.currentTarget.style.backgroundColor = dropdownItemHoverStyle.backgroundColor} onMouseLeave={(e) => e.currentTarget.style.backgroundColor = ''} ><a href='/profile'>Edit Profile</a></div>
                  <div style={dropdownItemStyle} onMouseEnter={(e) => e.currentTarget.style.backgroundColor = dropdownItemHoverStyle.backgroundColor} onMouseLeave={(e) => e.currentTarget.style.backgroundColor = ''} onClick={handleLogout}>
                   Logout
                  </div>
                </div>
              )}
              </div>
            </div>
        </div>
      </nav>
    </>
  );
}
export default Navbar;

