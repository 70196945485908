import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { WebApimanager } from "../../../WebApiManager";
import { useRecoilState } from "recoil";
import { siteId } from "../../../atoms";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";

Chart.register(ArcElement, Tooltip, Legend);

const centerTextPlugin = {
  id: "centerText",
  afterDatasetDraw: (chart) => {
    const {
      ctx,
      chartArea: { width, height },
    } = chart;

    if (!chart.data.datasets || !chart.data.datasets[0].data.length) {
      return;
    }

    const total = chart.data.datasets[0].data.reduce(
      (acc, value) => acc + value,
      0
    );
    const percentages = chart.data.datasets[0].data.map((value) =>
      ((value / total) * 100).toFixed(1)
    );

    const meta = chart.getDatasetMeta(0);
    if (!meta.data.length) {
      return;
    }

    let startAngle = meta.data[0].startAngle;

    percentages.forEach((percentage, index) => {
      const data = meta.data[index];
      const endAngle = data.endAngle;
      const middleAngle = (endAngle + startAngle) / 2;

      const innerRadius = data.innerRadius;
      const outerRadius = data.outerRadius;
      const textRadius = innerRadius + (outerRadius - innerRadius) / 2;

      ctx.font = "bold 14px poppins";
      ctx.textBaseline = "middle";
      ctx.textAlign = "center";
      ctx.fillStyle = "#FFF";

      const x = width / 2 + textRadius * Math.cos(middleAngle);
      const y = height / 2 + textRadius * Math.sin(middleAngle);
      ctx.fillText(`${percentage}%`, x, y);

      startAngle = endAngle;

      // Draw inner circle
      ctx.beginPath();
      ctx.arc(width / 2, height / 2, innerRadius, 0, 2 * Math.PI);
      ctx.fillStyle = "#FFF"; // White inner circle
      ctx.fill();

      // Draw "Total" text
      ctx.font = "20px poppins";
      ctx.textBaseline = "middle";
      ctx.textAlign = "center";
      ctx.fillStyle = "#000";
      ctx.fillText("Total", width / 2, height / 2 - 20);

      // Draw "Registered Drawing" text
      ctx.font = "16px poppins";
      ctx.fillText("RFI Registered", width / 2, height / 2);

      // Draw total number of registered drawings
      ctx.font = "16px poppins";
      ctx.fillText(total, width / 2, height / 2 + 20);
      ctx.save();

      ctx.save();
    });
  },
};

const RfiStatus = (props) => {
  let webApi = new WebApimanager();
  const [site, setSite] = useRecoilState(siteId);
  const [drawingList, setDrawingList] = useState([]);

  const fetchData = async () => {
    const response = await webApi.get(
      props.type === "architectToRo"
        ? `api/architectureToRoRequested?siteId=${site}`
        : `api/roToSiteLevelRequested/site?siteId=${site}`
    );
    console.log(response.data.data);
    setDrawingList(response.data.data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const statusCounts = (drawingList || []).reduce((acc, item) => {
    acc[item.status] = (acc[item.status] || 0) + 1;
    return acc;
  }, {});

  const data = {
    labels: Object.keys(statusCounts),
    datasets: [
      {
        data: Object.values(statusCounts),
        backgroundColor: ["#064D77", "#0670A2", "#4F9DC4", "#E6ECFA"],
        hoverOffset: 4,
        borderWidth: 2,
        borderColor: "#FFF",
      },
    ],
  };

  const options = {
    cutout: "70%",
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${context.label}: ${context.raw}`;
          },
        },
      },
    },
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "30px",
        }}
      >
        <div style={{ color: "var(--primary-color)", marginBottom: "10px" }}>
          RFI Status
        </div>
        <div style={{ width: "25vw", height: "25vw" }}>
          <Doughnut
            data={data}
            options={options}
            plugins={[centerTextPlugin]}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "20px",
            marginTop: "20px",
          }}
        >
          {Object.keys(statusCounts).map((status, index) => (
            <div
              key={status}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "15px",
                  height: "15px",
                  borderRadius: "50%",
                  backgroundColor: ["#064D77", "#0670A2", "#4F9DC4", "#E6ECFA"][
                    index
                  ],
                  marginRight: "5px",
                }}
              ></div>
              <span>{status}</span>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default RfiStatus;
