import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types"; // Import prop-types
import { Loader } from "../../../Widgets/notificationFeedbacks";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import { RxCross1 } from "react-icons/rx";
import { WebApimanager } from "../../../WebApiManager";
import { usePDF } from "react-to-pdf";
import { calculateDueDays, formatDate } from "../../../Utilities/DateUtils";
import { useRecoilState } from "recoil";
import { siteId, userInfo } from "../../../atoms";
import "./TablePage.css";

const TablePage = (props) => {
  const { isOpen, handleDialogClose, popupcontent } = props; // Provide default empty object

  const { toPDF, targetRef } = usePDF({ filename: "report.pdf" });

  const webApi = new WebApimanager();

  const [tableData, setTableData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [downloaded, setDownloaded] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [currentUserInfo, setCurrentUserInfo] = useRecoilState(userInfo);
  const [site, setSite] = useRecoilState(siteId);
  const [siteData, setSiteData] = useState([]);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 1000;

  // Calculate total pages
  const totalPages = useMemo(
    () =>
      Math.ceil(
        (Array.isArray(tableData) ? tableData.length : 0) / itemsPerPage
      ),
    [tableData.length]
  );

  // Calculate current page data
  const paginatedData = useMemo(() => {
    if (Array.isArray(tableData)) {
      const start = (currentPage - 1) * itemsPerPage;
      const end = start + itemsPerPage;
      return tableData.slice(start, end);
    }
    return [];
  }, [currentPage, tableData]);

  useEffect(() => {
    const fetchSiteData = async () => {
      try {
        const siteResponse = await webApi.get("api/sites/getAllSitesInfo");
        setSiteData(siteResponse.data.sites);
        console.log("Site Name Response:", siteResponse.data.sites);
      } catch (error) {
        console.log(error);
      }
    };
    fetchSiteData();
  }, []);

  const matchedSite = siteData.find((siteItem) => siteItem._id === site);

  const now = new Date();

  const day = now.toLocaleDateString("en-US", { day: "numeric" });
  const month = now.toLocaleDateString("en-US", { month: "long" });
  const year = now.toLocaleDateString("en-US", { year: "numeric" });

  const time = now.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  });

  useEffect(() => {
    console.log("PopupContent Details:", popupcontent);

    if (
      (popupcontent.reportType &&
        popupcontent.designConsultant &&
        popupcontent.fromDate &&
        popupcontent.toDate) ||
      (popupcontent.month && popupcontent.year) ||
      popupcontent.timePeriod
    ) {
      const fetchData = async () => {
        try {
          let apiUrl = `api/pdf/${
            props.type === "architectToRo" ? "report" : "RoReport"
          }?reportType=${popupcontent.reportType}&designDrawingConsultantId=${
            popupcontent.designConsultant
          }`;

          if (popupcontent.fromDate && popupcontent.toDate) {
            apiUrl += `&selectTimePeriod=byDate&fromDate=${popupcontent.fromDate}&toDate=${popupcontent.toDate}`;
          } else if (popupcontent.timePeriod === "fromBeginningToTillDate") {
            apiUrl += `&selectTimePeriod=${popupcontent.timePeriod}`;
          } else {
            apiUrl += `&selectTimePeriod=byMonth&month=${popupcontent.month}&year=${popupcontent.year}`;
          }

          const response = await webApi.get(apiUrl);
          const data = await response.data;
          const startDate = new Date(data.startDate)
            .toISOString()
            .split("T")[0];
          const endDate = new Date(data.endDate).toISOString().split("T")[0];

          setStartDate(startDate);
          setEndDate(endDate);
          setTableData(data);
          setLoaded(true);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchData();
    }
  }, []);

  useEffect(() => {
    if (
      popupcontent.selectViewDownload === "Download" &&
      tableData.length > 0
    ) {
      switch (popupcontent.fileFormat) {
        case "PDF":
          toPDF().then(() => {
            setDownloaded(true);
            handleDialogClose();
          });
          break;

        case "XLS":
          downloadXLS();
          break;

        case "CSV":
          downloadCSV();
          break;

        default:
          break;
      }
    }
  }, [tableData.length > 0]);

  const downloadXLS = () => {
    const xlsData = tableData.map((row, index) => ({
      "S.No": index + 1,
      "Drawing No": row.drawingNo,
      "Drawing Consultant": row.designDrawingConsultant.role,
      "Drawing Category": row.category?.category || "",
      "Drawing Title": row.drawingTitle,
      [props.type === "architectToRo"
        ? "Accepted Ro Submission Date"
        : "Accepted Site Submission Date"]:
        props.type === "architectToRo"
          ? formatDate(row.acceptedROSubmissionDate)
          : formatDate(row.acceptedSiteSubmissionDate),
      "Final Submission Date":
        (props.type === "architectToRo"
          ? formatDate(row.acceptedArchitectRevisions[0]?.softCopySubmittedDate)
          : formatDate(row.acceptedRORevisions[0]?.softCopySubmittedDate)) ||
        "N/A",
      "Due/Overdue": calculateDueDays(
        row.acceptedSiteSubmissionDate,
        row.acceptedRORevisions[0]?.softCopySubmittedDate
      ),
      Revision:
        props.type === "architectToRo"
          ? row.acceptedArchitectRevisions.length > 0
            ? row.acceptedArchitectRevisions.slice(-1)[0].revision
            : "NA"
          : row.acceptedRORevisions.length > 0
          ? row.acceptedRORevisions.slice(-1)[0].revision
          : "NA",
    }));

    const xlsx = require("xlsx");
    const worksheet = xlsx.utils.json_to_sheet(xlsData);
    const workbook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(workbook, worksheet, "Report");
    xlsx.writeFile(workbook, "report.xlsx");
    setDownloaded(true);
    handleDialogClose();
  };

  const downloadCSV = () => {
    const csvData = tableData.map((row, index) => ({
      "S.No": index + 1,
      "Drawing No": row.drawingNo,
      "Drawing Consultant": row.designDrawingConsultant.role,
      "Drawing Category": row.category?.category || "",
      "Drawing Title": row.drawingTitle,
      [props.type === "architectToRo"
        ? "Accepted Ro Submission Date"
        : "Accepted Site Submission Date"]:
        props.type === "architectToRo"
          ? formatDate(row.acceptedROSubmissionDate)
          : formatDate(row.acceptedSiteSubmissionDate),
      "Final Submission Date":
        (props.type === "architectToRo"
          ? formatDate(row.acceptedArchitectRevisions[0]?.softCopySubmittedDate)
          : formatDate(row.acceptedRORevisions[0]?.softCopySubmittedDate)) ||
        "N/A",
      "Due/Overdue": calculateDueDays(
        row.acceptedSiteSubmissionDate,
        row.acceptedRORevisions[0]?.softCopySubmittedDate
      ),
      Revision:
        props.type === "architectToRo"
          ? row.acceptedArchitectRevisions.length > 0
            ? row.acceptedArchitectRevisions.slice(-1)[0].revision
            : "NA"
          : row.acceptedRORevisions.length > 0
          ? row.acceptedRORevisions.slice(-1)[0].revision
          : "NA",
    }));

    const csvContent =
      "data:text/csv;charset=utf-8," +
      Object.keys(csvData[0]).join(",") +
      "\n" +
      csvData.map((row) => Object.values(row).join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "report.csv");
    document.body.appendChild(link);
    link.click();
    setDownloaded(true);
    handleDialogClose();
  };

  const renderTableHeader = () => {
    switch (popupcontent.reportType) {
      case "drawing":
        return (
          <tr>
            <th style={{ width: "5%", paddingLeft: "10px" }}>S.No</th>
            <th style={{ width: "10%" }}>Drawing No</th>
            <th style={{ width: "10%" }}>Drawing Consultant</th>
            <th style={{ width: "10%" }}>Drawing Category</th>
            <th style={{ width: "10%" }}>Drawing Title</th>
            <th style={{ width: "13%", paddingLeft: "10px" }}>
              {props.type === "architectToRo"
                ? "Accepted Ro Submission Date"
                : "Accepted Site Submission Date"}
            </th>
            <th style={{ width: "13%", paddingLeft: "10px" }}>
              Final Submission Date
            </th>
            <th style={{ width: "13%" }}>Due/Overdue</th>
            <th style={{ width: "10%" }}>Revision</th>
          </tr>
        );
      case "RFI":
        return (
          <tr>
            <th style={{ width: "5%", paddingLeft: "10px" }}>S.No</th>
            <th style={{ width: "10%" }}>Drawing Number</th>
            <th style={{ width: "10%" }}>Design Consultant</th>
            <th style={{ width: "10%" }}>Drawing Category</th>
            <th style={{ width: "10%" }}>Drawing Title</th>
            <th style={{ width: "13%" }}>Requested Date</th>
            <th style={{ width: "13%" }}>Expected Date</th>
            <th style={{ width: "10%" }}>Revision</th>
            <th style={{ width: "10%" }}>Status</th>
          </tr>
        );
      case "pending":
        return (
          <tr>
            <th style={{ width: "5%", paddingLeft: "10px" }}>S.No</th>
            <th style={{ width: "10%", paddingLeft: "15px" }}>Drawing No</th>
            <th style={{ width: "15%", paddingLeft: "15px" }}>
              Design Consultant
            </th>
            <th style={{ width: "10%", paddingLeft: "15px" }}>
              Drawing Category
            </th>
            <th style={{ width: "15%", paddingLeft: "15px" }}>Drawing Title</th>
            <th style={{ width: "13%", paddingLeft: "15px" }}>
              {props.type === "architectToRo"
                ? "Accepted Ro Submission Date"
                : "Accepted Site Submission Date"}
            </th>
            <th style={{ width: "10%", paddingLeft: "15px" }}>Due/Overdue</th>
          </tr>
        );
      case "register":
        return (
          <tr>
            <th style={{ width: "5%", paddingLeft: "10px" }}>S.No</th>
            <th style={{ width: "15%" }}>Drawing No</th>
            <th style={{ width: "10%" }}>Design Consultant</th>
            <th style={{ width: "15%" }}>Drawing Category</th>
            <th style={{ width: "15%" }}>Drawing Title</th>
            {props.type === "architectToRo" && (
              <th style={{ width: "15%" }}>Accepted R0 Submission Date</th>
            )}
            <th style={{ width: "15%" }}>Accepted Site Submission Date</th>
          </tr>
        );
      default:
        return null;
    }
  };

  const dataArray = Array.isArray(tableData.data) ? tableData.data : [];

  const renderTableBody = () => {
    return dataArray.map((item, index) => (
      <tr key={item.id}>
        <td style={{ paddingLeft: "15px" }}>{index + 1}</td>
        {popupcontent.reportType === "drawing" ? (
          <>
            <td>{item.drawingNo}</td>
            <td>{item.designDrawingConsultant.role}</td>
            <td>{item.category?.category || ""}</td>
            <td>{item.drawingTitle}</td>
            <td>
              {props.type === "architectToRo"
                ? formatDate(item.acceptedROSubmissionDate)
                : formatDate(item.acceptedSiteSubmissionDate)}
            </td>
            <td>
              {props.type === "architectToRo"
                ? formatDate(
                    item.acceptedArchitectRevisions[0]?.softCopySubmittedDate
                  ) || "N/A"
                : formatDate(
                    item.acceptedRORevisions[0]?.softCopySubmittedDate
                  ) || "N/A"}
            </td>
            <td>
              {props.type === "architectToRo" ? (
                <span
                  style={{
                    color:
                      new Date(item.finalSubmissionDate) < new Date()
                        ? "black"
                        : calculateDueDays(
                            item.acceptedROSubmissionDate,
                            item.acceptedArchitectRevisions[0]
                              ?.softCopySubmittedDate
                          ) >= 0
                        ? "green"
                        : "red",
                  }}
                >
                  {calculateDueDays(
                    item.acceptedROSubmissionDate,
                    item.acceptedArchitectRevisions[0]?.softCopySubmittedDate
                  ) === "-"
                    ? "-"
                    : calculateDueDays(
                        item.acceptedROSubmissionDate,
                        item.acceptedArchitectRevisions[0]
                          ?.softCopySubmittedDate
                      ) >= 0
                    ? `${calculateDueDays(
                        item.acceptedROSubmissionDate,
                        item.acceptedArchitectRevisions[0]
                          ?.softCopySubmittedDate
                      )} days due`
                    : `${Math.abs(
                        calculateDueDays(
                          item.acceptedROSubmissionDate,
                          item.acceptedArchitectRevisions[0]
                            ?.softCopySubmittedDate
                        )
                      )} days overdue`}
                </span>
              ) : (
                <span
                  style={{
                    color:
                      new Date(item.finalSubmissionDate) < new Date()
                        ? "black"
                        : calculateDueDays(
                            item.acceptedSiteSubmissionDate,
                            item.acceptedRORevisions[0]?.softCopySubmittedDate
                          ) >= 0
                        ? "green"
                        : "red",
                  }}
                >
                  {calculateDueDays(
                    item.acceptedSiteSubmissionDate,
                    item.acceptedRORevisions[0]?.softCopySubmittedDate
                  ) === "-"
                    ? "-"
                    : calculateDueDays(
                        item.acceptedSiteSubmissionDate,
                        item.acceptedRORevisions[0]?.softCopySubmittedDate
                      ) >= 0
                    ? `${calculateDueDays(
                        item.acceptedSiteSubmissionDate,
                        item.acceptedRORevisions[0]?.softCopySubmittedDate
                      )} days due`
                    : `${Math.abs(
                        calculateDueDays(
                          item.acceptedSiteSubmissionDate,
                          item.acceptedRORevisions[0]?.softCopySubmittedDate
                        )
                      )} days overdue`}
                </span>
              )}
            </td>
            <td>
              {props.type === "architectToRo" ? (
                item.acceptedArchitectRevisions.length > 0 ? (
                  <div>
                    {item.acceptedArchitectRevisions
                      .slice(-4)
                      .map(
                        (revisionItem, idx) =>
                          `${revisionItem.revision}` || "NA"
                      )
                      .join(", ")}
                  </div>
                ) : (
                  <div>-</div>
                )
              ) : item.acceptedRORevisions.length > 0 ? (
                <div>
                  {item.acceptedRORevisions
                    .slice(-4)
                    .map(
                      (revisionItem, idx) => `${revisionItem.revision}` || "NA"
                    )
                    .join(", ")}
                </div>
              ) : (
                <div>-</div>
              )}
            </td>
          </>
        ) : popupcontent.reportType === "RFI" ? (
          <>
            <td>{item.drawingNo}</td>
            <td>{item.drawingId.designDrawingConsultant?.role || ""}</td>
            <td>{item.drawingId.category?.category || ""}</td>
            <td>{item.drawingId.drawingTitle}</td>
            <td>{item.requestedDate}</td>
            <td>{item.expectedDate}</td>
            <td>{item.revision}</td>
            <td>{item.status}</td>
          </>
        ) : popupcontent.reportType === "pending" ? (
          <>
            <td>{item.drawingNo}</td>
            <td>{item.designDrawingConsultant.role}</td>
            <td>{item.category?.category || ""}</td>
            <td>{item.drawingTitle}</td>
            <td>
              {props.type === "architectToRo"
                ? formatDate(item.acceptedROSubmissionDate)
                : formatDate(item.acceptedSiteSubmissionDate)}
            </td>
            <td>
              {props.type === "architectToRo" ? (
                <span
                  style={{
                    color:
                      calculateDueDays(
                        item.acceptedROSubmissionDate,
                        item.submittedDate
                      ) >= 0
                        ? "green"
                        : "red",
                  }}
                >
                  {calculateDueDays(
                    item.acceptedROSubmissionDate,
                    item.submittedDate
                  ) === "-"
                    ? "-"
                    : calculateDueDays(
                        item.acceptedROSubmissionDate,
                        item.submittedDate
                      ) >= 0
                    ? `${calculateDueDays(
                        item.acceptedROSubmissionDate,
                        item.submittedDate
                      )} days`
                    : `${Math.abs(
                        calculateDueDays(
                          item.acceptedROSubmissionDate,
                          item.submittedDate
                        )
                      )} days overdue`}
                </span>
              ) : (
                <span
                  style={{
                    color:
                      calculateDueDays(
                        item.acceptedSiteSubmissionDate,
                        item.submittedDate
                      ) >= 0
                        ? "green"
                        : "red",
                  }}
                >
                  {calculateDueDays(
                    item.acceptedSiteSubmissionDate,
                    item.submittedDate
                  ) === "-"
                    ? "-"
                    : calculateDueDays(
                        item.acceptedSiteSubmissionDate,
                        item.submittedDate
                      ) >= 0
                    ? `${calculateDueDays(
                        item.acceptedSiteSubmissionDate,
                        item.submittedDate
                      )} days`
                    : `${Math.abs(
                        calculateDueDays(
                          item.acceptedSiteSubmissionDate,
                          item.submittedDate
                        )
                      )} days overdue`}
                </span>
              )}
            </td>
          </>
        ) : popupcontent.reportType === "register" ? (
          <>
            <td>{item.drawingNo}</td>
            <td>{item.designDrawingConsultant.role}</td>
            <td>{item.category?.category || ""}</td>
            <td>{item.drawingTitle}</td>
            {props.type === "architectToRo" && (
              <td>{formatDate(item.acceptedROSubmissionDate)}</td>
            )}
            <td>{formatDate(item.acceptedSiteSubmissionDate)}</td>
          </>
        ) : null}
      </tr>
    ));
  };

  return (
    <Dialog
      open={isOpen}
      disableBackdropClickonClose={(event, reason) => {
        if (reason === "backdropClick") {
          return;
        }
        handleDialogClose();
      }}
      maxWidth="xxl"
      fullWidth
      PaperProps={{
        style: {
          borderRadius: 20,
          fontFamily: "Poppins",
          border: "1px solid",
          padding: "10px",
        },
      }}
    >
      <DialogContent>
        <div ref={targetRef}>
          {popupcontent.selectViewDownload === "View" && (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p></p>
              <RxCross1
                size={15}
                onClick={handleDialogClose}
                style={{ cursor: "pointer" }}
              />
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: "5px 55px",
              gap: "20px",
            }}
          >
            <div>
              <p className="HeadingStatus">Machinery status</p>
            </div>
            <div className="project">
              {matchedSite ? (
                <p className="projectTitle">
                  Project:{" "}
                  <span
                    className="siteNameProject"
                    style={{ fontWeight: "400" }}
                  >
                    {matchedSite.siteName}
                  </span>
                </p>
              ) : (
                <p>No site Name</p>
              )}
            </div>
            <div className="date-range-container">
              <p className="PeriodTime">Period :</p>
              {(popupcontent.timePeriod === "By Date" ||
                popupcontent.timePeriod === "Last 6 Months") && (
                <>
                  <p style={{ fontWeight: "400" }}>From..</p>
                  <p style={{ fontWeight: "400" }}>{popupcontent.fromDate}</p>
                  <p style={{ fontWeight: "400" }}>To..</p>
                  <p style={{ fontWeight: "400" }}>{popupcontent.toDate}</p>
                </>
              )}
              {popupcontent.timePeriod === "fromBeginningToTillDate" && (
                <>
                  <p style={{ fontWeight: "400" }}>From..</p>
                  <p style={{ fontWeight: "400" }}>{startDate}</p>
                  <p style={{ fontWeight: "400" }}>To..</p>
                  <p style={{ fontWeight: "400" }}>{endDate}</p>
                </>
              )}

              {popupcontent.timePeriod === "By Month" && (
                <>
                  <p style={{ fontWeight: "400" }}>
                    {popupcontent.month}th month
                  </p>
                  <p style={{ fontWeight: "400" }}>{popupcontent.year} year</p>
                </>
              )}
            </div>
          </div>

          {!loaded ? (
            <>
              <Loader />
              <p style={{ textAlign: "center" }}>No data avaliable</p>
            </>
          ) : (
            <>
              <table>
                <thead>{renderTableHeader()}</thead>
                <tbody>{renderTableBody()}</tbody>
              </table>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "20px",
                  padding: "5px 55px",
                }}
              >
                <div>
                  <p className="downloadBy">
                    Download by {currentUserInfo.firstName}
                    <span>{currentUserInfo.lastName}</span>
                  </p>
                </div>

                <div>
                  <p>
                    Page {currentPage} of {totalPages}
                  </p>
                </div>

                <div className="date-time-container">
                  <div className="date-container">
                    <p>{day}</p>
                    <p>{month}</p>
                    <p>{year}</p>
                  </div>
                  <p className="time">{time}</p>
                </div>

                <div>
                  <p className="footerTitle">RCON SPACE</p>
                </div>
              </div>
            </>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

TablePage.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleDialogClose: PropTypes.func.isRequired,
  popupcontent: PropTypes.shape({
    reportType: PropTypes.string,
    consultantId: PropTypes.string,
    fromDate: PropTypes.string,
    toDate: PropTypes.string,
  }),
};

export default TablePage;
