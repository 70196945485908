import React, { useState, useEffect } from "react";
import PerformanceSummaryReport from "./PerformanceSummaryReport";
import LogReportStatus from "../PNMreports/LogReportStatus/LogReportStatus";
import Tabs from "../PNMAssets/Tabs";
import Tab from "../PNMAssets/MainTab";

const Reports = () => {
  const [key, setKey] = useState("LRS");

  return (
    <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="nav-pills"
      style={{ marginTop: "15px" }}
    >
      <Tab eventKey="LRS" title="Log Report Status">
        <div><LogReportStatus/></div>
      </Tab>
      <Tab eventKey="PSR" title=" Perform Summary Report">
        <PerformanceSummaryReport />
      </Tab>
    </Tabs>
  );
};

export default Reports;
