import React, { useState, useEffect } from "react";
import * as Icon from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Dialog, DialogContent } from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import { WebApimanager } from "../../../WebApiManager";
import { RxCross1 } from "react-icons/rx";
import { useRecoilState } from "recoil";
import { registerAtom, siteId } from "../../../atoms";
import { Loader } from "../../../Widgets/notificationFeedbacks";
import ReceivedTable from "./ReceivedTable";
import { Tabs, Tab, Typography, Box } from "@mui/material";
import TableWrapper from "../../../Utilities/TableWrapper";
import { updateRegister } from "../BackendUtils";
import { SUCESS_STATUS } from "../../../Utilities/Constants";
import DrawingTableWrapper from "../Components/DrawingTableWrapper";

const PendingTable = () => {
  const formatDate = (dateString) => {
    if (!dateString) return "-";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
  };

  let webApi = new WebApimanager();
  const todayDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
    const yyyy = today.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  };
  const navigate = useNavigate();
  const [drawingList, setDrawingList] = useState([]);
  const [initialDrawingList, setInitialDrawingList] = useState([]);
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [searchActive, setSearchActive] = useState(false);
  const [selectedDrawing, setSelectedDrawing] = useState(null);
  const [site, setSite] = useRecoilState(siteId);
  const [errorMessage, setErrorMessage] = useState({});
  const [formData, setFormData] = useState({
    registerId: "",
    drawingNumber: "",
    drawingName: "",
    category: "",
    designDrawingConsultant: "",
    acceptedArchitectDate: "",
    // actualSubmissionDate: todayDate(),
    // dueDays: '',
    // remarks:'',
    submittedDate: "",
    issuedSoftCopy: "",
    receivedSoftCopy: "",
    drawingFileName: null,
    revisions: [],
    receivedCopies: "",
    note: "",
  });
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  const [currentView, setCurrentView] = useState("upload");
  const [reloadData, setReloadData] = useState(false);

  const handleTabViewChange = (event, newValue) => {
    setCurrentView(newValue);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [currentTab, setCurrentTab] = useState("upload");

  const [registerInfo, setRegisterInfo] = useRecoilState(registerAtom);

  const handleTabChange = (tabKey) => {
    setCurrentTab(tabKey);
  };

  // Example validation function
  const validateForm = (formData, currentTab) => {
    const newErrors = {};
    if (currentTab === "upload") {
      if (!formData.drawingNumber.trim()) {
        newErrors.drawingNumber = "DrawingNumber  is required";
      }
      if (!formData.drawingName.trim()) {
        newErrors.drawingName = "Drawing Title is required";
      }
      if (!formData.designDrawingConsultant.trim()) {
        newErrors.designDrawingConsultant =
          "DesignDrawingConsultant is required";
      }
      if (!formData.category.trim()) {
        newErrors.category = "Category is required";
      }
      if (!formData.submittedDate.trim()) {
        newErrors.submittedDate = "Submitted Date is required";
      }
      // if(!formData.note){
      //   newErrors.note = 'Note is required'
      // }

      if (!formData.drawingFileName) {
        newErrors.drawingFileName = "Drawing File is required";
      }
      // if (!formData.remarks) errors.remarks = 'This field is required.';
    } else if (currentTab === "received") {
      if (!formData.drawingNumber.trim()) {
        newErrors.drawingNumber = "DrawingNumber  is required";
      }
      if (!formData.drawingName.trim()) {
        newErrors.drawingName = "Drawing Title is required";
      }
      if (!formData.designDrawingConsultant.trim()) {
        newErrors.designDrawingConsultant =
          "DesignDrawingConsultant is required";
      }
      if (!formData.category.trim()) {
        newErrors.category = "Category is required";
      }
      if (!formData.submittedDate.trim()) {
        newErrors.submittedDate = "Submitted Date is required";
      }
      if (!formData.revisions) {
        newErrors.revisions = "revision is required";
      }
      if (!formData.receivedCopies) {
        newErrors.receivedCopies = "receivedCopies are required";
      }

      if (!formData.drawingFileName) {
        newErrors.drawingFileName = "Drawing File is required";
      }
    }
    return newErrors;
  };

  const handleFormSubmit = async () => {
    // Validate the form data
    const errors = validateForm(formData, currentTab);
    setErrors(errors);

    // If there are errors, return early
    if (Object.keys(errors).length > 0) return;

    // Perform API call based on the active tab
    if (currentTab === "upload") {
      setApiProcessing({
        loader: true,
        message: "Submitting...",
      });
      await submitUpload(formData.registerId);
    }
    if (currentTab === "received") {
      setApiProcessing({
        loader: true,
        message: "Submitting...",
      });
      await submitReceived(formData.registerId);
    }
  };

  const submitUpload = async (registerId) => {
    console.log("registerId", registerId);
    try {
      const ApprovalFormData = {
        acceptedArchitectRevisions: [
          {
            softCopySubmittedDate: todayDate(),
            issuedSoftCopy: formData.issuedSoftCopy,
            // changes:formData.note,
          },
        ],
      };

      console.log("Submitting Approval data:", ApprovalFormData);

      const response = await webApi.put(
        `api/architectureToRoRegister/revisions/${registerId}`,
        ApprovalFormData
      );

      // If there's a file to upload, initiate PUT request to upload file
      if (formData.drawingFileName) {
        const drawingFileName = new FormData();
        drawingFileName.append("drawingFileName", formData.drawingFileName);
        await webApi.imagePut(
          `api/architectureToRoRegister/${registerId}`,
          drawingFileName
        );
      }
      setApiProcessing({ loader: false, message: "" });
      handleClose();
      fetchData();
      // window.location.reload();
    } catch (error) {
      console.error("Error submitting form:", error);
      setApiProcessing({ loader: false, message: "" });
      // Handle error, show error message, etc.
      alert("Failed to submit the form. Please try again later.");
    }
  };

  const submitReceived = async (registerId) => {
    console.log("registerId", registerId);
    try {
      const ApprovalFormData = {
        acceptedArchitectRevisions: [
          {
            hardCopySubmittedDate: todayDate(),
            receivedHardCopy: formData.issuedHardtCopy,
            revision: formData.revisions,
            receivedCopies: formData.receivedCopies,
          },
        ],
      };

      console.log("Submitting Approval data:", ApprovalFormData);

      const response = await webApi.put(
        `api/architectureToRoRegister/hardCopy/${registerId}`,
        ApprovalFormData
      );

      // If there's a file to upload, initiate PUT request to upload file
      if (formData.drawingFileName) {
        const hardCopyFile = new FormData();
        hardCopyFile.append("hardCopyFile", formData.drawingFileName);
        await webApi.imagePut(
          `api/architectureToRoRegister/hardCopeFile/${registerId}`,
          hardCopyFile
        );
      }
      setApiProcessing({ loader: false, message: "" });
      handleClose();
      fetchData();
      // window.location.reload();
    } catch (error) {
      console.error("Error submitting form:", error);
      setApiProcessing({ loader: false, message: "" });
      // Handle error, show error message, etc.
      alert("Failed to submit the form. Please try again later.");
    }
  };

  useEffect(()=>{
    setReloadData(true)
  },[drawingList])

  const fetchInData = async () => {
    if (registerInfo && registerInfo.length > 0) {
      setDrawingList(registerInfo);
      setInitialDrawingList(registerInfo);
      console.log("fetched from recoil", registerInfo);
    } else {
      await updateRegister(setRegisterInfo, site).then((response) => {
        if (response.status === SUCESS_STATUS) {
          setDrawingList(response.registers);
          setInitialDrawingList(response.registers);
        }
      });
    }
  };

  const fetchData = async () => {
    await updateRegister(setRegisterInfo, site).then((response) => {
      if (response.status === SUCESS_STATUS) {
        setDrawingList(response.registers);
        setInitialDrawingList(response.registers);
      }
    });
  };

  useEffect(() => {
    fetchInData();
  }, []);

  const handleEdit = (drawingId) => {
    const selected = drawingList.find((drawing) => drawing._id === drawingId);
    if (selected) {
      const combinedRevisions = [
        ...selected.acceptedArchitectRevisions,
        // ...selected.acceptedRORevisions,
      ];

      setSelectedDrawing(selected);
      setFormData({
        registerId: selected._id,
        drawingNumber: selected.drawingNo || "",
        drawingName: selected.drawingTitle || "",
        category: selected.category.category || "", // Ensure the category field is properly accessed
        designDrawingConsultant: `${selected.designDrawingConsultant?.role}`,
        //  selected.designDrawingConsultant.designDrawingConsultant || '',
        acceptedArchitectDate: selected.acceptedArchitectDate
          ? new Date(selected.acceptedArchitectDate).toLocaleDateString()
          : "",
        revisions: combinedRevisions,
        submittedDate: todayDate(),
        // dueDays: 0,
        // issuedSoftCopy: selected.issuedSoftCopy,
        // receivedSoftCopy: selected.receivedSoftCopy,
        // drawingFileName: selected.drawingFileName
      });
      setOpen(true);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === "file") {
      setFormData({
        ...formData,
        [name]: files[0], // Assuming you are only uploading one file
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const calculateDueDays = (acceptedRoDate, submittedDate = null) => {
    if (!acceptedRoDate) return "-";

    const dateToCompare = submittedDate ? new Date(submittedDate) : new Date();
    const acceptedDate = new Date(acceptedRoDate);
    const differenceInTime = acceptedDate.getTime() - dateToCompare.getTime();
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24)); // convert time difference to days

    return differenceInDays;
  };

  const searchResult = (e) => {
    const searchInput = e.target.value.trim().toLowerCase();
    setSearchValue(searchInput);
    setSearchActive(true);

    if (!searchInput) {
      // If search input is empty, reset the drawingList to its original state
      fetchData(); // This fetches all drawings again
      setSearchActive(false); // Set searchActive to false to allow periodic fetches
    } else {
      const filteredDrawings = initialDrawingList.filter((drawingItem) => {
        const drawingNumber = drawingItem.drawingNo?.toLowerCase() || "";
        const drawingName = drawingItem.drawingTitle?.toLowerCase() || "";
        const consultant = `${drawingItem.designDrawingConsultant?.role}`;
        // drawingItem.designDrawingConsultant.designDrawingConsultant?.toLowerCase() || "";

        const category = drawingItem?.category?.category?.toLowerCase() || "";
        const acceptedArchitectDate = drawingItem.acceptedROSubmissionDate
          ? new Date(drawingItem.acceptedROSubmissionDate)
              .toLocaleDateString()
              .toLowerCase()
          : "";
        const dueDays = calculateDueDays(
          drawingItem.acceptedArchitectDate
        ).toString();
        const dueStatus =
          dueDays >= 0
            ? `${dueDays} days`
            : `${Math.abs(dueDays)} days overdue`;
        // const revisions = drawingItem.acceptedArchitectRevisions.map((revisionItem) => revisionItem.revision.toLowerCase()).join(" ");

        return (
          drawingNumber.includes(searchValue) ||
          drawingName.includes(searchValue) ||
          consultant.includes(searchValue) ||
          category.includes(searchValue) ||
          acceptedArchitectDate.includes(searchValue) ||
          dueStatus.includes(searchValue) ||
          dueDays.includes(searchValue)
          // revisions.includes(searchValue)
        );
      });
      setDrawingList(filteredDrawings);
    }
  };

  return (
    <>
      <TableWrapper
        children={
          <>
            <div
              style={{
                display: "flex",
                marginLeft: "5px",
                marginRight: "5px",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {/* <h3 style={{ color: 'var(--primary-color)',fontSize: '20px' }}>Pending</h3> */}
              <div style={{ display: "flex" }}>
                <Tabs
                  value={currentView}
                  onChange={handleTabViewChange}
                  sx={{
                    "& .MuiTabs-indicator": {
                      backgroundColor: "var(--primary-color)", // Custom color for active tab indicator
                    },
                    "& .MuiTab-root": {
                      textTransform: "none", // Prevents text from being transformed to uppercase
                      color: "#131313", // Text color for all tabs
                      borderBottom: "none",
                      fontWeight: "bold",
                    },
                    "& .Mui-selected": {
                      color: "#131313 !important", // Text color for selected tab
                    },
                    "& .MuiTab-root:not(.Mui-selected)": {
                      color: "#131313", // Text color for non-selected tabs
                    },
                  }}
                >
                  <Tab label="Upload" value="upload" />
                  <Tab label="Received" value="received" />
                </Tabs>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                {/* <select
                  id="siteName"
                  name="siteName"
                  value={siteId}
                  onChange={handleSiteChange}
                  style={{ marginLeft: '10px', width: '240px', padding: '4px' }}
                >
                  <option value="">All Sites</option>
                  { sites && sites.map(site => (
                    <option key={site._id} value={site._id}>{site.siteName}</option>
                  ))}
                </select> */}
                <div
                  style={{
                    position: "relative",
                    width: "250px",
                    paddingLeft: "5px",
                    height: "50px",
                  }}
                >
                  <input
                    placeholder="Search Drawings"
                    onChange={searchResult}
                    style={{
                      paddingLeft: "20px",
                      borderRadius: "6px",
                      border: "1px solid var(--primary-color)",
                      width: "250px",
                      height: "40px",
                    }}
                  />
                  <Icon.Search
                    style={{
                      position: "absolute",
                      right: "6px",
                      top: "40%",
                      transform: "translateY(-50%)",
                    }}
                  />
                </div>
                {/* <Icon.FunnelFill size={25} style={{width: '40px',

height: "40px",marginLeft: "10px", alignItems: "center", border: "1px solid var(--primary-color)",}}/> */}
              </div>
            </div>
            <div>
              {currentView === "upload" && (
                <DrawingTableWrapper
                  tableHead={(
                    <tr>
                        <th style={{ width: "10%", paddingLeft: "15px" }}>
                          S.No
                        </th>
                        <th style={{ width: "10%", paddingLeft: "15px" }}>
                          Drawing No
                        </th>
                        <th style={{ width: "15%", paddingLeft: "15px" }}>
                          Design Consultant
                        </th>
                        <th style={{ width: "10%", paddingLeft: "15px" }}>
                          Drawing Category
                        </th>
                        <th style={{ width: "15%", paddingLeft: "15px" }}>
                          Drawing Title
                        </th>
                        <th style={{ width: "13%", paddingLeft: "15px" }}>
                          Accepted R0 Submission Date
                        </th>
                        {/* <th style={{ width: '13%' }}>Submitted Date</th> */}
                        <th style={{ width: "10%", paddingLeft: "15px" }}>
                          Due/Overdue
                        </th>
                        {/* <th style={{ width: '10%' }}>Revisions</th> */}
                        <th style={{ width: "10%", paddingLeft: "15px" }}>
                          Action
                        </th>
                      </tr>
                  )}
                  tableBody={(
                    <tbody>
                      {drawingList &&
                        [
                          ...drawingList
                            .filter(
                              (item) =>
                                item.acceptedArchitectRevisions.length === 0
                            )
                            // Items with revisions
                            .sort((a, b) => {
                              const consultantA = a.designDrawingConsultant?.role
                                ? a.designDrawingConsultant?.role.toLowerCase()
                                : "";
                              const consultantB = b.designDrawingConsultant?.role
                                ? b.designDrawingConsultant?.role.toLowerCase()
                                : "";
                              return consultantA.localeCompare(consultantB); // Sort alphabetically
                            }),
                        ].map((drawingItem, index) => (
                          <tr
                            key={index}
                            style={{
                              backgroundColor:
                                index % 2 === 0
                                  ? "var(--table-oddrow-color)"
                                  : "var(--table-evenrow-color)",
                            }}
                          >
                            <td>{index + 1}</td>
                            <td>{drawingItem?.drawingNo || ""}</td>
                            <td>
                              {drawingItem.designDrawingConsultant?.role || ""}
                            </td>
                            <td>{drawingItem.category?.category || ""}</td>
                            <td>{drawingItem?.drawingTitle || ""}</td>
                            <td>
                              {formatDate(
                                drawingItem?.acceptedROSubmissionDate || ""
                              )}
                            </td>
                            {/* <td>{formatDate(drawingItem.submittedDate)}</td> */}
                            <td>
                              <span
                                style={{
                                  color:
                                    calculateDueDays(
                                      drawingItem.acceptedROSubmissionDate,
                                      drawingItem.submittedDate
                                    ) >= 0
                                      ? "green"
                                      : "red",
                                }}
                              >
                                {calculateDueDays(
                                  drawingItem.acceptedROSubmissionDate,
                                  drawingItem.submittedDate
                                ) === "-"
                                  ? "-"
                                  : calculateDueDays(
                                      drawingItem.acceptedROSubmissionDate,
                                      drawingItem.submittedDate
                                    ) >= 0
                                  ? `${calculateDueDays(
                                      drawingItem.acceptedROSubmissionDate,
                                      drawingItem.submittedDate
                                    )} days`
                                  : `${Math.abs(
                                      calculateDueDays(
                                        drawingItem.acceptedROSubmissionDate,
                                        drawingItem.submittedDate
                                      )
                                    )} days overdue`}
                              </span>
                            </td>
                            <td>
                              <Icon.Pen
                                size={25}
                                style={{ marginLeft: "55px" }}
                                onClick={() => handleEdit(drawingItem._id)}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  )}
                />   
              )}
              {currentView === "received" && (
                <ReceivedTable selectedFilter={""} searchValue={searchValue} searchActive={searchActive} setSearchValue={setSearchValue} setSearchActive={setSearchActive}  reloadData={reloadData} setReloadData={setReloadData} filterCondition="pending" />
              )}
            </div>
          </>
        }
      />
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        PaperProps={{ style: { borderRadius: 20, fontFamily: "Poppins" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
          }}
        >
          <p
            style={{ margin: "5px 400px", textAlign: "center", color: "white" }}
          >
            Drawing
          </p>
          <RxCross1
            size={20}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "15px",
              color: "white",
            }}
            onClick={handleClose}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            marginTop: "20px",
            marginBottom: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "0",
              border: "1px solid var(--primary-color)",
            }}
          >
            <button
              onClick={() => {
                setCurrentTab("upload");
              }}
              style={{
                backgroundColor:
                  currentTab === "upload"
                    ? "var(--primary-color)"
                    : "transparent",
                color:
                  currentTab === "upload" ? "white" : "var(--primary-color)",
                width: "150px",
                height: "35px",
                cursor: "pointer",
                borderRight: "1px solid var(--primary-color)",
              }}
            >
              Upload
            </button>

            {/* <button
          onClick={() => setCurrentTab('received')}
          style={{
            background: currentTab === 'received' ? 'var(--primary-color)' : 'transparent',
            color: currentTab === 'received' ? 'white' : 'var(--primary-color)',
            width: '150px',
            height: '35px',
            cursor: 'pointer',

          }}
        >
        Received
        </button> */}
          </div>
        </div>
        <DialogContent
          style={{ padding: "25px", minWidth: "300px", minHeight: "300px" }}
        >
          <>
            <Loader
              open={apiProcessing.loader}
              message={apiProcessing.message}
            />
            <section
              style={{
                marginLeft: "80px",
                marginRight: "80px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "50px",
                  width: "80%",
                }}
              >
                <div>
                  <label
                    htmlFor="drawingNumber"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    Drawing No
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    id="drawingNumber"
                    name="drawingNumber"
                    value={formData.drawingNumber}
                    onChange={handleChange}
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                    readOnly
                  />

                  {errors.drawingNumber && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.drawingNumber}
                    </p>
                  )}
                </div>

                <div>
                  <label
                    htmlFor="drawingName"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    Drawing Title
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="drawingName"
                    name="drawingName"
                    value={formData.drawingName}
                    onChange={handleChange}
                    placeholder="Enter your drawing title"
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                    readOnly
                  />
                  {errors.drawingName && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.drawingName}
                    </p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="category"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    Drawing Category
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="category"
                    name="category"
                    value={formData.category}
                    onChange={handleChange}
                    placeholder="Enter your Drawing Category"
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                    readOnly
                  />
                  {errors.category && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.category}
                    </p>
                  )}
                </div>

                <div>
                  <label
                    htmlFor="designDrawingConsultant"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    Design Consultant
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="designDrawingConsultant"
                    name="designDrawingConsultant"
                    value={formData.designDrawingConsultant}
                    onChange={handleChange}
                    placeholder="Enter your  Design Consultant"
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                    readOnly
                  />
                  {errors.designDrawingConsultant && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.designDrawingConsultant}
                    </p>
                  )}
                </div>

                {currentTab !== "received" && (
                  <>
                    <div>
                      <label
                        htmlFor="revisions"
                        className="fs-10 fw-bold text-start py-2"
                      >
                        Revisions
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        id="revisions"
                        name="revisions"
                        value={formData.revisions}
                        onChange={handleChange}
                        className="form-control fs-12"
                        style={{ width: "100%" }}
                      >
                        <option value="">Select Revision</option>
                        <option value="R0">R0</option>
                      </select>
                      {errors.revisions && (
                        <p style={{ color: "red", fontSize: "12px" }}>
                          {errors.revisions}
                        </p>
                      )}
                    </div>
                  </>
                )}
                {currentTab === "received" && (
                  <>
                    <div>
                      <label
                        htmlFor="revisions"
                        className="fs-10 fw-bold text-start py-2"
                      >
                        Revisions
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        id="revisions"
                        name="revisions"
                        value={formData.revisions}
                        onChange={handleChange}
                        className="form-control fs-12"
                        style={{ width: "100%" }}
                      >
                        <option value="">Select Revision</option>
                        {formData.revision &&
                          formData.revisions.map((revision, index) => (
                            <option key={index} value={revision.revision}>
                              {revision.revision}
                            </option>
                          ))}
                      </select>
                      {errors.revisions && (
                        <p style={{ color: "red", fontSize: "12px" }}>
                          {errors.revisions}
                        </p>
                      )}
                    </div>

                    <div>
                      <label
                        htmlFor="receivedCopies"
                        className="fs-10 fw-bold text-start py-2
              "
                      >
                        No of Copies Received
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="number"
                        id="receivedCopies"
                        name="receivedCopies"
                        placeholder="Enter No of Received Copies"
                        value={formData.receivedCopies}
                        onChange={handleChange}
                        className="form-control fs-12"
                        style={{ width: "100%" }}
                      />
                    </div>
                  </>
                )}

                <div>
                  <label
                    htmlFor="drawingFileName"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    Upload Your Drawing File
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="file"
                    accept=".dwg"
                    id="drawingFileName"
                    name="drawingFileName"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (file && file.name.endsWith(".dwg")) {
                        handleChange(e);
                      } else {
                        alert("Please select a .dwg file.");
                        e.target.value = null; // Reset the file input if the file is not valid
                      }
                    }}
                    placeholder="Select your drawing file"
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                  />
                  {errors.drawingFileName && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.drawingFileName}
                    </p>
                  )}
                </div>
              </div>
            </section>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "45px",
              }}
            >
              <button
                style={{
                  padding: "8px",
                  borderRadius: "5px",
                  border: "1px solid lightgray",
                  backgroundColor: "transparent",
                  width: "100px",
                }}
                onMouseOver={(e) =>
                  (e.target.style.backgroundColor = "lightgray")
                }
                onMouseOut={(e) =>
                  (e.target.style.backgroundColor = "transparent")
                }
                onClose={handleClose}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary"
                style={{
                  marginLeft: "50px",
                  padding: "10px",
                  borderRadius: "5px",
                  border: "1px solid var(--primary-color)",
                  width: "100px",
                  backgroundColor: "var(--primary-color)",
                  color: "white",
                }}
                onClick={handleFormSubmit}
              >
                Submit
              </button>
            </div>
          </>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PendingTable;
