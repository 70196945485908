import React from 'react';
import styles from './ValueCard.module.css';

function ValueCard({ iconSrc, description }) {
  return (
    <div className={styles.valueCard}>
      <img src={iconSrc} alt="Value Icon" className={styles.valueIcon} />
      <p className={styles.valueDescription}>{description}</p>
    </div>
  );
}

export default ValueCard;