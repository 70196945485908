import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { WebApimanager } from "../../../WebApiManager";
import "./FolderDetailsDialog.css";
import { IoMdArrowRoundBack } from "react-icons/io";
import { BACKEND_URL } from "../../../Utilities/Constants";
import { Loader } from "../../../Widgets/notificationFeedbacks";

const FolderEditDialog = ({
  folder,
  open,
  onClose,
  onSave,
  onFolderDelete,
  onSuccess,
}) => {
  const [fName, setFName] = useState(folder?.fName || "");
  const [fImage, setFImage] = useState(null); // To handle new image file
  const [previewImage, setPreviewImage] = useState(
    folder?.fImage ? `${BACKEND_URL}${folder.fImage}` : ""
  ); // Image preview
  const webApi = new WebApimanager();
  const [isModalOpen, setIsModalOpen] = useState(open);

  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  useEffect(() => {
    setIsModalOpen(open);
  }, [open]);

  useEffect(() => {
    if (folder?.fImage) {
      setPreviewImage(`${BACKEND_URL}${folder.fImage}`);
    }
  }, []);

  const [creatorName, setCreatorName] = useState("");
  const [createdDate, setCreatedDate] = useState("");

  useEffect(() => {
    if (folder) {
      const creator = folder.folderCreatedBy;
      setCreatorName(`${creator?.firstName || ""} ${creator?.lastName || ""}`);
      setCreatedDate(
        folder.folderCreatedDate
          ? new Date(folder.folderCreatedDate).toLocaleDateString()
          : ""
      );
    }
  }, [folder]);

  // Handle image file selection
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Save folder details
  const handleSave = async () => {
    setApiProcessing({
      loader: true,
      message: "Submitting...",
    });
    const formData = new FormData();
    formData.append("fName", fName);

    try {
      const response = await webApi.put(
        `api/isCode/update/${folder._id}`,
        formData
      );
      console.log("Folder details update response:", response);

      if (fImage) {
        const imageFormData = new FormData();
        imageFormData.append("fImage", fImage);

        const imageResponse = await webApi.imagePut(
          `api/isCode/fImage/${folder._id}`,
          imageFormData
        );
        console.log("Image upload response:", imageResponse);
      }

      setApiProcessing({ loader: false, message: "" });
      if (onSuccess) onSuccess();

      onClose();
    } catch (error) {
      console.error("Error updating folder or uploading image:", error);
      setApiProcessing({ loader: false, message: "" });
      alert("Failed to update folder or upload image. Please try again later.");
    }
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  if (!open) return null;

  return (
    <>
      <Loader open={apiProcessing.loader} message={apiProcessing.message} />
      <div
        className="modal fade show d-block modal-lg"
        tabIndex="-1"
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-dialog-centered p-4">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{
                backgroundColor: "#303030",
                height: "50px",
                color: "white",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0 15px",
              }}
            >
              <h5 className="modal-title editFolderTitle">Edit Folder</h5>
              <button
                onClick={handleClose}
                aria-label="Close dialog"
                style={{
                  color: "white",
                  backgroundColor: "transparent",
                  border: "none",
                  fontSize: "20px",
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <div>
                <button
                  className=""
                  style={{ marginLeft: "-590px" }}
                  onClick={onClose}
                >
                  <IoMdArrowRoundBack size={20} />
                </button>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "15px",
                }}
              >
                <div>
                  {previewImage && (
                    <div>
                      <img
                        src={previewImage}
                        alt="Preview"
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                          cursor: "pointer",
                          borderRadius: "50%",
                          marginTop: "-30px",
                        }}
                        onClick={() =>
                          document.getElementById("folder-image-input").click()
                        }
                      />
                      <input
                        id="folder-image-input"
                        type="file"
                        name="folder-image-input"
                        accept="image/*"
                        onChange={handleImageChange}
                        style={{ display: "none" }} // Hide the input
                      />
                    </div>
                  )}
                  {!folder.fImage && !previewImage && (
                    <div>
                      <p>No image available for this folder</p>
                      <input
                        id="folder-image-input"
                        type="file"
                        name="folder-image-input"
                        accept="image/*"
                        onChange={handleImageChange}
                        style={{ display: "block", marginTop: "10px" }}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="d-flex align-items-center mb-3">
                <div className="flex-grow-1">
                  <label htmlFor="fName" className="editLable">
                    Enter folder name here
                  </label>
                  <input
                    type="text"
                    className="form-control w-100"
                    id="fName"
                    value={fName}
                    onChange={(e) => setFName(e.target.value)}
                  />
                </div>
              </div>

              <div className="editContainer">
                <div className="item">
                  <div className="flex-grow-1">
                    <label
                      htmlFor="createdBy"
                      className="editLable"
                      style={{ textAlign: "start" }}
                    >
                      Folder Created By
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="createdBy"
                      value={creatorName}
                      readOnly
                      style={{ width: "90%" }}
                    />
                  </div>
                </div>
                <div className="item">
                  <div className="flex-grow-1">
                    <label
                      htmlFor="createdDate"
                      className="editLable"
                      style={{ textAlign: "start" }}
                    >
                      Folder Created Date
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="createdDate"
                      value={createdDate}
                      readOnly
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "20px",
              }}
            >
              <div>
                <button
                  type="button"
                  className="cancleButton"
                  onClick={onClose}
                >
                  CANCEL
                </button>
              </div>
              <div>
                <button
                  type="button"
                  className="saveButton"
                  onClick={handleSave}
                >
                  SAVE
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FolderEditDialog;
