import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Dialog, DialogContent } from "@mui/material";
import { RxCross1 } from "react-icons/rx";
import { WebApimanager } from "../../WebApiManager";
import * as Icon from "react-bootstrap-icons";
import { Button } from "react-bootstrap";
import { queries } from "@testing-library/react";
import Styles from "./AssetCodeCreation.module.css";

const AssetCodeCreation = ({ assetStyle }) => {
  let webApi = new WebApimanager();
  const [open, setOpen] = useState(true);
  const [assetCode, setAssetCodeData] = useState([]);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [singleAssetCodeData, setSingleAssetCodeData] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [formData, setFormData] = useState({
    type: "",
    assetCode: "",
    Name: "",
    documents: [],
    drawings: [""], // Initial drawing input
    description: [],
    queries: [],
  });
  const [editData, setEditData] = useState({
    _id: "",
    type: "",
    assetCode: "",
    Name: "",
    documents: [],
    drawings: [""],
    queries: [""],
  });
  const [errors, setErrors] = useState({});

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      setShowForm(false);
    }, 2000);
    setFormData({
      drawings: [""], // Initial drawing input
    });
  };

  const handleEditOpen = (assetCode) => {
    setEditData({
      _id: assetCode._id,
      type: assetCode.type,
      assetCode: assetCode.assetCode,
      Name: assetCode.name,
      documents: assetCode.documents,
      drawings: assetCode.documents,
      description: assetCode.queries,
      queries: assetCode.queries,
    });
    setEditDialogOpen(true);
  };
  const handleEditClose = () => {
    setEditDialogOpen(false);
    setEditData({
      _id: "",
      type: "",
      assetCode: "",
      Name: "",
      documents: [],
      drawings: [""],
      queries: [""],
      description: [],
    });
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleEditDrawingChange = (index, value) => {
    const updatedDrawings = [...editData.drawings];
    updatedDrawings[index] = value;
    setEditData((prevData) => ({
      ...prevData,
      drawings: updatedDrawings,
    }));
  };
  const addEditDrawing = () => {
    setEditData((prevData) => ({
      ...prevData,
      drawings: [...prevData.drawings, ""],
    }));
  };
  const removeEditDrawing = (index) => {
    const updatedDrawings = editData.drawings.filter((_, i) => i !== index);
    setEditData((prevData) => ({
      ...prevData,
      drawings: updatedDrawings,
    }));
  };

  const addEditQuery = () => {
    setEditData((prevData) => ({
      ...prevData,
      queries: [...(prevData.queries || []), ""], // Ensure it's always an array
    }));
  };

  const handleEditQueryChange = (index, value) => {
    setEditData((prevData) => {
      const updatedQueries = [...(prevData.queries || [])];
      updatedQueries[index] = value;
      return {
        ...prevData,
        queries: updatedQueries,
      };
    });
  };

  const removeEditQuery = (index) => {
    setEditData((prevData) => {
      const updatedQueries = (prevData.queries || []).filter(
        (_, i) => i !== index
      );
      return {
        ...prevData,
        queries: updatedQueries,
      };
    });
  };

  const handleViewOpen = (assetCode) => {
    setSingleAssetCodeData(assetCode);
    setViewDialogOpen(true);
  };
  const handleViewClose = () => {
    setSingleAssetCodeData(null);
    setViewDialogOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDrawingChange = (index, value) => {
    const updatedDrawings = [...formData.drawings];
    updatedDrawings[index] = value;
    setFormData((prevData) => ({
      ...prevData,
      drawings: updatedDrawings,
    }));
  };

  const addDrawing = () => {
    setFormData((prevData) => ({
      ...prevData,
      drawings: [...prevData.drawings, ""],
    }));
  };

  const removeDrawing = (index) => {
    const updatedDrawings = formData.drawings.filter((_, i) => i !== index);
    setFormData((prevData) => ({
      ...prevData,
      drawings: updatedDrawings,
    }));
  };

  const handleQueryChange = (index, value) => {
    const updatedQueries = [...formData.queries];
    updatedQueries[index] = value;
    setFormData((prevData) => ({
      ...prevData,
      queries: updatedQueries,
    }));
  };
  const addQuery = () => {
    setFormData((prevData) => ({
      ...prevData,
      queries: [...prevData.queries, ""],
    }));
  };
  const removeQuery = (index) => {
    const updatedQueries = formData.queries.filter((_, i) => i !== index);
    setFormData((prevData) => ({
      ...prevData,
      queries: updatedQueries,
    }));
  };

  const handleSubmit = async () => {
    console.log("submit clicked");
    let valid = true;
    const newErrors = {};

    if (!formData.type.trim()) {
      newErrors.type = "Type is required";
      valid = false;
    }
    if (!formData.assetCode.trim()) {
      newErrors.assetCode = "AssetCode is required";
      valid = false;
    }
    if (!formData.Name.trim()) {
      newErrors.Name = "Name is required";
      valid = false;
    }
    console.log(">>>", formData, ">>", newErrors);
    // formData.drawings.forEach((drawing, index) => {
    //   if (drawing.trim() === '') {
    //     newErrors[`drawing${index}`] = 'Please fill out this field';
    //     valid = false;
    //   }
    // });
    formData.queries.forEach((query, index) => {
      if (query.trim() === "") {
        newErrors[`query${index}`] = "Please fill out this field";
        valid = false;
      }
    });
    console.log(newErrors);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      console.log("exiting");
      return; // Exit early if there are errors
    }

    // Proceed with submission logic
    console.log("Form submitted:", formData);
    const tempFormData = {
      type: formData.type,
      assetCode: formData.assetCode,
      name: formData.Name,
      documents: formData.drawings,
      query: formData.queries,
    };

    // const response = await webApi.post("api/pnms/assetCode", tempFormData);
    // if ( response.status === 201){
    //   window.location.reload();
    //   fetchAssetCodes();
    //   handleClose();
    // }

    //  else if ( response.status === 200){
    //   if (response.data && response.data.message) {
    //     setErrorMessage(response.data.message);
    //   } else {
    //     // If the specific message is not found, set a generic error message
    //     setErrorMessage('Asset code and name must be unique');
    //   }
    // } else{
    //     // If the specific message is not found, set a generic error message
    //     setErrorMessage('An unexpected error occurred.');
    //   }
    try {
      console.log("posting");
      const response = await webApi.post("api/pnms/assetCode", tempFormData);

      if (response.status === 201) {
        window.location.reload();
        fetchAssetCodes();
        handleClose();
      } else if (response.status === 200 || response.status === 400) {
        // Check if the response contains an error message array
        if (
          response.data &&
          Array.isArray(response.data) &&
          response.data.length > 0
        ) {
          setErrorMessage(response.data[0]); // Set the first error message
          window.location.reload();
        } else {
          // If the specific message is not found, set a generic error message
          setErrorMessage("Asset code and name must be unique");
          window.location.reload();
        }
      } else {
        // If the specific message is not found, set a generic error message
        setErrorMessage("An unexpected error occurred.");
      }
    } catch (error) {
      // Handle errors that occur during the request
      if (
        error.response &&
        error.response.data &&
        Array.isArray(error.response.data) &&
        error.response.data.length > 0
      ) {
        setErrorMessage(error.response.data[0]); // Set the first error message
      } else {
        setErrorMessage("Failed to submit data.");
      }
    }
  };

  const fetchAssetCodes = async () => {
    try {
      const response = await webApi.get("api/pnms/assetCode");
      console.log("API response:", response);
      if (response.data && Array.isArray(response.data.data.AssetCodes)) {
        setAssetCodeData(response.data.data.AssetCodes);
        // setEditData(response.data.data.data);
        console.log(response.data.data.AssetCodes);
      } else {
        setErrors("Unexpected data format");
        console.error("Unexpected data format:", response.data);
      }
    } catch (error) {
      // Log the entire error object to debug the structure
      console.error("Error object:", error);

      // Check if the error response contains a specific message and set it to errorMessage state
      if (
        error.response &&
        error.response.data &&
        error.response.data.message === "Asset code and name must be unique"
      ) {
        setErrorMessage("Asset code and name must be unique");
      } else {
        // If the specific message is not found, set a generic error message
        setErrorMessage("An unexpected error occurred.");
      }
    }
  };
  useEffect(() => {
    fetchAssetCodes();
  }, []);
  const handleTrash = async (id) => {
    try {
      const response = await webApi.delete(`api/pnms/deleteAssetCode/${id}`);
      if (response.status === 204) {
        console.log("Successfully deleted");
        fetchAssetCodes();
      } else {
        console.error("Failed to delete");
      }
    } catch (error) {
      console.error("There was an error deleting the asset code!", error);
    }
  };

  const handleEditSubmit = async () => {
    let valid = true;
    const newErrors = {};

    if (!editData.type.trim()) {
      newErrors.type = "type is required";
      valid = false;
    }

    if (!editData.assetCode.trim()) {
      newErrors.assetCode = "AssetCode is required";
      valid = false;
    }
    if (!editData.Name.trim()) {
      newErrors.Name = "Name is required";
      valid = false;
    }

    editData &&
      editData.documents &&
      editData.documents.forEach((drawing, index) => {
        if (drawing.trim() === "") {
          newErrors[`drawing${index}`] = "Please fill out this field";
          valid = false;
        }
      });
    editData &&
      editData.description &&
      editData.description.forEach((query, index) => {
        if (query.trim() === "") {
          newErrors[`query${index}`] = "Please fill out this field";
          valid = false;
        }
      });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return; // Exit early if there are errors
    }

    // Proceed with submission logic
    console.log("Form submitted:", editData);
    const tempEditData = {
      type: editData.type,
      assetCode: editData.assetCode,
      name: editData.Name,
      documents: editData.drawings,
      description: editData.queries,
    };
    try {
      const response = await webApi.put(
        `api/pnms/assetCode/${editData._id}`,
        tempEditData
      );
      //   console.log(response);

      //   handleEditClose(); // Close dialog or perform any other action
      //   fetchAssetCodes(); // Optionally, fetch updated data after edit
      // }   catch (error) {
      //   // Log the entire error object to debug the structure
      //   console.error('Error object:', error);

      //   // Check if the error response contains a message and set it to errorMessage state
      //   if (error.response && error.response.data) {
      //     const errorData = error.response.data;

      //     // Set error message based on the structure of errorData
      //     if (typeof errorData === 'string') {
      //       setErrorMessage(errorData); // If error message is a plain string
      //     } else if (typeof errorData === 'object' && errorData.message) {
      //       setErrorMessage(errorData.message); // If error message is in a 'message' field
      //     } else {
      //       setErrorMessage('An unexpected error occurred.'); // Fallback error message
      //     }
      //   } else {
      //     setErrorMessage('An unexpected error occurred.');
      //   }
      if (response.status === 201) {
        window.location.reload();
        fetchAssetCodes();
        handleClose();
      } else if (response.status === 200 || response.status === 400) {
        // Check if the response contains an error message array
        if (
          response.data &&
          Array.isArray(response.data) &&
          response.data.length > 0
        ) {
          setErrorMessage(response.data[0]); // Set the first error message
        } else {
          // If the specific message is not found, set a generic error message
          setErrorMessage("Asset code and name must be unique");
        }
      } else {
        // If the specific message is not found, set a generic error message
        setErrorMessage("An unexpected error occurred.");
      }
    } catch (error) {
      // Handle errors that occur during the request
      if (
        error.response &&
        error.response.data &&
        Array.isArray(error.response.data) &&
        error.response.data.length > 0
      ) {
        setErrorMessage(error.response.data[0]); // Set the first error message
      } else {
        setErrorMessage("Failed to submit data.");
      }
    }
  };

  return (
    <>
      <a
        className={`
         text-inherit no-underline`}
        href="#"
      >
        Asset code
      </a>
      {assetStyle.display && (
        <div
          className={`mt-[50px] absolute left-[140px] right-[140px] bg-white ${Styles.assetContainer}`}
        >
          {/* Top bar */}
          {!showForm && (
            <div
              style={{
                display: "flex",
                margin: !showForm ? "0px 45px 0px 50px" : "",
                justifyContent: showForm ? "center" : "space-between",
                alignItems: "center",
                width: showForm ? "972px" : "auto",
                color: showForm ? "white" : "black",
                backgroundColor: showForm ? "#4D4D4D" : "white",
                borderTopLeftRadius: "5px",
                borderTopRightRadius: "5px",
              }}
            >
              <div
                className={`   flex justify-center items-center ${
                  !showForm ? "mt-[50px]" : "h-[50px] w-[922px]"
                }`}
              >
                <h3
                  style={{
                    color: showForm ? "white" : "var(--primary-color)",
                    fontSize: "20px",
                    margin: 0,
                  }}
                >
                  Asset Codes
                </h3>
              </div>

              <div
                className={`flex justify-center items-center ${
                  !showForm ? "mt-[50px]" : ""
                } `}
              >
                {!showForm && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        width: "250px",
                        paddingRight: "5px",
                      }}
                    >
                      <input
                        placeholder="Search Asset Code List"
                        style={{
                          paddingLeft: "20px",
                          fontSize: "12px",
                          borderRadius: "6px",
                          border: "1px solid var(--primary-color)",
                          width: "250px",
                          height: "40px",
                        }}
                      />
                      <Icon.Search
                        style={{
                          size: "16px",
                          position: "absolute",
                          right: "6px",
                          top: "50%",
                          transform: "translateY(-50%)",
                        }}
                      />
                    </div>
                    <Button
                      className="btn btn-primary"
                      style={{
                        height: "40px",
                        width: "150px",
                        fontSize: "16px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "var(--primary-color)",
                        border: "none",
                        marginLeft: "50px",
                        marginRight: "50px",
                        display: "flex",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        setShowForm(true);
                        setOpen(true);
                      }}
                    >
                      <Icon.PlusCircle
                        size={16}
                        style={{ marginRight: "5px" }}
                      />{" "}
                      Add New
                    </Button>
                  </div>
                )}
              </div>
            </div>
          )}

          <div
            style={{
              padding: "0px 30px 0px 50px",
              height: "max-content",
              width: "100%",
            }}
          >
            {!showForm && (
              <table style={{ width: "100%", marginTop: "10px" }}>
                <thead
                  style={{
                    backgroundColor: "#F3F6F9",
                    textAlign: "start",
                    height: "45px",
                  }}
                >
                  <tr>
                    <th style={{ width: "5%" }}></th>
                    <th style={{ width: "10%" }}>S.No</th>
                    <th style={{ width: "10%" }}>Type</th>
                    <th style={{ width: "15%" }}>AssetCode</th>
                    <th style={{ width: "15%" }}>Name</th>
                    {/* <th style={{ width: '15%' }}>Documents</th> */}
                    <th style={{ width: "25%" }}>Queries(Documents)</th>
                    <th style={{ width: "15%" }}>Actions</th>
                  </tr>
                </thead>
                <tbody style={{ textAlign: "start" }}>
                  {[...assetCode].reverse().map((assetCode, index) => {
                    return (
                      <tr
                        key={index}
                        style={{
                          borderBottom: "1px solid #e5e5e5",
                          paddingRight: "15px",
                          height: "50px",
                        }}
                      >
                        <td> </td>
                        <td>{(index + 1).toString().padStart(2, "0")}</td>

                        <td>{assetCode.type}</td>
                        <td>{assetCode.assetCode}</td>
                        <td>{assetCode.name}</td>
                        {/* <td>{assetCode.documents.length}</td> */}
                        <td>{assetCode.query.length || "-"}</td>
                        <td
                          style={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                            marginTop: "10px",
                          }}
                        >
                          <Icon.Pen
                            size={20}
                            style={{ marginRight: "10px", cursor: "pointer" }}
                            onClick={() => handleEditOpen(assetCode)}
                          />
                          <Icon.EyeFill
                            size={20}
                            style={{ marginRight: "10px", cursor: "pointer" }}
                            onClick={() => handleViewOpen(assetCode)}
                          />
                          <Icon.Trash
                            size={20}
                            style={{ marginLeft: "10px", cursor: "pointer" }}
                            onClick={() => handleTrash(assetCode._id)}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      )}
      {showForm && (
        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth="md" // Controls max width, you can also use 'sm', 'lg', 'xl', or false for no max width
          fullWidth // Makes the dialog take up the full width specified by maxWidth
          PaperProps={{
            style: {
              height: "715px", // Set your desired height here
              width: "922px", // Set your desired width here
              maxWidth: "none",
            },
          }}
        >
          <div className="flex h-[50px] bg-[#4D4D4D] items-center">
            <h3 className="w-full text-white text-center"> Asset Code </h3>
            <RxCross1
              size={20}
              style={{
                marginLeft: "auto",
                cursor: "pointer",
                marginRight: "25px",
                color: "white",
              }}
              onClick={handleClose}
            />
          </div>

          <section className="mt-[50px] pr-[45px] pl-[25px] ">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "75px",
              }}
            >
              {/* Type Field */}
              <div style={{ flex: 1 }}>
                <label
                  htmlFor="type"
                  className="text-l text-start py-2 font-medium"
                >
                  Type
                  <span style={{ color: "black" }}>*</span>
                </label>

                <select
                  id="type"
                  name="type"
                  value={formData.type}
                  onChange={handleChange}
                  className="form-control  mt-[5px]"
                  style={{
                    width: "100%",
                    backgroundColor: "#F3F3F3",
                    height: "50px",
                  }}
                >
                  <option value="">Select Type</option>
                  <option value="Vehicle">Vehicle</option>
                  <option value="Machinery">Machinery</option>
                  <option value="Power Tools">Power Tools</option>
                  <option value="Distribution Board">Distribution Board</option>
                  <option value="Batching Point">Batching Point</option>
                </select>
                {errors.type && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.type}
                  </p>
                )}
              </div>

              {/* Asset Code Field */}
              <div style={{ flex: 1 }}>
                <label
                  htmlFor="type"
                  className="text-l text-start py-2 font-medium"
                >
                  Asset Code
                  <span style={{ color: "black" }}>*</span>
                </label>
                <input
                  type="text"
                  id="assetCode"
                  name="assetCode"
                  value={formData.assetCode}
                  onChange={handleChange}
                  fontSize="12px"
                  placeholder="Enter your asset code"
                  className="form-control mt-[5px] mb-[15px]"
                  style={{
                    width: "100%",
                    textTransform: "uppercase",
                    backgroundColor: "#F3F3F3",
                    height: "50px",
                  }}
                />
                {errors.assetCode && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.assetCode}
                  </p>
                )}
                {errorMessage && (
                  <div style={{ color: "red", fontSize: "15px" }}>
                    {errorMessage}
                  </div>
                )}
              </div>
            </div>

            <div>
              <label
                htmlFor="type"
                className="text-l text-start py-2 font-medium"
              >
                Name
                <span style={{ color: "black" }}>*</span>
              </label>
              <input
                type="text"
                id="Name"
                name="Name"
                value={formData.Name}
                onChange={handleChange}
                placeholder="Enter your Machinery Name"
                className="form-control  mt-[5px] mb-[15px] h-[50px]"
                style={{
                  width: "100%",
                  textTransform: "uppercase",
                  backgroundColor: "#F3F3F3",
                }}
              />
              {errors.Name && (
                <p style={{ color: "red", fontSize: "12px" }}>{errors.Name}</p>
              )}
            </div>
            {/* Query fields */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <label
                  htmlFor="addQueryButton"
                  className="text-l text-start py-2 font-medium"
                >
                  Add your Queries-Equipment Details
                  <span style={{ color: "black" }}>*</span>
                  <span
                    style={{
                      fontSize: "12px",
                      color: "#999",
                      marginLeft: "8px",
                    }}
                  >
                    DOCUMENT - (ID/SODT COPY)
                  </span>
                </label>
                <label
                  htmlFor="addQueryButton"
                  className="fs-10 fw-bold text-start py-2"
                  style={{ display: "inline-block" }}
                ></label>
              </div>
              <button
                className="btn btn-primary"
                onClick={addQuery}
                style={{
                  marginTop: "10px",
                  borderRadius: "100px",
                  height: "25px",
                  width: "25px",
                  backgroundColor: "#198754",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                id="addQueryButton"
              >
                +
              </button>
            </div>
            {(formData.queries || []).map((query, index) => (
              <>
                <div
                  key={index}
                  style={{
                    marginTop: "20px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="text"
                    id={`query${index}`}
                    name={`query${index}`}
                    value={query}
                    onChange={(e) => handleQueryChange(index, e.target.value)}
                    placeholder="Enter a query"
                    className="form-control fs-12"
                    style={{
                      flex: "1 0 auto",
                      marginRight: "10px",
                      width: "80%",
                      backgroundColor: "#F3F3F3",
                    }} // Updated background color
                  />
                  <button
                    className="btn btn-outline-danger btn-sm"
                    onClick={() => removeQuery(index)}
                    style={{ flex: "0 0 auto" }}
                  >
                    Delete
                  </button>
                </div>
                <div>
                  {errors[`query${index}`] && (
                    <p
                      style={{
                        color: "red",
                        fontSize: "12px",
                        marginLeft: "10px",
                      }}
                    >
                      {errors[`query${index}`]}
                    </p>
                  )}
                </div>
              </>
            ))}

            {/* Drawing fields */}
            {/* <div style = {{display: 'flex', justifyContent: 'space-between', marginTop: '10px'}}>
                <label htmlFor="addbutton" className="fs-10 fw-bold text-start py-2">
                    Add Document
                    <span style={{ color: 'red' }}>*</span>
                  </label>
                  <button
                  className="btn btn-primary"
                  onClick={addDrawing}
                  style={{ marginTop: '10px' }}
                  id = 'addbutton'
                >
                  Add Name
                </button>
              </div>
              {formData.drawings.map((drawing, index) => (
                <>
                <div key={index} style={{ marginTop: '20px', display: 'flex', alignItems: 'center' }}>
                  <input
                    type="text"
                    id={`drawing${index}`}
                    name={`drawing${index}`}
                    value={drawing}
                    onChange={(e) => handleDrawingChange(index, e.target.value)}
                    placeholder="Enter a drawing"
                    className="form-control fs-12"
                    style={{ flex: '1 0 auto', marginRight: '10px', width: '80%', backgroundColor: '#F3F3F3' }}
                  />
                  <button
                    className="btn btn-outline-danger btn-sm"
                    onClick={() => removeDrawing(index)}
                    style={{ flex: '0 0 auto' }}
                  >
                    Delete
                  </button>
                </div>
                <div>
                  {errors[`drawing${index}`] && <p style={{ color: 'red', fontSize: '12px', marginLeft: '10px' }}>{errors[`drawing${index}`]}</p>}
                </div>
                </>
              ))} */}

            <div
              style={{
                marginTop: "100px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* <button
                  style={{ padding: '8px', borderRadius: '5px', border: '1px solid lightgray', backgroundColor: 'transparent', width: '100px' }}
                  onMouseOver={(e) => e.target.style.backgroundColor = 'lightgray'}
                  onMouseOut={(e) => e.target.style.backgroundColor = 'transparent'}
                  onClick={() => setShowForm(false)}
                >
                  Cancel
                </button> */}
              <button
                style={{
                  marginBottom: "20px",
                  padding: "10px",
                  borderRadius: "5px",
                  width: "175px",
                  backgroundColor: "var(--primary-color)",
                  color: "white",
                }}
                className="btn "
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </section>
        </Dialog>
      )}
      <Dialog
        open={viewDialogOpen}
        onClose={handleViewClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{ style: { borderRadius: 20, fontFamily: "Poppins" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "0px",
          }}
        >
          <p
            style={{ marginLeft: "25px", fontSize: "25px", marginTop: "20px" }}
          >
            Details Of AssetCode
          </p>
          <Icon.XCircleFill
            size={20}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "25px",
            }}
            onClick={handleViewClose}
          />
        </div>
        <DialogContent
          style={{ padding: "25px", minWidth: "300px", minHeight: "300px" }}
        >
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              rowGap: "20px",
              columnGap: "20px",
              paddingLeft: "30px",
              paddingRight: "30px",
            }}
          >
            <div>
              <strong>Type</strong>
            </div>
            <div>:</div>
            <div>{singleAssetCodeData?.type}</div>
            <div>
              <strong>Asset Code</strong>
            </div>
            <div>:</div>
            <div>{singleAssetCodeData?.assetCode}</div>
            <div>
              <strong>Name</strong>
            </div>
            <div>:</div>
            <div>{singleAssetCodeData?.name}</div>
            <div>
              <strong>Document Names</strong>
            </div>
            <div>:</div>
            <div>{singleAssetCodeData?.documents.join(", ")}</div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={editDialogOpen}
        onClose={handleEditClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{ style: { borderRadius: 20, fontFamily: "Poppins" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "0px",
          }}
        >
          <p
            style={{ marginLeft: "25px", fontSize: "25px", marginTop: "20px" }}
          >
            Edit AssetCode
          </p>
          <Icon.XCircleFill
            size={20}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "25px",
            }}
            onClick={handleEditClose}
          />
        </div>
        <DialogContent
          style={{ padding: "25px", minWidth: "300px", minHeight: "300px" }}
        >
          <section>
            <div>
              <label htmlFor="type" className="fs-10 fw-bold text-start py-2">
                Type
                <span style={{ color: "red" }}>*</span>
              </label>
              <select
                id="type"
                name="type"
                value={editData.type}
                onChange={handleEditChange}
                className="form-control fs-12"
                style={{ width: "100%" }}
              >
                <option value="">Select Type</option>
                <option value="Vehicle">Vehicle</option>
                <option value="Machinery">Machinery</option>
                <option value="Power tools">PowerTools</option>
              </select>
              {errors.type && (
                <p style={{ color: "red", fontSize: "12px" }}>{errors.type}</p>
              )}
            </div>
            <div>
              <label
                htmlFor="assetCode"
                className="fs-10 fw-bold text-start py-2"
              >
                Asset Code
                <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                id="assetCode"
                name="assetCode"
                value={editData.assetCode}
                onChange={handleEditChange}
                placeholder="Enter your SCode"
                className="form-control fs-12"
                style={{ width: "100%", textTransform: "uppercase" }}
              />
              {errors.assetCode && (
                <p style={{ color: "red", fontSize: "12px" }}>
                  {errors.assetCode}
                </p>
              )}
              {errorMessage && (
                <div style={{ color: "red", fontSize: "15px" }}>
                  {errorMessage}
                </div>
              )}
            </div>

            <div>
              <label htmlFor="Name" className="fs-10 fw-bold text-start py-2">
                Name
                <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                id="Name"
                name="Name"
                value={editData.Name}
                onChange={handleEditChange}
                placeholder="Enter your Machinery Name"
                className="form-control fs-12"
                style={{ width: "100%", textTransform: "uppercase" }}
              />
              {errors.Name && (
                <p style={{ color: "red", fontSize: "12px" }}>{errors.Name}</p>
              )}
            </div>

            {/* Drawing fields */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              <label
                htmlFor="addbutton"
                className="fs-10 fw-bold text-start py-2"
              >
                Add Document
                <span style={{ color: "red" }}>*</span>
              </label>
              <button
                className="btn btn-primary"
                onClick={addEditDrawing}
                style={{ marginTop: "10px" }}
                id="addbutton"
              >
                Add Name
              </button>
            </div>
            {editData.drawings.map((drawing, index) => (
              <>
                <div
                  key={index}
                  style={{
                    marginTop: "20px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="text"
                    id={`drawing${index}`}
                    name={`drawing${index}`}
                    value={drawing}
                    onChange={(e) =>
                      handleEditDrawingChange(index, e.target.value)
                    }
                    placeholder="Enter a drawing"
                    className="form-control fs-12"
                    style={{
                      flex: "1 0 auto",
                      marginRight: "10px",
                      width: "80%",
                    }}
                  />
                  <button
                    className="btn btn-outline-danger btn-sm"
                    onClick={() => removeEditDrawing(index)}
                    style={{ flex: "0 0 auto" }}
                  >
                    Delete
                  </button>
                </div>
                <div>
                  {errors[`drawing${index}`] && (
                    <p
                      style={{
                        color: "red",
                        fontSize: "12px",
                        marginLeft: "10px",
                      }}
                    >
                      {errors[`drawing${index}`]}
                    </p>
                  )}
                </div>
              </>
            ))}

            {/* Query fields */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              <label
                htmlFor="addQueryButton"
                className="fs-10 fw-bold text-start py-2"
              >
                Add Query
                <span style={{ color: "red" }}>*</span>
              </label>
              <button
                className="btn btn-primary"
                onClick={addEditQuery}
                style={{ marginTop: "10px" }}
                id="addQueryButton"
              >
                Add Query
              </button>
            </div>
            {(editData.queries || []).map((query, index) => (
              <>
                <div
                  key={index}
                  style={{
                    marginTop: "20px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="text"
                    id={`query${index}`}
                    name={`query${index}`}
                    value={query}
                    onChange={(e) =>
                      handleEditQueryChange(index, e.target.value)
                    }
                    placeholder="Enter a query"
                    className="form-control fs-12"
                    style={{
                      flex: "1 0 auto",
                      marginRight: "10px",
                      width: "80%",
                    }}
                  />
                  <button
                    className="btn btn-outline-danger btn-sm"
                    onClick={() => removeEditQuery(index)}
                    style={{ flex: "0 0 auto" }}
                  >
                    Delete
                  </button>
                </div>
                <div>
                  {errors[`query${index}`] && (
                    <p
                      style={{
                        color: "red",
                        fontSize: "12px",
                        marginLeft: "10px",
                      }}
                    >
                      {errors[`query${index}`]}
                    </p>
                  )}
                </div>
              </>
            ))}

            <div
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                style={{
                  padding: "8px",
                  borderRadius: "5px",
                  border: "1px solid lightgray",
                  backgroundColor: "transparent",
                  width: "100px",
                }}
                onMouseOver={(e) =>
                  (e.target.style.backgroundColor = "lightgray")
                }
                onMouseOut={(e) =>
                  (e.target.style.backgroundColor = "transparent")
                }
                onClick={handleEditClose}
              >
                Cancel
              </button>
              <button
                style={{
                  marginLeft: "50px",
                  padding: "10px",
                  borderRadius: "5px",
                  width: "100px",
                }}
                className="btn btn-primary"
                onClick={handleEditSubmit}
              >
                Submit
              </button>
            </div>
          </section>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AssetCodeCreation;
