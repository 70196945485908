import React, { useState, useEffect } from 'react';
import styles from './LogReportStatus.module.css';
import TimePeriodOption from './TimePeriodOption';

const LogReportStatus = () => {
  const [selectedOption, setSelectedOption] = useState('By Date');
  const [dateRange, setDateRange] = useState({ from: '', to: '' });
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear()); // Store the current year

  const timePeriodOptions = [
    { label: 'By Date', isSelected: selectedOption === 'By Date' },
    { label: 'By Month', isSelected: selectedOption === 'By Month' },
    { label: 'Last 6 Months', isSelected: selectedOption === 'Last 6 Months' },
    { label: 'Year', isSelected: selectedOption === 'Year' },
  ];

  useEffect(() => {
    if (selectedOption === 'Last 6 Months') {
      const today = new Date();
      const sixMonthsAgo = new Date(today);
      sixMonthsAgo.setMonth(today.getMonth() - 6);

      // Format dates
      const formatDate = (date) => {
        return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
      };

      setDateRange({
        from: formatDate(sixMonthsAgo),
        to: formatDate(today),
      });
    }
  }, [selectedOption]);

  // Generate an array of years for the dropdown, e.g., from 1900 to current year
  const generateYearOptions = () => {
    const years = [];
    const currentYear = new Date().getFullYear();
    for (let year = currentYear; year >= 1900; year--) {
      years.push(year);
    }
    return years;
  };

  return (
    <section className={styles.container}>
      <div className={styles.content}>
        <h1 className={styles.title}>Log Report Status</h1>

        <div className={styles.reportTypeContainer}>
          <label htmlFor="reportType" className={styles.label}>Report Type:</label>
          <select id="reportType" className={styles.select}>
            <option>Select date</option>
          </select>
          <div style={{ display: 'flex', flex: '1' }}>
            <label htmlFor="equipmentType" className={styles.equipmentTypeLabel}>Equipment Type:</label>
            <select id="equipmentType" className={styles.select}>
              <option>Select date</option>
            </select>
          </div>
        </div>

        <h2 className={styles.timePeriodTitle}>Select Time Period</h2>
        <div className={styles.timePeriodOptions}>
          {timePeriodOptions.map((option) => (
            <TimePeriodOption
              key={option.label}
              label={option.label}
              isSelected={option.isSelected}
              onSelect={() => setSelectedOption(option.label)}
            />
          ))}
        </div>
        {selectedOption === 'By Date' && (
          <div className={styles.reportTypeContainer}>
            <label htmlFor="fromDate" className={styles.label}>From:</label>
            <input type="date" id="fromDate" className={styles.dateInput} />
            <div style={{ display: 'flex', flex: '1' }}>
              <label htmlFor="toDate" className={styles.equipmentTypeLabel}>To:</label>
              <input type="date" id="toDate" className={styles.dateInput} />
            </div>
          </div>
        )}
        {selectedOption === 'By Month' && (
          <div className={styles.reportTypeContainer}>
            <label htmlFor="month" className={styles.label}>Month:</label>
            <select id="month" className={styles.select}>
            <option value="">Select Month</option>
              <option value="1">January</option>
              <option value="2">February</option>
              <option value="3">March</option>
              <option value="4">April</option>
              <option value="5">May</option>
              <option value="6">June</option>
              <option value="7">July</option>
              <option value="8">August</option>
              <option value="9">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </select>
            <div style={{ display: 'flex', flex: '1' }}>
              <label htmlFor="year" className={styles.equipmentTypeLabel}>Year:</label>
              <input type="number" id="year" className={styles.dateInput} placeholder="YYYY" min="1900" max="2099" />
            </div>
          </div>
        )}
        {selectedOption === 'Last 6 Months' && (
          <div className={styles.reportTypeContainer}>
            <p className={styles.dateRangeText}>{dateRange.from} to {dateRange.to}</p>
          </div>
        )}
        {selectedOption === 'Year' && (
          <div className={styles.reportTypeContainer}>
            <label htmlFor="selectYear" className={styles.label}>Select Year:</label>
            <select
              id="selectYear"
              className={`${styles.select} ${styles.selectDropdown}`} // Use both styles for the select element
              value={currentYear}
              onChange={(e) => setCurrentYear(e.target.value)}
            >
              {generateYearOptions().map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>
        )}

        <div className={styles.actionButtons}>
          <button type="submit" className={styles.actionButton}>GO</button>
          <button type="reset" className={styles.actionButton}>RESET</button>
        </div>
      </div>
    </section>
  );
};

export default LogReportStatus;