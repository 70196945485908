import React, { useState, useRef, useEffect } from "react";
import { FileSizeValidation, PhoneNumFormat } from "./FieldDependants";
import { GetTimein12HrFormat } from "../CommonWidgets";
import { ButtonCustom } from "../Buttons";

import { FaRegEye } from "react-icons/fa";
import { RiEyeCloseFill } from "react-icons/ri";
import {
  Select,
  Chip,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Divider,
} from "@mui/material";
import { MobileTimePicker } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { MdCancel } from "react-icons/md";
import { FiPaperclip } from "react-icons/fi";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import swal from "sweetalert";
import { TiDelete } from "react-icons/ti";

//Genaral Input Field (optimal for type = text, number, date)
const InputField = (props) => {
  return (
    <>
      <div hidden={props.hidden} className={`${props.style} w-80`}>
        <label
          htmlFor={props.name}
          className={` text-gray-700 text-sm font-semibold`}
        >
          {props.label}
          <span className={props.label2Style + `  `}>{props.label2}</span>
          {/* <span hidden={props.notImp} className="text-red-600">
            *
          </span> */}
        </label>
        <input
          className={
            props.className +
            ` shadow border appearance-none rounded w-full mt-1 py-2 px-3 text-gray-700 leading-tight focus:outline-none`
          }
          name={props.name}
          id={props.id}
          data-cy={props.dataCy}
          ref={props.innerRef || null}
          readOnly={props.readOnly ? true : false}
          disabled={props.disabled ? true : false}
          type={props.type}
          required={!props.notImp}
          placeholder={props.placeholder}
          value={props.value}
          onChange={props.onChange}
          {...props.customProps}
          maxLength={props.maxLength}
        />
        <span
          hidden={props.errorText ? false : true}
          className="text-red-10 text-sm font-semibold"
        >
          {props.errorText || ""}
        </span>
        <span
          hidden={props.errorText ? true : false}
          className="text-sm text-gray-400/100 font-semibold"
        >
          {props.hintText || ""}
        </span>
      </div>
    </>
  );
};

export default InputField;

//Customised Phone Number input feild
export const InputPhoneNumField = (props) => {
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState(
    PhoneNumFormat(props.value.replace(/^\+1/, ""))
  );

  useEffect(() => {
    if (props.value.trim()) {
      handleInputChange(props.value.replace(/^\+1/, ""));
    }
  }, [props.value]);

  const handleInputChange = (number) => {
    const formattedNumber = PhoneNumFormat(number);
    const rawPhoneNumber = formattedNumber.replace(/[^\d]/g, "");
    setFormattedPhoneNumber(formattedNumber);
    if (rawPhoneNumber) {
      props.setValue("+1" + rawPhoneNumber);
    } else {
      props.setValue("");
    }
  };

  return (
    <>
      <label
        htmlFor={props.name}
        hidden={props.hidden}
        className={props.style + ` w-80 text-gray-700 text-sm font-semibold`}
      >
        {props.label}
        <span hidden={props.notImp} className="text-red-600">
          *
        </span>
        <span className="flex mt-1 items-center">
          <select
            name="countryCode"
            className="bg-gray-50 border rounded text-gray-900 text-sm focus:border-blue-52 w-fit p-2"
          >
            <option value="+1"> US (+1) </option>
          </select>
          <input
            className={
              props.className +
              ` shadow border appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none`
            }
            name={props.name}
            id={props.id}
            data-cy={props.dataCy}
            type="text"
            placeholder={props.placeholder}
            value={formattedPhoneNumber}
            onChange={(e) => handleInputChange(e.target.value)}
          />
        </span>
        <span
          hidden={props.errorText ? false : true}
          className="text-red-10 text-sm"
        >
          {props.errorText || ""}
        </span>
        <span
          hidden={props.errorText ? true : false}
          className="text-sm text-gray-400/100"
        >
          {props.hintText || ""}
        </span>
      </label>
    </>
  );
};

//Customised Password field
export const InputPasswordField = (props) => {
  const [type, setType] = useState("password");

  const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;

  // Function to check if the password matches the regex
  const isPasswordValid = (password) => {
    return passwordRegex.test(password);
  };

  const passwordFormat = () => {
    const passwordFormatClassName = isPasswordValid(props.value)
      ? "text-green-500"
      : "text-red-600";
    return `
      Password format:
      - Must contain at least 8 characters
      - Must contain at least one uppercase letter
      - Must contain at least one lowercase letter
      - Must contain at least one digit
      - May contain special characters
    `;
  };

  return (
    <>
      <label
        title={passwordFormat()}
        htmlFor={props.name}
        hidden={props.hidden}
        className={props.style + ` w-80 text-gray-700 text-sm font-semibold`}
      >
        {props.label}
        <span hidden={props.notImp} className="text-red-600">
          *
        </span>
        <span className="flex items-center justify-end mt-1">
          <input
            className={
              props.className +
              ` shadow border appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none`
            }
            name={props.name}
            id={props.id}
            type={type}
            placeholder={props.placeholder}
            value={props.value}
            onChange={props.onChange}
          />
          <FaRegEye
            className={
              type === "password" ? `hidden` : `block w-8 h-5 absolute`
            }
            onClick={(e) => setType("password")}
          />
          <RiEyeCloseFill
            className={type === "text" ? `hidden` : `block w-8 h-5 absolute`}
            onClick={(e) => setType("text")}
          />
        </span>
        <span
          hidden={props.errorText ? false : true}
          className="text-red-10 text-sm"
        >
          {props.errorText || ""}
        </span>
        <span
          hidden={props.errorText ? true : false}
          className="text-sm text-gray-400/100"
        >
          {props.hintText || ""}
        </span>
      </label>
    </>
  );
};

//Customised Textarea Field
export const InputTextArea = (props) => {
  return (
    <>
      <label
        htmlFor={props.name}
        hidden={props.hidden}
        className={props.style + ` w-80 text-gray-700 text-sm font-semibold`}
      >
        {props.label}
        {/* <span hidden={props.notImp} className="text-red-600">
          *
        </span> */}
        <textarea
          className={
            props.className +
            ` shadow border appearance-none rounded w-full mt-1 py-2 px-3 text-gray-700 leading-tight focus:outline-none`
          }
          name={props.name}
          id={props.id}
          data-cy={props.dataCy}
          type={props.type}
          required={!props.notImp}
          placeholder={props.placeholder}
          value={props.value}
          onChange={props.onChange}
        />
        <span
          hidden={props.errorText ? false : true}
          className="text-red-10 text-sm"
        >
          {props.errorText || ""}
        </span>
        <span
          hidden={props.errorText ? true : false}
          className="text-sm text-gray-400/100"
        >
          {props.hintText || ""}
        </span>
      </label>
    </>
  );
};

//Customised Select Field
export const InputSelectField = (props) => {
  return (
    <>
      <label
        htmlFor={props.name}
        hidden={props.hidden}
        className={props.style + " text-gray-700 w-80 text-sm font-semibold"}
      >
        {props.label}
        <span hidden={props.notImp} className="text-red-600">
          *
        </span>
        <select
          name={props.name}
          id={props.id}
          data-cy={props.dataCy}
          required={!props.notImp}
          disabled={props.disabled ? true : false}
          value={props.value}
          onChange={props.onChange}
          className={
            props.className +
            " mt-1 p-2.5 w-full bg-gray-50 border rounded-md text-gray-900 text-sm focus:border-blue-52"
          }
        >
          <option value="" disabled>
            {props.placeholder}
          </option>
          {props.options && props.options.length > 0 ? (
            <>
              {props.options.map((option, index) => (
                <option key={index} value={option} data-cy={option}>
                  {option}
                </option>
              ))}
            </>
          ) : (
            <option value="">No options</option>
          )}
        </select>
        <span
          hidden={props.errorText ? false : true}
          className="text-red-10 text-sm"
        >
          {props.errorText || ""}
        </span>
        <span
          hidden={props.errorText ? true : false}
          className="text-sm text-gray-400/100"
        >
          {props.hintText || ""}
        </span>
      </label>
    </>
  );
};

//Customised Input Object Select Field
export const InputObjectSelectField = (props) => {
  const isSelected =
    props.options &&
    props.options.some((option) => option[props.optionValue] === props.value);

  return (
    <>
      <label
        htmlFor={props.name}
        hidden={props.hidden}
        className={
          props.style + " text-gray-700 w-auto max-w-80 text-sm font-semibold"
        }
      >
        {props.label}
        <span hidden={props.notImp} className="text-red-600">
          *
        </span>
        <select
          name={props.name}
          id={props.id}
          data-cy={props.dataCy}
          disabled={props.disabled ? true : false}
          required={!props.notImp}
          value={isSelected ? props.value : ""}
          onChange={props.onChange}
          className={
            props.className +
            " mt-1 p-2.5 w-full bg-gray-50 border rounded-md text-gray-900 text-sm focus:border-blue-52"
          }
        >
          <option
            value={
              props.defaultPlaceholderValue ? props.defaultPlaceholderValue : ""
            }
            disabled
          >
            {props.placeholder}
          </option>
          {props.options && props.options.length > 0 ? (
            <>
              {props.options.map((option, index) => (
                <option key={index} value={option[props.optionValue]} 
                data-profile={option.last4} //this is only for connected accounts dropdown, dont use this for any other
                >
                  {props.applyMethod
                    ? props.applyMethod(option, index)
                    : option[props.optionName]}
                </option>
              ))}
            </>
          ) : (
            <option>No options</option>
          )}
        </select>
        <span
          hidden={props.errorText ? false : true}
          className="text-red-10 text-sm"
        >
          {props.errorText || ""}
        </span>
        <span
          hidden={props.errorText ? true : false}
          className="text-sm text-gray-400/100"
        >
          {props.hintText || ""}
        </span>
      </label>
    </>
  );
};

//Customised Multi Select Field
export const InputMultiSelectField = (props) => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
    variant: "menu",
  };

  const handleDelete = (e, value) => {
    e.preventDefault();
    props.setValue((cate) => props.value.filter((item) => item !== value));
  };

  return (
    <>
      <label
        htmlFor={props.name}
        hidden={props.hidden}
        className={props.style + " text-gray-700 w-80 text-sm font-semibold"}
      >
        {props.label}
        <span hidden={props.notImp} className="text-red-600">
          *
        </span>

        <Select
          className={
            props.className +
            " mt-1 w-full bg-gray-50 border text-gray-900 text-sm focus:border-blue-52"
          }
          labelId={props.id}
          name={props.name}
          data-cy={props.dataCy}
          multiple
          //variant="standard"
          value={props.value}
          //IconComponent={MdOutlineKeyboardArrowDown}
          onChange={(e) => props.setValue(e.target.value)}
          //inputProps={{ disableUnderline: true }}
          MenuProps={MenuProps}
          renderValue={(value) => (
            <span className="flex flex-wrap gap-1 ">
              {props.value.map((value) => (
                <Chip
                  key={value}
                  label={value}
                  variant="outlined"
                  deleteIcon={
                    <MdCancel
                      onMouseDown={(event) => event.stopPropagation()}
                    />
                  }
                  onDelete={(e) => handleDelete(e, value)}
                />
              ))}
            </span>
          )}
        >
          <MenuItem disabled value="">
            <em>{props.placeholder}</em>
          </MenuItem>
          {props.options.map((item) => (
            <MenuItem key={item} value={item}>
              <ListItemIcon>
                <Checkbox checked={props.value.indexOf(item) > -1} />
              </ListItemIcon>
              <ListItemText primary={item} />
            </MenuItem>
          ))}
        </Select>

        <span
          hidden={props.errorText ? false : true}
          className="text-red-10 text-sm"
        >
          {props.errorText || ""}
        </span>
        <span
          hidden={props.errorText ? true : false}
          className="text-sm text-gray-400/100"
        >
          {props.hintText || ""}
        </span>
      </label>
    </>
  );
};

//Customised File Upload Field
export const InputFileField = (props) => {
  const [inputKey, setInputKey] = useState(0);
  const fileInput = useRef(null);
  const [fileSizeError, setFileSizeError] = useState("");
  const maxSize = props.maxSizeinMB || 5;

  const handleInputValue = () => {
    if (props.multiple) {
      if (props.value.length) {
        if (props.value.length > 1)
          return props.value.length + " Files Selected";
        else return props.value[0].name;
      } else return "";
    } else return props.value.name;
  };

  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    setInputValue(props.value ? handleInputValue() : handleClearButton());
  }, [props.value]);

  const handleValidFiles = (files) => {
    const allowedExtensions = props.accept
      ? props.accept.split(",").map((type) => type.trim())
      : [];
    const validFiles = [];
    const invalidFiles = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileExtension = file.name.split(".").pop().toLowerCase();

      if (
        allowedExtensions.length === 0 ||
        allowedExtensions.includes("." + fileExtension)
      ) {
        validFiles.push(file);
      } else {
        invalidFiles.push(file);
      }
    }

    if (validFiles.length > 0) {
      let validSizeFiles = FileSizeValidation(files, props.maxSizeinMB);
      if (validSizeFiles.length) {
        if (props.multiple) {
          props.setValue && props.setValue(validFiles);
          props.onChange && props.onChange(validFiles);
          if (validFiles.length > 1)
            setInputValue(`${validFiles.length} Files Selected`);
          else setInputValue(validFiles[0].name);
        } else {
          props.setValue && props.setValue(validFiles[0]);
          props.onChange && props.onChange(validFiles[0]);
          setInputValue(validFiles[0].name);
        }
        setFileSizeError("");
      }
    }

    if (invalidFiles.length > 0) {
      swal(
        "Warning",
        `${invalidFiles.length} Invalid file types. ${
          props.accept ? "Only " + props.accept : "All"
        } files are allowed.`,
        "warning"
      );
    }
  };

  const handleClearButton = () => {
    props.setValue && props.setValue("");
    props.onChange && props.onChange("");
    setInputValue("");
    setInputKey(inputKey + 1);
  };

  return (
    <>
      <label
        htmlFor={props.name}
        hidden={props.hidden}
        className={props.style + ` text-gray-700 text-sm font-semibold`}
      >
        {props.label}
        <span hidden={props.notImp} className="text-red-600">
          *
        </span>
        <div className="flex justify-end items-center mt-1 w-full relative">
          <input
            type="file"
            key={inputKey}
            hidden={true}
            ref={fileInput}
            id="FileSelector"
            name="FileSelector"
            data-cy="FileSelector"
            accept={props.accept}
            multiple={props.multiple || false}
            onChange={(e) => {
              e.preventDefault();
              handleValidFiles(e.target.files);
              // let files = FileSizeValidation(e.target.files, props.maxSizeinMB);
              // if (files.length) {
              //   handleValidFiles(files);
              // } else {
              //   swal(
              //     "Warning",
              //     `File Size should be less than ${maxSize} MB`,
              //     "warning"
              //   );
              // }
            }}
          />
          <div className="flex justify-end items-center w-full">
            <input
              className={
                props.className +
                ` shadow border appearance-none rounded-l w-full py-2 px-3 pr-[30px] text-gray-700 leading-tight focus:outline-none`
              }
              name={props.name}
              id={props.id}
              data-cy={props.dataCy}
              readOnly={true}
              required={!props.notImp}
              placeholder={props.placeholder}
              value={inputValue}
            />
            {inputValue && (
              <TiDelete
                id="clearFileButton"
                name="clearFileButton"
                data-cy="clearFileButton"
                size={25}
                onClick={handleClearButton}
                className="cursor-pointer absolute text-red-500 mr-1"
              />
            )}
          </div>
          <ButtonCustom
            id={"Button" + props.id}
            btnName={"Button" + props.id}
            data-cy={"Button" + props.id}
            name={
              <>
                <FiPaperclip className="mr-1" /> Select
              </>
            }
            onClick={(event) => {
              event.preventDefault();
              fileInput.current.click();
            }}
            className="flex justify-center items-center w-fit rounded-r p-2 font-semibold bg-slate-300 h-full
      hover:bg-[#3D72BF] hover:text-white focus:bg-[#3D72BF] focus:text-white active:scale-95 transform transition duration-100"
          />
        </div>

        <span
          hidden={fileSizeError || props.errorText ? false : true}
          className="text-red-10 text-sm"
        >
          {fileSizeError || props.errorText || ""}
        </span>
        <span
          hidden={props.errorText || fileSizeError ? true : false}
          className="text-sm text-gray-400/100"
        >
          {props.hintText || ""}
        </span>
      </label>
    </>
  );
};

//Customised MUI Date and Time Range Field
export const InputDateAndTimeRangeField = (props) => {
  let newDate = new Date();
  const [date, setDate] = useState(null);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);

  const tomorrow = dayjs(newDate).add(1, "day");

  const styles = {
    datePicker: {
      "& .MuiInputBase-input": {
        padding: "8px",
        fontSize: "14px",
        fontWeight: 300,
      },
      "& .MuiIconButton-root": {
        padding: "8px",
      },
      "& .MuiPickersDay-daySelected": {
        backgroundColor: "#F1F5F9",
        "&:hover": {
          backgroundColor: "#006699",
        },
      },
      "& .MuiPickersDay-current": {
        color: "#F1F5F9",
        fontWeight: 300,
      },
    },
    timePicker: {
      //boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      borderRadius: "5px",
      appearance: "none",
      padding: "2px",
      color: "#4B5563",
      lineHeight: "tight",
      outline: "none",
      width: "30%",
      fontSize: "14px",
      fontWeight: 300,
    },
  };

  return (
    <>
      <label
        htmlFor={props.name}
        hidden={props.hidden}
        className={props.style + ` w-80 text-gray-700 text-sm font-semibold`}
      >
        {props.label}
        <span hidden={props.notImp} className="text-red-600">
          *
        </span>
        <span className={"flex items-center gap-[1%] mt-1 w-full"}>
          <DatePicker
            value={date}
            id={props.id}
            name={props.name}
            data-cy={props.dataCy}
            //inputFormat="MM DD YYYY"
            minDate={tomorrow}
            onChange={(e) => {
              props.setValue({
                ...props.value,
                date: dayjs(e.$d).format("MM/DD/YYYY"),
              });
              setDate(e);
            }}
            //sx={styles.datePicker}
            className={
              props.dateClassName +
              ` shadow rounded appearance-none text-gray-700 text-base focus:outline-none w-[44%]`
            }
          />
          <MobileTimePicker
            label="From"
            id="from"
            name="from"
            data-cy="from"
            renderInput={(params) => <TextField {...params} />}
            value={from}
            onChange={(e) => {
              if (e !== null) {
                props.setValue({
                  ...props.value,
                  from: GetTimein12HrFormat(e.$d),
                });
                setFrom(e);
              }
            }}
            //sx={styles.timePicker}
            className={
              props.fromClassName +
              ` shadow rounded appearance-none text-gray-700 focus:outline-none w-[27%]`
            }
          />
          <MobileTimePicker
            label="To"
            id="to"
            name="to"
            data-cy="to"
            renderInput={(params) => <TextField {...params} />}
            value={to}
            onChange={(e) => {
              if (e !== null) {
                props.setValue({
                  ...props.value,
                  to: GetTimein12HrFormat(e.$d),
                });
                setTo(e);
              }
            }}
            //sx={styles.timePicker}
            className={
              props.toClassName +
              ` shadow rounded appearance-none text-gray-700 focus:outline-none w-[27%]`
            }
          />
        </span>
        <span
          hidden={props.errorText ? false : true}
          className="text-red-10 text-sm"
        >
          {props.errorText || ""}
        </span>
        <span
          hidden={props.errorText ? true : false}
          className="text-sm text-gray-400/100"
        >
          {props.hintText || ""}
        </span>
      </label>
    </>
  );
};

//Customised Zipcode Input field
export const InputZipCodeField = (props) => {
  return (
    <label
      htmlFor={props.name}
      hidden={props.hidden}
      className={props.style + ` w-80 text-gray-700 text-sm font-semibold`}
    >
      {props.label}
      <span hidden={props.notImp} className="text-red-600">
        *
      </span>
      <input
        className={
          props.className +
          ` shadow border appearance-none rounded w-full mt-1 py-2 px-3 text-gray-700 leading-tight focus:outline-none`
        }
        name={props.name}
        id={props.id}
        readOnly={props.readOnly ? true : false}
        type="text"
        minLength={5}
        maxLength={5}
        pattern="[0-9]{5}"
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
      />
      <span
        hidden={props.errorText ? false : true}
        className="text-red-10 text-sm"
      >
        {props.errorText || ""}
      </span>
      <span
        hidden={props.errorText ? true : false}
        className="text-sm text-gray-400/100"
      >
        {props.hintText || ""}
      </span>
    </label>
  );
};

//Customised Dollars Input Field
export const InputDollarsField = (props) => {
  return (
    <>
      <label
        htmlFor={props.name}
        title={props.title}
        hidden={props.hidden}
        className={
          props.style + ` w-auto max-w-80 text-gray-700 text-sm font-semibold`
        }
      >
        {props.label}
        <span hidden={props.notImp} className="text-red-600">
          *
        </span>
        <span className="flex mt-1 items-center">
          <span className="bg-gray-50 border rounded text-gray-900 text-sm focus:border-blue-52 w-fit p-2">
            $
          </span>
          <input
            className={
              props.className +
              ` shadow border appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none`
            }
            name={props.name}
            id={props.id}
            data-cy={props.dataCy}
            type="text"
            placeholder={props.placeholder}
            // value={props.value ? props.value.toString() : ""}
            // onChange={handleInputChange}
            disabled={props.disabled ? true : false}
            value={props.value}
            onChange={(e) => {
              let newValue = "";
              if (e.target.value.replace(/[^\d]/g, "")) {
                newValue = parseInt(e.target.value.replace(/[^\d]/g, ""));
              }
              props.setValue(newValue);
            }}
            readOnly={props.readOnly ? true : false}
          />
        </span>
        <span
          hidden={props.errorText ? false : true}
          className="text-red-10 text-sm"
        >
          {props.errorText || ""}
        </span>
        <span
          hidden={props.errorText ? true : false}
          className="text-sm text-gray-400/100"
        >
          {props.hintText || ""}
        </span>
      </label>
    </>
  );
};

//Customised MUI Date Field, returns MM/DD/YYYY
export const InputDateField = (props) => {
  const datePickerRef = useRef(null);

  const styles = {
    datePicker: {
      "& .MuiInputBase-input": {
        padding: "8px",
        fontSize: "14px",
        fontWeight: 300,
        margin: "4px 0px 0px 0px",
      },
      "& .MuiIconButton-root": {
        padding: "8px",
      },
      "& .MuiPickersDay-daySelected": {
        backgroundColor: "#F1F5F9",
        "&:hover": {
          backgroundColor: "#006699",
        },
      },
      "& .MuiPickersDay-current": {
        color: "#F1F5F9",
        fontWeight: 300,
      },
      // Add the margin rule here
      margin: "4px 0 0 0",
    },
  };
  return (
    <>
      <label
        htmlFor={props.name}
        hidden={props.hidden}
        className={props.style + ` w-80 text-gray-700 text-sm font-semibold`}
      >
        {props.label}
        <span hidden={props.notImp} className="text-red-600">
          *
        </span>
        <DatePicker
          ref={datePickerRef}
          readOnly={props.readOnly}
          id={props.id}
          name={props.btnName}
          data-cy={props.dataCy}
          value={dayjs(props.value.toString())}
          onChange={(e) => {
            const value = dayjs(e.$d).format("MM/DD/YYYY");
            props.onChange(value);
          }}
          minDate={props.minDate ? dayjs(props.minDate) : undefined}
          maxDate={props.maxDate ? dayjs(props.maxDate) : undefined}
          sx={styles.datePicker}
          className={props.className + ` w-full`}
        />
        <span
          hidden={props.errorText ? false : true}
          className="text-red-10 text-sm"
        >
          {props.errorText || ""}
        </span>
        <span
          hidden={props.errorText ? true : false}
          className="text-sm text-gray-400/100"
        >
          {props.hintText || ""}
        </span>
      </label>
    </>
  );
};

//Customised MUI Year Field, returns YYYY
export const InputYearField = (props) => {
  const styles = {
    datePicker: {
      "& .MuiInputBase-input": {
        padding: "8px",
        fontSize: "14px",
        fontWeight: 300,
        margin: "4px 0px 0px 0px",
      },
      "& .MuiIconButton-root": {
        padding: "8px",
      },
      "& .MuiPickersDay-daySelected": {
        backgroundColor: "#F1F5F9",
        "&:hover": {
          backgroundColor: "#006699",
        },
      },
      "& .MuiPickersDay-current": {
        color: "#F1F5F9",
        fontWeight: 300,
      },
      // Add the margin rule here
      margin: "4px 0 0 0",
    },
  };

  let yearValue = props.value ? dayjs(props.value.toString()) : null;
  const yearPickerRef = useRef(null);

  return (
    <>
      <label
        htmlFor={props.name}
        hidden={props.hidden}
        className={props.style + ` w-80 text-gray-700 text-sm font-semibold`}
      >
        {props.label}
        <span hidden={props.notImp} className="text-red-600">
          *
        </span>
        <DatePicker
          ref={yearPickerRef}
          readOnly={props.readOnly}
          id={props.id}
          name={props.btnName}
          data-cy={props.dataCy}
          value={yearValue}
          views={["year"]}
          onChange={(e) => {
            const value = e.$y;
            props.onChange(value);
          }}
          minDate={props.minDate ? dayjs(props.minDate) : undefined}
          maxDate={props.maxDate ? dayjs(props.maxDate) : undefined}
          sx={styles.datePicker}
          className={props.className + ` w-full`}
        />
        <span
          hidden={props.errorText ? false : true}
          className="text-red-10 text-sm"
        >
          {props.errorText || ""}
        </span>
        <span
          hidden={props.errorText ? true : false}
          className="text-sm text-gray-400/100"
        >
          {props.hintText || ""}
        </span>
      </label>
    </>
  );
};

//Customised MUI Month, Year Field
export const InputMonthYearField = (props) => {
  const styles = {
    datePicker: {
      "& .MuiInputBase-input": {
        padding: "8px",
        fontSize: "14px",
        fontWeight: 300,
        margin: "4px 0px 0px 0px",
      },
      "& .MuiIconButton-root": {
        padding: "8px",
      },
      "& .MuiPickersDay-daySelected": {
        backgroundColor: "#F1F5F9",
        "&:hover": {
          backgroundColor: "#006699",
        },
      },
      "& .MuiPickersDay-current": {
        color: "#F1F5F9",
        fontWeight: 300,
      },
      // Add the margin rule here
      margin: "4px 0 0 0",
    },
  };

  const monthYearPickerRef = useRef(null);

  const getDate = (value) => {
    const dateString = value;
    const [month, year] = dateString.split(" ");

    const monthMap = {
      Jan: 0,
      Feb: 1,
      Mar: 2,
      Apr: 3,
      May: 4,
      Jun: 5,
      Jul: 6,
      Aug: 7,
      Sep: 8,
      Oct: 9,
      Nov: 10,
      Dec: 11,
    };

    const dateObject = new Date(year, monthMap[month]);
    return dayjs(dateObject);
  };

  let yearMonthValue = props.value ? getDate(props.value) : null;

  return (
    <>
      <label
        htmlFor={props.name}
        hidden={props.hidden}
        className={props.style + ` w-80 text-gray-700 text-sm font-semibold`}
      >
        {props.label}
        <span hidden={props.notImp} className="text-red-600">
          *
        </span>
        <DatePicker
          ref={monthYearPickerRef}
          readOnly={props.readOnly}
          id={props.id}
          value={yearMonthValue}
          views={["month", "year"]}
          onChange={(date) => {
            const formattedValue = date.format("MMM YYYY");
            props.onChange(formattedValue);
          }}
          minDate={props.minDate ? dayjs(props.minDate) : undefined}
          maxDate={props.maxDate ? dayjs(props.maxDate) : undefined}
          sx={styles.datePicker}
          className={props.className + ` w-full`}
        />
        <span
          hidden={props.errorText ? false : true}
          className="text-red-10 text-sm"
        >
          {props.errorText || ""}
        </span>
        <span
          hidden={props.errorText ? true : false}
          className="text-sm text-gray-400/100"
        >
          {props.hintText || ""}
        </span>
      </label>
    </>
  );
};

//Customised Chips Field
export const InputChipsField = (props) => {
  const [chips, setChips] = useState([]);

  useEffect(() => {
    if (props.value) {
      setChips(props.value);
    }
  }, [props.value]);

  const handleAddChip = (chip) => {
    setChips((prevChips) => [...prevChips, chip]);
  };

  const handleDeleteChip = (chip) => {
    setChips((prevChips) => prevChips.filter((c) => c !== chip));
  };

  return (
    <>
      <div hidden={props.hidden} className={`${props.style} w-80`}>
        <label
          htmlFor={props.name}
          className="text-gray-700 text-sm font-semibold"
        >
          {props.label}
          <span hidden={props.notImp} className="text-red-600">
            *
          </span>
        </label>
        <div>
          {chips.map((chip) => (
            <Chip
              key={chip}
              label={chip}
              onDelete={() => handleDeleteChip(chip)}
              className={props.className}
            />
          ))}
        </div>
        <input
          type="text"
          placeholder={props.placeholder}
          onKeyDown={(e) => {
            if (e.key === "Enter" && e.target.value !== "") {
              handleAddChip(e.target.value);
              e.target.value = "";
            }
          }}
        />
        <span
          hidden={props.errorText ? false : true}
          className="text-red-10 text-sm font-semibold"
        >
          {props.errorText || ""}
        </span>
        <span
          hidden={props.errorText ? true : false}
          className="text-sm text-gray-400/100 font-semibold"
        >
          {props.hintText || ""}
        </span>
      </div>
    </>
  );
};
