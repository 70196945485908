import React from 'react'
import Styles from "./CreatePassword.module.css"
import MobileLogo from "../Images/MobileLogo.png"
import CreatePasswordImg from "../Images/CreatePasswordImg.png"
import { Link } from 'react-router-dom'
const CreatePassword = () => {
  return (
    <div className={Styles.LoginContainer}>
    <div className={Styles.LeftContainer}>
     
      <form  className={Styles.FormContainer}>
      <div className={Styles.ImageContainer}>
              <img className={Styles.Logo} src="logo/logo3.png" />
            </div>
             <h5 className={Styles.LoginText}>Set a Password</h5>
             <p className={Styles.FormDescription}>
           Please set a new pasword for your account<br/>
           we'll ask for this password whenever you login
           </p>
             
            <div className={Styles.InputContainer}>
                  <div className={Styles.EmailContent}>
                    <label className={Styles.InputLabel}>Create Password</label>
                    <input type="password" className={Styles.InputFeild} 
                    placeholder="xxxxxx"
                    
                    />
                   
              
                    
                  </div>
                  <div className={Styles.PasswordContent} >
                    <label className={Styles.InputLabel}>Re-enter Password</label>
                    <input type="password" className={Styles.InputFeild} 
                    placeholder="xxxxxxx"
                    minLength="6"
                
                    />
        </div>
                  

                </div>
            <div className={Styles.SubmitBtnContainer}>
                
            <button type="submit" className={Styles.SubmitBtn}>
              <Link to="/password-successful" style={{textDecoration:"none",color:"white"}}>Set Password</Link></button>
            </div>
            <div className={Styles.HelpContainer}>
                    <p className={Styles.HelpHeading} style={{color:"#616161", fontSize:"12px",padding:"0",margin:"0"}}>Secure Password Tips.</p>
                    <p style={{fontSize:"10px",padding:"0",margin:"0"}}>&#183; Use at least 8 characters, a combination of numbers and letters is best.</p>
                    <p style={{fontSize:"10px",padding:"0",margin:"0"}}>&#183; Do not use the same password you have used with us previously</p>
                    <p style={{fontSize:"10px",padding:"0",margin:"0"}}>&#183; Do not use dictionary words, your name, e-mail address, mobile phone number or other personal information that can be easily obtained.</p>
                    <p style={{fontSize:"10px",padding:"0",margin:"0"}}>&#183; Do not use the same password for multiple online accounts.</p>
                    
            </div>
      </form>
    </div>
    <div className={Styles.RightContainer} >
    <img src={CreatePasswordImg} alt="" className={Styles.RightContainerImage} />
    </div>
    <div className={Styles.MobileContainer}>
        <div className={Styles.MobileView}>
            <div className={Styles.MobileViewLogo}>
              <img src={MobileLogo} alt="" />
              <p className={Styles.HoldOn}>Hold On!</p>
            </div> 
        </div>
        <div className={Styles.MobileMsgContainer}>
              <p className={Styles.MobileMsg}>We are currently not supporting Mobile Devices please click the below button to Download mobile application</p>
              {/* <p className={Styles.MobileMsg}>Please click the below button to Download</p> */}
              {/* <p></p> */}
              <div className={Styles.BtnContainer}>
        <button className={Styles.DownloadBtn}>
            <Link to="https://www.google.com/" style={{textDecoration:"none",color:"white"}}>Download</Link>
      </button>

        </div>
        </div>
        
        </div>
  </div>
  )
}

export default CreatePassword