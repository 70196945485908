import React from 'react'
import styles from './floating.module.css';

const FloatingImageContainer = (props) => {
    const { title, description, titleMarginTop } = props

    const formattedDescription = description.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));

  return (
    <section
        className={styles.floatingImgSection}
      >
        <div className={styles.floatingContentContainer}>
            <div className={styles.floatingImgContainer}>
                <img
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/f1912023438bf62dc5ff78e3d7702c9bafa69e25d71575fd116022719516adf0?apiKey=3e99c58a56f84e4cb0d84873c390b13e&"
                  alt={title}
                  className={styles.floatingImg}
                />
            </div>
            <div className={styles.floatingContent}>
                <h2 style={titleMarginTop?{ marginTop: titleMarginTop }:{}} className={styles.floatingTitle}>{title}</h2>
                <p className={styles.floatingDescription}>{formattedDescription}</p>
            </div>
        </div>
        <div className={styles.floatingMobileContainer}>
            <img
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/f1912023438bf62dc5ff78e3d7702c9bafa69e25d71575fd116022719516adf0?apiKey=3e99c58a56f84e4cb0d84873c390b13e&"
                alt="Why Us"
                style={{ height: '35%' }}
                />
            <div className={styles.floatingMobileContentContainer}>
                <h2 className={styles.floatingTitle}>{title}</h2>
                <p className={styles.floatingDescription}>{formattedDescription}</p>
            </div>
        </div>
    </section>
  )
}

export default FloatingImageContainer