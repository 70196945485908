import React from 'react';
import { useEffect } from 'react'
import * as Icon from "react-bootstrap-icons";

const DesignTable = (props) => {
  const { data, handleCreateForm, webApi, setData, setError, setLoading } = props

  useEffect(() => {
    webApi.get( "api/checkListForm/AllForms")
      .then((response) => {
        console.log("API response:", response);
        if (response.data && Array.isArray(response.data.data.data)) {
          setData(response.data.data.data);
          // setEditData(response.data.data.data);
          console.log(response.data.data.data);
        } else {
          setError("Unexpected data format");
          console.error("Unexpected data format:", response.data);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
        setError("There was an error fetching the data");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '20px 100px' }}>
      <table style={{ width: '100%' }}>
          <thead style={{ backgroundColor: 'lightblue', textAlign: 'center', height: '45px' }}>
              <tr>
                  <th style={{ width: '10%' }}>S.No</th>
                  <th style={{ width: '20%' }}>Heading</th>
                  
                  <th style={{ width: '15%' }}>Actions</th>
              </tr>
          </thead>
          <tbody style={{ textAlign: 'center', boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)' }}>
              {data.map((item, index) => (
                  <tr key={item._id} style={{ border: '1px solid black' }}>
                      <td>{index + 1}</td>
                      <td>{item.heading}</td>
                      <td style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          {item.description.length > 1 ? (
                              <Icon.Pen size={25} style={{ marginRight: '10px' }}  />
                          ) : (
                              <Icon.FileEarmarkPlus size={25} style={{ marginRight: '10px' }} onClick={() => handleCreateForm(index)}/>
                          )}
                      </td>
                  </tr>
              ))}
          </tbody>
      </table>
    </div>
  )
}

export default DesignTable