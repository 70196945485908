import React from "react";
import "./ToggleSwitch.css";
import { IoIosArrowForward } from "react-icons/io";

const ToggleSubModuleSwitch = ({ label, isChecked, onToggle, onIconClick }) => {
  return (
    <div className="toggle-submodule-switch">
      <span className="toggle-submodule-switch-label">{label}</span>
      <label className="switch">
        <input type="checkbox" checked={isChecked} onChange={onToggle} />
        <span className="slider round"></span>
      </label>
      <div onClick={onIconClick} style={{ cursor: "pointer" }}>
        <IoIosArrowForward />
      </div>
    </div>
  );
};

export default ToggleSubModuleSwitch;
