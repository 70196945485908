import React from "react";
import styles from "./ProductContentBlock.module.css";

function ProductContentBlock({ imageUrl, title, description, reverse }) {
  return (
    <section className={styles.productContainer}>
      <div
        className={`${styles.productRow} ${reverse ? styles.reverseRow : ""}`}
      >
        <div className={styles.imageContainer}>
          <img
            loading="lazy"
            src={imageUrl}
            className={styles.systemImage}
            alt={`${title} visual representation`}
          />
        </div>
        <div className={styles.textContainer}>
          <div className={styles.textContent}>
            <h3 className={styles.productTitle}>{title}</h3>
            <p className={styles.systemDescription}>{description}</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProductContentBlock;