import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import styles from "../Projects/Projects.module.css";
import Container from 'react-bootstrap/Container';

const Projects = ({ projectsData, readMore, from }) => {
  return (
    <section>
      {from === "overview" && (
        <h2
          className="text-center my-4 "
          style={{
            color: "#E47331",
            fontFamily: "Poppins",
            fontSize: "40px",
            fontStyle: "normal",
            fontWeight: "500",
          }}
        >
          Our Products
        </h2>
      )}
      <Container className="mt-5">
        <div className="row" style={{ marginTop: "20px" }}>
          {projectsData.map((project, index) => (
            <div key={index} className="col-12 col-md-6 col-lg-4 mb-4">
              <div className="card projectCard" style={{ border: "none" }}>
                <img
                  src={project.image}
                  alt={project.title}
                  className="card-img-top"
                />
                <div className="card-body">
                  <h5
                    className="card-title"
                    style={{
                      color: "#000",
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: "500",
                    }}
                  >
                    {project.title}
                  </h5>
                  <p
                    className="card-text"
                    style={{
                      color: "#4D4D4D",
                     
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontWeight: "300",
                      
                    }}
                  >
                    {project.desc}
                  </p>
                  {readMore && (
                    <a
                      href={project.readMorelk}
                      className={` ${styles.projectReadMore}`}
                    >
                      Read More
                    </a>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default Projects;
