import React, { useState, useEffect } from "react";
import * as Icon from "react-bootstrap-icons";
import { Dialog, DialogContent } from "@mui/material";
import { Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { WebApimanager } from "../../../WebApiManager";
import AddDrawingDialog from "./Components/AddDrawingDialog";
import { todayDate } from "../../../Utilities/DateUtils";
import { useRecoilState } from "recoil";
import { registerAtom, siteId } from "../../../atoms";
import MultiSelectSearch from "../../../Utilities/MultiSelectSearch";
import { RxCross1 } from "react-icons/rx";
import { Tabs, Tab, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import TableWrapper from "../../../Utilities/TableWrapper";
import { Loader } from "../../../Widgets/notificationFeedbacks";
import ReceivedTable from "./ReceivedTable";
import SearchDropdownIdVal from "../../../Widgets/SearchDropdownIdVal";
import { processFile, updateRegister } from "../BackendUtils";
import { SUCESS_STATUS } from "../../../Utilities/Constants";
import DrawingTableWrapper from "../Components/DrawingTableWrapper";

const ApprovedTable = () => {
  const navigate = useNavigate();

  const formatDate = (dateString) => {
    if (!dateString) return "-";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
  };

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const tomorrowDateString = tomorrow.toISOString().split("T")[0];

  let webApi = new WebApimanager();

  const [open, setOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [singleDrawingData, setSingleDrawingData] = useState(null);
  const [error, setError] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [searchActive, setSearchActive] = useState(false);
  const [drawingList, setDrawingList] = useState([]);
  const [initialDrawingList, setInitialDrawingList] = useState([]);
  const [registerList, setRegisterList] = useState([]);
  const [editData, setEditData] = useState({
    drawingId: "",
    drawingNumber: "",
    expectedDate: "",
    requestedDate: todayDate(),
    remarks: "",
    drawingFileName: null,
    revision: [],
    natureofrequestedInfo: "",
  });
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });
  const [site, setSite] = useRecoilState(siteId);
  const [selectedRevision, setSelectedRevision] = useState("");

  // const handleSiteChange = (e) => {
  //   setSiteId(e.target.value);
  // };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
    fetchData();
  };
  const handleEditOpen = (drawingId) => {
    const drawingItem = drawingList.find((item) => item._id === drawingId);
    if (drawingItem) {
      const combinedRevisions = drawingItem.acceptedSiteRevisions.flatMap(
        (rev) => rev.revision
      );
      setEditData({
        drawingId: drawingId,
        drawingNumber: drawingItem.drawingNo,
        expectedDate: "", // Consider initializing this with a valid date if needed
        requestedDate: todayDate(),
        remarks: "",
        drawingFileName: null,
        selectedRevision: "", // Make sure this is included
        revision: combinedRevisions, // Ensure this is set as an array
        natureofrequestedInfo: "", // Ensure this key is initialized if used elsewhere
      });

      setEditDialogOpen(true);
    }
  };
  const handleRevisionSelection = (event) => {
    setSelectedRevision(event.target.value);
  };

  const handleEditClose = () => {
    setEditDialogOpen(false);
    setEditData({
      drawingId: "",
      drawingNumber: "",
      expectedDate: "",
      remarks: "",
      drawingFileName: null,
      revision: [],
      natureofrequestedInfo: [],
    });
  };
  const [selectedTab, setSelectedTab] = useState(0);

  // const handleChevronClick = (index) => {
  //   setExpandedRevision(expandedRevision === index ? null : index);
  // };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleViewOpen = (drawingItem) => {
    setSingleDrawingData(drawingItem);
    setViewDialogOpen(true);
  };

  const handleViewClose = () => {
    setSingleDrawingData(null);
    setViewDialogOpen(false);
  };

  const [registerInfo, setRegisterInfo] = useRecoilState(registerAtom);

  const fetchInData = async () => {
    if (registerInfo && registerInfo.length > 0) {
      setDrawingList(registerInfo);
      setInitialDrawingList(registerInfo);
      console.log("fetched from recoil", registerInfo);
    } else {
      await updateRegister(setRegisterInfo, site).then((response) => {
        if (response.status === SUCESS_STATUS) {
          setDrawingList(response.registers);
          setInitialDrawingList(response.registers);
        }
      });
    }
  };

  const fetchData = async () => {
    await updateRegister(setRegisterInfo, site).then((response) => {
      if (response.status === SUCESS_STATUS) {
        setDrawingList(response.registers);
        setInitialDrawingList(response.registers);
      }
    });
  };

  useEffect(() => {
    fetchInData();
  }, []);
 
  
  // const handleDownload = async (id, revision) => {
  //   setApiProcessing({
  //     loader: true,
  //     message: "Downloading...",
  //   });
  //   try {
  //     // Request the file with binary response handling
  //     //TODO: need to check this path correct or not
  //     const response = await webApi.getImage(
  //       `api/architectureToRoRegister/${id}/revisionsSite/${revision}`,
  //       {
  //         responseType: "arraybuffer", // Expect binary data
  //       }
  //     );

  //     processFile(response, "download", navigate); // Process file for viewing
  //   } catch (error) {
  //     console.error("Error downloading file:", error);
  //   } finally {
  //     setApiProcessing({ loader: false, message: "" });
  //   }
  // };

  const handleDownload = async (id, revision) => {
    setApiProcessing({
      loader: true,
      message: "Downloading...",
    });

    try {
      // Request the DWG file with binary response handling
      const response = await webApi.getImage(
        `api/architectureToRoRegister/${id}/revisionsSite/${revision}`,
        {
          responseType: "arraybuffer", // Expect binary data
        }
      );

      // Convert the response to a Blob and create a download link
      const blob = new Blob([response.data], { type: response.headers['content-type'] || 'application/octet-stream' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `DrawingFile_${revision}.dwg`); // Set the file name with .dwg extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Clean up the DOM
    } catch (error) {
      console.error("Error downloading file:", error);
    } finally {
      setApiProcessing({ loader: false, message: "" });
    }
};


  const handleViewFile = async (id, revision) => {
    setApiProcessing({
      loader: true,
      message: "Viewing...",
    });
    try {
      // Request the file with binary response handling
      const response = await webApi.getImage(
        `api/architectureToRoRegister/${id}/revisionsSite/${revision}`,
        {
          responseType: "arraybuffer", // Expect binary data
        }
      );

      processFile(response, "view", navigate); // Process file for downloading
    } catch (error) {
      console.error("Error viewing file:", error);
    } finally {
      setApiProcessing({ loader: false, message: "" });
    }
  };

  const listItems = [
    { id: "A - Missing Information", name: "A - Missing Information" },
    { id: "B - Clarification", name: "B - Clarification" },
    { id: "C - Additional Information", name: "C - Additional Information" },
    { id: "D - Uncoordinated Drawings", name: "D - Uncoordinated Drawings" },
  ];

  // Function to get the name from id
  const getNameFromId = (id) => {
    const item = listItems.find((item) => item.id === id);
    return item ? item.name : "";
  };

  const [currentView, setCurrentView] = useState("upload");

  const handleTabViewChange = (event, newValue) => {
    setCurrentView(newValue);
  };

  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("");

  const searchResult = (e) => {
    const searchInput = e.target.value.trim().toLowerCase(); // Convert search input to lowercase and trim any extra spaces
    setSearchValue(searchInput);
    setSearchActive(true);

    if (!searchInput) {
      fetchData(); // Fetch all drawings again if the search input is empty
      setSearchActive(false);
    } else {
      const filteredDrawings = initialDrawingList.filter((drawingItem) => {
        // Convert each field to lowercase for case-insensitive comparison
        const drawingNumber = drawingItem.drawingNo?.toLowerCase() || "";
        const drawingName = drawingItem.drawingTitle?.toLowerCase() || "";
        const consultant =
          drawingItem.designDrawingConsultant?.role.toLowerCase() || "";
        const category = drawingItem.category?.category?.toLowerCase() || "";
        const acceptedROSubmissionDate = drawingItem.acceptedROSubmissionDate
          ? new Date(drawingItem.acceptedROSubmissionDate)
              .toLocaleDateString()
              .toLowerCase()
          : "";
        const submittedROSubmissionDate = drawingItem.submittedROSubmissionDate
          ? new Date(drawingItem.submittedROSubmissionDate)
              .toLocaleDateString()
              .toLowerCase()
          : "";
        const dueDays = calculateDueDays(drawingItem.acceptedArchitectDate)
          .toString()
          .toLowerCase(); // Convert dueDays to lowercase for comparison
        const dueStatus =
          dueDays >= 0
            ? `${dueDays} days`
            : `${Math.abs(dueDays)} days overdue`;
        const revisions =
          drawingItem.acceptedSiteRevisions
            ?.map((revisionItem) => revisionItem.revision.toLowerCase())
            .join(" ") || "";

        // Filter based on the selected filter criteria
        switch (selectedFilter) {
          case "Drawing No":
            return drawingNumber.includes(searchInput);
          case "Drawing Consultant":
            return consultant.includes(searchInput);
          case "Drawing Category":
            return category.includes(searchInput);
          case "Drawing Title":
            return drawingName.includes(searchInput);
          case "Accepted RO Submission Date":
            return acceptedROSubmissionDate.includes(searchInput);
          case "Final Submission Date":
            return submittedROSubmissionDate.includes(searchInput);
          case "Due/Overdue":
            return (
              dueStatus.includes(searchInput) || dueDays.includes(searchInput)
            );
          case "Revision":
            return revisions.includes(searchInput);
          default:
            return (
              drawingNumber.includes(searchInput) ||
              drawingName.includes(searchInput) ||
              consultant.includes(searchInput) ||
              category.includes(searchInput) ||
              acceptedROSubmissionDate.includes(searchInput) ||
              submittedROSubmissionDate.includes(searchInput) ||
              dueStatus.includes(searchInput) ||
              dueDays.includes(searchInput) ||
              revisions.includes(searchInput)
            );
        }
      });

      setDrawingList(filteredDrawings); // Update the drawing list with filtered results
    }
  };

  const handleDropdownClick = (filter) => {
    setSelectedFilter(filter);
    setShowDropdown(false); // Close dropdown after selection
  };
  const placeholderText = selectedFilter
    ? `Search by ${selectedFilter}`
    : "Search Drawing Lists";

  const calculateDueDays = (
    acceptedROSubmissionDate,
    softCopySubmittedDate = null
  ) => {
    if (!acceptedROSubmissionDate) return "-";

    const dateToCompare = softCopySubmittedDate
      ? new Date(softCopySubmittedDate)
      : new Date();
    const acceptedDate = new Date(acceptedROSubmissionDate);
    const differenceInTime = acceptedDate.getTime() - dateToCompare.getTime();
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24)); // convert time difference to days

    return differenceInDays;
  };

  return (
    <>
      <TableWrapper
        children={
          <>
            <div
              style={{
                display: "flex",
                marginLeft: "5px",
                marginRight: "5px",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex" }}>
                <h3 style={{ color: "var(--primary-color)", fontSize: "20px" }}>
                  Drawing Lists
                </h3>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    position: "relative",
                    width: "250px",
                    paddingLeft: "20px",
                    height: "50px",
                  }}
                >
                  <input
                    placeholder={placeholderText}
                    onChange={searchResult}
                    style={{
                      paddingLeft: "20px",
                      borderRadius: "6px",
                      border: "1px solid var(--primary-color)",
                      width: "250px",
                      height: "40px",
                    }}
                  />
                  <Icon.Search
                    style={{
                      position: "absolute",
                      right: "-9px",
                      top: "40%",
                      transform: "translateY(-50%)",
                    }}
                  />
                </div>
                <Button
                  style={{
                    height: "40px",
                    marginLeft: "40px",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "var(--primary-color)",
                    border: "1px solid var(--primary-color)",
                  }}
                  onClick={handleClickOpen}
                >
                  <Icon.PlusCircle size={20} style={{ marginRight: "5px" }} />{" "}
                  Add New
                </Button>
              </div>
            </div>
            <div>
              {currentView === "upload" ? (
                <DrawingTableWrapper
                  tableHead={
                    <tr>
                      <th style={{ width: "5%", paddingLeft: "10px" }}>S.No</th>
                      <th style={{ width: "10%" }}>Drawing No</th>
                      <th style={{ width: "10%" }}>Drawing Consultant</th>
                      <th style={{ width: "10%" }}>Drawing Category</th>
                      <th style={{ width: "10%" }}>Drawing Title</th>
                      <th style={{ width: "10%" }}>Release Date</th>
                      <th style={{ width: "10%" }}>Revision</th>
                      <th style={{ width: "10%" }}>Action</th>
                    </tr>
                  }
                  tableBody={
                    <tbody>
                      {drawingList &&
                        [
                          ...drawingList
                            .filter(
                              (item) => item.acceptedSiteRevisions.length > 0
                            ) // Items with revisions
                            .sort((a, b) => {
                              const consultantA = a.designDrawingConsultant
                                ?.role
                                ? a.designDrawingConsultant?.role.toLowerCase()
                                : "";
                              const consultantB = b.designDrawingConsultant
                                ?.role
                                ? b.designDrawingConsultant?.role.toLowerCase()
                                : "";
                              return consultantA.localeCompare(consultantB); // Sort alphabetically
                            }),
                        ].map((drawingItem, index) => (
                          <tr
                            key={index}
                            style={{
                              backgroundColor:
                                index % 2 === 0
                                  ? "var(--table-oddrow-color)"
                                  : "var(--table-evenrow-color)",
                            }}
                          >
                            <td>{index + 1}</td>
                            <td>{drawingItem?.drawingNo || ""}</td>
                            <td>
                              {drawingItem.designDrawingConsultant?.role || "-"}
                            </td>
                            <td>{drawingItem.category?.category || ""}</td>
                            <td>{drawingItem?.drawingTitle || ""}</td>
                            <td>
                              {formatDate(
                                drawingItem.acceptedSiteRevisions[0]
                                  ?.softCopySubmittedDate
                              ) || "N/A"}
                            </td>

                            {/* <td>
                  {drawingItem.acceptedSiteRevisions.length > 0 ? (
                    <div>{drawingItem.acceptedSiteRevisions.map((revisionItem) => `${revisionItem.revision}` || 'NA').join(', ')}</div>
                  ) : (
                    <div>-</div>
                  )}
                </td>
         */}
                            <td>
                              {drawingItem.acceptedSiteRevisions.length > 0 ? (
                                <div>
                                  {drawingItem.acceptedSiteRevisions
                                    .slice(-4) // Show only the latest four revisions
                                    .map(
                                      (revisionItem, idx) =>
                                        `${revisionItem.revision}` || "NA"
                                    )
                                    .join(", ")}
                                </div>
                              ) : (
                                <div>-</div>
                              )}
                            </td>
                            <td
                              style={{
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                                marginTop: "20px",
                                paddingLeft: "0px",
                              }}
                            >
                              {/* <Icon.Pen size={20} style={{ marginRight: '5px', cursor: 'pointer' }} onClick={() => handleEditOpen(drawingItem._id)} /> */}
                              <Icon.EyeFill
                                size={20}
                                style={{
                                  marginLeft: "10px",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleViewOpen(drawingItem)}
                              />
                              {/* <Icon.Download size={20} style={{ marginLeft: '5px', cursor: 'pointer' }}
                    onClick={() => {
                      const latestRevision = drawingItem.acceptedSiteRevisions.length > 0
                        ? drawingItem.acceptedSiteRevisions[drawingItem.acceptedSiteRevisions.length - 1].revision
                        : null;
                      handleDownload(drawingItem._id, latestRevision);
                    }}
                  /> */}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  }
                />
              ) : (
                <ReceivedTable filterCondition="approved" />
              )}
            </div>
          </>
        }
      />
      <AddDrawingDialog
        handleDialogClose={handleDialogClose}
        open={open}
        site={site}
      />
      <Dialog
        open={viewDialogOpen}
        onClose={handleViewClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            borderRadius: 20,
            fontFamily: "Poppins",
            borderRadius: "10px",
            background: "#FFF",
            // boxShadow: "0px 0px 10px 0px #F3F3F3",
            // width: '922px',height: '1060px'
          },
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
          }}
        >
          <p
            style={{ margin: "5px 400px", textAlign: "center", color: "white" }}
          >
            View
          </p>
          <RxCross1
            size={20}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "20px",
              color: "white",
            }}
            onClick={handleViewClose}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            marginTop: "20px",
            marginBottom: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "0",
              border: "1px solid var(--primary-color)",
            }}
          >
            {singleDrawingData?.acceptedSiteRevisions?.length > 0 ? (
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                indicatorColor="transparent" // Hides the default indicator
                textColor="inherit" // Ensures text color is handled manually
                style={{ backgroundColor: "transparent" }}
              >
                {singleDrawingData.acceptedSiteRevisions.map(
                  (revisionItem, index) => (
                    <Tab
                      key={index}
                      label={`${revisionItem.revision}`}
                      style={{
                        backgroundColor:
                          singleDrawingData.acceptedSiteRevisions.length ===
                            1 || selectedTab === index
                            ? "var(--primary-color)"
                            : "transparent",
                        color:
                          singleDrawingData.acceptedSiteRevisions.length ===
                            1 || selectedTab === index
                            ? "white"
                            : "var(--primary-color)",
                        width: `${
                          100 / singleDrawingData.acceptedSiteRevisions.length
                        }%`,
                        height: "30px",
                        minWidth: "20px",
                        cursor: "pointer",
                        borderRight:
                          index <
                          singleDrawingData.acceptedSiteRevisions.length - 1
                            ? "1px solid var(--primary-color)"
                            : "none",
                      }}
                    />
                  )
                )}
              </Tabs>
            ) : (
              <div>No Revisions</div>
            )}
          </div>
        </div>
        {singleDrawingData?.acceptedSiteRevisions?.length > 0 && (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              {singleDrawingData?.acceptedSiteRevisions?.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10px",
                  }}
                >
                  {selectedTab ===
                  singleDrawingData.acceptedSiteRevisions.length - 1 ? (
                    <div style={{ color: "green" }}>Good For Construction</div>
                  ) : (
                    <div style={{ color: "red" }}>Superseeded</div>
                  )}
                </div>
              )}
            </div>
          </>
        )}
        <DialogContent
          style={{ padding: "25px", minWidth: "300px", minHeight: "300px" }}
        >
          <>
            <Loader
              open={apiProcessing.loader}
              message={apiProcessing.message}
            />
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gap: "20px",
                padding: "20px",
                color: "#4D4D4D",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "28px",

                justifyContent: "center",
                marginLeft: "80px",
              }}
            >
              <div>Drawing Number</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.drawingNo || "NA"}
              </div>

              <div>Design Consultant</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.designDrawingConsultant
                  ?.designDrawingConsultant || "NA"}
              </div>

              <div>Category</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.category?.category || "NA"}
              </div>

              <div>Drawing Title</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.drawingTitle || "NA"}
              </div>

              <div>Scheduled submission date</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.acceptedROSubmissionDate
                  ? new Date(singleDrawingData.acceptedROSubmissionDate)
                      .toISOString()
                      .split("T")[0]
                  : "NA"}
              </div>

              {singleDrawingData?.acceptedSiteRevisions?.length > 0 && (
                <>
                  {singleDrawingData.acceptedSiteRevisions[selectedTab] && (
                    <>
                      <div>Actual Final Submission Date</div>
                      <div>:</div>
                      <div style={{ marginLeft: "-120px" }}>
                        {singleDrawingData.acceptedSiteRevisions[selectedTab]
                          .softCopySubmittedDate
                          ? formatDate(
                              singleDrawingData.acceptedSiteRevisions[
                                selectedTab
                              ].softCopySubmittedDate
                            )
                          : "No Submitted Date"}
                      </div>
                    </>
                  )}
                </>
              )}

              {singleDrawingData?.acceptedSiteRevisions?.length > 0 && (
                <>
                  {singleDrawingData.acceptedSiteRevisions[selectedTab] && (
                    <>
                      <div>Revision</div>
                      <div>:</div>
                      <div style={{ marginLeft: "-120px" }}>
                        {singleDrawingData.acceptedSiteRevisions[selectedTab]
                          .revision || "NA"}
                      </div>
                    </>
                  )}
                </>
              )}

              <div>Due / Overdue</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                <span
                  style={{
                    color:
                      singleDrawingData &&
                      singleDrawingData.acceptedSiteRevisions &&
                      singleDrawingData.acceptedSiteRevisions.length > 0
                        ? calculateDueDays(
                            singleDrawingData.acceptedROSubmissionDate,
                            singleDrawingData.acceptedSiteRevisions[0]
                              ?.softCopySubmittedDate
                          ) >= 0
                          ? "green"
                          : "red"
                        : "black",
                  }}
                >
                  {singleDrawingData &&
                  singleDrawingData.acceptedSiteRevisions &&
                  singleDrawingData.acceptedSiteRevisions.length > 0
                    ? calculateDueDays(
                        singleDrawingData.acceptedROSubmissionDate,
                        singleDrawingData.acceptedSiteRevisions[0]
                          ?.softCopySubmittedDate
                      ) === "-"
                      ? "NA"
                      : calculateDueDays(
                          singleDrawingData.acceptedROSubmissionDate,
                          singleDrawingData.acceptedSiteRevisions[0]
                            ?.softCopySubmittedDate
                        ) >= 0
                      ? `${calculateDueDays(
                          singleDrawingData.acceptedROSubmissionDate,
                          singleDrawingData.acceptedSiteRevisions[0]
                            ?.softCopySubmittedDate
                        )} days due`
                      : `${Math.abs(
                          calculateDueDays(
                            singleDrawingData.acceptedROSubmissionDate,
                            singleDrawingData.acceptedSiteRevisions[0]
                              ?.softCopySubmittedDate
                          )
                        )} days overdue`
                    : "-"}
                </span>
              </div>

              {singleDrawingData?.acceptedSiteRevisions?.length > 0 &&
                singleDrawingData?.acceptedSiteRevisions[selectedTab]
                  ?.issuesInRevision?.length > 0 && (
                  <>
                    <div>Issues Reasons:</div>
                    <div style={{ marginLeft: "-120px" }}>
                      {singleDrawingData.acceptedSiteRevisions[
                        selectedTab
                      ].issuesInRevision.join(", ") || "NA"}
                    </div>
                  </>
                )}

              {singleDrawingData?.acceptedSiteRevisions?.length > 0 && (
                <>
                  {singleDrawingData.acceptedSiteRevisions[selectedTab] && (
                    <>
                      <div>Drawing File </div>
                      <div>:</div>
                      <div style={{ display: "flex", marginLeft: "-120px" }}>
                        <Icon.Image
                          size={20}
                          style={{ marginRight: "5px", cursor: "pointer" }}
                          onClick={() =>
                            handleViewFile(
                              singleDrawingData._id,
                              singleDrawingData.acceptedSiteRevisions[
                                selectedTab
                              ].revision
                            )
                          }
                        />
                            <Icon.Download
                              size={20}
                              style={{ marginRight: "10px", cursor: "pointer" }}
                              onClick={() => handleDownload(singleDrawingData._id, singleDrawingData.acceptedSiteRevisions[
                                selectedTab
                              ].revision)}
                            />
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ApprovedTable;
