import React, { useState, useEffect } from "react";
import * as Icon from "react-bootstrap-icons";
import { Dialog, DialogContent } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import Navbar from "../Navbar/Header";
import { WebApimanager } from "../../WebApiManager";
import TableHead from "./TableHead";
import DesignTable from "./DesignTable";
import FormTable from "./FormTable";
import ApprovalPending from "./ApprovalPending";
import ChecklistUiForm from "./ChecklistUiForm";
import Approved from "./Approved";
import { useRecoilState } from "recoil";
import { siteId } from "../../atoms";
import SelectSite from "../../Utilities/SelectSite";
import Footer from "../Footer/Footer";

function Checklist() {
  let webApi = new WebApimanager();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [activeHeader, setActiveHeader] = useState("design");
  const [heading, setHeading] = useState("");
  const [category, setCategory] = useState("");
  const [department, setDepartment] = useState("");
  const [data, setData] = useState([]); // Add this line
  const [editData, setEditData] = useState([]);
  const [idData, setIdData] = useState();
  const [error, setError] = useState(null); // Add this line
  const [loading, setLoading] = useState(true); // Add this line
  const token = localStorage.getItem("token");
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [approvalDialogOpen, setApprovalDialogOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [users, setUsers] = useState([]);
  const [datasites, setDataSites] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [statusData, setStatusData] = useState([]);
  const [site, setSite] = useRecoilState(siteId);

  const [formData, setFormData] = useState({
    project: "",
    block: "",
    floor: "",
    flat: "",
    startDate: "",
    // grid: '',
    endDate: "",
    refOrg: "",
    // revNoDate: '',
    description: [], // Initialize description as an empty array
    approvalRequest: [],
    heading: "",
  });
  useEffect(() => {
    if (idData) {
      const descriptions = idData.description.map((description) => {
        return {
          heading: description,
          value: "",
          remarks: "",
        };
      });
      setFormData({
        ...formData,
        heading: idData.heading || "",
        description: descriptions || [],
      });
    }
  }, [idData]);

  const [formErrors, setFormErrors] = useState({
    siteName: "",
    project: "",
    block: "",
    floor: "",
    flat: "",
    startDate: "",
    grid: "",
    endDate: "",
    refOrg: "",
    revNoDate: "",
    approvedBy: [],
  });

  const handleStatusSubmit = () => {
    console.log("Submit button clicked");
    const errors = {};

    if (!formData.siteName) {
      errors.siteName = "Site is required";
    }
    if (!formData.project) {
      errors.project = "Project is required";
    }
    if (!formData.block) {
      errors.block = "Block is required";
    }
    if (!formData.floor) {
      errors.floor = "Floor is required";
    }
    if (!formData.flat) {
      errors.flat = "Flat is required";
    }
    if (!formData.startDate) {
      errors.startDate = "Start Date is required";
    }
    // if(!formData.grid){
    //   errors.grid = 'Grid is required';
    // }
    if (!formData.endDate) {
      errors.endDate = "End Date is required";
    }
    if (!formData.refOrg) {
      errors.refOrg = "Reference Organization is required";
    }

    // if(!formData.revNoDate){
    //   errors.revNoDate = 'Revision Number and Date is required';
    // }
    if (!formData.approvalRequest) {
      errors.approvalRequest = "Approval User is required";
    }

    //TODO: keep validation for descriptions @bharathi
    // Set errors if any
    setFormErrors(errors);

    // If no errors, submit the form
    if (Object.keys(errors).length === 0) {
      // Your submit logic here
      console.log("Form submitted:", formData);

      // Make API call to store form data
      webApi
        .post("api/checklistSubmit/formSubmit", formData)
        .then((response) => {
          console.log("Form data stored successfully:", response.data);
          // Reload the page or perform any other action as needed
          window.location.reload();
        })
        .catch((error) => {
          console.error("Error storing form data:", error);
          // Handle error as needed
        });
    } else {
      console.log("Form has errors:", errors);
    }
  };

  const handleStatusChange = (item, status) => {
    // TODO: need to change this logic sometimes text can repeat so need to check it properly
    const updatedDescriptions = formData.description.map((obj) => {
      if (obj.heading === item.heading) {
        return {
          ...obj,
          value: status,
        };
      }
      return obj;
    });

    setFormData((prevData) => ({
      ...prevData,
      description: updatedDescriptions,
    }));
  };
  useEffect(() => {
    if (idData) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        heading: idData.heading,
      }));
    }
  }, [idData]);

  const handleRemarksChange = (item, e) => {
    const { value } = e.target;

    const updatedDescriptions = formData.description.map((obj) => {
      if (obj.heading === item.heading) {
        return {
          ...obj,
          remarks: value,
        };
      }
      return obj;
    });

    setFormData((prevData) => ({
      ...prevData,
      description: updatedDescriptions,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    // TODO: for now we are doing with single user so keeping directly in list for multi user need to change logic
    if (name === "approvalRequest") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: [value],
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  const handleClick = (header) => {
    setActiveHeader(header);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleHeadingChange = (event) => {
    setHeading(event.target.value);
  };
  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };
  const handleDepartmentChange = (event) => {
    setDepartment(event.target.value);
  };
  const handleEditDialogOpen = (id) => {
    fetchData(id);
    console.log(id);
    setEditDialogOpen(true);
  };
  const handleApprovalDialogOpen = (id) => {
    fetchData(id);
    console.log(id);
    setEditDialogOpen(true);
  };
  const fetchData = async (id) => {
    setError(null);
    setLoading(true); // Assuming you also want to set loading to true at the start
    try {
      const response = await webApi.get(`api/checkListForm/form/${id}`);
      console.log("API response:", response);
      if (response.data && Array.isArray(response.data.data.description)) {
        setIdData(response.data.data);
      } else {
        setError("Unexpected data format");
        console.error("Unexpected data format:", response.data);
      }
    } catch (error) {
      console.error("There was an error fetching the data!", error);
      setError("There was an error fetching the data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedItemId) {
      fetchData(selectedItemId);
    }
  }, [selectedItemId]);

  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
    setSelectedItemId(null);
    setEditData([]);
  };
  const handleApprovalClose = () => {
    setApprovalDialogOpen(false);
  };

  const handleSubmit = () => {
    const errors = {};

    if (!heading.trim() || !department.trim() || !category.trim()) {
      setError("Heading,category and Department are required.");
      console.log("Heading,category and Department are required.");
      return; // Exit function early if heading or department is empty
    }

    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      webApi
        .post("api/checkListForm/form", {
          heading: heading,
          category: category,
          department: department,
        })
        .then((response) => {
          const data = response.data;
          console.log(response.data);
          console.log("Server Response:", response);
          console.log("Data:", data);
          handleClose();
          window.location.reload();
        })

        .catch((error) => {
          console.error("There was an error submitting the form!", error);
        });
    } else {
      console.log("Form has errors:", errors);
    }
  };

  const handleCreateForm = (index) => {
    const updatedData = [...data];
    updatedData[index].formCreated = true;
    console.log(updatedData[index]);
    setData(updatedData);
    const checklistId = updatedData[index]._id;
    navigate("/create-checklist", {
      state: {
        heading: data[index].heading,
        category: data[index].category,
        department: data[index].department,
        checklistId: checklistId,
      },
    });
  };

  useEffect(() => {
    webApi
      .get("api/users")
      .then((response) => {
        // console.log("API response:", response);
        if (response.data && Array.isArray(response.data.data.allUsers)) {
          setUsers(response.data.data.allUsers);
          // console.log(response.data.data.machinary);
        } else {
          setError("Unexpected data format");
          console.error("Unexpected data format:", response.data);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
        setError("There was an error fetching the data");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    webApi
      .get("api/sites/AllSites")
      .then((response) => {
        if (response.data && Array.isArray(response.data.sites)) {
          setDataSites(response.data.sites);
          console.log(response.data.sites);
        } else {
          setError("Unexpected data format");
          console.error("Unexpected data format:", response.data);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
        setError("There was an error fetching the data");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    webApi
      .get("api/planner")
      .then((response) => {
        console.log("API response:", response);
        if (response.data && Array.isArray(response.data.data.allPlans)) {
          setTasks(response.data.data.allPlans);
          console.log(response.data.data.allPlans);
        } else {
          setError("Unexpected data format");
          console.error("Unexpected data format:", response.data);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
        setError("There was an error fetching the data");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    webApi
      .get("api/checkListSubmit/AllFormsSumitted")
      .then((response) => {
        console.log("API response:", response);
        if (response.data && Array.isArray(response.data.data.checklists)) {
          setStatusData(response.data.data.checklists);
          // setEditData(response.data.data.data);
          console.log(response.data.data.checklists);
        } else {
          setError("Unexpected data format");
          console.error("Unexpected data format:", response.data);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
        setError("There was an error fetching the data");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <div>
        {site === "" ? (
          <>
            <SelectSite />
          </>
        ) : (
          <>
            <div style={{ marginTop: "50px" }}>
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <h3 style={{ color: "brown" }}>CheckLists</h3>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div
                    style={{
                      position: "relative",
                      width: "250px",
                      paddingLeft: "20px",
                      marginLeft: "200px",
                      height: "50px",
                    }}
                  >
                    {" "}
                    {/* Adjust width as needed */}
                    <input
                      placeholder="Search CheckLists"
                      style={{
                        paddingLeft: "20px",
                        borderRadius: "10px",
                        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
                        border: "none",
                        width: "250px",
                        height: "100%",
                      }}
                    />
                    <Icon.Search
                      style={{
                        position: "absolute",
                        right: "1px",
                        top: "50%",
                        transform: "translateY(-50%)",
                      }}
                    />
                  </div>
                  <Button
                    style={{
                      background: "none",
                      color: "blue",
                      height: "50px",
                      marginLeft: "30px",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={handleClickOpen}
                  >
                    <Icon.PlusCircle size={20} style={{ marginRight: "5px" }} />{" "}
                    Add New
                  </Button>
                </div>
              </div>
              <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
                <DialogContent
                  style={{
                    padding: "25px",
                    minWidth: "300px",
                    minHeight: "300px",
                  }}
                >
                  {/* Some content here */}
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h4>Checklist</h4>
                      <div onClick={(e) => e.stopPropagation()}>
                        <Icon.X size={25} onClick={handleClose} />
                        <br />
                        {/* <Icon.Trash size={25}/> */}
                      </div>
                    </div>
                    <label
                      style={{ marginBottom: "10px" }}
                      className="fs-10 fw-bold text-start py-2"
                    >
                      Heading <a style={{ color: "red" }}>*</a>
                    </label>
                    <input
                      style={{
                        width: "100%",
                        height: "50px",
                        marginBottom: "20px",
                        padding: "10px",
                        border: "none",
                        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
                      }}
                      value={heading}
                      placeholder="Enter the heading"
                      className="form-control fs-12"
                      onChange={handleHeadingChange}
                    />
                    <label
                      style={{ marginBottom: "10px" }}
                      className="fs-10 fw-bold text-start py-2"
                    >
                      Category <a style={{ color: "red" }}>*</a>
                    </label>
                    <select
                      id="category"
                      name="category"
                      value={category}
                      className="form-control fs-12"
                      style={{
                        width: "100%",
                        height: "50px",
                        marginBottom: "20px",
                        padding: "10px",
                        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
                      }}
                      onChange={handleCategoryChange}
                    >
                      <option value="">Select A Category</option>
                      <option value="Finishing"> Finishing</option>
                      <option value="Structural">Structural</option>
                      <option value="Others">Others</option>
                    </select>
                    <label
                      style={{ marginBottom: "10px" }}
                      className="fs-10 fw-bold text-start py-2"
                    >
                      Department <a style={{ color: "red" }}>*</a>
                    </label>
                    <select
                      id="department"
                      name="department"
                      value={department}
                      className="form-control fs-12"
                      style={{
                        width: "100%",
                        height: "50px",
                        marginBottom: "20px",
                        padding: "10px",
                        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
                      }}
                      onChange={handleDepartmentChange}
                    >
                      <option value="">Select A Department</option>
                      <option value="Management"> Management</option>
                      <option value="Execution">Execution</option>
                      <option value="Planning">Planning</option>
                      <option value="SiteManagement">SiteManagement</option>
                      <option value="QualitySurveyorBilling">
                        Quality Survey Or Billing
                      </option>
                      <option value="MEP">MEP</option>
                      <option value="EHS">EHS</option>
                      <option value="CRMTECH">CRM-TECH</option>
                      <option value="Store">Store</option>
                      <option value="Survey">Survey</option>
                      <option value="Accounts">Accounts</option>
                      <option value="Admin">Admin</option>
                    </select>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <button
                      style={{
                        backgroundColor: "#7C1034",
                        borderRadius: "10px",
                        color: "white",
                        width: "200px",
                        border: "none",
                      }}
                      // onClick={() => {handleSubmit(); handleClose();handleClick('design');}} disabled={heading.trim() === '' || title.trim() === ''}>Submit</button>
                      onClick={() => {
                        handleSubmit();
                        handleClose();
                        handleClick("design");
                      }}
                      disabled={
                        heading.trim() === "" ||
                        department.trim() === "" ||
                        category.trim() === ""
                      }
                    >
                      Submit
                    </button>
                  </div>
                </DialogContent>
              </Dialog>
              <TableHead
                handleClick={handleClick}
                activeHeader={activeHeader}
              />
              {activeHeader === "design" && (
                <DesignTable
                  data={data}
                  handleCreateForm={handleCreateForm}
                  webApi={webApi}
                  setData={setData}
                  setError={setError}
                  setLoading={setLoading}
                />
              )}
              {activeHeader === "form" && (
                <FormTable
                  handleEditDialogOpen={handleEditDialogOpen}
                  editData={editData}
                  webApi={webApi}
                  setEditData={setEditData}
                  setError={setError}
                  setLoading={setLoading}
                />
              )}
              {activeHeader === "approval" && (
                <ApprovalPending
                  handleApprovalDialogOpen={handleApprovalDialogOpen}
                  webApi={webApi}
                  setError={setError}
                  setLoading={setLoading}
                />
              )}
              {activeHeader === "approved" && (
                <Approved
                  webApi={webApi}
                  setError={setError}
                  setLoading={setLoading}
                />
              )}
              <Dialog
                open={editDialogOpen}
                onClose={handleEditDialogClose}
                maxWidth="xl"
                fullWidth
              >
                <DialogContent>
                  <Icon.X
                    size={25}
                    onClick={handleEditDialogClose}
                    style={{ marginLeft: "1050px" }}
                  />
                  <h3
                    style={{
                      margin: "0 auto",
                      borderCollapse: "collapse",
                      width: "80%",
                      textAlign: "center",
                      border: "2px solid black",
                    }}
                  >
                    <select
                      id="siteName"
                      name="siteName"
                      value={formData.siteName}
                      onChange={handleChange}
                      className="form-control fs-12"
                      style={{
                        width: "100%",
                        border: "none",
                        textAlign: "center",
                        fontSize: "20px",
                        boxSizing: "border-box",
                      }}
                    >
                      <option value="">Select a Site Location</option>
                      {datasites.map((site) => (
                        <option key={site._id} value={site._id}>
                          {site.localityName}
                        </option>
                      ))}
                    </select>
                    {formErrors.siteName && (
                      <span style={{ color: "red" }}>
                        {formErrors.siteName}
                      </span>
                    )}
                  </h3>
                  <h3
                    style={{
                      margin: "0 auto",
                      borderCollapse: "collapse",
                      width: "80%",
                      textAlign: "center",
                      border: "2px solid black",
                    }}
                  >
                    CHECKLIST
                  </h3>
                  <h3
                    style={{
                      margin: "0 auto",
                      borderCollapse: "collapse",
                      width: "80%",
                      textAlign: "center",
                      border: "2px solid black",
                    }}
                  >
                    Activity- {formData.heading}
                  </h3>
                  <table
                    style={{
                      margin: "0 auto",
                      borderCollapse: "collapse",
                      width: "80%",
                      textAlign: "center",
                      border: "2px solid black",
                    }}
                  >
                    <thead>
                      <tr>
                        <th
                          style={{
                            border: "1px solid black",
                            width: "10%",
                            height: "50px",
                          }}
                        >
                          Project
                        </th>
                        <th style={{ width: "70%" }}>
                          <input
                            type="text"
                            name="project"
                            value={formData.project}
                            onChange={handleChange}
                            // placeholder="Enter block"
                            style={{
                              width: "98%",
                              height: "50px",
                              border: "none",
                              fontSize: "20px",
                            }}
                          ></input>
                          {formErrors.project && (
                            <span style={{ color: "red" }}>
                              {formErrors.project}
                            </span>
                          )}
                        </th>
                        <th style={{ border: "1px solid black" }}>
                          System SIPL/CIVIL/034
                        </th>
                      </tr>
                    </thead>
                  </table>
                  <table
                    style={{
                      margin: "0 auto",
                      borderCollapse: "collapse",
                      width: "80%",
                      textAlign: "center",
                      border: "2px solid black",
                    }}
                  >
                    <thead>
                      <tr style={{ height: "50px" }}>
                        <th style={{ border: "1px solid black", width: "10%" }}>
                          Block:
                        </th>
                        <th style={{ border: "1px solid black", width: "10%" }}>
                          <select
                            id="block"
                            name="block"
                            value={formData.block}
                            onChange={handleChange}
                            className="form-control fs-12"
                            style={{
                              width: "100%",
                              boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
                            }}
                          >
                            <option value="">Select Block</option>
                            {datasites
                              .find(
                                (site) => site.siteName === formData.siteName
                              )
                              ?.towers.map((tower) => (
                                <option key={tower.towerId} value={tower.name}>
                                  {tower.name}
                                </option>
                              ))}
                          </select>
                          {formErrors.block && (
                            <span style={{ color: "red" }}>
                              {formErrors.block}
                            </span>
                          )}
                        </th>
                        <th style={{ border: "1px solid black", width: "10%" }}>
                          Floor:
                        </th>
                        <th style={{ border: "1px solid black", width: "10%" }}>
                          <select
                            id="floor"
                            name="floor"
                            value={formData.floor}
                            onChange={handleChange}
                            //  placeholder="Enter floor"
                            style={{
                              width: "95%",
                              border: "none",
                              fontSize: "20px",
                            }}
                          >
                            <option value="">Select Floor</option>
                            {datasites
                              .find(
                                (site) => site.siteName === formData.siteName
                              )
                              ?.towers.find(
                                (tower) => tower.name === formData.block
                              )
                              ?.floors.map((floor) => (
                                <option value={floor.name}>{floor.name}</option>
                              ))}
                          </select>
                          {formErrors.floor && (
                            <span style={{ color: "red" }}>
                              {formErrors.floor}
                            </span>
                          )}
                        </th>
                        <th style={{ border: "1px solid black", width: "10%" }}>
                          Flat:
                        </th>
                        <th style={{ border: "1px solid black", width: "10%" }}>
                          <select
                            id="unit"
                            name="flat"
                            value={formData.flat}
                            onChange={handleChange}
                            //  placeholder="Enter flat"
                            style={{
                              width: "95%",
                              border: "none",
                              fontSize: "20px",
                            }}
                          >
                            <option value="">Select Unit</option>
                            {datasites
                              .find(
                                (site) => site.siteName === formData.siteName
                              )
                              ?.towers.find(
                                (tower) => tower.name === formData.block
                              )
                              ?.floors.find(
                                (floor) => floor.name === formData.floor
                              )
                              ?.units.map((unit) => (
                                <option key={unit._id} value={unit.name}>
                                  {unit.name}
                                </option>
                              ))}
                          </select>
                          {formErrors.flat && (
                            <span style={{ color: "red" }}>
                              {formErrors.flat}
                            </span>
                          )}
                        </th>
                      </tr>
                    </thead>
                  </table>
                  <table
                    style={{
                      margin: "0 auto",
                      borderCollapse: "collapse",
                      width: "80%",
                      textAlign: "center",
                      border: "2px solid black",
                    }}
                  >
                    <thead>
                      <tr style={{ height: "50px" }}>
                        {/* <th style={{ border: '1px solid black', width: '10%' }}>Grid</th> */}
                        {/* <th style={{ border: '1px solid black', width: '50%' }}>
                                        <input 
                                        type="text"
                                        name="grid"
                                        value={formData.grid}
                                        onChange={handleChange}
                                        style={{width: '98%', border:'none',fontSize:'20px'}}/>
                                        {formErrors.grid && <span style={{ color: 'red' }}>{formErrors.grid}</span>} 
                                        </th> */}
                        <th style={{ border: "1px solid black", width: "20%" }}>
                          Checking Start Date & Time{" "}
                        </th>
                        <th style={{ border: "1px solid black", width: "25%" }}>
                          <input
                            type="date"
                            name="startDate"
                            value={formData.startDate}
                            onChange={handleChange}
                            style={{
                              width: "95%",
                              border: "none",
                              fontSize: "20px",
                            }}
                          />
                          {formErrors.startDate && (
                            <span style={{ color: "red" }}>
                              {formErrors.startDate}
                            </span>
                          )}
                        </th>
                        <th style={{ border: "1px solid black", width: "20%" }}>
                          Checking End Date & Time
                        </th>
                        <th style={{ border: "1px solid black", width: "25%" }}>
                          <input
                            type="date"
                            name="endDate"
                            value={formData.endDateDate}
                            onChange={handleChange}
                            style={{
                              width: "98%",
                              border: "none",
                              fontSize: "20px",
                            }}
                          />

                          {formErrors.endDate && (
                            <span style={{ color: "red" }}>
                              {formErrors.endDate}
                            </span>
                          )}
                        </th>
                      </tr>
                    </thead>
                  </table>
                  <table
                    style={{
                      margin: "0 auto",
                      borderCollapse: "collapse",
                      width: "80%",
                      textAlign: "center",
                      border: "2px solid black",
                    }}
                  >
                    <thead>
                      <tr style={{ height: "50px" }}>
                        <th style={{ border: "1px solid black", width: "25%" }}>
                          {/* Ref.Org (Architectural floor plan)  */}
                          Reference Drawing
                        </th>
                        <th style={{ border: "1px solid black", width: "55%" }}>
                          <input
                            type="text"
                            name="refOrg"
                            value={formData.refOrg}
                            onChange={handleChange}
                            style={{
                              width: "98%",
                              border: "none",
                              fontSize: "20px",
                            }}
                          />
                          {formErrors.refOrg && (
                            <span style={{ color: "red" }}>
                              {formErrors.refOrg}
                            </span>
                          )}
                        </th>
                        {/* <th style={{ border: '1px solid black', width: '10%' }}>Rev.No, date</th> */}
                        {/* <th style={{ border: '1px solid black', width: '25%' }}>
                                        <input 
                                        type="date"
                                        name="revNoDate"
                                        value={formData.revNoDate}
                                        onChange={handleChange}
                                        style={{width: '98%', border:'none',fontSize:'20px'}}/>
                                        {formErrors.revNoDate && <span style={{ color: 'red' }}>{formErrors.revNoDate}</span>}
                                        </th> */}
                      </tr>
                    </thead>
                  </table>

                  <table
                    style={{
                      margin: "0 auto",
                      borderCollapse: "collapse",
                      width: "80%",
                      textAlign: "center",
                      border: "2px solid black",
                    }}
                  >
                    <thead>
                      <tr style={{ height: "50px" }}>
                        <th style={{ border: "1px solid black", width: "10%" }}>
                          SI.No
                        </th>
                        <th style={{ border: "1px solid black", width: "35%" }}>
                          Description
                        </th>
                        <th style={{ border: "1px solid black", width: "15%" }}>
                          Status
                        </th>
                        <th style={{ border: "1px solid black", width: "35%" }}>
                          Remarks
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* {idData && idData.description && idData.description.map((item, index) => ( */}
                      {formData.description.map((item, index) => (
                        <tr key={index}>
                          <td style={{ border: "1px solid black" }}>
                            {index + 1}
                          </td>
                          <td
                            style={{
                              border: "1px solid black",
                              width: "50%",
                              height: "40px",
                              fontSize: "20px",
                            }}
                          >
                            {item.heading}
                          </td>
                          <td style={{ border: "1px solid black" }}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-evenly",
                              }}
                            >
                              <button
                                style={{
                                  borderRadius: "5px",
                                  padding: "3px",
                                  border: "2px solid black",
                                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                                  backgroundColor:
                                    item.value === "Yes"
                                      ? "green"
                                      : "transparent",
                                  color:
                                    item.value === "Yes" ? "white" : "black",
                                }}
                                onClick={() => handleStatusChange(item, "Yes")}
                              >
                                Yes
                              </button>
                              <button
                                style={{
                                  borderRadius: "5px",
                                  padding: "3px",
                                  border: "2px solid black",
                                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                                  backgroundColor:
                                    item.value === "No" ? "red" : "transparent",
                                  color:
                                    item.value === "No" ? "white" : "black",
                                }}
                                onClick={() => handleStatusChange(item, "No")}
                              >
                                No
                              </button>
                              <button
                                style={{
                                  borderRadius: "5px",
                                  padding: "3px",
                                  border: "2px solid black",
                                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                                  backgroundColor:
                                    item.value === "NA"
                                      ? "darkgray"
                                      : "transparent",
                                  color:
                                    item.value === "NA" ? "white" : "black",
                                }}
                                onClick={() => handleStatusChange(item, "NA")}
                              >
                                NA
                              </button>
                            </div>
                          </td>
                          <td
                            style={{
                              border: "1px solid black",
                              width: "98%",
                              height: "40px",
                              fontSize: "20px",
                            }}
                          >
                            <input
                              name={`remarks_${index}`}
                              placeholder="Enter the Remarks"
                              value={item.remarks || ""}
                              onChange={(e) => handleRemarksChange(item, e)}
                              style={{
                                width: "100%",
                                border: "none",
                                fontSize: "20px",
                              }}
                            />
                            {formErrors[`remarks_${index}`] && (
                              <span style={{ color: "red" }}>
                                {formErrors[`remarks_${index}`]}
                              </span>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "10px",
                    }}
                  >
                    <label className="fs-10 fw-bold text-start py-2">
                      Request For Approval <a style={{ color: "red" }}>*</a>:
                    </label>
                    <select
                      name="approvalRequest"
                      value={formData.approvalRequest}
                      onChange={handleChange}
                      className="form-control fs-12"
                      style={{
                        width: "200px",
                        height: "45px",
                        padding: "10px",
                        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
                      }}
                    >
                      <option value="Select">Select a user</option>
                      {users.map((user) => (
                        <option key={user._id} value={user._id}>
                          {user.firstName} {user.lastName}
                        </option>
                      ))}
                    </select>
                    {formErrors.approvalRequest && (
                      <span style={{ color: "red" }}>
                        {formErrors.approvalRequest}
                      </span>
                    )}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "15px",
                    }}
                  >
                    <button
                      style={{
                        backgroundColor: "#7C1034",
                        color: "white",
                        width: "100px",
                        border: "none",
                        marginLeft: "10px",
                      }}
                      onClick={handleApprovalClose}
                    >
                      Cancel
                    </button>
                    <button
                      style={{
                        backgroundColor: "#7C1034",
                        color: "white",
                        width: "100px",
                        border: "none",
                        marginLeft: "10px",
                      }}
                      onClick={handleStatusSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </DialogContent>
              </Dialog>
              <ChecklistUiForm
                handleCloseDialog={handleApprovalClose}
                openDialog={approvalDialogOpen}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Checklist;
