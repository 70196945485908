import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import * as Icon from "react-bootstrap-icons";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [conformCode, setConformCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [emailError, setEmailError] = useState("");
  const [conformCodeError, setConformCodeError] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const [success, setSuccess] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    setEmailError("");
    setConformCodeError("");
    setNewPasswordError("");
    setConfirmPasswordError("");
    setSuccess("");

    let hasError = false;

    if (!email) {
      setEmailError("Email is required");
      hasError = true;
    }
    if (!conformCode) {
      setConformCodeError("Conform Code is required");
      hasError = true;
    }
    if (!newPassword) {
      setNewPasswordError("New Password is required");
      hasError = true;
    }
    if (newPassword !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match");
      hasError = true;
    }
    if (!confirmPassword) {
      setConfirmPasswordError("Confirm Password is required");
      hasError = true;
    }

    if (hasError) {
      return;
    }

    setSuccess("Password successfully reset");
  };

  return (
    <div className="container d-flex justify-content-center align-items-center mt-4">
      <form
        onSubmit={handleSubmit}
        className="w-100"
        style={{ maxWidth: "400px" }}
      >
        <h2 className="mb-4 text-center">Reset Password</h2>

        <div className="mb-3">
          <label
            htmlFor="email"
            className="form-label"
            style={{ marginLeft: "0px" }}
          >
            Email
          </label>
          <div className="input-group">
            <input
              type="email"
              className={`form-control ${emailError ? "is-invalid" : ""}`}
              id="email"
              placeholder="Email ..."
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          {emailError && <div className="invalid-feedback">{emailError}</div>}
        </div>

        <div className="mb-3">
          <label
            htmlFor="conformCode"
            className="form-label"
            style={{ marginLeft: "0px" }}
          >
            Conform Code
          </label>
          <input
            type="text"
            className={`form-control ${conformCodeError ? "is-invalid" : ""}`}
            id="conformCode"
            placeholder="Enter your code"
            value={conformCode}
            onChange={(e) => setConformCode(e.target.value)}
          />
          {conformCodeError && (
            <div className="invalid-feedback">{conformCodeError}</div>
          )}
        </div>

        <div className="mb-3">
          <label
            htmlFor="newPassword"
            className="form-label"
            style={{ marginLeft: "0px" }}
          >
            New Password
          </label>
          <input
            type="password"
            className={`form-control ${newPasswordError ? "is-invalid" : ""}`}
            id="newPassword"
            placeholder="Enter new password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          {newPasswordError && (
            <div className="invalid-feedback">{newPasswordError}</div>
          )}
        </div>

        <div className="mb-3">
          <label
            htmlFor="confirmPassword"
            className="form-label"
            style={{ marginLeft: "0px" }}
          >
            Confirm Password
          </label>
          <input
            type="password"
            className={`form-control ${
              confirmPasswordError ? "is-invalid" : ""
            }`}
            id="confirmPassword"
            placeholder="Confirm new password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          {confirmPasswordError && (
            <div className="invalid-feedback">{confirmPasswordError}</div>
          )}
        </div>

        {success && <div className="alert alert-success">{success}</div>}

        {/* Submit Button */}
        <button
          type="submit"
          className="btn mt-3 w-100"
          style={{ color: "white", backgroundColor: "#E47331" }}
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default ResetPassword;
