// src/components/Tabs.jsx
import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from './Tabs.module.css'; // Import the CSS module

const Tabs = ({ children }) => {
  const [activeKey, setActiveKey] = useState(children[0].props.eventKey);

  const handleSelect = (key) => {
    setActiveKey(key);
  };

  return (
    <div className={styles.tabsContainer}>
      <div className={styles.tabs}>
        {React.Children.map(children, (child) => (
          <div
            className={`${styles.tab} ${child.props.eventKey === activeKey ? styles.active : ""}`}
            onClick={() => handleSelect(child.props.eventKey)}
          >
            {child.props.title}
          </div>
        ))}
      </div>
      <div className={styles.tabContent}>
        {React.Children.map(children, (child) =>
          child.props.eventKey === activeKey ? child.props.children : null
        )}
      </div>
    </div>
  );
};

Tabs.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Tabs;
