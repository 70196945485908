import React, {  useState, useEffect } from "react";
import * as Icon from "react-bootstrap-icons";
import Styles from "./ForgotPassword.module.css"
import PasswordImg from "./Images/PasswordImg.png"
import MobileLogo from "../SignIn/Images/MobileLogo.png"


import { Button } from "react-bootstrap"; // Import Button component from React Bootstrap
import { Link,useNavigate } from "react-router-dom";
import Navbar from "../Navbar/Header";

function ForgotPassword() {
  const [email, setEmail] = useState("");
const [emailError, setEmailError] = useState("");
const navigate = useNavigate();

const validateEmail = (email) => {
  // Regular expression for basic email validation
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
const handleSubmit = (e) => {
  e.preventDefault();

  // Check if email and password are empty
  if (!email.trim()) {
    // If both email and password are empty, display general error message
    setEmailError("Email  is required");
    return; // Exit the function to prevent further execution
  }
};

useEffect(() => {
  // Disable scrolling when the component is mounted
  document.body.style.overflow = 'hidden';

  // Re-enable scrolling when the component is unmounted
  return () => {
    document.body.style.overflow = '';
  };
}, []);

return (
  // <main className={styles.forgotPasswordContainer}>
  //   <section className={styles.contentWrapper}>
  //     <div className={styles.innerContent}>
  //       <div className={styles.twoColumnLayout}>
  //         <div className={styles.leftColumn}>
  //           <div className={styles.formContainer}>
  //             <img 
  //               loading="lazy" 
  //               src="https://cdn.builder.io/api/v1/image/assets/TEMP/241eba2f4e0a44c7b35cb008c7a23e06258fdc52625d202abdc9513a3d0bbb21?placeholderIfAbsent=true&apiKey=860967788028437b8a0095d5a96bdd20" 
  //               className={styles.logo} 
  //               alt="Company logo" 
  //             />
  //             <h1 className={styles.formTitle}>Forgot your password</h1>
  //             <p className={styles.formDescription}>
  //               Don't worry, happens to all of us. <br />
  //               Enter your email ID or phone number below to recover your password
  //             </p>
  //             <form>
  //               <label htmlFor="emailOrPhone" className={styles.inputLabel}>
  //                 Enter your email or phone number
  //               </label>
  //               <input
  //                 type="text"
  //                 id="emailOrPhone"
  //                 className={styles.inputField}
  //                 placeholder="support@mrchams.com"
  //                 aria-label="Enter your email or phone number"
  //               />
  //               <button type="submit" className={styles.submitButton}>
  //                 CONTINUE
  //               </button>
  //             </form>
  //           </div>
  //         </div>
  //         <div className={styles.rightColumn}>
  //           <div className={styles.imageContainer}>
  //             <img 
  //               loading="lazy" 
  //               src="/logo/FPW.png" 
  //               className={styles.illustrationImage} 
  //               alt="Illustration for password recovery" 
  //             />
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </section>
  // </main>
  <div className={Styles.LoginContainer}>
        <div className={Styles.LeftContainer}>
         
          <form onSubmit={handleSubmit} className={Styles.FormContainer}>
          <div className={Styles.ImageContainer}>
                  <img className={Styles.Logo} src="logo/logo3.png" />
                </div>
                 <h5 className={Styles.LoginText}>Forget Your Password</h5>
                 <p className={Styles.FormDescription}>
               Don't worry, happens to all of us. <br />
              Enter your email ID or phone number below to recover your password
               </p>
                 <div className={Styles.InputContainer}>
                  <div className={Styles.EmailContent}>
                    <label className={Styles.InputLabel}>Enter Your Email or Phone number*</label>
                    <input
                   type="text"
                   id="emailOrPhone"
                   className={Styles.InputFeild}
                   placeholder="support@mrchams.com"
                   aria-label="Enter your email or phone number"
                 />
                    {/* {emailError && (
                <p  className={Styles.Error}>
                  {emailError}
                </p>
              )} */}
                    
                  </div>
                  

                </div>
                <div className={Styles.SubmitBtnContainer}>
                <button type="submit" className={Styles.SubmitBtn}>
                  <Link to="/reset-password" style={{textDecoration:"none",color:"white"}}>Continue</Link>
                </button>
                </div>
          </form>
        </div>
        <div className={Styles.RightContainer} >
        <img src={PasswordImg} alt="" className={Styles.RightContainerImage} />
        </div>
        <div className={Styles.MobileContainer}>
        <div className={Styles.MobileView}>
            <div className={Styles.MobileViewLogo}>
              <img src={MobileLogo} alt="" />
              <p className={Styles.HoldOn}>Hold On!</p>
            </div> 
        </div>
        <div className={Styles.MobileMsgContainer}>
              <p className={Styles.MobileMsg}>We are currently not supporting Mobile Devices please click the below button to Download mobile application</p>
              {/* <p className={Styles.MobileMsg}>Please click the below button to Download</p> */}
              {/* <p></p> */}
              <div className={Styles.BtnContainer}>
        <button className={Styles.DownloadBtn}>
            <Link to="https://www.google.com/" style={{textDecoration:"none",color:"white"}}>Download</Link>
      </button>

        </div>
        </div>
        
        </div>
      </div>
);
}

export default ForgotPassword;
