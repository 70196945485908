import { Dialog, DialogContent } from '@mui/material'
import * as Icon from "react-bootstrap-icons";
import React from 'react'

const ChecklistUiForm = (props) => {

  const { handleCloseDialog, openDialog } = props

  return (
    <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="xl" fullWidth>
         <DialogContent>
              <Icon.X size={25} onClick={handleCloseDialog}style={{ marginLeft: '1050px' }} />
              <h3 style={{ margin: '0 auto', borderCollapse: 'collapse', width: '80%', textAlign: 'center', border: '2px solid black' }}></h3>
              <h3 style={{ margin: '0 auto', borderCollapse: 'collapse', width: '80%', textAlign: 'center', border: '2px solid black' }}>
                            CHECKLIST
              </h3>
              <h3 style={{ margin: '0 auto', borderCollapse: 'collapse', width: '80%', textAlign: 'center', border: '2px solid black' }}>
                            Activity- 
              </h3>
              <table style={{ margin: '0 auto', borderCollapse: 'collapse', width: '80%', textAlign: 'center', border: '2px solid black' }}>
                  <thead>
                    <tr>
                        <th style={{ border: '1px solid black', width: '10%', height: '50px' }}>Project</th>
                        <th style={{ width: '70%' }}>
                            
                        </th>
                        <th style={{ border: '1px solid black' }}>System SIPL/CIVIL/034</th>
                    </tr>
                  </thead>
              </table>
              <table style={{ margin: '0 auto', borderCollapse: 'collapse', width: '80%', textAlign: 'center', border: '2px solid black' }}>
                  <thead>
                      <tr style={{ height: '50px' }}>
                          <th style={{ border: '1px solid black', width: '10%' }}>Block:</th>
                          <th style={{ border: '1px solid black', width: '10%' }}>
                              
                          </th>
                          <th style={{ border: '1px solid black', width: '10%' }}>Floor:</th>
                          <th style={{ border: '1px solid black', width: '10%' }}>
                              
                          </th>
                          <th style={{ border: '1px solid black', width: '10%' }}>Flat:</th>
                          <th style={{ border: '1px solid black', width: '10%' }}>
                            
                          </th>
                          <th style={{ border: '1px solid black', width: '20%' }}>Checking Start Date & Time </th>
                          <th style={{ border: '1px solid black', width: '25%' }}>
                              
                          </th>
                      </tr>
                  </thead>
              </table>
              <table style={{ margin: '0 auto', borderCollapse: 'collapse', width: '80%', textAlign: 'center', border: '2px solid black' }}>
                  <thead>
                      <tr style={{ height: '50px' }}>
                          <th style={{ border: '1px solid black', width: '10%' }}>Grid</th>
                          <th style={{ border: '1px solid black', width: '50%' }}>
                          </th>
                          <th style={{ border: '1px solid black', width: '20%' }}>Checking End Date & Time</th>
                          <th style={{ border: '1px solid black', width: '25%' }}>
                          
                          </th>
                      </tr>
                  </thead>
              </table>
              <table style={{ margin: '0 auto', borderCollapse: 'collapse', width: '80%', textAlign: 'center', border: '2px solid black' }}>
                  <thead>
                      <tr style={{ height: '50px' }}>
                          <th style={{ border: '1px solid black', width: '25%' }}>Ref.Org (Architectural floor plan) </th>
                          <th style={{ border: '1px solid black', width: '55%' }}>
                              
                          </th>
                          <th style={{ border: '1px solid black', width: '10%' }}>Rev.No, date</th>
                          <th style={{ border: '1px solid black', width: '25%' }}>
                          
                          </th>
                      </tr>
                  </thead>
              </table>
              <table style={{ margin: '0 auto', borderCollapse: 'collapse', width: '80%', textAlign: 'center', border: '2px solid black' }}>
                  <thead>
                      <tr style={{ height: '50px' }} >
                          <th style={{ border: '1px solid black', width: '10%' }}>SI.No</th>
                          <th style={{ border: '1px solid black', width: '35%' }}>Description</th>
                          <th style={{ border: '1px solid black', width: '15%' }}>Status</th>
                          <th style={{ border: '1px solid black',width:'35%' }}>Remarks</th>
                      </tr>
                  </thead>
                  <tbody>
                  
                  
      <tr >
        <td style={{ border: '1px solid black' }}></td>
        <td style={{ border: '1px solid black', width: '50%', height: '40px', fontSize: '20px' }}>
    
        </td>
        <td style={{ border: '1px solid black' }}>

</td>
<td style={{ border: '1px solid black', width: '98%', height: '40px', fontSize: '20px' }}>
</td>
      </tr>

                  </tbody>
              </table>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
                <label className="fs-10 fw-bold text-start py-2">
                  Request For Approval <a style={{ color: "red" }}>*</a>:
                </label>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
              <label className="fs-10 fw-bold text-start py-2">
              Status Of Approval <a style={{ color: "red" }}>*</a>:
              </label>
              <select
                                  >
                                    <option value="">Select A Status</option>
                                    <option value="Approved"> Approved</option>
                                    <option value="Approval Pending">Approval Pending</option>
                                  </select>

              </div>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',marginTop:'15px' }}>
                  <button style={{ backgroundColor: '#7C1034', color: 'white', width: '100px', border: 'none', marginLeft: '10px' }} onClick={handleCloseDialog}>Cancel</button>
                  <button style={{ backgroundColor: '#7C1034', color: 'white', width: '100px', border: 'none', marginLeft: '10px' }}   >Submit</button>
              </div>
         </DialogContent>
    </Dialog>
  )
}

export default ChecklistUiForm