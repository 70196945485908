import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { WebApimanager } from "../../../WebApiManager";
import { siteId } from "../../../atoms";
import { useRecoilState } from "recoil";
import styles from "./Vehicle&Machinery.module.css";
import FormHeadings from "../PNMAssets/FormHeadings";
import FormItem from "../../PNM/PNMAssets/FormItem";
import st from "./PowerTools.module.css";

const BatchingPlant = () => {
  let webApi = new WebApimanager();

  const [sites, setDatasites] = useState([]);
  const [site, setSite] = useRecoilState(siteId);
  const [id, setId] = useState();

  const handleSiteChange = (e) => {
    const { name, value } = e.target;

    setSite(e.target.value);
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    webApi
      .get("api/sites/getAllSitesInfo")
      .then((response) => {
        console.log("API response:", response);
        if (response.data && Array.isArray(response.data.sites)) {
          setDatasites(response.data.sites);
          if (site === "") {
            setSite(response.data.sites[0]._id);
          }
        } else {
          console.error("Unexpected data format:", response.data);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, [site]);

  const [formData, setFormData] = useState({
    type: "",
    siteName: "",
    assetCode: "",
    equipmentType: "Batching Point",
    transitionDate: "",
    meterStartReadings: "",
    meterEndReadings: "",
    meterDifference: "",
    startDate: "",
    endDate: "",
    differenceDate: "",
    startTime: "",
    endTime: "",
    differenceTime: "",
    Engine2StartReadings: "",
    Engine2EndReadings: "",
    Engine2ReadingsDifference: "",
    scopeOfWorkDetails: "",
    location: "",
    remark: "",
    subCode: "",
    debitNote: "" || "No",
    employeeId: "",
    employeeType: "",
    debitAmount: "",
    debitReason: "",
    totalConcreteProduction: "",
  });

  const [errors, setErrors] = useState({});
  const [assetCodeData, setAssetCodeData] = useState([]);
  const [nameData, setNameData] = useState([]);

  function calculateDateDifference(startDate, endDate) {
    const [startYear, startMonth, startDay] = startDate.split("-").map(Number);
    const [endYear, endMonth, endDay] = endDate.split("-").map(Number);
    console.log(startYear, endYear);
    console.log(startMonth, endMonth);
    console.log(startDay, endDay);

    const start = new Date(startYear, startMonth - 1, startDay);
    const end = new Date(endYear, endMonth - 1, endDay);

    if (end >= start) {
      const diffTime = end.getTime() - start.getTime();
      const diffDays = diffTime / (1000 * 60 * 60 * 24);
      return Math.round(diffDays);
    } else {
      return -1;
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newFormData = {
      ...formData,
      [name]: value,
    };
    if (name === "assetCode" && value) {
      const assCode = assetCodeData.find(
        (doc, ind) => doc.assetCode === value.split("/")[0]
      );
      if (assCode) {
        console.log(assCode.name);
        setId(assCode._id);
        newFormData = {
          ...newFormData,

          ["subCode"]: assCode.subCode,
        };
        setFormData(newFormData);
      }
    }
    setFormData(newFormData);

    if (name === "startDate" || name === "endDate") {
      if (newFormData.startDate !== "" && newFormData.endDate !== "") {
        const diff = calculateDateDifference(
          newFormData.startDate,
          newFormData.endDate
        );
        setFormData({ ...newFormData, differenceDate: diff });
        if (diff < 0) {
          errors.differenceDate = "start date should be less than end Date";
          setFormData({
            ...newFormData,
            differenceDate: "",
          });
        } else {
          setFormData({ ...newFormData, differenceDate: diff });
          errors.differenceDate = "";
        }
      } else {
        setFormData({ ...newFormData, differenceDate: "" });
      }
    }

    // Calculate meter difference
    if (name === "meterStartReadings" || name === "meterEndReadings") {
      if (
        newFormData.meterStartReadings !== "" &&
        newFormData.meterEndReadings !== ""
      ) {
        const start = parseInt(newFormData.meterStartReadings, 10);
        const end = parseInt(newFormData.meterEndReadings, 10);
        const difference = end - start;
        setFormData({
          ...newFormData,
          meterDifference: difference,
        });
        if (start >= end) {
          errors.meterDifference =
            "Start Reading should be less than End Reading";
          setFormData({
            ...newFormData,
            meterDifference: "",
          });
        } else {
          errors.meterDifference = "";
        }
      } else {
        setFormData({
          ...newFormData,
          meterDifference: "",
        });
      }
    }

    // Calculate Engine2 readings difference
    if (name === "Engine2StartReadings" || name === "Engine2EndReadings") {
      if (
        newFormData.Engine2StartReadings !== "" &&
        newFormData.Engine2EndReadings !== ""
      ) {
        const start = parseInt(newFormData.Engine2StartReadings, 10);
        const end = parseInt(newFormData.Engine2EndReadings, 10);
        const difference = end - start;
        setFormData({
          ...newFormData,
          Engine2ReadingsDifference: difference,
        });
        if (start >= end) {
          errors.Engine2ReadingsDifference =
            "Start Reading should be less than End Reading";
          setFormData({
            ...newFormData,
            Engine2ReadingsDifference: "",
          });
        } else {
          errors.Engine2ReadingsDifference = "";
        }
      } else {
        setFormData({
          ...newFormData,
          Engine2ReadingsDifference: "",
        });
      }
    }

    // Calculate time difference
    if (name === "startTime" || name === "endTime") {
      if (newFormData.startTime && newFormData.endTime) {
        const start = new Date(`1970-01-01T${newFormData.startTime}`);
        const end = new Date(`1970-01-01T${newFormData.endTime}`);
        let difference = (end.getTime() - start.getTime()) / 1000; // Difference in seconds

        const hours = Math.floor(difference / 3600);
        difference = difference % 3600;
        const minutes = Math.floor(difference / 60);

        const formattedHours = hours < 10 ? `0${hours}` : hours;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        const formattedDifference = `${formattedHours}:${formattedMinutes}`;
        if (start >= end) {
          errors.differenceTime = "Start Time should be less than End Date";
          setFormData({
            ...newFormData,
            differenceTime: "",
          });
        } else {
          errors.differenceTime = "";
        }
        setFormData({
          ...newFormData,
          differenceTime: formattedDifference,
        });
      } else {
        setFormData({
          ...newFormData,
          differenceTime: "",
        });
      }
    }
  };

  const filteredNames = assetCodeData.filter(
    (item) => item._id === formData.assetCode
  );
  const filteredSubcodes = assetCodeData.filter(
    (item) => item._id === formData.assetCode && formData.name
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = {};

    const requiredFields = [
      "type",
      "siteName",
      "equipmentType",
      "assetCode",
      "startDate",
      "endDate",
      "differenceDate",
      "startTime",
      "endTime",
      "differenceTime",
      "meterStartReadings",
      "meterEndReadings",
      "meterDifference",
      "differenceTime",
      "Engine2StartReadings",
      "Engine2EndReadings",
      "Engine2ReadingsDifference",
      "transitionDate",
      "scopeOfWorkDetails",
      "location",
      "remark",
      "subCode",
      "debitNote",
      "totalConcreteProduction",
    ];

    requiredFields.forEach((key) => {
      if (!formData[key]) {
        formErrors[key] = `${key} is required`;
      }
    });

    if (formData.debitNote === "Yes") {
      const conditionalDebitNoteFields = [
        "employeeType",
        "employeeId",
        "debitAmount",
        "debitReason",
      ];
      conditionalDebitNoteFields.forEach((key) => {
        if (!formData[key]) {
          formErrors[key] = `${key} is required`;
        }
      });
    }

    // if (formData.workareadetails === "yes") {
    //   const conditionalWorkAreaFields = ["floor", "flat", "room"];
    //   conditionalWorkAreaFields.forEach((key) => {
    //     if (!formData[key]) {
    //       formErrors[key] = `${key} is required`;
    //     }
    //   });
    // }

    console.log("submitted", formData);

    if (Object.keys(formErrors).length > 0) {
      console.log("Form errors:", formErrors);
      setErrors(formErrors);
    } else {
      console.log(formData);
      const tempFormData = {
        siteName: site,
        type: formData.type,
        equipmentType: formData.equipmentType,
        assetCode: id,

        meterStartReadings: Number(formData.meterStartReadings),
        meterEndReadings: Number(formData.meterEndReadings),
        meterDifference: Number(formData.meterDifference),
        Engine2StartReadings: Number(formData.Engine2StartReadings),
        Engine2EndReadings: Number(formData.Engine2EndReadings),
        Engine2ReadingsDifference: Number(formData.Engine2ReadingsDifference),

        startDate: formData.startDate,
        endDate: formData.endDate,
        startTime: formData.startTime,
        endTime: formData.endTime,
        differenceTime: formData.differenceTime,
        transitionDate: formData.transitionDate,
        scopeOfWorkDetails: formData.scopeOfWorkDetails,
        location: formData.location,
        remark: formData.remark,
        subCode: formData.subCode,
        debitAmount: Number(formData.debitAmount),
        debitReason: formData.debitReason,

        debitNote: formData.debitNote || "No",
        employeeType: formData.employeeType,

        employeeId: formData.employeeId,
      };

      console.log(tempFormData);
      const response = await webApi.post("api/dailyLogReport/bp", tempFormData);
      console.log(response);
      window.location.reload();
    }
  };

  useEffect(() => {
    const fetchAssetCodeData = async () => {
      let response = await webApi.get(`api/pnms/assetCode`);
      setAssetCodeData(response.data.data.AssetCodes);
    };

    fetchAssetCodeData();
  }, []);

  return (
    <>
      <div className={styles.VMmainDiv}>
        <div className={styles.VMFormcont}>
          <h3 className={styles.VMheading} style={{ paddingBottom: "32px" }}>
            Daily Log Report For Batching Plant
          </h3>

          <div style={{ display: "flex" }}>
            <div style={{ fontFamily: "Poppins" }}>
              <form onSubmit={handleSubmit}>
                <div className={styles.FormItemsMain}>
                  <FormHeadings text={"Site Name:"} />
                  <div>
                    <select
                      className={styles.dropDownSelect}
                      name="siteName"
                      value={formData.siteName}
                      onChange={handleSiteChange}
                      style={{
                        width: "866px",
                      }}
                    >
                      <option value="" hidden>
                        Select a site
                      </option>
                      {sites &&
                        Array.isArray(sites) &&
                        sites.map((item) => (
                          <option value={item._id} key={item._id}>
                            {item.siteName}
                          </option>
                        ))}
                    </select>
                    <p className="text-danger absolute">{errors.siteName}</p>
                  </div>
                </div>
                {/* ------------------------- */}
                <div className={styles.formSecondryFields}>
                  <div>
                    <div
                      className={styles.FormItemsMain}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        whiteSpace: "nowrap",
                        justifyContent: "space-between",
                      }}
                    >
                      <FormHeadings text={"Transition ID:"} />
                      <input
                        type="text"
                        className={st.pdropDownSelect}
                        name="transitionID"
                        readOnly
                      />
                    </div>

                    <div
                      className={styles.FormItemsMain}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <FormHeadings text={"Equipment Type:"} />
                      <div className="relative">
                        <input
                          className={st.pdropDownSelect}
                          name="equipmentType"
                          value={formData.equipmentType}
                          style={{
                            paddingLeft: "5px",
                            marginLeft: "8px",
                            paddingLeft: "20px",
                          }}
                          readOnly
                        />
                        <p className="text-danger absolute left-[5%]">
                          {errors.equipmentType}
                        </p>
                      </div>
                    </div>
                    <div
                      className={styles.FormItemsMain}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        whiteSpace: "nowrap",
                        justifyContent: "space-between",
                      }}
                    >
                      <FormHeadings text={"Type:"} />
                      <div className="relative">
                        <select
                          className={st.pdropDownSelect}
                          name="type"
                          value={formData.type}
                          onChange={handleChange}
                          style={{
                            paddingLeft: "5px",
                            marginLeft: "8px",
                            paddingLeft: "20px",
                          }}
                        >
                          <option value="" hidden>
                            select Type
                          </option>
                          <option value="Own">Own</option>
                          <option value="Hire">Hire</option>
                        </select>
                        <p className="text-danger absolute left-[5%]">
                          {errors.type}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div>
                      <div
                        className={`${styles.FormItemsMain} justify-between`}
                      >
                        <FormHeadings text={"Transition Date:"} />
                        <div>
                          <input
                            type="date"
                            className={st.pdropDownSelect}
                            style={{ paddingLeft: "20px" }}
                            name="transitionDate"
                            value={formData.transitionDate}
                            onChange={handleChange}
                            placeholder="---------"
                          />
                          <p className="text-danger absolute">
                            {errors.transitionDate}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="flex mt-6 justify-between ">
                      <FormHeadings text={"Asset code/Name:"} />
                      <div>
                        <select
                          className={st.pdropDownSelect}
                          name="assetCode"
                          value={formData.assetCode}
                          onChange={handleChange}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            whiteSpace: "nowrap",
                            justifyContent: "space-between",
                          }}
                        >
                          <option value="" hidden>
                            Select name
                          </option>
                          {assetCodeData.length &&
                            assetCodeData
                              .filter((item) => item.type === "Batching Point")
                              .map((item) => (
                                <option
                                  value={item.assetCode + "/" + item.name}
                                  key={item._id}
                                >
                                  {item.assetCode + "/" + item.name}
                                </option>
                              ))}
                        </select>
                        <p className="text-danger absolute">
                          {errors.assetCode}
                        </p>
                      </div>
                    </div>
                    <div
                      className={styles.FormItemsMain}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        whiteSpace: "nowrap",
                        justifyContent: "space-between",
                      }}
                    >
                      <FormHeadings text={"Sub code:"} />
                      <div>
                        <input
                          className={st.pdropDownSelect}
                          type="text"
                          placeholder="Enter SubCode"
                          name="subCode"
                          onChange={handleChange}
                          value={formData.subCode}
                        />
                        <p className="text-danger absolute">{errors.subCode}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.formSeperator}></div>
                <div className={`${styles.VMFormSecondsection} `}>
                  <div className={`${styles.VWFormSecond} my-3`}>
                    <p
                      className={styles.VMsecondheading}
                      style={{ fontWeight: "500" }}
                    >
                      1st Km/Hr Meter :
                    </p>
                    <div className={styles.FormItemsSecondry}>
                      <label className="form-label fs-6"></label>
                      <FormHeadings text={"Start:"} />
                      <div className="relative ">
                        <input
                          placeholder="Enter Reading"
                          type="number"
                          className={styles.dropDownSelectSeconf}
                          name="meterStartReadings"
                          value={formData.meterStartReadings}
                          onChange={handleChange}
                          style={{ paddingLeft: "20px" }}
                        />
                        <p className="text-danger absolute ">
                          {errors.meterStartReadings}
                        </p>
                      </div>
                    </div>
                    <div className={styles.FormItemsSecondry}>
                      <label className="form-label fs-6"></label>
                      <FormHeadings text={"End:"} />
                      <div className="relative">
                        <input
                          placeholder="Enter Reading"
                          type="number"
                          className={styles.dropDownSelectSeconf}
                          name="meterEndReadings"
                          value={formData.meterEndReadings}
                          onChange={handleChange}
                          style={{ paddingLeft: "20px" }}
                        />
                        <p className="text-danger absolute ">
                          {errors.meterEndReadings}
                        </p>
                      </div>
                    </div>
                    <div className={styles.FormItemsSecondry}>
                      <label className="form-label fs-6"></label>
                      <FormHeadings text={"Difference:"} />
                      <div className="relative">
                        <input
                          type="number"
                          className={styles.dropDownSelectSeconf}
                          name="meterDifference"
                          value={formData.meterDifference}
                          onChange={handleChange}
                          style={{ paddingLeft: "20px" }}
                        />
                        <p className="text-danger absolute ">
                          {errors.meterDifference}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.VWFormSecond} my-3`}>
                    <p
                      className={styles.VMsecondheading}
                      style={{ fontWeight: "500" }}
                    >
                      2nd Km/Hr Meter :
                    </p>
                    <div className={`${styles.FormItemsSecondry} ml-[-12px]`}>
                      <label className="form-label fs-6"></label>
                      <FormHeadings text={"Start:"} />
                      <div className="relative ">
                        <input
                          placeholder="Enter Reading"
                          type="number"
                          className={styles.dropDownSelectSeconf}
                          name="Engine2StartReadings"
                          value={formData.Engine2StartReadings}
                          onChange={handleChange}
                          style={{ paddingLeft: "20px" }}
                        />
                        <p className="text-danger absolute ">
                          {errors.Engine2StartReadings}
                        </p>
                      </div>
                    </div>
                    <div className={styles.FormItemsSecondry}>
                      <label className="form-label fs-6"></label>
                      <FormHeadings text={"End:"} />
                      <div className="relative">
                        <input
                          placeholder="Enter Reading"
                          type="number"
                          className={styles.dropDownSelectSeconf}
                          name="Engine2EndReadings"
                          value={formData.Engine2EndReadings}
                          onChange={handleChange}
                          style={{ paddingLeft: "20px" }}
                        />
                        <p className="text-danger absolute ">
                          {errors.Engine2EndReadings}
                        </p>
                      </div>
                    </div>
                    <div className={styles.FormItemsSecondry}>
                      <label className="form-label fs-6"></label>
                      <FormHeadings text={"Difference:"} />
                      <div className="relative">
                        <input
                          type="number"
                          className={styles.dropDownSelectSeconf}
                          name="Engine2ReadingsDifference"
                          value={formData.Engine2ReadingsDifference}
                          onChange={handleChange}
                          style={{ paddingLeft: "20px" }}
                        />
                        <p className="text-danger absolute ">
                          {errors.Engine2ReadingsDifference}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.VWFormSecond} my-3`}>
                    <p
                      className={styles.VMsecondheading}
                      style={{ fontWeight: "500", marginRight: "100px" }}
                    >
                      Date :
                    </p>
                    <div className={styles.FormItemsSecondry}>
                      <label className="form-label fs-6"></label>
                      <FormHeadings text={"Start:"} />
                      <div className="relative ">
                        <input
                          placeholder="Enter Reading"
                          type="date"
                          className={styles.dropDownSelectSeconf}
                          name="startDate"
                          value={formData.startDate}
                          onChange={handleChange}
                          style={{ paddingLeft: "20px" }}
                        />
                        <p className="text-danger absolute ">
                          {errors.startDate}
                        </p>
                      </div>
                    </div>
                    <div className={styles.FormItemsSecondry}>
                      <label className="form-label fs-6"></label>
                      <FormHeadings text={"End:"} />
                      <div className="relative">
                        <input
                          placeholder="Enter Reading"
                          type="date"
                          className={styles.dropDownSelectSeconf}
                          name="endDate"
                          value={formData.endDate}
                          onChange={handleChange}
                          style={{ paddingLeft: "20px" }}
                        />
                        <p className="text-danger absolute ">
                          {errors.endDate}
                        </p>
                      </div>
                    </div>
                    <div className={styles.FormItemsSecondry}>
                      <label className="form-label fs-6"></label>
                      <FormHeadings text={"Difference:"} />
                      <div className="relative">
                        <input
                          type="number"
                          className={styles.dropDownSelectSeconf}
                          name="differenceDate"
                          value={formData.differenceDate}
                          onChange={handleChange}
                          style={{ paddingLeft: "20px" }}
                        />
                        <p className="text-danger absolute ">
                          {errors.differenceDate}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.VWFormSecond} my-3`}>
                    <p
                      className={styles.VMsecondheading}
                      style={{ fontWeight: "500", marginRight: "90px" }}
                    >
                      Clock :
                    </p>
                    <div className={`${styles.FormItemsSecondry} pl-[26px]`}>
                      <label className="form-label fs-6"></label>
                      <FormHeadings text={"In:"} />
                      <div className="relative ">
                        <input
                          placeholder="Enter Reading"
                          type="time"
                          className={styles.dropDownSelectSeconf}
                          name="startTime"
                          value={formData.startTime}
                          onChange={handleChange}
                          // style={{ paddingLeft: "20px" }}
                        />
                        <p className="text-danger absolute ">
                          {errors.startTime}
                        </p>
                      </div>
                    </div>
                    <div className={styles.FormItemsSecondry}>
                      <label className="form-label fs-6"></label>
                      <FormHeadings text={"Out:"} />
                      <div className="relative">
                        <input
                          placeholder="Enter Reading"
                          type="time"
                          className={styles.dropDownSelectSeconf}
                          name="endTime"
                          value={formData.endTime}
                          onChange={handleChange}
                          style={{ paddingLeft: "20px" }}
                        />
                        <p className="text-danger absolute ">
                          {errors.endTime}
                        </p>
                      </div>
                    </div>
                    <div className={styles.FormItemsSecondry}>
                      <label className="form-label fs-6"></label>
                      <FormHeadings text={"Difference:"} />
                      <div className="relative">
                        <input
                          type="time"
                          className={styles.dropDownSelectSeconf}
                          name="differenceTime"
                          value={formData.differenceTime}
                          onChange={handleChange}
                          style={{ paddingLeft: "20px" }}
                        />
                        <p className="text-danger absolute ">
                          {errors.differenceTime}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={styles.formSeperator}></div>
                <div className={`${styles.FormItemsMain} my-6`}>
                  <FormHeadings text={"Total Concrete Production:"} />
                  <div>
                    <input
                      type="text"
                      placeholder="Enter concrete Production"
                      className={styles.dropDownSelect}
                      name="totalConcreteProduction"
                      value={formData.totalConcreteProduction}
                      onChange={handleChange}
                      style={{ width: "792px", paddingLeft: "20px" }}
                    />
                    <p className="text-danger absolute">
                      {errors.totalConcreteProduction}
                    </p>
                  </div>
                </div>
                <div className={`${styles.FormItemsMain} my-3`}>
                  <FormHeadings text={"Scope of work Details:"} />
                  <div>
                    <input
                      type="text"
                      placeholder="Enter Scope of work"
                      className={styles.dropDownSelect}
                      name="scopeOfWorkDetails"
                      value={formData.scopeOfWorkDetails}
                      onChange={handleChange}
                      style={{ width: "792px", paddingLeft: "20px" }}
                    />
                    <p className="text-danger absolute">
                      {errors.scopeOfWorkDetails}
                    </p>
                  </div>
                </div>
                <div className={styles.formSecondryFields}>
                  <div>
                    <div className={`${styles.FormItemsMain} my-4`}>
                      <FormHeadings text={"Location:"} />
                      <div className="relative">
                        <input
                          className={styles.dropDownSelect}
                          placeholder="Enter Location"
                          name="location"
                          value={formData.location}
                          onChange={handleChange}
                          style={{ marginLeft: "14px", paddingLeft: "20px" }}
                        />

                        <p className="text-danger absolute left-[5%]">
                          {errors.location}
                        </p>
                      </div>
                    </div>
                    <div className={`${styles.FormItemsMain} my-4`}>
                      <FormHeadings text={"Debit Note:"} />
                      <div className="relative">
                        <select
                          className={styles.dropDownSelect}
                          name="debitNote"
                          value={formData.debitNote || "No"}
                          onChange={handleChange}
                          style={{ marginLeft: "14px", paddingLeft: "20px" }}
                        >
                          <option value="No">No</option>
                          <option value="Yes">Yes</option>
                        </select>
                        <p className="text-danger absolute left-[10%]">
                          {errors.debitNote}
                        </p>
                      </div>
                    </div>
                    {formData.debitNote === "Yes" && (
                      <div className={`${styles.FormItemsMain} mb-3`}>
                        <FormHeadings text={"Employee:"} />
                        <div>
                          <input
                            className={styles.dropDownSelect}
                            name="employeeType"
                            value={formData.employeeType}
                            placeholder="Enter employee Type"
                            onChange={handleChange}
                            // style={{ marginLeft: "16px" }}
                          />

                          <p className="text-danger absolute">
                            {errors.employeeType}
                          </p>
                        </div>
                      </div>
                    )}

                    {formData.debitNote === "Yes" && (
                      <div className={`${styles.FormItemsMain}`}>
                        <FormHeadings text={"Debit Reason:"} />
                        <div>
                          <input
                            className={styles.dropDownSelect}
                            name="debitReason"
                            placeholder="Enter Debit Reason"
                            value={formData.debitReason}
                            onChange={handleChange}
                          />
                          <p className="text-danger absolute">
                            {errors.debitReason}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className={`${styles.FormItemsMain} gap-3`}>
                      <FormHeadings text={"Remark:"} />
                      <div>
                        <input
                          type="text"
                          placeholder="Enter Remark"
                          className={styles.dropDownSelect}
                          name="remark"
                          value={formData.remark}
                          onChange={handleChange}
                          style={{ marginLeft: "-10px" }}
                        />
                        <p className="text-danger absolute">{errors.remark}</p>
                      </div>
                    </div>
                    <div>
                      {formData.debitNote === "Yes" && (
                        <div
                          className={`${styles.FormItemsMain} mb-4 `}
                          style={{ paddingTop: "74x" }}
                        >
                          <FormHeadings text={"Employee ID:"} />
                          <div>
                            <input
                              className={styles.dropDownSelect}
                              name="employeeId"
                              placeholder="Enter Employee Id"
                              value={formData.employeeId}
                              onChange={handleChange}
                            />

                            <p className="text-danger absolute">
                              {errors.employeeId}
                            </p>
                          </div>
                        </div>
                      )}
                      {formData.debitNote === "Yes" && (
                        <div className={`${styles.FormItemsMain} gap-3`}>
                          <FormHeadings text={"Debit amount:"} />
                          <div>
                            <input
                              type="number"
                              placeholder="Enter Debit Amount"
                              className={styles.dropDownSelect}
                              name="debitAmount"
                              value={formData.debitAmount}
                              onChange={handleChange}
                              // style={{ marginLeft: "-10px" }}
                            />
                            <p className="text-danger absolute">
                              {errors.debitAmount}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div style={{ paddingTop: "32px" }}>
                  <button type="submit" className={styles.submitBtn}>
                    SUBMIT
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BatchingPlant;
