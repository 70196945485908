import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { WebApimanager } from "../../../WebApiManager";
import FormItem from "../PNMAssets/FormItem";
import styles from "../../PNM/DailyLogReport/Vehicle&Machinery.module.css";
import FormHeadings from "../PNMAssets/FormHeadings";
import st from "../../PNM/DailyLogReport/PowerTools.module.css";
import { siteId } from "../../../atoms";
import { useRecoilState } from "recoil";

const MiscellaneousReport = () => {
  const webApi = new WebApimanager();
  const [formData, setFormData] = useState({
    siteName: "",
    makeName: "",
    selectRateType: "",
    rateCharge: "",
    equipmentType: "",
    model: "",
    vendor: "",
    mobileNo: "",
    vehicleNo: "",
    transitionDate: "",
    assetCode: "",
    scopeOfWorkDetails: "",
    remarks: "",
    location: "",
    startDate: "",
    endDate: "",
    differenceDate: "",
    startTime: "",
    endTime: "",
    differenceTime: "",
    debitNote: "" || "No",
    employeeType: "",
    employeeId: "",
    debitAmount: "",
    debitReason: "",
  });

  const [errors, setErrors] = useState({});
  const [assetCodeData, setAssetCodeData] = useState([]);
  const [nameData, setNameData] = useState([]);
  const [site, setSite] = useRecoilState(siteId);
  const [sites, setDatasites] = useState([]);
  const [id, setId] = useState();
  const [equipment, setEquipment] = useState("");

  function calculateDateDifference(startDate, endDate) {
    const [startYear, startMonth, startDay] = startDate.split("-").map(Number);
    const [endYear, endMonth, endDay] = endDate.split("-").map(Number);
    console.log(startYear, endYear);
    console.log(startMonth, endMonth);
    console.log(startDay, endDay);

    const start = new Date(startYear, startMonth - 1, startDay);
    const end = new Date(endYear, endMonth - 1, endDay);

    if (end >= start) {
      const diffTime = end.getTime() - start.getTime();
      const diffDays = diffTime / (1000 * 60 * 60 * 24);
      return Math.round(diffDays);
    } else {
      return -1;
    }
  }

  const handleSiteChange = (e) => {
    const { name, value } = e.target;

    setSite(e.target.value);
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    // const newValue = name === "kmOrHrReading" ? parseInt(value, 10) : value;
    let newFormData = {
      ...formData,
      [name]: value,
    };
    if (name === "equipmentType" && value) {
      setEquipment(value);
    }
    if (name === "assetCode" && value) {
      const assCode = assetCodeData.find(
        (asset) => asset.assetCode === value.split("/")[0]
      );
      if (assCode) {
        setId(assCode._id);
        newFormData = {
          ...newFormData,
          ["subCode"]: assCode.subCode,
          ["equipmentType"]: assCode.type,
        };
      }
    }
    setFormData(newFormData);

    if (name === "startDate" || name === "endDate") {
      if (newFormData.startDate !== "" && newFormData.endDate !== "") {
        const diff = calculateDateDifference(
          newFormData.startDate,
          newFormData.endDate
        );
        setFormData({ ...newFormData, differenceDate: diff });
        if (diff < 0) {
          errors.differenceDate = "start date should be less than end Date";
          setFormData({
            ...newFormData,
            differenceDate: "",
          });
        } else {
          setFormData({ ...newFormData, differenceDate: diff });
          errors.differenceDate = "";
        }
      } else {
        setFormData({ ...newFormData, differenceDate: "" });
      }
    }
    if (name === "startTime" || name === "endTime") {
      if (newFormData.startTime && newFormData.endTime) {
        const start = new Date(`1970-01-01T${newFormData.startTime}`);
        const end = new Date(`1970-01-01T${newFormData.endTime}`);
        let difference = (end.getTime() - start.getTime()) / 1000; // Difference in seconds

        const hours = Math.floor(difference / 3600);
        difference = difference % 3600;
        const minutes = Math.floor(difference / 60);

        const formattedHours = hours < 10 ? `0${hours}` : hours;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        const formattedDifference = `${formattedHours}:${formattedMinutes}`;
        if (start >= end) {
          errors.differenceTime = "Start Time should be less than End Date";
          setFormData({
            ...newFormData,
            differenceTime: "",
          });
        } else {
          errors.differenceTime = "";
        }
        setFormData({
          ...newFormData,
          differenceTime: formattedDifference,
        });
      } else {
        setFormData({
          ...newFormData,
          differenceTime: "",
        });
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = {};

    const requiredFields = [
      "siteName",
      "makeName",
      "selectRateType",
      "rateCharge",
      "equipmentType",
      "assetCode",
      "startDate",
      "endDate",
      "differenceDate",
      "startTime",
      "endTime",
      "differenceTime",
      "model",
      "vehicleNo",
      "mobileNo",
      "vendor",
      "transitionDate",
      "scopeOfWorkDetails",
      "location",
      "remarks",
    ];

    requiredFields.forEach((key) => {
      if (!formData[key]) {
        formErrors[key] = `${key} is required`;
      }
    });

    if (formData.debitNote === "Yes") {
      const conditionalDebitNoteFields = [
        "employeeType",
        "employeeId",
        "debitAmount",
        "debitReason",
      ];
      conditionalDebitNoteFields.forEach((key) => {
        if (!formData[key]) {
          formErrors[key] = `${key} is required`;
        }
      });
    }

    console.log("submitted", formData);

    if (Object.keys(formErrors).length) {
      console.log("Form errors:", formErrors);
      setErrors(formErrors);
    } else {
      console.log(formData);
      const tempFormData = {
        siteName: site,
        type: formData.type,
        assetCode: id,
        equipmentType: formData.equipmentType,

        startDate: formData.startDate,
        endDate: formData.endDate,
        differenceDate: formData.differenceDate,
        startTime: formData.startTime,
        endTime: formData.endTime,
        differenceTime: formData.differenceTime,

        transitionDate: formData.transitionDate,
        scopeOfWorkDetails: formData.scopeOfWorkDetails,
        location: formData.location,
        remarks: formData.remarks,
        debitAmount: Number(formData.debitAmount),
        debitReason: formData.debitReason,
        model: formData.model,
        vendor: formData.vendor,
        mobileNo: formData.mobileNo,
        phoneNo: formData.phoneNo,
        debitNote: formData.debitNote || "No",
        employeeType: formData.employeeType,
        debitAmount: Number(formData.debitAmount),
        debitReason: formData.debitReason,
        employeeId: formData.employeeId,
      };

      console.log("temp", tempFormData);
      console.log("formData", formData);
      const response = await webApi.post(
        "api/pnms/miscellaneous",
        tempFormData
      );
      console.log(response);
      // window.location.reload();
    }
  };

  useEffect(() => {
    const fetchAssetCodeData = async () => {
      const response = await webApi.get(`api/pnms/assetCode`);
      setAssetCodeData(response.data.data.AssetCodes);
    };

    fetchAssetCodeData();
  }, []);

  useEffect(() => {
    webApi
      .get("api/sites/getAllSitesInfo")
      .then((response) => {
        console.log("API response:", response);
        if (response.data && Array.isArray(response.data.sites)) {
          setDatasites(response.data.sites);
          if (site === "") {
            setSite(response.data.sites[0]._id);
          }
        } else {
          console.error("Unexpected data format:", response.data);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, [site]);

  return (
    <div className={styles.VMmainDiv}>
      <div className={styles.VMFormcont}>
        <h3 className={styles.VMheading} style={{ paddingBottom: "32px" }}>
          MiscellaneousReport Report
        </h3>
        <div style={{ display: "flex" }}>
          <form onSubmit={handleSubmit}>
            <div>
              <div>
                <div className={styles.FormItemsMain}>
                  <FormHeadings text={"Site Name:"} />
                  <div>
                    <select
                      className={styles.dropDownSelect}
                      name="siteName"
                      value={formData.siteName}
                      onChange={handleSiteChange}
                      style={{
                        width: "850px",
                      }}
                    >
                      <option value="" hidden>
                        Select a site
                      </option>
                      {sites &&
                        Array.isArray(sites) &&
                        sites.map((item) => (
                          <option value={item._id} key={item._id}>
                            {item.siteName}
                          </option>
                        ))}
                    </select>
                    <p className="text-danger absolute">{errors.siteName}</p>
                  </div>
                </div>
                {/* ------------------------- */}
                <div className={styles.formSecondryFields}>
                  <div>
                    <div
                      className={styles.FormItemsMain}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        whiteSpace: "nowrap",
                        justifyContent: "space-between",
                      }}
                    >
                      <FormHeadings text={"Transition ID:"} />
                      <input
                        type="text"
                        className={st.pdropDownSelect}
                        name="transitionID"
                        readonly
                      />
                    </div>

                    <div
                      className={styles.FormItemsMain}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <FormHeadings text={"Equipment Type:"} />
                      <div className="relative">
                        <select
                          className={st.pdropDownSelect}
                          name="equipmentType"
                          value={formData.equipmentType}
                          style={{
                            paddingLeft: "5px",
                            marginLeft: "8px",
                            paddingLeft: "20px",
                          }}
                          onChange={handleChange}
                        >
                          <option value="" hidden>
                            Select Equipment Type
                          </option>
                          <option value="Vehicle">Vehicle</option>
                          <option value="Machinery">Machinery</option>
                          <option value="Power Tools">Power Tools</option>
                          <option value="Distribution Board">
                            Distribution Board
                          </option>
                          <option value="Batching Point">Batching Point</option>
                        </select>
                        <p className="text-danger absolute left-[5%]">
                          {errors.equipmentType}
                        </p>
                      </div>
                    </div>
                    <div
                      className={styles.FormItemsMain}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        whiteSpace: "nowrap",
                        justifyContent: "space-between",
                      }}
                    >
                      <FormHeadings text={"Make Name:"} />
                      <div className="relative">
                        <input
                          className={st.pdropDownSelect}
                          name="makeName"
                          value={formData.makeName}
                          onChange={handleChange}
                          placeholder="Enter Name"
                          style={{
                            paddingLeft: "5px",
                            marginLeft: "8px",
                            paddingLeft: "20px",
                          }}
                        />

                        <p className="text-danger absolute left-[5%]">
                          {errors.makeName}
                        </p>
                      </div>
                    </div>
                    <div
                      className={styles.FormItemsMain}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        whiteSpace: "nowrap",
                        justifyContent: "space-between",
                      }}
                    >
                      <FormHeadings text={"Vender Name:"} />
                      <div>
                        <input
                          type="text"
                          placeholder="Enter Name"
                          className={st.pdropDownSelect}
                          name="vendor"
                          value={formData.vendor}
                          onChange={handleChange}
                        />
                        <p className="text-danger absolute">{errors.vendor}</p>
                      </div>
                    </div>
                    <div
                      className={styles.FormItemsMain}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        // whiteSpace: "nowrap",
                        justifyContent: "space-between",
                      }}
                    >
                      <FormHeadings text={"rate:"} />
                      <div className="flex items-center relative">
                        <select
                          name="selectRateType"
                          value={formData.selectRateType}
                          onChange={handleChange}
                          style={{
                            width: "150px",
                            height: "30px",
                            backgroundColor: "#f3f3f3",
                            color: "rgba(180,180,180,1)",
                            border: "1px solid rgba(151,151,151,0.25)",
                            fontSize: "12px",
                            fontFamily: "Poppins",
                          }}
                        >
                          <option value="" hidden>
                            Select Date/Hr
                          </option>
                          <option value="Week">Week</option>
                          <option value="Day">Day</option>
                          <option value="Hour">Hour</option>
                        </select>
                        <input
                          type="text"
                          style={{
                            width: "150px",
                            height: "30px",
                            backgroundColor: "#f3f3f3",
                            color: "rgba(180,180,180,1)",
                            border: "1px solid rgba(151,151,151,0.25)",
                            fontSize: "12px",
                            fontFamily: "Poppins",
                          }}
                          placeholder="Enter Number"
                          name="rateCharge"
                          value={formData.rateCharge}
                          onChange={handleChange}
                        />
                        {!formData.selectRateType && (
                          <p className="text-danger absolute top-[100%]">
                            {errors.selectRateType}
                          </p>
                        )}
                        {formData.selectRateType && !formData.rateCharge && (
                          <p className="text-danger absolute top-[100%]">
                            {errors.rateCharge}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div>
                    <div>
                      <div
                        className={`${styles.FormItemsMain} justify-between`}
                      >
                        <FormHeadings text={"Transition Date:"} />
                        <div>
                          <input
                            type="date"
                            className={st.pdropDownSelect}
                            style={{ paddingLeft: "20px" }}
                            name="transitionDate"
                            value={formData.transitionDate}
                            onChange={handleChange}
                            placeholder="---------"
                          />
                          <p className="text-danger absolute">
                            {errors.transitionDate}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="flex mt-6 justify-between ">
                      <FormHeadings text={"Asset code/Name:"} />
                      <div>
                        <select
                          className={st.pdropDownSelect}
                          name="assetCode"
                          value={formData.assetCode}
                          onChange={handleChange}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            whiteSpace: "nowrap",
                            justifyContent: "space-between",
                          }}
                        >
                          <option value="" hidden>
                            Select name
                          </option>
                          {assetCodeData.length &&
                            assetCodeData
                              .filter((item) => item.type === equipment)
                              .map((item) => (
                                <option
                                  value={item.assetCode + "/" + item.name}
                                  key={item._id}
                                >
                                  {item.assetCode + "/" + item.name}
                                </option>
                              ))}
                        </select>
                        <p className="text-danger absolute">
                          {errors.assetCode}
                        </p>
                      </div>
                    </div>
                    <div
                      className={styles.FormItemsMain}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        whiteSpace: "nowrap",
                        justifyContent: "space-between",
                      }}
                    >
                      <FormHeadings text={"Model:"} />
                      <div>
                        <input
                          className={st.pdropDownSelect}
                          type="text"
                          placeholder="Enter Model"
                          name="model"
                          onChange={handleChange}
                          value={formData.model}
                        />
                        <p className="text-danger absolute">{errors.model}</p>
                      </div>
                    </div>
                    <div
                      className={styles.FormItemsMain}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        whiteSpace: "nowrap",
                        justifyContent: "space-between",
                      }}
                    >
                      <FormHeadings text={"Mobile Number:"} />
                      <div className="relative">
                        <input
                          type="number"
                          placeholder="enter mobile Number"
                          className={st.pdropDownSelect}
                          name="mobileNo"
                          value={formData.mobileNo}
                          onChange={handleChange}
                          style={{
                            paddingLeft: "5px",
                            marginLeft: "8px",
                            paddingLeft: "20px",
                          }}
                        />

                        <p className="text-danger absolute left-[5%]">
                          {errors.mobileNo}
                        </p>
                      </div>
                    </div>
                    <div
                      className={styles.FormItemsMain}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        whiteSpace: "nowrap",
                        justifyContent: "space-between",
                      }}
                    >
                      <FormHeadings text={"Vehicle Number:"} />
                      <div className="relative">
                        <input
                          type="text"
                          placeholder="Enter vehicle Number"
                          className={st.pdropDownSelect}
                          name="vehicleNo"
                          value={formData.vehicleNo}
                          onChange={handleChange}
                          style={{
                            paddingLeft: "5px",
                            marginLeft: "8px",
                            paddingLeft: "20px",
                          }}
                        />

                        <p className="text-danger absolute left-[5%]">
                          {errors.vehicleNo}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.formSeperator}></div>
              <h3
                className={styles.VMheading}
                style={{ paddingBottom: "32px" }}
              >
                Details
              </h3>
              <div className={styles.VMFormSecondsection}>
                <div className={styles.VWFormSecond}></div>
                <div className={`${styles.VWFormSecond} w-full`}>
                  <div className="w-1/5 mb-4">
                    <p
                      className={styles.VMsecondheading}
                      style={{ fontWeight: "500", textAlign: "left" }}
                    >
                      Date
                    </p>
                  </div>
                  <div
                    className="flex justify-between"
                    // style={{ width: "80%" }}
                  >
                    <div className={styles.FormItemsSecondry}>
                      <label className="form-label fs-6"></label>
                      <FormHeadings text={"Start:"} />
                      <div>
                        <input
                          placeholder="Enter Date"
                          type="date"
                          className={styles.dropDownSelectSeconf}
                          name="startDate"
                          value={formData.startDate}
                          onChange={handleChange}
                        />
                        <p className="text-danger absolute">
                          {errors.startDate}
                        </p>
                      </div>
                    </div>
                    <div className={styles.FormItemsSecondry}>
                      <label className="form-label fs-6"></label>
                      <FormHeadings text={"End:"} />
                      <div>
                        <input
                          placeholder="Enter"
                          type="date"
                          className={styles.dropDownSelectSeconf}
                          name="endDate"
                          value={formData.endDate}
                          onChange={handleChange}
                        />
                        <p className="text-danger absolute">{errors.endDate}</p>
                      </div>
                    </div>
                    <div className={styles.FormItemsSecondry}>
                      <label className="form-label fs-6"></label>
                      <FormHeadings text={"Difference:"} />
                      <div>
                        <input
                          type="number"
                          className={styles.dropDownSelectSeconf}
                          name="differenceDate"
                          value={formData.differenceDate}
                          onChange={handleChange}
                        />
                        <p className="text-danger absolute">
                          {errors.differenceDate}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`${styles.VWFormSecond} w-full`}>
                  <div className="w-1/5 ">
                    <p
                      className={styles.VMsecondheading}
                      style={{ fontWeight: "500", textAlign: "left" }}
                    >
                      Clock
                    </p>
                  </div>
                  <div
                    className="flex justify-between"
                    // style={{ width: "80%", paddingLeft: "24.5px" }}
                  >
                    <div className={styles.FormItemsSecondry}>
                      <label className="form-label fs-6"></label>
                      <FormHeadings text={"In:"} />
                      <div>
                        <input
                          placeholder="Enter Date"
                          type="time"
                          className={styles.dropDownSelectSeconf}
                          name="startTime"
                          value={formData.startTime}
                          onChange={handleChange}
                        />
                        <p className="text-danger absolute">
                          {errors.startTime}
                        </p>
                      </div>
                    </div>
                    <div className={styles.FormItemsSecondry}>
                      <label className="form-label fs-6"></label>
                      <FormHeadings text={"Out:"} />
                      <div>
                        <input
                          placeholder="Enter"
                          type="time"
                          className={styles.dropDownSelectSeconf}
                          name="endTime"
                          value={formData.endTime}
                          onChange={handleChange}
                        />
                        <p className="text-danger absolute">{errors.endTime}</p>
                      </div>
                    </div>
                    <div className={styles.FormItemsSecondry}>
                      <label className="form-label fs-6"></label>
                      <FormHeadings text={"Difference:"} />
                      <div>
                        <input
                          type="time"
                          className={styles.dropDownSelectSeconf}
                          name="differenceTime"
                          value={formData.differenceTime}
                          onChange={handleChange}
                        />
                        <p className="text-danger absolute">
                          {errors.differenceTime}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.formSeperator}></div>
              <div className={styles.formSecondryFields}>
                <div>
                  <div className={`${styles.FormItemsMain} gap-3`}>
                    <FormHeadings text={"Debit note:"} />
                    <div className="relative">
                      <select
                        className={styles.dropDownSelect}
                        name="debitNote"
                        value={formData.debitNote || "No"}
                        onChange={handleChange}
                        style={{ marginLeft: "14px", paddingLeft: "20px" }}
                      >
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                      </select>
                      <p className="text-danger absolute left-[10%]">
                        {errors.debitNote}
                      </p>
                    </div>
                  </div>
                  {formData.debitNote === "Yes" && (
                    <>
                      <div className={`${styles.FormItemsMain} mb-3`}>
                        <FormHeadings text={"Employee:"} />
                        <div className="relative">
                          <input
                            placeholder="Enter"
                            type="text"
                            className={styles.dropDownSelect}
                            name="employeeType"
                            value={formData.employeeType}
                            onChange={handleChange}
                            style={{ paddingLeft: "20px" }}
                          />
                          <p className="text-danger absolute">
                            {errors.employeeType}
                          </p>
                        </div>
                      </div>

                      <div className={styles.FormItemsMain}>
                        <FormHeadings text={"Debit Reason:"} />
                        <div className="relative">
                          <input
                            className={styles.dropDownSelect}
                            name="debitReason"
                            placeholder="enter Debit Reason"
                            value={formData.debitReason}
                            onChange={handleChange}
                            style={{
                              // marginLeft: "-12px",
                              paddingLeft: "20px",
                            }}
                          />
                          <p className="text-danger absolute">
                            {errors.debitReason}
                          </p>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div style={{ alignSelf: "flex-end" }}>
                  {formData.debitNote === "Yes" && (
                    <>
                      <div
                        className={`${styles.FormItemsMain} `}
                        style={{ paddingTop: "60px" }}
                      >
                        <FormHeadings text={"Employee ID:"} />
                        <div>
                          <input
                            placeholder="Enter Employee ID"
                            type="text"
                            className={styles.dropDownSelect}
                            name="employeeId"
                            value={formData.employeeId}
                            onChange={handleChange}
                            style={{ paddingLeft: "20px" }}
                          />
                          <p className="text-danger absolute">
                            {errors.employeeId}
                          </p>
                        </div>
                      </div>

                      <div>
                        <div className={`${styles.FormItemsMain} gap-3 `}>
                          <FormHeadings text={"Debit amount:"} />
                          <div>
                            <input
                              type="number"
                              placeholder="enter number"
                              className={styles.dropDownSelect}
                              name="debitAmount"
                              value={formData.debitAmount}
                              onChange={handleChange}
                            />
                            <p className="text-danger absolute">
                              {errors.debitAmount}
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className={styles.formSeperator}></div>
              <div className="flex gap-4">
                <FormHeadings text="Scope of Work Details:" />
                <div className="relative">
                  <input
                    name="scopeOfWorkDetails"
                    value={formData.scopeOfWorkDetails}
                    onChange={handleChange}
                    className={styles.dropDownSelect}
                    type="text"
                    placeholder="Enter Scope of work"
                    style={{ width: "790px" }}
                  />
                  <p className="absolute text-danger ">
                    {errors.scopeOfWorkDetails}
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "28px",
                }}
              >
                <div className="flex ">
                  <FormHeadings text="location:" />
                  <div className="relative">
                    <input
                      label="Location:"
                      name="location"
                      value={formData.location}
                      onChange={handleChange}
                      className={styles.dropDownSelect}
                      type="text"
                      placeholder="Enter Work Location"
                    />
                    <p className="text-danger absolute">{errors.location}</p>
                  </div>
                </div>
                <div className="flex ">
                  <FormHeadings text="Remark:" />
                  <div className="relative">
                    <input
                      name="remarks"
                      value={formData.remarks}
                      onChange={handleChange}
                      className={styles.dropDownSelect}
                      type="text"
                      placeholder="Enter Remarks"
                    />
                    <p className="text-danger absolute ">{errors.remarks}</p>
                  </div>
                </div>
              </div>
              <div style={{ paddingTop: "40px" }}>
                <button type="submit" className={styles.submitBtn}>
                  SUBMIT
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default MiscellaneousReport;
