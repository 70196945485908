import Header from "../Components/Navbar/Header";
import Projects from "../Components/Projects/Projects";
import Headline from "../Components/constants-comp/headline/Headline";
import PagesHeroSection from "../Components/constants-comp/pagesHerosection";
import { OUR_PRODUCTS, UPCOMING_PRODUCTS } from "../Utilities/Constants";
import styles from "./Product.module.css";
import ProductContentBlock from "./products-content-block/ProductContent";

const Product = () => {

  const description1 = `Are you struggling to keep up with the daily demands of managing your vehicles, machinery, and equipment in a fast-paced construction environment? Our ERP solution is here to help. We know that tracking work hours, fuel usage, and maintenance schedules can be overwhelming, especially when downtime and breakdowns can lead to costly delays. With our ERP tool, you'll have everything you need to monitor and maintain your equipment efficiently—ensuring optimal performance and extending the life of your assets.
                  Our solution makes it easy to set up automated maintenance reminders, track usage data, and analyze performance to inform smarter purchasing decisions. By selecting the right machinery for each job and sticking to a well-planned maintenance schedule, you can cut unnecessary costs and improve productivity. Plus, with built-in spare part management and streamlined repair processes, you'll be prepared for any unexpected issues. Let our ERP solution take the guesswork out of plant and machinery management, so you can focus on what matters—getting the job done.`

  const description2 = `Are drawing delays slowing down your projects? Our ERP solution ensures seamless communication and coordination between your site, RO/HO, and consultants, streamlining the entire drawing management process. From raising RFIs to distributing and tracking drawings, our system maps drawing timelines directly to your project schedule, preventing costly delays. It automatically flags and alerts you to any holdups, holding consultants accountable while keeping your project on track. You can also schedule and monitor consultant site visits, ensuring issues are escalated promptly. Whether managing hard or soft copies, our ERP tool ensures accurate and timely drawing distribution, helping you avoid costly project delays. Let us help you stay organized and efficient, so you can focus on delivering projects on time.`;

  const description3 = `Looking for a streamlined way to manage all your worksheets, departmental data, codes, and reference materials? Our ERP solution provides a centralized drive management system designed to store everything you need—technical notes, supporting documents, installation guides, FAQs, and more—all in one easily accessible place. No more wasting time searching for information or downloading scattered files. With fast, organized access to data, you can quickly pull up calculations, methodologies, and departmental codes, allowing you to focus on getting the job done efficiently. Let our ERP tool help you save time and keep everything organized, so your team always has what they need at their fingertips.`;
  return (
    <>
      <Header />
      <PagesHeroSection
        imageUrl={
          "https://cdn.builder.io/api/v1/image/assets/TEMP/4e265835733c8d6a6eea6998a16b66ad872c731fcb9c8299c01f76cb0d5bfde9?apiKey=3e99c58a56f84e4cb0d84873c390b13e&&apiKey=3e99c58a56f84e4cb0d84873c390b13e"
        }
        title={"Our Products"}
      />
      <div>
        <ProductContentBlock
          imageUrl="https://cdn.builder.io/api/v1/image/assets%2F7fa683a22a9c42d7a697097e1847f1c9%2Fb3f13c5fcd574482b09e106b1632268f"
          title="P & M Management System"
          description={description1}
          reverse={false}
        />
      </div>
      <div style={{ background: "#F2F4F6" }}>
        <ProductContentBlock
          imageUrl="https://cdn.builder.io/api/v1/image/assets%2F7fa683a22a9c42d7a697097e1847f1c9%2Fd3c10b35c8914ed29c81dc622992ae1c"
          title="Drawing Management System"
          description={description2}
          reverse={true}
        />
      </div>
      <div>
        <ProductContentBlock
          imageUrl="https://cdn.builder.io/api/v1/image/assets%2F7fa683a22a9c42d7a697097e1847f1c9%2Fa74c791b9233458abe0821cbb5158b3f"
          title="Drive Management System"
          description={description3}
          reverse={false}
        />
      </div>
      <Headline headlineText={"Upcoming Products"} />
      <div className={styles.productMain}>
        <Projects readMore={false} projectsData={UPCOMING_PRODUCTS} />
      </div>
    </>
  );
};

export default Product;
