import React, { useState,useEffect } from 'react';
import * as Icon from "react-bootstrap-icons";
import { Dialog, DialogContent } from "@mui/material";
import MultiSelectSearch from "../../Utilities/MultiSelectSearch";
import { WebApimanager } from '../../WebApiManager';

const AddDocumentsDialog = (props) => {
const { openAddDocumentsDialog, onClose,site} = props
let webApi = new WebApimanager();
const [selectedCheckListIds, setSelectedCheckListIds] = useState([]);
const [datasequences, setDataSequences] = useState([]);
const [dataHeadings,setDataHeadings] = useState([]);
const [loading, setLoading] = useState(false);
const [errors, setErrors] = useState({});
const [error, setError] = useState(null);
const [selectedChecklistIdsArray, setSelectedChecklistIdsArray] = useState([]);

useEffect(() => {
  // Initialize selectedChecklistIdsArray based on datasequences length
  setSelectedChecklistIdsArray(Array(datasequences.length).fill([]));
}, [datasequences]);


const handleChange = async (e) => {
  const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });



};

const handleSubmit = () => {

  const newErrors = {};


  if (!formData.siteName) {
    newErrors.siteName = 'Site Name is required';
  }
  const hasError = selectedChecklistIdsArray.some(ids => ids.length === 0);
  if (hasError) {
    newErrors.checkList = 'At least one checklist item must be selected for each row';
  }
  setErrors(newErrors);

  if (Object.keys(newErrors).length === 0) {
    const documentsToCreate = datasequences.map((sequence, index) => ({
      workSequenceId: sequence._id,
      checklists: selectedChecklistIdsArray[index] || []
    }));
    webApi.post("api/WorkSequenceDocument", documentsToCreate)
    .then((response) => {
      console.log("Documents created successfully:", response.data);
      setErrors({});
      // Optionally close dialog or perform any other action upon successful creation
      onClose();
    })

    .then((response) => {
      const data = response.data;
      console.log("Server Response:", response); 
      console.log("Data:", data);
      // openAddDocumentsDialog(false)
      setErrors({});
      // window.location.reload();
    })
    .catch((error) => {
      console.error("There was an error logging in!", error);
      setError("There was an error logging in");
    })
    .finally(() => {
      setLoading(false);  
    });
    console.log('Form data:', formData, selectedCheckListIds);
  }
};

const [formData, setFormData] = useState({
  workSequenceId: '',
  checkList:[]
});



useEffect(() => {
  const fetchData = async () => {
    setLoading(true);
    try {
      if (site) {
        const sequenceResponse = await webApi.get(`api/WorkSequence/site/${site}`);
        setDataSequences(sequenceResponse.data.data.workSequences || []);
        console.log("sequenceResponse",sequenceResponse);
      }

      const headingsResponse = await webApi.get('api/checkListSubmit/AllFormsSumitted');
      if (headingsResponse.data && headingsResponse.data.data && Array.isArray(headingsResponse.data.data.checklists)) {
        const headings = headingsResponse.data.data.checklists.map(item => ({
          id: item._id,
          name: item.heading
        }));
        setDataHeadings(headings);
      } else {
        setErrors({ ...errors, headings: 'Unexpected data format' });
        console.error('Unexpected data format:', headingsResponse.data);
      }
    } catch (error) {
      setErrors({ ...errors, fetch: 'Error fetching data' });
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  fetchData();
}, []); 


return (

<Dialog open={openAddDocumentsDialog} onClose={onClose} maxWidth="md" fullWidth>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#7C1034', margin: '0px' }}>
        <p style={{ color: 'white', textAlign: 'center', margin: '5px 200px' }}>Add Documents to Work Sequence</p>
        <Icon.XCircle size={20} style={{ marginLeft: 'auto', cursor: 'pointer', color: 'white', marginRight: '10px' }} onClick={onClose} />
      </div>
      <DialogContent style={{ padding: '25px', minWidth: '300px', minHeight: '300px' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {/* <label htmlFor="siteName" className="fs-10 fw-bold text-start py-2">
              Site Name
              <span style={{ color: 'red' }}>*</span>
            </label>
            <select
              id="siteName"
              name="siteName"
              value={formData.siteName}
              onChange={handleChange}
              className="form-control fs-12"
              style={{ width: '40%', boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',margin:'10px' }}
            >
              <option value="">Select a Site</option>
              {datasites.map(site => (
                <option key={site._id} value={site.siteName}>{site.siteName}</option>
              ))}
            </select> */}
            </div>
            {/* {errors.siteName && <p style={{ color: 'red', fontSize: '12px' }}>{errors.siteName}</p>} */}
            <table style={{ width: '100%', marginTop: '10px' , boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',textAlign: 'center' }}>
              <thead style={{ height: '45px' }}>
                <tr style={{ borderBottom: '1px solid #ddd' }}>
                  <th style={{ width: '20%' }}>S.No</th>
                  <th style={{ width: '40%' }}>Name</th>
                  <th style={{ width: '40%' }}>Checklist</th>
                </tr>
              </thead>
              <tbody >
                {loading ? (
                  <tr>
                    <td colSpan="3">Loading...</td>
                  </tr>
                ) : datasequences.map((sequence, index) => (
                  <tr key={sequence._id} style={{ borderBottom: '1px solid #ddd' }}>
                    <td>{index + 1}</td>
                    <td>{sequence.name}</td>
                    <td>
                    <MultiSelectSearch
                      listItems={dataHeadings}
                      selectedIds={selectedChecklistIdsArray[index]}
                      setSelectedIds={(selectedIds) => {
                        setSelectedChecklistIdsArray(prevState => {
                          const newState = [...prevState];
                          newState[index] = selectedIds;
                          return newState;
                        });
                      }}
                      handleSelectedPropertyOption={(data) => {
                        setSelectedChecklistIdsArray(prevState => {
                          const newState = [...prevState];
                          if (newState[index].includes(data.id)) {
                            newState[index] = newState[index].filter(id => id !== data.id);
                          } else {
                            newState[index] = [...newState[index], data.id];
                          }
                          return newState;
                        });
                      }}
                      clearAll={() => {
                        setSelectedChecklistIdsArray(prevState => {
                          const newState = [...prevState];
                          newState[index] = [];
                          return newState;
                        });
                      }}
                      addAll={() => {
                        setSelectedChecklistIdsArray(prevState => {
                          const newState = [...prevState];
                          const allIds = dataHeadings.map(item => item.id);
                          newState[index] = allIds;
                          return newState;
                        });
                      }}
                    />
                  </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {errors.checkList && <p style={{ color: 'red', fontSize: '12px', marginTop: '10px' }}>{errors.checkList}</p>}
          </div>

        <div style={{ marginTop: '45px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <button
            style={{ padding: '8px', borderRadius: '5px', border: '1px solid lightgray', backgroundColor: 'transparent', width: '100px' }}
            onMouseOver={(e) => e.target.style.backgroundColor = 'lightgray'}
            onMouseOut={(e) => e.target.style.backgroundColor = 'transparent'}
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            style={{ marginLeft: '50px', padding: '10px', borderRadius: '5px', border: '1px solid lightgray', backgroundColor: 'brown', width: '100px' }}
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </DialogContent>
    </Dialog>

);
}

export default AddDocumentsDialog;