import React from 'react';
import './styles.css'; // Ensure you import your CSS file

const TableHead = (props) => {
  const { handleClick, activeHeader } = props;

  return (
    <div className="tab-container">
      <div
        className={`tab ${activeHeader === 'design' ? 'tab-active' : ''}`}
        onClick={() => handleClick('design')}
      >
        Design
      </div>
      <div
        className={`tab ${activeHeader === 'form' ? 'tab-active' : ''}`}
        onClick={() => handleClick('form')}
      >
        Form
      </div>
      {/* <div
        className={`tab ${activeHeader === 'approval' ? 'tab-active' : ''}`}
        onClick={() => handleClick('approval')}
      >
        Approval Pending
      </div> */}
      {/* <div
        className={`tab ${activeHeader === 'approved' ? 'tab-active' : ''}`}
        onClick={() => handleClick('approved')}
      >
        Approved
      </div> */}
    </div>
  );
};

export default TableHead;
