import React, { useState, useEffect } from 'react';
import * as Icon from "react-bootstrap-icons";
import { Dialog, DialogContent } from "@mui/material";
import { WebApimanager } from '../../WebApiManager';
import Task from './Task';

const CreateTasksDialog = (props) => {
    const { openCreateTaskDialog, onClose, site } = props;

   

   

    return (
        <Dialog open={openCreateTaskDialog} onClose={onClose} maxWidth="xl" fullWidth site={site}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#7C1034', margin: '0px' }}>
                <p style={{ color: 'white', textAlign: 'center', margin: '5px 200px', alignItems: 'center' }}>Create Tasks</p>
                <Icon.XCircle size={20} style={{ marginLeft: 'auto', cursor: 'pointer', color: 'white', marginRight: '10px' }} onClick={onClose} />
            </div>
            <DialogContent style={{ padding: '25px', minWidth: '300px', minHeight: '300px' }}>
                
                <Task  />
            </DialogContent>
        </Dialog>
    );
};

export default CreateTasksDialog;
