import Axios from "axios";
// import Store from "../../Store.js";
import swal from "sweetalert";
import { getRecoil } from "recoil-nexus";
import { jwtToken } from "./atoms";
import { BACKEND_URL } from "./Utilities/Constants";

export class WebApimanager {
  get(url, qs) {
    try {
      //   let jwtExpiryStatus = Utility.verifyJWTTokenExpiration();
      let baseURL = BACKEND_URL;
      let jwttoken = getRecoil(jwtToken);
      //console.log("jwtToken--------->", jwtTokenVal, jwttoken);
      let headers = {
        Authorization: "Bearer " + jwttoken,
        // "jwtToken": jwttoken,
        "Content-Type": "application/json",
      };
      //console.log(baseURL + url);
      return Axios.get(baseURL + url, { headers, params: qs })
        .then((res) => {
          if (res.status >= 200 && res.status < 500) {
            console.log("Response data:", res.data);
            return res;
          } else {
            throw new Error(`Unexpected status code: ${res.status}`);
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            //Setting a Custom error message if authorization failed
            error.customErrorMessage =
              "Apologies! An error occurred. Please log in again to continue.";
          }
 
          throw error;
        });
    } catch (e) {
      swal(
        `Failure`,
        `Something went wrong. Please try again after sometime.`,
        "error"
      );
    }
  }
 
  getImage(url, qs) {
    try {
      let baseURL = BACKEND_URL;
      let jwttoken = getRecoil(jwtToken);
 
      let headers = {
        Authorization: "Bearer " + jwttoken,
        "Content-Type": "application/json",
      };
 
      return Axios.get(baseURL + url, {
        headers,
        params: qs,
        responseType: 'blob'  // Ensures the response is treated as binary data suitable for creating a Blob
      })
      .then((res) => {
        if (res.status >= 200 && res.status < 500) {
          console.log("Response data for image:", baseURL + url);
          return res;
        } else {
          throw new Error(`Unexpected status code: ${res.status}`);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          error.customErrorMessage =
            "Apologies! An error occurred. Please log in again to continue.";
        }
 
        throw error;
      });
    } catch (e) {
      swal(
        `Failure`,
        `Something went wrong. Please try again after sometime.`,
        "error"
      );
    }
  }
  getDwg(url, qs) {
    let baseURL = BACKEND_URL;
    let jwttoken = getRecoil(jwtToken);
    let headers = {
      Authorization: "Bearer " + jwttoken,
      "Content-Type": "application/json",
    };
 
    return Axios.get(baseURL + url, {
      headers,
      params: qs,
      responseType: 'arraybuffer'  // Handle as arraybuffer for multipart responses
    })
      .then((response) => {
        // Convert arraybuffer to text
        const contentType = response.headers['content-type'];
        const boundary = contentType.split('boundary=')[1];
        const data = new Uint8Array(response.data);
        const text = new TextDecoder().decode(data);
 
        // Split parts based on boundary
        const parts = text.split(`--${boundary}`);
        let token = null;
        let urn = null;
        let blob = null;
 
        parts.forEach(part => {
          if (part.includes('application/json')) {
            // Extract JSON part
            const jsonPart = part.split('\r\n\r\n')[1].split('\r\n--')[0];
            const parsedData = JSON.parse(jsonPart);
            token = parsedData.token;
            urn = parsedData.urn;
          } else if (part.includes('application/octet-stream')) {
            // Extract binary part
            const binaryPart = part.split('\r\n\r\n')[1].split('\r\n--')[0];
            const binaryData = Uint8Array.from(binaryPart.split('').map(char => char.charCodeAt(0)));
            blob = new Blob([binaryData], { type: 'application/octet-stream' });
          }
        });
 
        if (token || urn || blob) {
          return { token, urn, blob };
        } else {
          throw new Error('Unable to parse multipart response.');
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          error.customErrorMessage = "Apologies! An error occurred. Please log in again to continue.";
        }
 
        swal(
          `Failure`,
          `Something went wrong. Please try again after sometime.`,
          "error"
        );
 
        throw error;
      });
  }
 
 
 
  delete(url, data) {
    try {
      let baseURL = BACKEND_URL;
      let jwttoken = getRecoil(jwtToken);
      //   let jwttoken = Store.get("JWTTOKEN");
      //   let jwtToken = Store.get("jwtToken");
      let headers = {
        Authorization: "Bearer " + jwttoken,
        // jwtToken: jwtToken,
        "Content-Type": "application/json",
      };
      return Axios.delete(baseURL + url, {
        data: JSON.stringify(data),
        headers,
      })
        .then((res) => {
          if (res.status >= 200 && res.status < 500) {
            console.log("Response data:", res.data);
            return res;
          } else {
            throw new Error(`Unexpected status code: ${res.status}`);
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            //Setting a Custom error message if authorization failed
            error.customErrorMessage =
              "Apologies! An error occurred. Please log in again to continue.";
          }
 
          throw error;
        });
    } catch (e) {
      swal(
        `Failure`,
        `Something went wrong. Please try again after sometime.`,
        "error"
      );
    }
  }
 
  imagePut(url, data) {
    try {
      let baseURL = BACKEND_URL;
      let jwttoken = getRecoil(jwtToken);
      //   let jwttoken = Store.get("JWTTOKEN");
      //   let jwtToken = Store.get("jwtToken");
      let headers = {
        Authorization: "Bearer " + jwttoken,
        // jwtToken: jwtToken,
        "Content-Type": "multipart/form-data",
      };
      return Axios.put(baseURL + url, data, { headers })
        .then((res) => {
          if (res.status >= 200 && res.status < 500) {
            console.log("Response data:", res.data);
            return res;
          } else {
            throw new Error(`Unexpected status code: ${res.status}`);
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            //Setting a Custom error message if authorization failed
            error.customErrorMessage =
              "Apologies! An error occurred. Please log in again to continue.";
          }
 
          throw error;
        });
    } catch (e) {
      swal(
        `Failure`,
        `Something went wrong. Please try again after sometime.`,
        "error"
      );
    }
  }
 
 
  put(url, data) {
    try {
      let baseURL = BACKEND_URL;
      let jwttoken = getRecoil(jwtToken);
      //   let jwttoken = Store.get("JWTTOKEN");
      //   let jwtToken = Store.get("jwtToken");
      let headers = {
        Authorization: "Bearer " + jwttoken,
        // jwtToken: jwtToken,
        "Content-Type": "application/json",
      };
      return Axios.put(baseURL + url, data, { headers })
        .then((res) => {
          if (res.status >= 200 && res.status < 500) {
            console.log("Response data:", res.data);
            return res;
          } else {
            throw new Error(`Unexpected status code: ${res.status}`);
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            //Setting a Custom error message if authorization failed
            error.customErrorMessage =
              "Apologies! An error occurred. Please log in again to continue.";
          }
 
          throw error;
        });
    } catch (e) {
      swal(
        `Failure`,
        `Something went wrong. Please try again after sometime.`,
        "error"
      );
    }
  }
  post(url, data) {
    try {
      let baseURL = BACKEND_URL;
      let jwttoken = getRecoil(jwtToken);
      //   let jwttoken = Store.get("JWTTOKEN");
      //   let jwtToken = Store.get("jwtToken");
      console.log(jwttoken);
      let headers = {
        Authorization: "Bearer " + jwttoken,
        // jwtToken: jwtToken,
        "Content-Type": "application/json",
      };
      console.log(headers);
      return Axios.post(
        baseURL + url,
        // { data: JSON.stringify(data) },
        data,
        { headers }
      )
        .then((res) => {
          if (res.status >= 200 && res.status < 500) {
            console.log("Response data:", res.data);
            return res;
          } else {
            throw new Error(`Unexpected status code: ${res.status}`);
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            //Setting a Custom error message if authorization failed
            error.customErrorMessage =
              "Apologies! An error occurred. Please log in again to continue.";
          }
 
          throw error;
        });
    } catch (e) {
      swal(
        `Failure`,
        `Something went wrong. Please try again after sometime.`,
        "error"
      );
    }
  }
  postwithouttoken(url, data) {
    try {
      let baseURL = BACKEND_URL;
      // let jwttoken = getRecoil(jwtToken);
      //   let jwttoken = Store.get("JWTTOKEN");
      //   let jwtToken = Store.get("jwtToken");
      // console.log(jwttoken);
      let headers = {
        Authorization: "Bearer ",
        // jwtToken: jwtToken,
        "Content-Type": "application/json",
      };
      console.log(headers);
      return Axios.post(
        baseURL + url,
        // { data: JSON.stringify(data) },
        data,
        { headers }
      )
        .then((res) => {
          if (res.status >= 200 && res.status < 500) {
            console.log("Response data:", res.data);
            return res;
          } else {
            throw new Error(`Unexpected status code: ${res.status}`);
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            //Setting a Custom error message if authorization failed
            error.customErrorMessage =
              "Apologies! An error occurred. Please log in again to continue.";
          }
 
          throw error;
        });
    } catch (e) {
      swal(
        `Failure`,
        `Something went wrong. Please try again after sometime.`,
        "error"
      );
    }
  }
  patch(url, data) {
    try {
      let baseURL = BACKEND_URL;
      //let jwttoken = getRecoil(idToken);
      let jwtTokenVal = getRecoil(jwtToken);
      //   let jwtToken = Store.get("jwtToken");
      let headers = {
        Authorization: "Bearer " + jwtTokenVal,
        // jwtToken: jwtToken,
        "Content-Type": "application/json",
      };
      return Axios.patch(baseURL + url, data, { headers })
        .then((res) => {
          if (res.status >= 200 && res.status < 500) {
            console.log("Response data:", res.data);
            return res;
          } else {
            throw new Error(`Unexpected status code: ${res.status}`);
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            //Setting a Custom error message if authorization failed
            error.customErrorMessage =
              "Apologies! An error occurred. Please log in again.";
          }
 
          throw error;
        });
    } catch (e) {
      swal(
        `Failure`,
        `Something went wrong. Please try again after sometime.`,
        "error"
      );
    }
  }
 
  //Custom Image Upload APi
  uploadFile(prefix, metaData, file) {
    const errorMessage = "";
    const data = {
      objectKey: prefix,
      metadata: metaData,
    };
 
    const generateHeadersFromMetadata = (metadata) => {
      const headers = {};
      for (const key in metadata) {
        headers[`x-amz-meta-${key}`] = metadata[key];
      }
      return headers;
    };
 
    return new Promise((resolve, reject) => {
      this.post(`/documents/upload`, data)
        .then(async (res) => {
          if (res.data.errorCode) {
            errorMessage = res.data.errormessage;
            throw new Error(res.data.errormessage);
          } else {
            const fileHeaders = generateHeadersFromMetadata(data.metadata);
            await Axios({
              method: "PUT",
              url: res.data.objectKey,
              data: file,
              headers: fileHeaders,
            }).then((awsRes) => {
              if (awsRes.status === 200) {
                resolve(awsRes); // Resolve with the response from AWS
              } else {
                throw new Error("AWS File Upload Failed");
              }
            });
          }
        })
        .catch((error) => {
          if (!errorMessage) {
            error.customErrorMessage = errorMessage;
          }
          reject(error); // Reject the promise with the error
        });
    });
  }
}