import React from 'react'

const SitesDropdown = (props) => {

  const { siteId, handleSiteChange, sites, defaultValue, hideDefault } = props

  return (
    <select
        id="siteName"
        name="siteName"
        value={siteId}
        className='form-control'
        onChange={handleSiteChange}
        style={{ marginLeft: '10px', width: '240px', padding: '4px' , border:'1px solid var(--primary-color)'}}
    >
        { hideDefault && <option value="">{defaultValue ?? "All Sites"}</option> }
        { sites && sites.map(site => (
        <option key={site._id} value={site._id}>{site.siteName}</option>
        ))}
    </select>
  )
}

export default SitesDropdown