import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Dialog, Stepper, StepLabel, Step, DialogContent } from "@mui/material";
import { RxCross1 } from "react-icons/rx";
import { WebApimanager } from "../../../WebApiManager";
import * as Icon from "react-bootstrap-icons";
import { Button } from "react-bootstrap";
import Select from "react-select";
import SearchableDropdown from "../../../Widgets/SearchableDropdown";
import StepConnector from "@mui/material/StepConnector";
const AddnewPnm = ({ newPnMStyle }) => {
  let webApi = new WebApimanager();
  const getDaysCountOptions = (daysType) => {
    let options = [];

    if (daysType === "week") {
      options = Array.from({ length: 54 }, (_, i) => i + 1);
    } else if (daysType === "month") {
      options = Array.from({ length: 12 }, (_, i) => i + 1);
    } else if (daysType === "days") {
      options = Array.from({ length: 31 }, (_, i) => i + 1);
    } else if (daysType === "years") {
      options = Array.from({ length: 5 }, (_, i) => i + 1);
    }

    return options;
  };

  const [open, setOpen] = useState(false);
  const [assetCodeData, setAssetCodeData] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [showForm, setShowForm] = useState(false);
  const [filteredAssets, setFilteredAssets] = useState([]);
  const [filteredNames, setFilteredNames] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [documentsData, setDocumentsData] = useState([]);

  const [formData, setFormData] = useState({
    type: "",
    fuelRnot: "",
    assetCode: "",
    name: "",
    makename: "",
    modelname: "",
    servicebases: "",
    hours: "",
    days: "",
    months: "",
    kilometers: "",
    inspections: "",
    recycletimeperiod: "",
    engineRmotor: "",
    selectNo: "",
    ownRhire: "",
    hirecontactname: "",
    hirepreviousServiceDate: "",
    hireinsurance: "",
    daysTypehirehowmanydays: "",
    daysCounthirehowmanydays: "",
    hirecharges: "",
    hireprice: "",
    OWN: "",
    oldpreviousServiceDate: "",
    oldpurchasePrice: "",
    oldpurchaseDate: "",
    oldinsuranceY: false,
    daysTypeoldinsurance: "",
    daysCountoldinsurance: "",
    oldwarrantyY: false,
    daysTypeoldwarranty: "",
    daysCountoldwarranty: "",
    oldguaranteeY: false,
    daysTypeoldguarantee: "",
    daysCountoldguarantee: "",
    newpurchaseDate: "",
    newpurchasePrice: "",
    newinsuranceY: false,
    daysTypenewinsurance: "",
    daysCountnewinsurance: "",
    newwarrantyY: false,
    daysTypenewwarranty: "",
    daysCountnewwarranty: "",
    newguaranteeY: false,
    daysTypenewguarantee: "",
    daysCountnewguarantee: "",
    document: documentsData,
    queries: [],
    documents: [],
  });

  const [inputFields, setInputFields] = useState([{ name: "" }]);
  const handleAddFields = () => {
    setInputFields([...inputFields, { name: "" }]);
  };

  const changeHandler = (e, index) => {
    const { name, value, type, files } = e.target;

    console.log(`Handling change for index ${index}:`, {
      name,
      value,
      type,
      files,
    });

    setDocumentsData((prevData) => {
      const newData = [...prevData];

      if (type === "file") {
        // Handle file input separately if needed
        newData[index] = {
          ...newData[index],
          [name]: files[0] || null, // Store the file object or null if no file is selected
        };
      } else {
        newData[index] = {
          ...newData[index],
          [name]: value,
        };
      }

      return newData;
    });
  };

  useEffect(() => {
    if (formData.assetCode) {
      const asset = filteredAssets.find(
        (asset) => asset._id === formData.assetCode
      );
      if (asset) {
        const initialDocumentsData = asset.documents.map(() => ({}));
        setDocumentsData(initialDocumentsData);
      }
    }
  }, [formData.assetCode, filteredAssets]);

  const currentAsset = filteredAssets.find(
    (asset) => asset._id === formData.assetCode
  );
  const documents = currentAsset?.documents || [];

  const handleRemoveFields = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  };

  const handleInputChange = (index, event) => {
    const values = [...inputFields];
    values[index][event.target.name] = event.target.value;
    setInputFields(values);
    setFormData((prev) => ({
      ...prev,
      queries: values,
    }));
  };

  const [errors, setErrors] = useState({});

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      setShowForm(false);
      setFormData({});
    }, 2000);
    window.location.reload();
  };

  const handleChange = (e) => {
    setErrors({});
    const { name, value, type, checked, files } = e.target;

    let newValue = value;
    if (type === "checkbox") {
      newValue = checked;
    }
    if (type === "file") {
      newValue = files[0];
    }

    // Additional logic for kilometers validation and filtered assets update
    if (name === "kilometers" && (value === "" || parseFloat(value) >= 0)) {
      setFormData((prevState) => ({
        ...prevState,
        [name]: newValue,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: newValue,
      }));
    }

    // Update filtered assets and names
    updateFilteredAssetsAndNames(name, newValue);

    // Set selected type
    if (name === "type") {
      setSelectedType(newValue);
    }

    // Find the corresponding id and update formData with id instead of value for assetCode and name
    if (name === "assetCode" || name === "name") {
      const selectedItem = assetCodeData.find((item) => {
        if (name === "assetCode") {
          return item.assetCode === newValue;
        } else if (name === "name") {
          return item.name === newValue;
        }
        return false;
      });

      const id = selectedItem ? selectedItem._id : "";
      console.log(`Selected ${name} ID:`, id);

      setFormData((prevData) => ({
        ...prevData,
        [`${name}`]: id,
      }));
    }
  };

  // const handleDocumentUpload = (e, docName, index) => {
  //   // console.log("clicked", handleDocumentUpload);
  //   // const file = e.target.files[0];
  //   // if (file) {
  //   //   console.log(`Uploading ${docName}`, file); // Log file details
  //   //   setFormData((prevData) => ({
  //   //     ...prevData,
  //   //     [`document_${docName}`]: file,
  //   //   }));
  //   // }
  //   const file = e.target.files[0];
  //   if (file) {
  //     const updatedDocumentsData = documentsData.map((doc, docIndex) =>
  //       docIndex === index ? { ...doc, [`document_${docName}`]: file } : doc
  //     );

  //     setDocumentsData(updatedDocumentsData);

  //     // Update formData with the updated documentsData including file
  //     setFormData((prev) => ({
  //       ...prev,
  //       documents: updatedDocumentsData,
  //     }));

  //     console.log("updated Document", updatedDocumentsData);
  //   }
  // };

  const updateFilteredAssetsAndNames = (fieldName, fieldValue) => {
    let updatedFilteredAssets = [...assetCodeData];
    let updatedFilteredNames = [...assetCodeData];

    if (fieldName === "type") {
      updatedFilteredAssets = assetCodeData.filter(
        (item) => item.type === fieldValue
      );
      updatedFilteredNames = assetCodeData.filter(
        (item) => item.type === fieldValue
      );
    }

    if (fieldName === "assetCode") {
      updatedFilteredNames = assetCodeData.filter(
        (item) => item.assetCode === fieldValue
      );
    }

    if (fieldName === "name") {
      updatedFilteredAssets = assetCodeData.filter(
        (item) => item.name === fieldValue
      );
    }

    setFilteredAssets(updatedFilteredAssets);
    setFilteredNames(updatedFilteredNames);
  };

  const handleDocumentUpload = (e, doc) => {
    const file = e.target.files[0];
    if (file) {
      console.log(`Uploading ${doc}:`, file); // Log file details

      setFormData((prevData) => ({
        ...prevData,
        documents: {
          ...prevData.documents,
          [`document_${doc}`]: file, // Store the file under a dynamic key
        },
      }));
    }

    console.log("File selected for:", doc, e.target.files[0]);
  };

  useEffect(() => {
    console.log("active step", activeStep);
    console.log("from data console log", formData);
  }, []);


  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleNext = () => {
    if (activeStep === 0) {
      setDocumentsData(
        Array.from(
          {
            length:
              filteredAssets.find((asset) => asset._id === formData.assetCode)
                ?.documents.length || 0,
          },
          () => ({
            documentNo: "",
            regDate: "",
            expDate: "",
            file: null,
          })
        )
      );
    }
    if (activeStep === 1) {
      setFormData((prev) => ({ ...prev, document: documentsData }));
      console.log();
    }
    if (activeStep === 2) {
      handleSubmit();
    } else {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  useEffect(() => {
    console.log(formData.document, "documents");
  }, []);

  const handleSubmit = () => {
    const payload = {
      type: formData.type,
      assetCode: formData.assetCode,
      name: formData.name,
      fuelRnot: formData.fuelRnot,
      makeName: formData.makename,
      modelName: formData.modelname,
      serviceBases: formData.servicebases,
      ...(formData.servicebases === "hours" && { hours: formData.hours }),
      ...(formData.servicebases === "days" && { days: formData.days }),
      ...(formData.servicebases === "months" && { months: formData.months }),
      ...(formData.servicebases === "kilometers" && {
        kilometers: formData.kilometers,
      }),
      ...(formData.servicebases === "Both Months&Kilometers" && {
        months: formData.months,
        kilometers: formData.kilometers,
      }),
      inspections: formData.inspections,
      ...(formData.inspections === "Yes" && {
        recycleTimePeriod: formData.recycletimeperiod,
        daysCountRecycleTimePeriod: formData.daysCountrecycletimeperiod,
      }),
      engineRmotor: formData.engineRmotor,
      selectNo: formData.selectNo,
      ownRhire: formData.ownRhire,
      ...(formData.ownRhire === "hire" && {
        hireContactName: formData.hirecontactname,
        hirePreviousServiceDate: formData.hirepreviousServiceDate,
        hireInsurance: formData.hireinsurance,
        daysTypeHireHowManyDays: formData.daysTypehirehowmanydays,
        daysCountHireHowManyDays: formData.daysCounthirehowmanydays,
        hireCharges: formData.hirecharges,
        hirePrice: formData.hireprice,
      }),
      ...(formData.ownRhire === "own" && {
        OWN: formData.OWN,
        ...(formData.OWN === "old" && {
          oldPreviousServiceDate: formData.oldpreviousServiceDate,
          oldPurchasePrice: formData.oldpurchasePrice,
          oldPurchaseDate: formData.oldpurchaseDate,
          ...(formData.oldinsuranceY && {
            oldInsurance: `${formData.daysTypeoldinsurance} ${formData.daysCountoldinsurance}`,
          }),
          ...(formData.oldwarrantyY && {
            oldWarranty: `${formData.daysTypeoldwarranty} ${formData.daysCountoldwarranty}`,
          }),
          ...(formData.oldguaranteeY && {
            oldGuarantee: `${formData.daysTypeoldguarantee} ${formData.daysCountoldguarantee}`,
          }),
        }),
        ...(formData.OWN === "new" && {
          newPreviousServiceDate: formData.hirepreviousServiceDate,
          newPurchasePrice: formData.newpurchasePrice,
          newPurchaseDate: formData.newpurchaseDate,
          ...(formData.newinsuranceY && {
            newInsurance: {
              daysType: formData.daysTypenewinsurance,
              daysCount: formData.daysCountnewinsurance,
            },
            newWarranty: {
              daysType: formData.daysTypenewwarranty,
              daysCount: formData.daysCountnewwarranty,
            },
            newGuarantee: {
              daysType: formData.daysTypenewguarantee,
              daysCount: formData.daysCountnewguarantee,
            },
          }),
        }),
      }),
      queries: [formData.queries],
    };

    webApi
      .post("api/newPnm/", payload)
      .then((response) => {
        const data = response.data;
        console.log(response.data);
        console.log("Server Response:", response);
        console.log("Data:", data);
        const id = response.data.newpnm.data._id;
        console.log(id);
        uploadDocuments(id);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const uploadDocuments = (id) => {
    const formDataPayload = new FormData();

    // Append documents
    Object.keys(formData).forEach((key) => {
      if (key.startsWith("document_") && formData[key] instanceof File) {
        formDataPayload.append("document", formData[key]); // Append as 'document'
      }
    });
    assetCodeData.documents.forEach((doc, index) => {
      formDataPayload.append(`document[${index}].documentNo`, doc.documentNo);
      formDataPayload.append(`document[${index}].regDate`, doc.regDate);
      formDataPayload.append(`document[${index}].expDate`, doc.expDate);
      if (doc.file) {
        formDataPayload.append(`document[${index}][file]`, doc.file);
      }
    });

    webApi
      .put(`api/newPnm/upload/${id}`, formDataPayload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        const data = response.data;
        console.log(response.data);
        console.log("Server Response:", response);
        console.log("Data:", data);
        handleClose();
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle error appropriately
      });
  };

  useEffect(() => {
    const fetchAssetCodeData = async () => {
      try {
        const response = await webApi.get(`api/pnms/assetCode`);
        const assetCodes = response.data.data.AssetCodes;
        console.log("Asset Codes from API checking purpose:", assetCodes); // Debugging: Log asset codes to console
        setAssetCodeData(assetCodes);
      } catch (error) {
        console.error("Error fetching asset codes:", error);
        // Handle error fetching asset codes
      }
    };

    fetchAssetCodeData();
  }, []);

  return (
    <>
      <a
        className={`${
          newPnMStyle.display ? "text-[white]" : "text-[#E47331]"
        } no-underline`}
        href="#"
      >
        Add P&M
      </a>
      {newPnMStyle.display && (
        <div
          className={`mt-[50px] absolute left-[140px] right-[140px] bg-white `}
        >
          {!showForm && (
            <div
              style={{
                display: "flex",
                margin: !showForm ? "0px 45px 0px 50px" : "",
                justifyContent: "space-between",
                alignItems: "center",
                color: "black",
                backgroundColor: "white",
                position: "relative",
                borderTopLeftRadius: "5px",
                borderTopRightRadius: "5px",
              }}
            >
              <div className={`flex justify-center items-center  mt-[50px]`}>
                <h3
                  style={{
                    color: showForm ? "white" : "var(--primary-color)",
                    fontSize: "20px",
                    margin: 0,
                  }}
                >
                  Add New P&M
                </h3>
              </div>

              <div
                className={`flex justify-center items-center ${
                  !showForm ? "mt-[50px]" : ""
                } `}
                style={{ position: "relative" }}
              >
                {!showForm && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        width: "250px",
                        paddingRight: "5px",
                      }}
                    >
                      <input
                        placeholder="Search Asset Code List"
                        style={{
                          paddingLeft: "20px",
                          fontSize: "12px",
                          borderRadius: "6px",
                          border: "1px solid var(--primary-color)",
                          width: "250px",
                          height: "40px",
                        }}
                      />
                      <Icon.Search
                        style={{
                          size: "16px",
                          position: "absolute",
                          right: "6px",
                          top: "50%",
                          transform: "translateY(-50%)",
                        }}
                      />
                    </div>
                    <Button
                      className="btn btn-primary"
                      style={{
                        height: "40px",
                        width: "150px",
                        fontSize: "16px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "var(--primary-color)",
                        border: "none",
                        marginLeft: "50px",
                        marginRight: "50px",
                        display: "flex",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        setShowForm(true);
                        setOpen(true);
                      }}
                    >
                      <Icon.PlusCircle
                        size={16}
                        style={{ marginRight: "5px" }}
                      />{" "}
                      Add New
                    </Button>
                  </div>
                )}
              </div>
            </div>
          )}
          <div
            style={{
              padding: "0px 30px 0px 50px",
              height: "max-content",
              width: "100%",
            }}
          >
            {!showForm && (
              <table style={{ width: "100%", marginTop: "10px" }}>
                <thead
                  style={{
                    backgroundColor: "#F3F6F9",
                    textAlign: "start",
                    height: "45px",
                  }}
                >
                  <tr>
                    <th
                      style={{
                        width: "2%",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    ></th>
                    <th
                      style={{
                        width: "5%",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      S.No
                    </th>
                    <th
                      style={{
                        width: "10%",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      Type
                    </th>
                    <th
                      style={{
                        width: "10%",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      AssetCode
                    </th>
                    <th
                      style={{
                        width: "10%",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      Sub Code
                    </th>
                    <th
                      style={{
                        width: "10%",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      Name
                    </th>
                    <th
                      style={{
                        width: "10%",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      Make Name
                    </th>
                    <th
                      style={{
                        width: "10%",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      Fuel/Not
                    </th>
                    <th
                      style={{
                        width: "10%",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      Own/Hire
                    </th>
                    {/* <th style={{ width: '15%' }}>Documents</th> */}
                    <th
                      style={{
                        width: "15%",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      Next Inspection
                    </th>
                    <th
                      style={{
                        width: "10%",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
              </table>
            )}
          </div>
        </div>
      )}

      {showForm && (
        <Dialog
          open={open}
          disableEnforceFocus
          onClose={handleClose}
          maxWidth="md" // Controls max width, you can also use 'sm', 'lg', 'xl', or false for no max width
          fullWidth // Makes the dialog take up the full width specified by maxWidth
          PaperProps={{
            style: {
              height: "1553px", // Set your desired height here
              width: "1440px", // Set your desired width here
              maxWidth: "none",
            },
          }}
        >
          <div className="flex h-[50px] bg-[#4D4D4D] items-center">
            <h3 className="w-full text-white text-center"> Asset Code </h3>
            <RxCross1
              size={20}
              style={{
                marginLeft: "auto",
                cursor: "pointer",
                marginRight: "25px",
                color: "white",
              }}
              onClick={handleClose}
            />
          </div>
          {showForm && (
            <>
              <Stepper
                activeStep={activeStep}
                alternativeLabel
                connector={<StepConnector style={{ top: "20px" }} />}
                className="my-4 mx-10"
                style={{
                  borderBottom: "2px dotted gray",
                  paddingBottom: "60px",
                  paddingTop: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Step style={{ fontSize: "40px", minWidth: "80px" }}>
                  <StepLabel
                    StepIconComponent={() => (
                      <div
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          border: `2px solid #4C4C4C`,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "18px",
                          color: activeStep === 0 ? "#fff" : "#4C4C4C",
                          backgroundColor:
                            activeStep === 0 ? "#4C4C4C" : "transparent",
                        }}
                      >
                        1
                      </div>
                    )}
                  >
                    Equipment Type Details
                  </StepLabel>
                </Step>
                <Step style={{ fontSize: "40px", minWidth: "80px" }}>
                  <StepLabel
                    StepIconComponent={() => (
                      <div
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          border: `2px solid ${
                            activeStep === 0 || activeStep === 1
                              ? "#4C4C4C"
                              : "#979797"
                          }`,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "18px",
                          color: activeStep === 1 ? "#fff" : "#4C4C4C",
                          backgroundColor:
                            activeStep === 1 ? "#4C4C4C" : "transparent",
                        }}
                      >
                        2
                      </div>
                    )}
                  >
                    Equipment Make Details
                  </StepLabel>
                </Step>
                <Step style={{ fontSize: "40px", minWidth: "80px" }}>
                  <StepLabel
                    StepIconComponent={() => (
                      <div
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          border: `2px solid ${
                            activeStep < 2 ? "#4C4C4C" : "#979797"
                          }`,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "18px",
                          color: activeStep === 2 ? "#fff" : "#4C4C4C",
                          backgroundColor:
                            activeStep === 2 ? "#4C4C4C" : "transparent",
                        }}
                      >
                        3
                      </div>
                    )}
                  >
                    Equipment Make Confirm Details
                  </StepLabel>
                </Step>
              </Stepper>

              <form>
                {activeStep === 0 ? (
                  <>
                    <div>
                      <div className="row mb-2 d-flex justify-content-around">
                        <div className="col-md-5 mb-3">
                          <label
                            className="form-label fs-6 "
                            style={{ margin: "0px", marginBottom: "15px" }}
                          >
                            Type
                          </label>
                          <select
                            className="form-select "
                            name="type"
                            value={formData.type}
                            onChange={handleChange}
                            style={{
                              backgroundColor: "#F3F3F3",

                              fontSize: "12px",
                              borderRadius: "0px",
                            }}
                          >
                            <option value="">Select Type</option>

                            <option value="Vehicle">Vehicle</option>
                            <option value="Machinery">Machinery</option>
                            <option value="Power tools">PowerTools</option>
                          </select>
                          <p className="text-danger">{errors.type}</p>
                        </div>
                        <div className="col-md-5 mb-3">
                          <label
                            className="form-label fs-6 "
                            style={{ margin: "0px", marginBottom: "15px" }}
                          >
                            Fuel/Not
                          </label>
                          <select
                            className="form-select"
                            name="fuelRnot"
                            value={formData.fuelRnot}
                            onChange={handleChange}
                            style={{
                              backgroundColor: "#F3F3F3",
                              borderRadius: "0px",
                              fontSize: "12px",
                              border: "1px solid #F3F3F3", // Optional: Add a border to match styling
                            }}
                          >
                            <option value="">Select Type</option>
                            <option value="Petrol">Petrol</option>
                            <option value="Disel">Disel</option>
                            <option value="Power">Power</option>
                          </select>
                          <p className="text-danger">{errors.fuelRnot}</p>
                        </div>

                        {(formData.type === "Vehicle" ||
                          formData.type === "Machinery" ||
                          formData.type === "Power tools") && (
                          <>
                            <div className="col-md-5 mb-3">
                              <label
                                className="form-label fs-6"
                                style={{
                                  margin: "0px",
                                  marginBottom: "15px",
                                }}
                              >
                                Asset Code
                              </label>
                              <SearchableDropdown
                                options={filteredAssets.map(
                                  (item) => item.assetCode
                                )}
                                onOptionSelect={(option) =>
                                  handleChange({
                                    target: {
                                      name: "assetCode",
                                      value: option,
                                    },
                                  })
                                }
                              />
                              <p className="text-danger">{errors.assetCode}</p>
                            </div>
                            <div className="col-md-5 mb-3">
                              <label
                                className="form-label fs-6"
                                style={{
                                  margin: "0px",
                                  marginBottom: "15px",
                                }}
                              >
                                Name
                              </label>
                              <SearchableDropdown
                                options={filteredNames.map((item) => item.name)}
                                onOptionSelect={(option) =>
                                  handleChange({
                                    target: {
                                      name: "name",
                                      value: option,
                                    },
                                  })
                                }
                              />
                              <p className="text-danger">{errors.name}</p>
                            </div>
                          </>
                        )}

                        <div className="col-md-5 mb-3">
                          <label
                            className="form-label fs-6"
                            style={{ margin: "0px", marginBottom: "15px" }}
                            // style={{ margin: "0px", marginBottom: "15px" }}
                          >
                            Make Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="makename"
                            placeholder="Enter Make Name"
                            value={formData.makename}
                            onChange={handleChange}
                            style={{
                              backgroundColor: "#F3F3F3",
                              borderRadius: "0px",
                              fontSize: "12px",
                              border: "1px solid #ccc", // Optional: Add a border to match styling
                            }}
                          />
                          <p className="text-danger">{errors.makename}</p>
                        </div>

                        <div className="col-md-5 mb-3">
                          <label
                            className="form-label fs-6"
                            style={{ margin: "0px", marginBottom: "15px" }}
                          >
                            Model Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="modelname"
                            placeholder="Enter Model Name"
                            value={formData.modelname}
                            onChange={handleChange}
                            style={{
                              backgroundColor: "#F3F3F3",

                              fontSize: "12px",
                              borderRadius: "0px",
                              // border: "1px solid #979797" // Optional: Add a border to match styling
                            }}
                          />
                          <p className="text-danger">{errors.modelname}</p>
                        </div>

                        <div className="col-md-5 mb-3">
                          <label
                            className="form-label fs-6"
                            style={{ margin: "0px", marginBottom: "15px" }}
                          >
                            Engine/Motor
                          </label>
                          <div style={{ display: "flex" }}>
                            <div style={{ width: "100%" }}>
                              <select
                                className="form-select"
                                style={{
                                  backgroundColor: "#F3F3F3",
                                  fontSize: "12px",
                                }}
                                name="engineRmotor"
                                value={formData.engineRmotor}
                                onChange={handleChange}
                              >
                                <option value="">Select Type</option>
                                <option value="engine">Engine</option>
                                <option value="motor">Motor</option>
                              </select>
                              <p className="text-danger">
                                {errors.engineRmotor}
                              </p>
                            </div>
                            {(formData.engineRmotor === "engine" ||
                              formData.engineRmotor === "motor") && (
                              <div className="col-md-5 mb-3">
                                <select
                                  className="form-select"
                                  name="selectNo"
                                  value={formData.selectNo}
                                  style={{
                                    backgroundColor: "#F3F3F3",
                                    borderRadius: "0px",

                                    fontSize: "12px",
                                    border: "1px solid #ccc", 
                                  }}
                                  onChange={handleChange}
                                >
                                  <option value="">Select No.of</option>
                                  <option value="Single">Single</option>
                                  <option value="Double">Double</option>
                                </select>
                                <p className="text-danger">
                                  {errors.engineRmotorNO}
                                </p>
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-md-5 mb-3">
                          <label
                            className="form-label fs-6"
                            style={{ margin: "0px", marginBottom: "15px" }}
                          >
                            Service Bases
                          </label>
                          <div
                            style={{
                              display: "flex",
                              borderRadius: "0px",
                              fontSize: "12px",
                            }}
                          >
                            <select
                              className="form-select"
                              name="servicebases"
                              value={formData.servicebases}
                              onChange={handleChange}
                              style={{
                                backgroundColor: "#F3F3F3",
                                flex: "1",
                                fontSize: "12px",
                                borderRadius: "0px",
                              }}
                            >
                              <option value="">Select Service Bases</option>
                              <option value="Hours">Hours</option>
                              <option value="Days">Days</option>
                              <option value="Weeks">Weeks</option>
                              <option value="Months">Months</option>
                              <option value="Kilometers">Kilometers</option>
                              <option value="Both Months&Kilometers">
                                Both Months&Kilometers
                              </option>
                            </select>

                            {formData.servicebases && (
                              <div className="col-md-5 mb-3 d-flex align-items-center">
                                {formData.servicebases === "Kilometers" ||
                                formData.servicebases ===
                                  "Both Months&Kilometers" ? (
                                  <input
                                    type="number"
                                    className="form-control"
                                    name="kilometers"
                                    min="0"
                                    placeholder="Enter No Of KM"
                                    value={formData.kilometers}
                                    onChange={handleChange}
                                  />
                                ) : (
                                  // TODO : update the styling
                                  <select
                                    className="form-control"
                                    style={{
                                      backgroundColor: "#F3F3F3",
                                      borderRadius: "0px",
                                      fontSize: "12px",
                                      border: "1px solid #ccc", // Optional: Add a border to match styling
                                    }}
                                    name={
                                      formData.servicebases === "Hours"
                                        ? "hours"
                                        : formData.servicebases === "Days"
                                        ? "days"
                                        : formData.servicebases === "Weeks"
                                        ? "weeks"
                                        : "months"
                                    }
                                    value={
                                      formData.servicebases === "Hours"
                                        ? formData.hours
                                        : formData.servicebases === "Days"
                                        ? formData.days
                                        : formData.servicebases === "Weeks"
                                        ? formData.weeks
                                        : formData.months
                                    }
                                    onChange={handleChange}
                                  >
                                    <option value="">
                                      {formData.servicebases === "Hours"
                                        ? "Select Hours"
                                        : formData.servicebases === "Days"
                                        ? "Select Days"
                                        : formData.servicebases === "Weeks"
                                        ? "Select Weeks"
                                        : "Select Months"}
                                    </option>
                                    {Array.from(
                                      {
                                        length:
                                          formData.servicebases === "Hours"
                                            ? 24
                                            : formData.servicebases === "Days"
                                            ? 7
                                            : formData.servicebases === "Weeks"
                                            ? 54
                                            : 12,
                                      },
                                      (_, i) => i + 1
                                    ).map((value) => (
                                      <option key={value} value={value}>
                                        {value}
                                      </option>
                                    ))}
                                  </select>
                                )}
                                <p className="text-danger mb-0 ms-2">
                                  {formData.servicebases === "Hours"
                                    ? errors.hours
                                    : formData.servicebases === "Days"
                                    ? errors.days
                                    : formData.servicebases === "Weeks"
                                    ? errors.weeks
                                    : formData.servicebases === "Kilometers" ||
                                      formData.servicebases ===
                                        "Both Months&Kilometers"
                                    ? errors.kilometers
                                    : errors.months}
                                </p>
                              </div>
                            )}
                          </div>
                          <p className="text-danger">{errors.servicebases}</p>
                        </div>

                        <div className="col-md-11 mb-3 ">
                          <label
                            className="form-label fs-6"
                            style={{ margin: "0px", marginBottom: "15px" }}
                          >
                            Inspections
                          </label>
                          <div style={{ display: "flex" }}>
                            <div className="col-md-5 mb-3 w-full">
                              <select
                                className="form-select"
                                name="inspections"
                                value={formData.inspections}
                                onChange={handleChange}
                                style={{
                                  backgroundColor: "#F3F3F3",
                                  borderRadius: "0px",
                                  fontSize: "12px",
                                  border: "1px solid #ccc", // Optional: Add a border to match styling
                                }}
                              >
                                <option value="">Select Inspections</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                              <p className="text-danger">
                                {errors.inspections}
                              </p>
                            </div>

                            {formData.inspections === "Yes" && (
                              <div className="mb-3 w-full">
                                <div
                                  style={{
                                    borderRadius: "0px",
                                  }}
                                >
                                  <div style={{ display: "flex" }}>
                                    <div style={{ flex: 1 }}>
                                      <select
                                        className="form-control"
                                        name="daysTyperecycletimeperiod"
                                        value={formData.recycletimeperiod}
                                        onChange={handleChange}
                                        style={{
                                          backgroundColor: "#F3F3F3",
                                          borderRadius: "0px",
                                          fontSize: "12px",
                                          border: "1px solid #ccc", // Optional: Add a border to match styling
                                        }}
                                      >
                                        <option value="">Select Type</option>
                                        <option value="week">Week</option>
                                        <option value="month">Month</option>
                                        <option value="days">Days</option>
                                      </select>
                                    </div>
                                    {formData.recycletimeperiod && (
                                      <div style={{ flex: 1 }}>
                                        <select
                                          className="form-control"
                                          name="daysCountrecycletimeperiod"
                                          value={
                                            formData.daysCountrecycletimeperiod
                                          }
                                          onChange={handleChange}
                                          style={{
                                            backgroundColor: "#F3F3F3",
                                            margin: "0px",
                                            borderRadius: "0px",
                                            fontSize: "12px",
                                            border: "1px solid #ccc", // Optional: Add a border to match styling
                                          }}
                                        >
                                          <option value="">Select Count</option>
                                          {getDaysCountOptions(
                                            formData.recycletimeperiod
                                          ).map((count) => (
                                            <option key={count} value={count}>
                                              {count}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <p className="text-danger">
                                  {errors.daysTyperecycletimeperiod}
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                        <h3
                          style={{
                            color: "var(--primary-color)",
                            fontSize: "24px",
                            marginLeft: "50px",
                            marginBottom: "25px",
                          }}
                        >
                          Equipment Details
                        </h3>

                        <div className="col-md-5 mb-3">
                          <label
                            className="form-label fs-6"
                            style={{ margin: "0px", marginBottom: "15px" }}
                          >
                            Own/Hire
                          </label>
                          <select
                            className="form-select"
                            name="ownRhire"
                            value={formData.ownRhire}
                            onChange={handleChange}
                            style={{
                              backgroundColor: "#F3F3F3",
                              borderRadius: "0px",
                              fontSize: "12px",
                              border: "1px solid #ccc",
                            }}
                          >
                            <option style={{ color: "#B4B4B4" }} value="">
                              Select Own/Hire
                            </option>
                            <option value="own">Own</option>
                            <option value="hire">Hire</option>
                          </select>
                          <p className="text-danger">{errors.ownRhire}</p>
                        </div>

                        <div className="col-md-5 mb-3">
                          {formData.ownRhire === "own" && (
                            <>
                              <div>
                                <label
                                  className="form-label fs-6"
                                  style={{
                                    margin: "0px",
                                    marginBottom: "15px",
                                  }}
                                >
                                  Own
                                </label>
                                <select
                                  className="form-select"
                                  name="OWN"
                                  value={formData.OWN}
                                  onChange={handleChange}
                                  style={{
                                    backgroundColor: "#F3F3F3",
                                    borderRadius: "0px",
                                    fontSize: "12px",
                                    border: "1px solid #ccc", // Optional: Add a border to match styling
                                  }}
                                >
                                  <option value="">Select Old/New</option>
                                  <option value="old">Old</option>
                                  <option value="new">New</option>
                                </select>
                                <p className="text-danger">{errors.OWN}</p>
                              </div>
                            </>
                          )}
                        </div>
                        {formData.ownRhire !== "hire" &&
                          formData.OWN === "new" && (
                            <div className="col-md-11 d-flex justify-between">
                              <div className="w-full">
                                <h5>New</h5>
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "130px",
                                    marginTop: "15px",
                                  }}
                                >
                                  <div style={{ flex: 1 }}>
                                    <label
                                      className="form-label fs-6"
                                      style={{
                                        margin: "0px",
                                        marginBottom: "15px",
                                      }}
                                    >
                                      Purchase Date:
                                    </label>
                                    <input
                                      type="date"
                                      className="form-control"
                                      name="newpurchaseDate"
                                      value={formData.newpurchaseDate}
                                      style={{
                                        backgroundColor: "#F3F3F3",
                                        fontSize: "12px",
                                        borderRadius: "0px",
                                        border: "1px solid #F3F3F3",
                                      }}
                                      onChange={handleChange}
                                    />
                                    <p className="text-danger">
                                      {errors.newpurchaseDate}
                                    </p>
                                  </div>
                                  <div style={{ flex: 1 }}>
                                    <label
                                      className="form-label fs-6"
                                      style={{
                                        margin: "0px",
                                        marginBottom: "15px",
                                      }}
                                    >
                                      Purchase Price:
                                    </label>
                                    <input
                                      type="number"
                                      className="form-control"
                                      name="newpurchasePrice"
                                      placeholder="Enter Purchase Price"
                                      value={formData.newpurchasePrice}
                                      style={{
                                        backgroundColor: "#F3F3F3",
                                        fontSize: "12px",
                                        borderRadius: "0px",
                                        border: "1px solid #F3F3F3",
                                      }}
                                      onChange={handleChange}
                                    />
                                    <p className="text-danger">
                                      {errors.newpurchasePrice}
                                    </p>
                                  </div>
                                </div>
                                <div
                                  className="w-full"
                                  style={{ display: "flex" }}
                                >
                                  <div style={{ flex: 1 }}>
                                    <div className="space-x-2">
                                      <input
                                        type="checkbox"
                                        name="newinsuranceY"
                                        value={formData.newinsuranceY}
                                        onChange={handleChange}
                                      />
                                      <label
                                        className="form-label fs-6"
                                        style={{
                                          margin: "0px",
                                          marginBottom: "15px",
                                        }}
                                      >
                                        Insurance:
                                      </label>
                                    </div>
                                    <div className="d-flex gap-1">
                                      {formData.newinsuranceY && (
                                        <div
                                          style={{
                                            marginTop: "10px",
                                          }}
                                        >
                                          <div style={{ display: "flex" }}>
                                            <div style={{ flex: 1 }}>
                                              <select
                                                className="form-control"
                                                name="daysTypenewinsurance"
                                                value={
                                                  formData.daysTypenewinsurance
                                                }
                                                onChange={handleChange}
                                                style={{
                                                  backgroundColor: "#F3F3F3",
                                                  fontSize: "12px",
                                                  borderRadius: "0px",
                                                  border: "1px solid #F3F3F3",
                                                }}
                                              >
                                                <option value="">
                                                  Select Type
                                                </option>
                                                <option value="week">
                                                  Week
                                                </option>
                                                <option value="month">
                                                  Month
                                                </option>
                                                <option value="days">
                                                  Days
                                                </option>
                                                <option value="years">
                                                  Years
                                                </option>
                                              </select>
                                            </div>
                                            {formData.daysTypenewinsurance && (
                                              <div style={{ flex: 1 }}>
                                                <select
                                                  className="form-control"
                                                  name="daysCountnewinsurance"
                                                  value={
                                                    formData.daysCountnewinsurance
                                                  }
                                                  onChange={handleChange}
                                                  style={{
                                                    backgroundColor: "#F3F3F3",
                                                    fontSize: "12px",
                                                    borderRadius: "0px",
                                                    border: "1px solid #F3F3F3",
                                                  }}
                                                >
                                                  <option value="">
                                                    Select Count
                                                  </option>
                                                  {getDaysCountOptions(
                                                    formData.daysTypenewinsurance
                                                  ).map((count) => (
                                                    <option
                                                      key={count}
                                                      value={count}
                                                    >
                                                      {count}
                                                    </option>
                                                  ))}
                                                </select>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    <p className="text-danger">
                                      {errors.daysTypenewinsurance}
                                    </p>
                                  </div>
                                  <div style={{ flex: 1 }}>
                                    <div className="space-x-2">
                                      <input
                                        type="checkbox"
                                        name="newwarrantyY"
                                        value={formData.newwarrantyY}
                                        onChange={handleChange}
                                      />
                                      <label
                                        className="form-label fs-6"
                                        style={{
                                          margin: "0px",
                                          marginBottom: "15px",
                                        }}
                                      >
                                        Warranty:
                                      </label>
                                    </div>
                                    <div className="d-flex gap-1">
                                      {formData.newwarrantyY && (
                                        <div
                                          style={{
                                            marginTop: "10px",
                                            border: "1px solid #ccc",
                                          }}
                                        >
                                          <div style={{ display: "flex" }}>
                                            <div style={{ flex: 1 }}>
                                              <select
                                                className="form-control"
                                                name="daysTypenewwarranty"
                                                value={
                                                  formData.daysTypenewwarranty
                                                }
                                                onChange={handleChange}
                                                style={{
                                                  backgroundColor: "#F3F3F3",
                                                  fontSize: "12px",
                                                  borderRadius: "0px",
                                                  border: "1px solid #F3F3F3",
                                                }}
                                              >
                                                <option value="">
                                                  Select Type
                                                </option>
                                                <option value="week">
                                                  Week
                                                </option>
                                                <option value="month">
                                                  Month
                                                </option>
                                                <option value="days">
                                                  Days
                                                </option>
                                                <option value="years">
                                                  Years
                                                </option>
                                              </select>
                                            </div>
                                            {formData.daysTypenewwarranty && (
                                              <div style={{ flex: 1 }}>
                                                <select
                                                  style={{
                                                    backgroundColor: "#F3F3F3",
                                                    fontSize: "12px",
                                                    borderRadius: "0px",
                                                    border: "1px solid #F3F3F3",
                                                  }}
                                                  className="form-control"
                                                  name="daysCountnewwarranty"
                                                  value={
                                                    formData.daysCountnewwarranty
                                                  }
                                                  onChange={handleChange}
                                                >
                                                  <option value="">
                                                    Select Count
                                                  </option>
                                                  {getDaysCountOptions(
                                                    formData.daysTypenewwarranty
                                                  ).map((count) => (
                                                    <option
                                                      key={count}
                                                      value={count}
                                                    >
                                                      {count}
                                                    </option>
                                                  ))}
                                                </select>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    <p className="text-danger">
                                      {errors.daysTypenewwarranty}
                                    </p>
                                  </div>
                                  <div style={{ flex: 1 }}>
                                    <div className="space-x-2">
                                      <input
                                        type="checkbox"
                                        name="newguaranteeY"
                                        value={formData.newguaranteeY}
                                        onChange={handleChange}
                                      />
                                      <label
                                        className="form-label fs-6"
                                        style={{
                                          margin: "0px",
                                          marginBottom: "15px",
                                        }}
                                      >
                                        Guarantee:
                                      </label>
                                    </div>
                                    <div className="d-flex gap-1">
                                      {formData.newguaranteeY && (
                                        <div
                                          style={{
                                            marginTop: "10px",
                                            borderRadius: "4px",
                                          }}
                                        >
                                          <div style={{ display: "flex" }}>
                                            <div style={{ flex: 1 }}>
                                              <select
                                                className="form-control"
                                                name="daysTypenewguarantee"
                                                value={
                                                  formData.daysTypenewguarantee
                                                }
                                                onChange={handleChange}
                                                style={{
                                                  backgroundColor: "#F3F3F3",
                                                  borderRadius: "0px",
                                                  fontSize: "12px",
                                                  border: "1px solid #ccc", // Optional: Add a border to match styling
                                                }}
                                              >
                                                <option value="">
                                                  Select Type
                                                </option>
                                                <option value="week">
                                                  Week
                                                </option>
                                                <option value="month">
                                                  Month
                                                </option>
                                                <option value="days">
                                                  Days
                                                </option>
                                                <option value="years">
                                                  Years
                                                </option>
                                              </select>
                                            </div>
                                            {formData.daysTypenewguarantee && (
                                              <div style={{ flex: 1 }}>
                                                <select
                                                  className="form-control"
                                                  name="daysCountnewguarantee"
                                                  value={
                                                    formData.daysCountnewguarantee
                                                  }
                                                  onChange={handleChange}
                                                  style={{
                                                    backgroundColor: "#F3F3F3",
                                                    fontSize: "12px",
                                                    borderRadius: "0px",
                                                    border: "1px solid #F3F3F3",
                                                  }}
                                                >
                                                  <option value="">
                                                    Select Count
                                                  </option>
                                                  {getDaysCountOptions(
                                                    formData.daysTypenewguarantee
                                                  ).map((count) => (
                                                    <option
                                                      key={count}
                                                      value={count}
                                                    >
                                                      {count}
                                                    </option>
                                                  ))}
                                                </select>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    <p className="text-danger">
                                      {errors.daysTypenewguarantee}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        {formData.ownRhire !== "hire" &&
                          formData.OWN === "old" && (
                            <div className=" col-md-11 d-flex justify-between">
                              <div className="w-full">
                                <h5>Old</h5>
                                <div
                                  className="w-full"
                                  style={{ display: "flex", gap: "20px" }} // Added gap to space out the sections
                                >
                                  <div style={{ flex: 1 }}>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "8px",
                                      }}
                                    >
                                      <input
                                        type="checkbox"
                                        name="oldinsuranceY"
                                        value={formData.oldinsuranceY}
                                        onChange={handleChange}
                                        style={{ margin: 0 }} // Removes any default margin on the checkbox
                                      />
                                      <label
                                        className="form-label fs-6"
                                        style={{
                                          margin: "0px",
                                          marginBottom: "15px",
                                        }}
                                      >
                                        Insurance
                                      </label>
                                    </div>

                                    {formData.oldinsuranceY && (
                                      <div
                                        style={{
                                          marginTop: "10px",
                                          width: "100%",
                                        }}
                                      >
                                        <div style={{ display: "flex" }}>
                                          <select
                                            className="form-control"
                                            name="daysTypeoldinsurance"
                                            style={{
                                              backgroundColor: "#F3F3F3",
                                              fontSize: "12px",
                                              borderRadius: "0px",
                                              border: "1px solid #F3F3F3",
                                              width: "50%", // Adjusted to cover half of the available width
                                            }}
                                            value={
                                              formData.daysTypeoldinsurance
                                            }
                                            onChange={handleChange}
                                          >
                                            <option value="">
                                              Select Type
                                            </option>
                                            <option value="week">Week</option>
                                            <option value="month">Month</option>
                                            <option value="days">Days</option>
                                            <option value="years">Years</option>
                                          </select>
                                          {formData.daysTypeoldinsurance && (
                                            <select
                                              className="form-control"
                                              style={{
                                                backgroundColor: "#F3F3F3",
                                                fontSize: "12px",
                                                borderRadius: "0px",

                                                border: "1px solid #F3F3F3",
                                                width: "50%", // Adjusted to cover half of the available width
                                              }}
                                              name="daysCountoldinsurance"
                                              value={
                                                formData.daysCountoldinsurance
                                              }
                                              onChange={handleChange}
                                            >
                                              <option value="">
                                                Select Count
                                              </option>
                                              {getDaysCountOptions(
                                                formData.daysTypeoldinsurance
                                              ).map((count) => (
                                                <option
                                                  key={count}
                                                  value={count}
                                                >
                                                  {count}
                                                </option>
                                              ))}
                                            </select>
                                          )}
                                        </div>
                                      </div>
                                    )}
                                    <p className="text-danger">
                                      {errors.daysTypeoldinsurance}
                                    </p>
                                  </div>

                                  <div style={{ flex: 1 }}>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "8px",
                                      }}
                                    >
                                      <input
                                        type="checkbox"
                                        name="oldwarrantyY"
                                        value={formData.oldwarrantyY}
                                        onChange={handleChange}
                                        style={{ margin: 0 }} // Removes any default margin on the checkbox
                                      />
                                      <label
                                        className="form-label fs-6"
                                        style={{
                                          margin: "0px",
                                          marginBottom: "15px",
                                        }}
                                      >
                                        Warranty
                                      </label>
                                    </div>

                                    {formData.oldwarrantyY && (
                                      <div
                                        style={{
                                          marginTop: "10px",
                                          width: "100%",
                                        }}
                                      >
                                        <div style={{ display: "flex" }}>
                                          <select
                                            className="form-control"
                                            name="daysTypeoldwarranty"
                                            value={formData.daysTypeoldwarranty}
                                            style={{
                                              backgroundColor: "#F3F3F3",
                                              fontSize: "12px",
                                              borderRadius: "0px",

                                              border: "1px solid #F3F3F3",
                                              width: "50%", // Adjusted to cover half of the available width
                                            }}
                                            onChange={handleChange}
                                          >
                                            <option value="">
                                              Select Type
                                            </option>
                                            <option value="week">Week</option>
                                            <option value="month">Month</option>
                                            <option value="days">Days</option>
                                            <option value="years">Years</option>
                                          </select>
                                          {formData.daysTypeoldwarranty && (
                                            <select
                                              className="form-control"
                                              style={{
                                                backgroundColor: "#F3F3F3",
                                                fontSize: "12px",
                                                borderRadius: "0px",
                                                border: "1px solid #F3F3F3",
                                                width: "50%", // Adjusted to cover half of the available width
                                              }}
                                              name="daysCountoldwarranty"
                                              value={
                                                formData.daysCountoldwarranty
                                              }
                                              onChange={handleChange}
                                            >
                                              <option value="">
                                                Select Count
                                              </option>
                                              {getDaysCountOptions(
                                                formData.daysTypeoldwarranty
                                              ).map((count) => (
                                                <option
                                                  key={count}
                                                  value={count}
                                                >
                                                  {count}
                                                </option>
                                              ))}
                                            </select>
                                          )}
                                        </div>
                                      </div>
                                    )}
                                    <p className="text-danger">
                                      {errors.daysTypeoldwarranty}
                                    </p>
                                  </div>

                                  <div style={{ flex: 1 }}>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "8px",
                                      }}
                                    >
                                      <input
                                        type="checkbox"
                                        name="oldguaranteeY"
                                        value={formData.oldguaranteeY}
                                        onChange={handleChange}
                                        style={{ margin: 0 }} // Removes any default margin on the checkbox
                                      />
                                      <label
                                        className="form-label fs-6"
                                        style={{
                                          margin: "0px",
                                          marginBottom: "15px",
                                        }}
                                      >
                                        Guarantee
                                      </label>
                                    </div>

                                    {formData.oldguaranteeY && (
                                      <div
                                        style={{
                                          marginTop: "10px",
                                          width: "100%",
                                        }}
                                      >
                                        <div style={{ display: "flex" }}>
                                          <select
                                            className="form-control"
                                            name="daysTypeoldguarantee"
                                            value={
                                              formData.daysTypeoldguarantee
                                            }
                                            style={{
                                              backgroundColor: "#F3F3F3",
                                              fontSize: "12px",
                                              borderRadius: "0px",
                                              border: "1px solid #ccc",
                                              width: "50%", // Adjusted to cover half of the available width
                                            }}
                                            onChange={handleChange}
                                          >
                                            <option value="">
                                              Select Type
                                            </option>
                                            <option value="week">Week</option>
                                            <option value="month">Month</option>
                                            <option value="days">Days</option>
                                            <option value="years">Years</option>
                                          </select>
                                          {formData.daysTypeoldguarantee && (
                                            <select
                                              className="form-control"
                                              style={{
                                                backgroundColor: "#F3F3F3",
                                                fontSize: "12px",
                                                borderRadius: "0px",
                                                border: "1px solid #ccc",
                                                width: "50%", // Adjusted to cover half of the available width
                                              }}
                                              name="daysCountoldguarantee"
                                              value={
                                                formData.daysCountoldguarantee
                                              }
                                              onChange={handleChange}
                                            >
                                              <option value="">
                                                Select Count
                                              </option>
                                              {getDaysCountOptions(
                                                formData.daysTypeoldguarantee
                                              ).map((count) => (
                                                <option
                                                  key={count}
                                                  value={count}
                                                >
                                                  {count}
                                                </option>
                                              ))}
                                            </select>
                                          )}
                                        </div>
                                      </div>
                                    )}
                                    <p className="text-danger">
                                      {errors.daysTypeoldguarantee}
                                    </p>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-md-6 mb-3">
                                    <label
                                      className="form-label fs-6"
                                      style={{
                                        margin: "0px",
                                        marginBottom: "15px",
                                      }}
                                    >
                                      Previous Service Date
                                    </label>
                                    <input
                                      type="date"
                                      className="form-control"
                                      name="oldpreviousServiceDate"
                                      value={formData.oldnextServiceDate}
                                      onChange={handleChange}
                                      style={{
                                        backgroundColor: "#F3F3F3",
                                        fontSize: "12px",
                                        borderRadius: "0px",
                                        border: "1px solid #ccc", // Optional: Add a border to match styling
                                      }}
                                    />
                                  </div>

                                  <div className="col-md-6 mb-3">
                                    <label
                                      className="form-label fs-6"
                                      style={{
                                        margin: "0px",
                                        marginBottom: "15px",
                                      }}
                                    >
                                      Purchase Price
                                    </label>
                                    <input
                                      type="number"
                                      className="form-control"
                                      name="oldpurchasePrice"
                                      placeholder="Enter Purchase Price"
                                      value={formData.oldpurchasePrice}
                                      onChange={handleChange}
                                      style={{
                                        backgroundColor: "#F3F3F3",
                                        borderRadius: "0px",
                                        fontSize: "12px",
                                        border: "1px solid #ccc", // Optional: Add a border to match styling
                                      }}
                                    />
                                    <p className="text-danger">
                                      {errors.oldpurchasePrice}
                                    </p>
                                  </div>

                                  <div className="col-md-6 mb-3">
                                    <label
                                      className="form-label fs-6"
                                      style={{
                                        margin: "0px",
                                        marginBottom: "15px",
                                      }}
                                    >
                                      Purchase Date
                                    </label>
                                    <input
                                      type="date"
                                      className="form-control"
                                      name="oldpurchaseDate"
                                      value={formData.oldpurchaseDate}
                                      onChange={handleChange}
                                      style={{
                                        backgroundColor: "#F3F3F3",
                                        borderRadius: "0px",
                                        fontSize: "12px",
                                        border: "1px solid #ccc", // Optional: Add a border to match styling
                                      }}
                                    />
                                    <p className="text-danger">
                                      {errors.oldpurchaseDate}
                                    </p>
                                  </div>

                                  <div className="col-md-6 mb-3">
                                    <p className="text-danger">
                                      {errors.daysTypeoldguarante}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        {formData.ownRhire === "hire" && (
                          <div className=" col-md-11 d-flex justify-between">
                            <div className="w-full">
                              <h5>Hire</h5>

                              <div className="row">
                                <div className="col-md-6 mb-3">
                                  <label
                                    className="form-label fs-6"
                                    style={{
                                      margin: "0px",
                                      marginBottom: "15px",
                                    }}
                                  >
                                    Contact Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="enter contact name"
                                    name="hirecontactname"
                                    value={formData.hirecontactname}
                                    onChange={handleChange}
                                    style={{
                                      backgroundColor: "#F3F3F3",
                                      fontSize: "12px",
                                      borderRadius: "0px",
                                      border: "1px solid #ccc", // Optional: Add a border to match styling
                                    }}
                                  />
                                  <p className="text-danger">
                                    {errors.hirecontactname}
                                  </p>
                                </div>

                                <div className="col-md-6 mb-3">
                                  <label
                                    className="form-label fs-6"
                                    style={{
                                      margin: "0px",
                                      marginBottom: "15px",
                                    }}
                                  >
                                    How Many Days:
                                  </label>
                                  <div>
                                    <div style={{ display: "flex" }}>
                                      <div style={{ flex: 1 }}>
                                        <select
                                          style={{
                                            backgroundColor: "#F3F3F3",
                                            fontSize: "12px",
                                            borderRadius: "0px",
                                            border: "1px solid #ccc", // Optional: Add a border to match styling
                                          }}
                                          className="form-control"
                                          name="daysTypehirehowmanydays"
                                          value={
                                            formData.daysTypehirehowmanydays
                                          }
                                          onChange={handleChange}
                                        >
                                          <option value="">Select Type</option>
                                          <option value="week">Week</option>
                                          <option value="month">Month</option>
                                          <option value="days">Days</option>
                                          <option value="years">Years</option>
                                        </select>
                                      </div>
                                      {formData.daysTypehirehowmanydays && (
                                        <div style={{ flex: 1 }}>
                                          <select
                                            style={{
                                              backgroundColor: "#F3F3F3",
                                              fontSize: "12px",
                                              borderRadius: "0px",
                                              border: "1px solid #ccc", // Optional: Add a border to match styling
                                            }}
                                            className="form-control"
                                            name="daysCounthirehowmanydays"
                                            value={
                                              formData.daysCounthirehowmanydays
                                            }
                                            onChange={handleChange}
                                          >
                                            <option value="">
                                              Select Count
                                            </option>
                                            {getDaysCountOptions(
                                              formData.daysTypehirehowmanydays
                                            ).map((count) => (
                                              <option key={count} value={count}>
                                                {count}
                                              </option>
                                            ))}
                                          </select>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <p className="text-danger">
                                    {errors.daysTypehirehowmanydays}
                                  </p>
                                  <p className="text-danger">
                                    {errors.oldpurchasePrice}
                                  </p>
                                </div>

                                <div className="col-md-6 mb-3">
                                  <label
                                    className="form-label fs-6"
                                    style={{
                                      margin: "0px",
                                      marginBottom: "15px",
                                    }}
                                  >
                                    Previous Service Date
                                  </label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    name="hirepreviousServiceDate"
                                    value={formData.hirenextServiceDate}
                                    onChange={handleChange}
                                    style={{
                                      backgroundColor: "#F3F3F3",
                                      borderRadius: "0px",
                                      fontSize: "12px",
                                      border: "1px solid #ccc", // Optional: Add a border to match styling
                                    }}
                                  />
                                  <p className="text-danger">
                                    {errors.hirepreviousServiceDate}
                                  </p>
                                </div>

                                <div className="col-md-6 mb-3">
                                  <label
                                    className="form-label fs-6"
                                    style={{
                                      margin: "0px",
                                      marginBottom: "15px",
                                    }}
                                  >
                                    Charges
                                  </label>
                                  <select
                                    className="form-select"
                                    name="hirecharges"
                                    value={formData.hirecharges}
                                    style={{
                                      backgroundColor: "#F3F3F3",
                                      fontSize: "12px",
                                      borderRadius: "0px",
                                      border: "1px solid #ccc", // Optional: Add a border to match styling
                                    }}
                                    onChange={handleChange}
                                  >
                                    <option value="">Select D/H</option>
                                    <option value="Daily">Daily</option>
                                    <option value="Hourly">Hourly</option>
                                  </select>
                                  <p className="text-danger">
                                    {errors.hirecharges}
                                  </p>
                                </div>

                                <div className="col-md-6 mb-3">
                                  <label
                                    className="form-label fs-6"
                                    style={{
                                      margin: "0px",
                                      marginBottom: "15px",
                                    }}
                                  >
                                    Insurance
                                  </label>
                                  <select
                                    style={{
                                      backgroundColor: "#F3F3F3",
                                      fontSize: "12px",
                                      borderRadius: "0px",
                                      border: "1px solid #ccc", // Optional: Add a border to match styling
                                    }}
                                    className="form-select"
                                    name="hireinsurance"
                                    value={formData.hireinsurance}
                                    onChange={handleChange}
                                  >
                                    <option value="">Select Yes/No</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </select>
                                  <p className="text-danger">
                                    {errors.hireinsurance}
                                  </p>
                                </div>

                                <div className="col-md-6 mb-3">
                                  <label
                                    className="form-label fs-6"
                                    style={{
                                      margin: "0px",
                                      marginBottom: "15px",
                                    }}
                                  >
                                    Price
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    name="hireprice"
                                    placeholder="Enter The Price"
                                    value={formData.hireprice}
                                    onChange={handleChange}
                                    style={{
                                      backgroundColor: "#F3F3F3",
                                      fontSize: "12px",
                                      borderRadius: "0px",
                                      border: "1px solid #ccc", // Optional: Add a border to match styling
                                    }}
                                  />
                                  <p className="text-danger">
                                    {errors.hireprice}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                ) : activeStep === 1 ? (
                  <div>
                    <div className="row mb-2 d-flex justify-content-around">
                      {formData.assetCode &&
                        filteredAssets
                          .find((asset) => asset._id === formData.assetCode)
                          ?.documents?.map((doc, index) => (
                            <div key={index} className="mb-3 flex-col">
                              <label className="form-label fs-6">{doc}:</label>
                              <div className="flex justify-between">
                                {/* Text Input for Document Number */}
                                <div className="w-[300px] h-[41px]">
                                  <input
                                    type="text"
                                    name={`documentNo_${index}`}
                                    placeholder="Enter the Data No"
                                    className="w-full h-full"
                                    value={
                                      documentsData[index]?.documentNo || ""
                                    }
                                    onChange={(e) => changeHandler(e, index)}
                                  />
                                </div>
                                {/* Date Input for Registration Date */}
                                <div className="w-[300px] h-[41px]">
                                  <input
                                    type="date"
                                    name={`regDate_${index}`}
                                    className="w-full h-full"
                                    value={documentsData[index]?.regDate || ""}
                                    onChange={(e) => changeHandler(e, index)}
                                  />
                                </div>
                                {/* Date Input for Expiry Date */}
                                <div className="w-[300px] h-[41px]">
                                  <input
                                    type="date"
                                    name={`expDate_${index}`}
                                    value={documentsData[index]?.expDate || ""}
                                    className="w-full h-full"
                                    onChange={(e) => changeHandler(e, index)}
                                  />
                                </div>
                                {/* File Upload Input */}
                                <div className="w-[300px] h-[41px]">
                                  <input
                                    type="file"
                                    className="form-control h-full w-full"
                                    name={`document_${doc}`}
                                    onChange={(e) =>
                                      handleDocumentUpload(e, doc)
                                    }
                                  />
                                  <p className="text-danger">
                                    {errors[`document_${doc}`]}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}

                      <div style={{width: '320px' }}>
                        <input
                          type="file"
                          className="form-control"
                          name="test_check"
                          onClick={(e) => {
                            e.stopPropagation()
                            //TODO: continue from here
                          }} 
                        />
                      </div>
                    </div>
                  </div>
                ) : activeStep === 3 ? (
                  <>
                  
                  </>
                ) : activeStep === 2 ? (
                  <>
                    <section className="grid grid-cols-7 gap-x-4 gap-y-6 mb-4 md:gap-x-4 mt-4 ml-10">
                      <p>
                        <strong style={{ color: "#4D4D4D" }}>Type</strong>
                      </p>
                      :<p>{formData.type}</p>
                      <p></p>
                      <p>
                        <strong style={{ color: "#4D4D4D" }}>Fuel/Not</strong>
                      </p>
                      :<p>{formData.fuelRnot}</p>
                      <p>
                        <strong style={{ color: "#4D4D4D" }}>Name</strong>
                      </p>
                      :<p>{formData.name}</p>
                      <p></p>
                      <p>
                        <strong style={{ color: "#4D4D4D" }}>Make Name</strong>
                      </p>
                      :<p>{formData.makename}</p>
                      <p>
                        <strong style={{ color: "#4D4D4D" }}>Model</strong>{" "}
                      </p>
                      :<p>{formData.modelname}</p>
                      <p></p>
                      <p>
                        <strong style={{ color: "#4D4D4D" }}>Inspection</strong>
                      </p>
                      :<p>{formData.inspections}</p>
                      <p>
                        <strong style={{ color: "#4D4D4D" }}>
                          select Engine / Motor
                        </strong>
                      </p>
                      :<p>{formData.engineRmotor}</p>
                      <p></p>
                      <p>
                        <strong style={{ color: "#4D4D4D" }}>Subcode</strong>
                      </p>
                      :<p>{formData.Subcode}</p>
                      <p>
                        <strong style={{ color: "#4D4D4D" }}>Insurance</strong>
                      </p>
                      :<p>{formData.Insurance}</p>
                      <p></p>
                      <p></p>
                      <p></p>
                      <p></p>
                      <p>
                        <strong style={{ color: "#4D4D4D" }}>New / Old</strong>
                      </p>
                      :<p>{formData.OWN}</p>
                      <p></p>
                      <p>
                        <strong style={{ color: "#4D4D4D" }}>
                          Purchase Date
                        </strong>
                      </p>
                      :
                      <p>
                        {formData.OWN === "New"
                          ? formData.newpurchaseDate
                          : formData.oldpurchaseDate}
                      </p>
                      <p>
                        <strong style={{ color: "#4D4D4D" }}>
                          Purchase Price
                        </strong>
                      </p>
                      :
                      <p>
                        {formData.OWN === "New"
                          ? formData.newpurchasePrice
                          : formData.oldpurchasePrice}
                      </p>
                      <p></p>
                      <p></p>
                      <p></p>
                      <p></p>
                      {formData.document.map((doc, index) => (
                        <div>
                          <p>
                            <strong style={{ color: "#4D4D4D" }}>RC</strong>
                          </p>
                          :<p>{assetCodeData.documents[index]}</p>
                          <p></p>
                          <p>
                            <strong style={{ color: "#4D4D4D" }}>
                              Date of Regn
                            </strong>
                          </p>
                          :<p>{formData.document[index].regDate}</p>
                          <p>
                            <strong style={{ color: "#4D4D4D" }}>
                              Regn. validity
                            </strong>
                          </p>
                          :<p>{formData.document[index].expDate}</p>
                          <p></p>
                          <p>
                            <strong style={{ color: "#4D4D4D" }}>
                              Chosen File
                            </strong>
                          </p>
                          :<p>{formData.file}</p>
                        </div>
                      ))}
                    </section>
                  </>
                ) : null}
                <div className="flex justify-between w-full mt-8 px-[60px]">
                  <button
                    type="button"
                    onClick={handleBack}
                    style={{
                      marginBottom: "20px",
                      padding: "10px",
                      borderRadius: "5px",
                      width: "175px",
                      backgroundColor: "white",
                      color: "var(--primary-color)",
                      border: "2px solid var(--primary-color)",
                    }}
                  >
                    Back
                  </button>
                  <button
                    type="button"
                    onClick={handleNext}
                    style={{
                      marginBottom: "20px",
                      padding: "10px",
                      borderRadius: "5px",
                      width: "175px",
                      backgroundColor: "var(--primary-color)",
                      color: "white",
                    }}

                    // className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
                  >
                    {activeStep === 2 ? "Finish" : "Next"}
                  </button>
                </div>
              </form>
            </>
          )}
        </Dialog>
      )}
    </>
  );
};

export default AddnewPnm;
