import React, { useState } from "react";
import { RxCross1 } from "react-icons/rx";
import { IoIosAddCircleOutline } from "react-icons/io";
import { WebApimanager } from "../../../WebApiManager";

const AddFolderConstruction = ({ onClose, onSaveSuccess }) => {
  const [imagePreview, setImagePreview] = useState(null);
  const [ConstructionfImage, setConstructionfImage] = useState(null);
  const [imageError, setImageError] = useState("");
  const [folderName, setFolderName] = useState("");
  const [folderNameError, setFolderNameError] = useState("");
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "",
  });
  const [errorMessage, setErrorMessage] = useState("");

  const webApi = new WebApimanager();

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setConstructionfImage(file);
      setImageError(""); // Clear the image error when an image is selected
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFolderNameChange = (e) => {
    setFolderName(e.target.value);
    if (folderNameError) {
      setFolderNameError(""); // Clear the folder name error when the user starts typing
    }
  };

  const handleSave = async () => {
    let valid = true;

    // Validate folder name
    if (folderName.trim() === "") {
      setFolderNameError("Folder name is required");
      valid = false;
    } else {
      setFolderNameError("");
    }

    // Validate image selection
    if (!ConstructionfImage) {
      setImageError("An image is required");
      valid = false;
    } else {
      setImageError("");
    }

    if (valid) {
      try {
        // Fetch existing folder names from the API
        const existingFoldersResponse = await webApi.get(
          "api/constructionNeeds"
        );
        const existingFolders =
          existingFoldersResponse.data?.data?.constructionNeeds || [];

        // Check if folder name already exists
        const folderExists = existingFolders.some(
          (folder) => folder.fName.toLowerCase() === folderName.toLowerCase()
        );

        if (folderExists) {
          setFolderNameError("Folder name already exists.");
          return; // Stop the save process
        }

        // Proceed with the save if the folder name doesn't exist
        const fName = { fName: folderName };
        const response = await webApi.post("api/constructionNeeds", fName);

        if (
          response.data &&
          response.data.data &&
          response.data.data.constructionNeeds
        ) {
          const responseId = response.data.data.constructionNeeds._id;
          console.log("Response ID:", responseId);

          if (ConstructionfImage) {
            const fImage = new FormData();
            fImage.append("fImage", ConstructionfImage);

            await webApi.imagePut(
              `api/constructionNeeds/fImage/${responseId}`,
              fImage
            );
          }

          setApiProcessing({ loader: false, message: "" });
          onSaveSuccess(); // Notify parent component of the successful save
          onClose(); // Close the modal
        } else {
          console.error("Unexpected response structure:", response);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        setApiProcessing({ loader: false, message: "" });
        const errorMsg =
          error.response?.data?.message ||
          "Failed to submit the form. Please try again later.";
        setErrorMessage(errorMsg);
      }
    }
  };

  return (
    <div
      className="modal show"
      style={{
        display: "block",
        width: "100%",
        margin: 0,
      }}
      tabIndex="-1"
    >
      <div
        className="modal-dialog modal-dialog-centered"
        style={{ width: "100%", maxWidth: "650px" }}
      >
        <div
          className="modal-content"
          style={{
            borderRadius: "10px",
            fontFamily: "Poppins",
            width: "100%",
            boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.3)",
          }}
        >
          <div
            className="modal-header"
            style={{
              backgroundColor: "#4D4D4D",
              height: "50px",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
            }}
          >
            <h5 className="modal-title ms-auto" style={{ color: "white" }}>
              Add Folder
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={onClose}
              style={{
                backgroundColor: "transparent",
                border: "none",
                color: "white",
              }}
            >
              <RxCross1 size={20} style={{ color: "white" }} />
            </button>
          </div>
          <div
            className="modal-body"
            style={{ padding: "25px", width: "100%", boxSizing: "border-box" }}
          >
            <div className="text-center" style={{ padding: "20px" }}>
              <div
                style={{
                  position: "relative",
                  width: "125px",
                  height: "125px",
                  margin: "0 auto",
                  borderRadius: "50%",
                  backgroundColor: imagePreview ? "transparent" : "#f2f2f2",
                  backgroundImage: imagePreview
                    ? `url(${imagePreview})`
                    : "none",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() =>
                  document.getElementById("folder-image-input").click()
                }
              >
                {!imagePreview && (
                  <div>
                    <IoIosAddCircleOutline
                      size={25}
                      style={{ marginLeft: "100px", marginTop: "70px" }}
                    />
                  </div>
                )}
              </div>
              <input
                id="folder-image-input"
                type="file"
                name="folder-image-input"
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: "none" }}
              />
              {imageError && (
                <div
                  style={{ color: "red", marginTop: "5px", marginLeft: "-38%" }}
                >
                  {imageError}
                </div>
              )}
            </div>
            <div>
              <label
                htmlFor="enterfolderName"
                className="form-label mt-5"
                style={{ marginLeft: "-250px" }}
              >
                <strong>
                  Enter Your Folder Name
                  <span style={{ color: "red" }}>*</span>
                </strong>
              </label>
              <input
                type="text"
                name="enterfolderName"
                value={folderName}
                placeholder="Enter folder name here"
                className="form-control"
                onChange={handleFolderNameChange}
                style={{ width: "75%", marginLeft: "67px" }}
              />
              {folderNameError && (
                <div
                  style={{ color: "red", marginTop: "5px", marginLeft: "-40%" }}
                >
                  {folderNameError}
                </div>
              )}
            </div>
            <div className="d-flex justify-content-center align-items-center mt-4">
              <button
                className="btn btn-primary"
                style={{
                  width: "160px",
                  height: "45px",
                  borderRadius: "5px",
                  border: "1px solid var(--primary-color)",
                  backgroundColor: "var(--primary-color)",
                  color: "white",
                  transition: "background-color 0.3s ease",
                }}
                onMouseEnter={(e) =>
                  (e.target.style.backgroundColor = "#d45f25")
                }
                onMouseLeave={(e) =>
                  (e.target.style.backgroundColor = "var(--primary-color)")
                }
                onClick={handleSave}
              >
                UPLOAD
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddFolderConstruction;
