import React from 'react';
import * as Icon from "react-bootstrap-icons";
import { Dialog, DialogContent } from "@mui/material";

const CreateWorkSequenceDialog = ({ open, onClose, formData, handleChange, handleSubmit, handleAddNameField, handleRemoveNameField, errors, site }) => (

  <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#7C1034', margin: '0px' }}>
      <p style={{ color: 'white', textAlign: 'center', margin: '5px 200px' }}>Create Work Sequence</p>
      <Icon.XCircle size={20} style={{ marginLeft: 'auto', cursor: 'pointer', color: 'white', marginRight: '10px' }} onClick={onClose} />
    </div>
    <DialogContent style={{ padding: '25px', minWidth: '300px', minHeight: '300px' }}>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {/* <label htmlFor="siteName" className="fs-10 fw-bold text-start py-2">
            Site Name
            <span style={{ color: 'red' }}>*</span>
          </label>
          <select
            id="siteName"
            name="siteName"
            value={formData.siteName}
            onChange={handleChange}
            className="form-control fs-12"
            style={{ width: '100%', boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)' }}
          >
            <option value="">Select a Site</option>
            {datasites.map(site => (
              <option key={site._id} value={site.siteName}>{site.siteName}</option>
            ))}
          </select>
          {errors.siteName && <p style={{ color: 'red', fontSize: '12px' }}>{errors.siteName}</p>}
           */}
          <label htmlFor="category" className="fs-10 fw-bold text-start py-2">Category<a style={{ color: "red" }}>*</a></label>
          <select
            id="category"
            name="category"
            value={formData.category}
            className="form-control fs-12"
            style={{ width: '100%', boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)' }}
            onChange={handleChange}
          >
            <option value="">Select A Category</option>
            <option value="Finishing">Finishing</option>
            <option value="Structural">Structural</option>
            <option value="Others">Others</option>
          </select>
          {errors.category && <p style={{ color: 'red', fontSize: '12px' }}>{errors.category}</p>}
          
          <div style={{ display: 'flex', flexDirection: 'row', gap: '12px', alignItems: 'center' }}>
            <label htmlFor="name" className="fs-10 fw-bold text-start py-2">Name<a style={{ color: "red" }}>*</a></label>
            <Icon.PlusCircleFill
              size={20}
              className="text-success"
              onClick={handleAddNameField}
              style={{ cursor: 'pointer' }}
            />
          </div>
          {formData.names.map((item, index) => (
            <div key={index} className="d-flex mb-2" style={{ alignItems: 'center' }}>
              <label style={{ marginRight: '8px' }}>{index + 1}.</label>
              <input
                type="text"
                name={`name${index}`}
                value={item.name}
                placeholder={`Enter the Name ${index + 1}`}
                onChange={(e) => handleChange(e, index)}
                className="form-control"
              />
              {errors.names && <span className="text-danger">{errors.names}</span>}
              {index > 0 && (
                <Icon.DashCircleFill
                  size={25}
                  className="ml-2 text-danger"
                  onClick={() => handleRemoveNameField(index)}
                  style={{ cursor: 'pointer' }}
                />
              )}
            </div>
          ))}
        </div>
      </div>
      <div style={{ marginTop: '45px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <button
          style={{ padding: '8px', borderRadius: '5px', border: '1px solid lightgray', backgroundColor: 'transparent', width: '100px' }}
          onMouseOver={(e) => e.target.style.backgroundColor = 'lightgray'}
          onMouseOut={(e) => e.target.style.backgroundColor = 'transparent'}
          onClick={onClose}
        >
          Cancel
        </button>
        <button
          style={{ marginLeft: '50px', padding: '10px', borderRadius: '5px', border: '1px solid #7C1034', backgroundColor: '#7C1034', color: 'white', width: '100px' }}
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
    </DialogContent>
  </Dialog>
);

export default CreateWorkSequenceDialog;
