import React from "react";
import PropTypes from "prop-types";
import styles from './DownloadComponent.module.css';

function DownloadComponent({ IconComponent, downloadText }) {
  return (
    <a href="#" className={styles.downloadLink}>
      <IconComponent className={styles.downloadIcon} />
      <span className={styles.downloadText}>{downloadText}</span>
    </a>
  );
}

DownloadComponent.propTypes = {
  IconComponent: PropTypes.elementType.isRequired,
  downloadText: PropTypes.string.isRequired,
};

export default DownloadComponent;
