import React from 'react';
import styles from './AboutUs.module.css';
import Header from '../Components/Navbar/Header';
import ValuesSection from './ValuesSection/ValuesSection';
import VisionSection from './VisionSection/VisionSection';
import FloatingImageContainer from '../Widgets/FloatingImageContainer';
import PagesHeroSection from '../Components/constants-comp/pagesHerosection';

function AboutUs() {
  return (
    <div className={styles.aboutUsContainer}>
      <Header/>
      <main>
      <PagesHeroSection
        imageUrl={
          "https://cdn.builder.io/api/v1/image/assets/TEMP/4e265835733c8d6a6eea6998a16b66ad872c731fcb9c8299c01f76cb0d5bfde9?apiKey=3e99c58a56f84e4cb0d84873c390b13e&&apiKey=3e99c58a56f84e4cb0d84873c390b13e"
        }
        title={"About Us"}
      />
        <FloatingImageContainer title={"About Us"} description={`
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          Etiam pretium purus at blandit consequat. Suspendisse
          vitae orci diam. Fusce quis sem nec nisi imperdiet
          rhoncus nec id dui. Curabitur bibendum tortor et metus
          sodales, nec hendrerit orci tristique.Donec et posuere
          arcu, et blandit est. Pellentesque non pulvinar dui. Nullam
          lacinia lacinia sapien, efficitur congue massa tempus ut.
        `}/>
        <ValuesSection />
        <VisionSection/>
      </main>
      {/* <Footer/> */}
    </div>
  );
}

export default AboutUs;