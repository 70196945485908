import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { WebApimanager } from "../../../WebApiManager";
import { Tabs, Tab, Typography, Box } from "@mui/material";
import VehicleAndMachinery from "./Vehicle&Machinery";
import PowerTools from "./PowerTools";
import DistributionBox from "./DistributionBox";
import BatchingPlant from "./BatchingPlant";

const DailyLogReport = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px",
          marginTop: "5px",
        }}
      >
        <Box
          sx={{
            border: "1px solid var(--primary-color)",
            width: "100%",
            maxWidth: "800px", // Adjust max-width as needed
          }}
        >
          <Tabs
            id="controlled-tab-example"
            value={value}
            onChange={handleChange}
            sx={{
              "& .MuiTabs-indicator": {
                display: "none",
              },
              "& .MuiTab-root": {
                textTransform: "none",
                borderBottom: "none",
                borderLeft: "1px solid var(--primary-color)",
                minWidth: "200px",
                fontWeight: "bold",
                color: "var(--primary-color)",
                "&:first-of-type": {
                  borderLeft: "none",
                },
                "&:last-of-type": {
                  borderRight: "none",
                },
              },
              "& .Mui-selected": {
                backgroundColor: "var(--primary-color)", // Background color for selected tab
                color: "white",
                borderBottom: "1px solid var(--primary-color)", // Maintain border at the bottom of selected tab
              },
              "& .MuiTab-root:not(.Mui-selected)": {
                color: "var(--primary-color)", // Text color for non-selected tabs
              },
            }}
          >
            <Tab label="Vehicle & Machinery " />
            <Tab label="PowerTools" />
            <Tab label="Distribution Box" />
            <Tab label="Batching Plant" />
          </Tabs>
        </Box>
      </div>

      <TabPanel value={value} index={0}>
        <VehicleAndMachinery />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <PowerTools />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <DistributionBox />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <BatchingPlant />
      </TabPanel>
    </>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {value === index && (
        <Box p={3} style={{ textAlign: "center", width: "100%" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default DailyLogReport;
