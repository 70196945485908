import React, { useState, useRef } from "react";
import { InputPhoneNumField } from "../../Widgets/Forms/InputFields";
import InputField from "../../Widgets/Forms/InputFields";
import { useRecoilState } from "recoil";
import { userInfo, profileImageUrl } from "../../atoms"; // Use userInfo instead of userData
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { FiEdit3 } from "react-icons/fi";
import { siteId } from '../../atoms';
import SelectSite from '../../Utilities/SelectSite';
import { ButtonBlue, ButtonGrayOutline } from "../../Widgets/Buttons";
import { DetailsTile } from "../../Widgets/Forms/FormReviewTiles";
import { ButtonIcon } from "../../Widgets/Buttons";
import Navbar from "../Navbar/Header";

const Profile = () => {
  let navigate = useNavigate();
  const [currentUserInfo, setCurrentUserInfo] = useRecoilState(userInfo); 
  const [profileImage, setProfileImage] = useRecoilState(profileImageUrl);
  const [firstName, setFirstName] = useState(currentUserInfo.firstName || ""); 
  const [lastName, setLastName] = useState(currentUserInfo.lastName || "");
  const [contactNo, setContactNo] = useState(currentUserInfo.contactNumber || "");
  const [emailId, setEmailId] = useState(currentUserInfo.email || "");
  const [openEmailCodeForm, setOpenEmailCodeForm] = useState(false);
  const [openChangePass, setopenChangePass] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [site, setSite] = useRecoilState(siteId)
  const handleFileInput = useRef(null);

  return (
    <>
    <div>
      
      {site === '' ? 
      <>
      <p style={{textAlign:'center',marginTop:'10px'}}>Please login to this site</p> 
      {/* <SelectSite />  */}
      </>
      : 
<>
      {/* <VerifyEmail trigger={openEmailCodeForm} setTrigger={setOpenEmailCodeForm} />
      <ChangePassword trigger={openChangePass} setTrigger={setopenChangePass} /> */}
      <div style={{marginTop:'50px',marginLeft:'50px',marginRight:'50px', fontFamily: 'Poppins',}}>
        <span className="flex justify-center items-center bg-blue-52 text-white font-semibold text-center h-10" style={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px'}}>
          PROFILE DETAILS
        </span>
        <div className="w-full flex h-44 bg-[#dce9e9] mb-20">
          <div className="relative">
            <img
              src={
                profileImage ||
                "https://w7.pngwing.com/pngs/981/645/png-transparent-default-profile-united-states-computer-icons-desktop-free-high-quality-person-icon-miscellaneous-silhouette-symbol-thumbnail.png"
              }
              alt=""
              className="w-52 h-52 rounded-full object-cover m-16 ml-20 bg-gray-400"
            />
            <span className="absolute h-10 w-10 rounded-full bg-white border-4 top-0 right-0 m-16 ml-20 mt-52">
              <button
                id="uploadPicture"
                name="uploadPicture"
                data-cy="uploadPicture"
                onClick={() => handleFileInput.current.click()}
                className="flex justify-center items-center h-full w-full focus:outline-none"
              >
                <FiEdit3 className="text-xl text-blue-52" />
                <input
                  accept=".png, .jpg, .jpeg"
                  type="file"
                  className="hidden"
                  ref={handleFileInput}
                />
              </button>
            </span>
          </div>
        </div>
        <div className="flex-row ml-80 -mt-12">
          <div className="flex-col justify-start">
            <p className="font-semibold text-gray-700 text-xl">
              {currentUserInfo.firstName + " " + currentUserInfo.lastName}
            </p>
            <p>Update your Photo and Personal Details!</p>
          </div>
          <ButtonBlue
            id="changePassword"
            btnName="changePassword"
            dataCy="changePassword"
            name="Change Password"
            onClick={() => setopenChangePass(true)}
            className="mt-1 w-fit px-2"
          />
        </div>

        <div className="flex flex-col w-full ml-6 mt-4">
          <span className="flex flex-row border-b-2 border-blue-52 mt-8 mb-6 text-blue-52 w-11/12 font-semibold justify-between">
            <p>Personal Information</p>
            <ButtonIcon
              id="editInfo"
              name="editInfo"
              dataCy="editInfo"
              title="Edit"
              onClick={() => setShowEdit(true)}
              icon={<FiEdit3 className="text-xl text-blue-52 cursor-pointer" title="Edit" />}
              className="hover:!text-red-600"
            />
          </span>

          {showEdit ? (
            <div className="flex flex-col w-96">
              <DetailsTile
                label="First Name"
                data={
                  <InputField
                    name="firstName"
                    id="firstName"
                    dataCy="firstName"
                    type="text"
                    placeholder=""
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    style="!w-96 mb-4"
                    notImp
                  />
                }
                className="!gap-2"
                dataClassName="text-gray-500"
              />

              <DetailsTile
                label="Last Name"
                data={
                  <InputField
                    name="lastName"
                    id="lastName"
                    dataCy="lastName"
                    type="text"
                    placeholder=""
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    style="!w-96 mb-4"
                    notImp
                  />
                }
                className=""
                dataClassName="text-gray-500"
              />

              <DetailsTile
                label="Contact Number"
                labelClassName="!w-52"
                data={
                  <InputPhoneNumField
                    name="contactNo"
                    id="contactNo"
                    dataCy="contactNo"
                    placeholder=""
                    value={contactNo}
                    setValue={setContactNo}
                    style=" mb-4"
                    notImp
                  />
                }
                className="mb-3"
                dataClassName="text-gray-500 w-96"
              />

              <DetailsTile
                label="Email ID"
                data={
                  <InputField
                    name="emailId"
                    id="emailId"
                    dataCy="emailId"
                    type="text"
                    placeholder=""
                    value={emailId}
                    onChange={(e) => setEmailId(e.target.value)}
                    style="!w-96 mb-4"
                    notImp
                  />
                }
                className=""
                dataClassName="text-gray-500"
              />

              <div className="flex justify-center items-center gap-16 mt-6 mb-4 ml-12 w-full">
                <ButtonGrayOutline
                  id="cancel"
                  btnName="cancel"
                  dataCy="cancel"
                  name="Cancel"
                  onClick={() => setShowEdit(false)}
                  className="mt-1"
                />
                <ButtonBlue
                  id="Save"
                  btnName="Save"
                  dataCy="Save"
                  name="Save"
                  className="mt-1"
                />
              </div>
            </div>
          ) : (
            <div className="flex items-start gap-x-10 gap-y-10 mb-10">
              <span className="flex flex-col justify-center items-start gap-y-8">
                <p className="text-gray-500">First Name</p>
                <p className="text-gray-500">Last Name</p>
                <p className="text-gray-500">Contact number</p>
                <p className="text-gray-500">Email ID</p>
              </span>
              <span className="flex flex-col justify-center items-start gap-y-8">
                <p>{firstName}</p>
                <p>{lastName}</p>
                <p>{contactNo}</p>
                <p>{emailId}</p>
              </span>
            </div>
          )}
        </div>
      </div>
    </>
}
</div>
</>
  );
};

export default Profile;
