import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Icon from "react-bootstrap-icons";
import { Dialog, DialogContent, DialogActions } from "@mui/material";
import Navbar from '../Navbar/Header';
import { WebApimanager } from '../../WebApiManager';

function CreateChecklist() {
    let webApi = new WebApimanager();
    const navigate = useNavigate();
    const location = useLocation();
    const [checklistItems, setChecklistItems] = useState([]);
    const [open, setOpen] = useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const { heading, checklistId } = location.state || {};

    const handleAddRow = () => {
        const newId = checklistItems.length + 1;
        const newItem = { id: newId, question: '', status: '' }; // Initialize question as empty string
        setChecklistItems(prevItems => [...prevItems, newItem]);
    };

    const handleDeleteRow = (id) => {
        setChecklistItems(prevItems => prevItems.filter(item => item.id !== id));
    };

    const handleChange = (event, id) => {
        const { value } = event.target;
        setChecklistItems(prevItems => prevItems.map(item => {
            if (item.id === id) {
                return { ...item, question: value };
            }
            return item;
        }));
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClickClose = () => {
        setOpen(false);
    };

    const handleConfirmDialogOpen = () => {
        setConfirmDialogOpen(true);
    };

    const handleConfirmDialogClose = () => {
        setConfirmDialogOpen(false);
    };

    const handleConfirmSubmit = () => {
        const updatedDescriptions = checklistItems.map(item => item.question);
        const postData = {
            description: updatedDescriptions
        };
        console.log("postData:", postData);
        // https://api.chromacheer.com/api/checkListForm/form/6662119dfeec5fce74bff4aa
        webApi
            .put(`api/checkListForm/form/${checklistId}`, postData)
            .then(response => {
                const data = response.data;
                console.log("Server Response:", response);
                console.log("Data:", data);
                setOpen(false);
                navigate(-1);
            })
            .catch(error => {
                console.error("There was an error submitting the form!", error);
            });

        setConfirmDialogOpen(false); // Close the confirmation dialog
    };

    return (
        <>
            <div style={{ marginTop: '50px' }}>
                <h1 style={{ textAlign: 'center' }}>{heading} Check List</h1>
                <table style={{ margin: '0 auto', borderCollapse: 'collapse', width: '80%', textAlign: 'center', border: '2px solid black' }}>
                    <thead>
                        <tr style={{ height: '50px' }} >
                            <th style={{ border: '1px solid black', width: '10%' }}>SI.No</th>
                            <th style={{ border: '1px solid black', width: '35%' }}>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        {checklistItems.map((item, index) => (
                            <tr key={item.id}>
                                <td style={{ border: '1px solid black' }}>{index + 1}</td>
                                <td style={{ border: '1px solid black',display: 'flex', alignItems: 'center' }}>
                                    <input
                                        type="text"
                                        value={item.question}
                                        onChange={(e) => handleChange(e, item.id)} // Pass item id to handleChange
                                        style={{ width: '98%', height: '40px', border: 'none', fontSize: '20px' }}
                                        placeholder={`${item.id}`}
                                    />
                                    <Icon.Trash onClick={() => handleDeleteRow(item.id)} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div>
                    <a style={{ marginLeft: '82%', marginTop: '10px', padding: '5px', border: 'none', background: 'none', color: 'blue', borderBottom: '1px solid blue' }} onClick={handleAddRow}>Add One More</a>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <button style={{ backgroundColor: '#7C1034', color: 'white', width: '100px', border: 'none', marginLeft: '10px' }} onClick={handleClickOpen}>Preview</button>
                        <button style={{ backgroundColor: '#7C1034', color: 'white', width: '100px', border: 'none', marginLeft: '10px' }} onClick={handleConfirmDialogOpen}>Submit</button>
                    </div>
                </div>
                <Dialog open={open} onClose={handleClickClose} maxWidth="xl" fullWidth>
                    <DialogContent>
                        <Icon.X size={25} onClick={handleClickClose} style={{ marginLeft: '1050px' }} />
                        <h3 style={{ margin: '0 auto', borderCollapse: 'collapse', width: '80%', textAlign: 'center', border: '2px solid black' }}>
                            {/* SR SUMADHURA INFRACON (P) LTD.<br />
                            CKB Plaza, Site No.43, 02nd floor, Varthur Main road, Marathahalli, <br />
                            Bangalore-560037, Phone (off)-080-42161470 */}
                            Lbits Company (P) LTD.<br />
                            2nd Floor, 43, MAithrivanam Main Road <br/>
                            Hyderabad-523338,phone (off)-080-4789552
                        </h3>
                        <h3 style={{ margin: '0 auto', borderCollapse: 'collapse', width: '80%', textAlign: 'center', border: '2px solid black' }}>
                            CHECKLIST
                        </h3>
                        <h3 style={{ margin: '0 auto', borderCollapse: 'collapse', width: '80%', textAlign: 'center', border: '2px solid black' }}>
                            Activity- {heading}
                        </h3>
                        <table style={{ margin: '0 auto', borderCollapse: 'collapse', width: '80%', textAlign: 'center', border: '2px solid black' }}>
                            <thead>
                                <tr>
                                    <th style={{ border: '1px solid black', width: '10%', height: '50px' }}>Project</th>
                                    <th style={{ width: '70%' }}>
                                        
                                    </th>
                                    <th style={{ border: '1px solid black' }}>System SIPL/CIVIL/034</th>
                                </tr>
                            </thead>
                        </table>
                        <table style={{ margin: '0 auto', borderCollapse: 'collapse', width: '80%', textAlign: 'center', border: '2px solid black' }}>
                            <thead>
                                <tr style={{ height: '50px' }}>
                                    <th style={{ border: '1px solid black', width: '10%' }}>Block:</th>
                                    <th style={{ border: '1px solid black', width: '10%' }}>
                                        
                                    </th>
                                    <th style={{ border: '1px solid black', width: '10%' }}>Floor:</th>
                                    <th style={{ border: '1px solid black', width: '10%' }}>
                                        
                                    </th>
                                    <th style={{ border: '1px solid black', width: '10%' }}>Flat:</th>
                                    <th style={{ border: '1px solid black', width: '10%' }}>
                                       
                                    </th>
                                    <th style={{ border: '1px solid black', width: '20%' }}>Checking Start Date & Time </th>
                                    <th style={{ border: '1px solid black', width: '25%' }}>
                                        
                                    </th>
                                </tr>
                            </thead>
                        </table>
                        <table style={{ margin: '0 auto', borderCollapse: 'collapse', width: '80%', textAlign: 'center', border: '2px solid black' }}>
                            <thead>
                                <tr style={{ height: '50px' }}>
                                    <th style={{ border: '1px solid black', width: '10%' }}>Grid</th>
                                    <th style={{ border: '1px solid black', width: '50%' }}>
                                        
                                    </th>
                                    <th style={{ border: '1px solid black', width: '20%' }}>Checking End Date & Time</th>
                                    <th style={{ border: '1px solid black', width: '25%' }}>
                                       
                                    </th>
                                </tr>
                            </thead>
                        </table>
                        <table style={{ margin: '0 auto', borderCollapse: 'collapse', width: '80%', textAlign: 'center', border: '2px solid black' }}>
                            <thead>
                                <tr style={{ height: '50px' }}>
                                    <th style={{ border: '1px solid black', width: '25%' }}>Ref.Org (Architectural floor plan) </th>
                                    <th style={{ border: '1px solid black', width: '55%' }}>
                                       
                                    </th>
                                    <th style={{ border: '1px solid black', width: '10%' }}>Rev.No, date</th>
                                    <th style={{ border: '1px solid black', width: '25%' }}>
                                       
                                    </th>
                                </tr>
                            </thead>
                        </table>

                        <table style={{ margin: '0 auto', borderCollapse: 'collapse', width: '80%', textAlign: 'center', border: '2px solid black' }}>
                            <thead>
                                <tr style={{ height: '50px' }} >
                                    <th style={{ border: '1px solid black', width: '10%' }}>SI.No</th>
                                    <th style={{ border: '1px solid black', width: '35%' }}>Description</th>
                                    <th style={{ border: '1px solid black', width: '15%' }}>Status</th>
                                    <th style={{ border: '1px solid black',width:'35%' }}>Remarks</th>
                                </tr>
                            </thead>
                            <tbody>
                                {checklistItems.map((item, index) => (
                                    <tr key={item.id}>
                                        <td style={{ border: '1px solid black' }}>{index + 1}</td>
                                        <td style={{ border: '1px solid black',width: '50%', height: '40px', fontSize: '20px'}}>{item.question}</td>
                                        <td style={{ border: '1px solid black'}}>
                <div style={{display:'flex',justifyContent:'space-evenly'}}>
                <button style={{ borderRadius:'10px' }}>Yes</button>
                <button style={{ borderRadius:'10px' }} >/No</button>
                <button style={{ borderRadius:'10px' }} >/NA</button>
                </div>
              </td>
              <td style={{ border: '1px solid black' ,width: '98%', height: '40px', fontSize:'20px'}}>
               
              </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',marginTop:'15px' }}>
                            <button style={{ backgroundColor: '#7C1034', color: 'white', width: '100px', border: 'none', marginLeft: '10px' }} onClick={handleClickClose}>Edit</button>
                            {/* <button style={{ backgroundColor: '#7C1034', color: 'white', width: '100px', border: 'none', marginLeft: '10px' }} 
                            // onClick={handleConfirmDialogOpen}
                            >Cancel</button> */}
                        </div>
                    </DialogContent>
                </Dialog>
                <Dialog open={confirmDialogOpen} onClose={handleConfirmDialogClose}>
                    <DialogContent>
                        Are you sure want to submit?
                    </DialogContent>
                    <DialogActions>
    <button style={{ boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)', marginRight: '5px',padding:'3px' , background:'gray'}} onClick={handleConfirmDialogClose}>Cancel</button>
    <button style={{ boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)',padding:'3px',backgroundColor: '#7C1034' }} onClick={handleConfirmSubmit} >Submit</button>
</DialogActions>

                </Dialog>

            </div>
        </>
    );
}

export default CreateChecklist;