import { Dialog, DialogContent } from "@mui/material";
import React, { useState, useEffect } from "react";
import { RxCross1 } from "react-icons/rx";
import { todayDate } from "../../../../Utilities/DateUtils";
import { WebApimanager } from "../../../../WebApiManager";
import SitesDropdown from "../../../../Utilities/SitesDropdown";
import { Loader } from "../../../../Widgets/notificationFeedbacks";
import SearchDropdownIdVal from "../../../../Widgets/SearchDropdownIdVal";
import { getRegisterData, updateRegister } from "../../BackendUtils";
import { registerAtom } from "../../../../atoms";
import { useRecoilState } from "recoil";
import { SUCESS_STATUS } from "../../../../Utilities/Constants";

const AddDrawingDialog = (props) => {
  const { open, handleDialogClose, site } = props;

  // setRegisterListBy fetching from .. ( status condition not required siteid query required )
  const [registerList, setRegisterList] = useState([]);
  const [filteredDrawingOptions, setFilteredDrawingOptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  let webApi = new WebApimanager();

  const [registerInfo, setRegisterInfo] = useRecoilState(registerAtom);

  const [formData, setFormData] = useState({
    registerId: "",
    drawingNumber: "",
    drawingName: "",
    category: "",
    designDrawingConsultant: "",
    // acceptedScheduledsubmissionDate: '',
    // actualSubmissionDate: todayDate(),
    // dueDays: '',
    // remarks:'',
    submittedDate: "",
    // issuedSoftCopy: '',
    // receivedHardCopy: '',
    drawingFileName: null,
    receivedCopies: "",
    revisions: [],
    note: "",
  });

  const [errors, setErrors] = useState({});

  const handleClose = () => {
    setFormData({
      regiterId: "",
      drawingNumber: "",
      drawingName: "",
      category: "",
      designDrawingConsultant: "",
      // acceptedScheduledsubmissionDate: '',
      // actualSubmissionDate: '',
      // dueDays: '',
      // remarks:'',
      submittedDate: "",
      // issuedSoftCopy: '',
      // receivedHardCopy: '',
      drawingFileName: null,
      revisions: "",
      note: "",
    });
    handleDialogClose();
    setErrors({});
  };

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === "file") {
      setFormData({
        ...formData,
        [name]: files[0], // Assuming you are only uploading one file
      });
    } else if (name === "receivedCopies" && /^\d*$/.test(value)) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }

    if (name === "designDrawingConsultant") {
      const selectedConsultant = value;

      // Filter drawing numbers based on the selected consultant
      const filteredDrawings = registerList.filter(
        (register) =>
          register.designDrawingConsultant?.role === selectedConsultant
      );

      setFilteredDrawingOptions(filteredDrawings);

      // Clear the selected drawing number, drawing name, category, and revisions when the consultant changes
      setFormData((prevFormData) => ({
        ...prevFormData,
        designDrawingConsultant: selectedConsultant,
        drawingNumber: "",
        drawingName: "",
        category: "",
        revisions: [],
        submittedDate: todayDate(),
        receivedCopies: "",
        note: "",
        selectedRevision: "",
      }));
    }

    if (name === "drawingNumber") {
      const selectedDrawing = registerList.find(
        (register) => register.drawingNo === value
      );

      if (selectedDrawing) {
        const combinedRevisions = Array.isArray(
          selectedDrawing.acceptedArchitectRevisions
        )
          ? selectedDrawing.acceptedArchitectRevisions.flatMap(
              (rev) => rev.revision || []
            )
          : [];

        setFormData((prevFormData) => ({
          ...prevFormData,
          registerId: selectedDrawing._id,
          drawingNumber: selectedDrawing.drawingNo,
          drawingName: selectedDrawing.drawingTitle,
          category: selectedDrawing.category?.category || "",
          revisions: combinedRevisions,
          submittedDate: todayDate(),
          receivedCopies: "",
          note: "",
          selectedRevision: "",
        }));
      } else {
        setFormData((prevFormData) => ({
          ...prevFormData,
          registerId: "",
          drawingName: "",
          category: "",
          revisions: [],
          submittedDate: todayDate(),
          receivedCopies: "",
          note: "",
          selectedRevision: "",
        }));
      }
    }

    if (name === "selectedRevision" || name === "note") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  const [currentTab, setCurrentTab] = useState("upload");

  const handleTabChange = (tab) => {
    setCurrentTab(tab);

    if (tab === "upload") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        registerId: "",
        drawingNumber: "",
        drawingName: "",
        category: "",
        designDrawingConsultant: "",
        submittedDate: "",
        drawingFileName: null,
        receivedCopies: "",
        revisions: [],
        note: "",
        selectedRevision: "", // Add this if you're using it in the received tab
      }));
    }

    // Reset form data when switching to the 'received' tab
    if (tab === "received") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        registerId: "",
        drawingNumber: "",
        drawingName: "",
        category: "",
        designDrawingConsultant: "",
        submittedDate: "",
        drawingFileName: null,
        receivedCopies: "",
        revisions: [],
        note: "",
        selectedRevision: "", // Add this if you're using it in the received tab
      }));
    }
  };

  // Example validation function
  const validateForm = (formData, currentTab) => {
    const newErrors = {};
    if (currentTab === "upload") {
      console.log(formData);
      if (!formData.drawingNumber.trim()) {
        newErrors.drawingNumber = "DrawingNumber  is required";
      }
      if (!formData.drawingName.trim()) {
        newErrors.drawingName = "Drawing Title is required";
      }
      if (!formData.designDrawingConsultant.trim()) {
        newErrors.designDrawingConsultant =
          "DesignDrawingConsultant is required";
      }
      if (!formData.category.trim()) {
        newErrors.category = "Category is required";
      }
      if (!formData.submittedDate.trim()) {
        newErrors.submittedDate = "Submitted Date is required";
      }

      if (!formData.drawingFileName) {
        newErrors.drawingFileName = "Drawing File is required";
      } else if (formData.drawingFileName instanceof File) {
        // Check if the file name ends with '.dwg'
        if (!formData.drawingFileName.name.endsWith(".dwg")) {
          newErrors.drawingFileName = "Drawing File must be a .dwg file";
        }
      } else if (typeof formData.drawingFileName === "string") {
        // Check if the string ends with '.dwg'
        if (!formData.drawingFileName.endsWith(".dwg")) {
          newErrors.drawingFileName = "Drawing File must be a .dwg file";
        }
      }
      // if (!formData.remarks) errors.remarks = 'This field is required.';
    } else if (currentTab === "received") {
      if (!formData.drawingNumber.trim()) {
        newErrors.drawingNumber = "DrawingNumber  is required";
      }
      if (!formData.drawingName.trim()) {
        newErrors.drawingName = "Drawing Title is required";
      }
      if (!formData.designDrawingConsultant.trim()) {
        newErrors.designDrawingConsultant =
          "DesignDrawingConsultant is required";
      }
      if (!formData.category.trim()) {
        newErrors.category = "Category is required";
      }
      if (!formData.submittedDate.trim()) {
        newErrors.submittedDate = "Submitted Date is required";
      }
      if (!formData.selectedRevision.trim()) {
        newErrors.selectedRevision = "revision is required";
      }

      if (!formData.drawingFileName) {
        newErrors.drawingFileName = "Drawing File is required";
      }
      if (!formData.receivedCopies) {
        newErrors.receivedCopies = "Received Copies is required";
      }
    }
    return newErrors;
  };

  const handleFormSubmit = async () => {
    // Validate the form data
    const errors = validateForm(formData, currentTab);
    setErrors(errors);

    // If there are errors, return early
    if (Object.keys(errors).length > 0) return;

    // Perform API call based on the active tab
    if (currentTab === "upload") {
      console.log("uploadclicking");
      setApiProcessing({
        loader: true,
        message: "Submitting...",
      });
      await submitUpload(formData.registerId);
    }
    if (currentTab === "received") {
      setApiProcessing({
        loader: true,
        message: "Submitting...",
      });
      await submitReceived(formData.registerId);
    }
  };

  const submitUpload = async (registerId) => {
    console.log("registerId", registerId);
    try {
      const ApprovalFormData = {
        acceptedArchitectRevisions: [
          {
            softCopySubmittedDate: todayDate(),
            changes: formData.note,
          },
        ],
      };

      console.log("Submitting Approval data:", ApprovalFormData);

      const response = await webApi.put(
        `api/architectureToRoRegister/revisions/${registerId}`,
        ApprovalFormData
      );

      // If there's a file to upload, initiate PUT request to upload file
      if (formData.drawingFileName) {
        const drawingFileName = new FormData();
        drawingFileName.append("drawingFileName", formData.drawingFileName);
        await webApi.imagePut(
          `api/architectureToRoRegister/${registerId}`,
          drawingFileName
        );
      }
      setApiProcessing({ loader: false, message: "" });
      handleClose();
    } catch (error) {
      console.error("Error submitting form:", error);
      setApiProcessing({ loader: false, message: "" });
      // Handle error, show error message, etc.
      alert("Failed to submit the form. Please try again later.");
    }
  };

  const submitReceived = async (registerId) => {
    console.log("registerId", registerId);
    try {
      const ApprovalFormData = {
        acceptedArchitectHardCopyRevisions: [
          {
            hardCopySubmittedDate: todayDate(),
            receivedCopies: formData.receivedCopies,
            revision: formData.selectedRevision,
          },
        ],
      };

      console.log("Submitting Approval data:", ApprovalFormData);

      const response = await webApi.put(
        `api/architectureToRoRegister/HardCopyRevision/${registerId}`,
        ApprovalFormData
      );

      // If there's a file to upload, initiate PUT request to upload file
      if (formData.drawingFileName) {
        const hardCopyFile = new FormData();
        hardCopyFile.append("hardCopyFile", formData.drawingFileName);
        await webApi.imagePut(
          `api/architectureToRoRegister/hardCopyFile/${registerId}`,
          hardCopyFile
        );
      }
      setApiProcessing({ loader: false, message: "" });
      handleClose();
    } catch (error) {
      console.error("Error submitting form:", error);
      setApiProcessing({ loader: false, message: "" });
      // Check if the error response has a message
      const errorMsg =
        error.response?.data?.message ||
        "Failed to submit the form. Please try again later.";
      setErrorMessage(errorMsg);
    }
  };

  const fetchInRegisterData = async () => {
    if (registerInfo && registerInfo.length > 0) {
      setRegisterList(registerInfo);
      console.log("fetched from recoil", registerInfo);
    } else {
      await updateRegister(setRegisterInfo, site).then((response) => {
        if (response.status === SUCESS_STATUS) {
          setRegisterList(response.registers);
        }
      });
    }
  };

  const fetchRegisterData = async () => {
    await updateRegister(setRegisterInfo, site).then((response) => {
      console.log("updating");
      if (response.status === SUCESS_STATUS) {
        setRegisterList(response.registers);
      }
    });
  };

  useEffect(() => {
    fetchInRegisterData();
  }, []);

  const getNextRevision = (revisions) => {
    const lastRevision = revisions[revisions.length - 1];
    const numberPartStr = lastRevision.replace("R", "");
    const numberPart = parseInt(numberPartStr, 10);

    if (!isNaN(numberPart)) {
      return `R${numberPart + 1}`;
    } else {
      console.error(
        `Invalid revision format or parsing error: ${lastRevision}`
      );
      return lastRevision; // Return the last valid revision if there's a parsing error
    }
  };

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  return (
    <>
      <Loader open={apiProcessing.loader} message={apiProcessing.message} />
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        PaperProps={{ style: { borderRadius: 20, fontFamily: "Poppins" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
          }}
        >
          <p
            style={{ margin: "5px 400px", textAlign: "center", color: "white" }}
          >
            Drawing
          </p>
          <RxCross1
            size={20}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "20px",
              color: "white",
            }}
            onClick={handleClose}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            marginTop: "20px",
            marginBottom: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "0",
              border: "1px solid var(--primary-color)",
            }}
          >
            <button
              onClick={() => handleTabChange("upload")}
              style={{
                backgroundColor:
                  currentTab === "upload"
                    ? "var(--primary-color)"
                    : "transparent",
                color:
                  currentTab === "upload" ? "white" : "var(--primary-color)",
                width: "150px",
                height: "35px",
                cursor: "pointer",
                borderRight: "1px solid var(--primary-color)",
              }}
            >
              Upload
            </button>

            <button
              onClick={() => handleTabChange("received")}
              style={{
                background:
                  currentTab === "received"
                    ? "var(--primary-color)"
                    : "transparent",
                color:
                  currentTab === "received" ? "white" : "var(--primary-color)",
                width: "150px",
                height: "35px",
                cursor: "pointer",
              }}
            >
              Received
            </button>
          </div>
        </div>
        <DialogContent
          style={{ padding: "25px", minWidth: "300px", minHeight: "300px" }}
        >
          <Loader open={apiProcessing.loader} message={apiProcessing.message} />
          <section
            style={{
              marginLeft: "80px",
              marginRight: "80px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "50px",
                width: "80%",
              }}
            >
              <div>
                <label
                  htmlFor="designDrawingConsultant"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Design Consultant
                  <span style={{ color: "red" }}>*</span>
                </label>

                <SearchDropdownIdVal
                  value={formData.designDrawingConsultant}
                  placeholder={"Select Design Consultant"}
                  options={Array.from(
                    new Set(
                      registerList.map(
                        (register) =>
                          `${register.designDrawingConsultant?.role}`
                      )
                    )
                  )
                    .filter(Boolean)
                    .map((consultant, index) => ({
                      name: consultant,
                      value: consultant,
                    }))}
                  onOptionSelect={(option) => {
                    handleChange({
                      target: {
                        name: "designDrawingConsultant",
                        value: option.value,
                      },
                    });
                  }}
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                />
                {errors.designDrawingConsultant && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.designDrawingConsultant}
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="drawingNumber"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Drawing No
                  <span style={{ color: "red" }}>*</span>
                </label>
                <SearchDropdownIdVal
                  placeholder={"Select Drawing No"}
                  value={formData.drawingNumber}
                  options={filteredDrawingOptions.map((register) => ({
                    name: register.drawingNo,
                    value: register.drawingNo,
                  }))}
                  onOptionSelect={(option) =>
                    handleChange({
                      target: { name: "drawingNumber", value: option.value },
                    })
                  }
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                />
                {errors.drawingNumber && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.drawingNumber}
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="drawingName"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Drawing Title
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  id="drawingName"
                  name="drawingName"
                  value={formData.drawingName}
                  onChange={handleChange}
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                  readOnly
                />
                {errors.drawingName && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.drawingName}
                  </p>
                )}
              </div>
              <div>
                <label
                  htmlFor="category"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Drawing Category
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  id="category"
                  name="category"
                  value={formData.category}
                  onChange={handleChange}
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                  readOnly
                />
                {errors.category && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.category}
                  </p>
                )}
              </div>
              {currentTab === "received" && (
                <div>
                  <label
                    htmlFor="receivedCopies"
                    className="fs-10 fw-bold text-start py-2
              "
                  >
                    No of Copies Received
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="receivedCopies"
                    name="receivedCopies"
                    placeholder="Enter No of Received Copies"
                    value={formData.receivedCopies}
                    onChange={handleChange}
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                  />
                  {errors.receivedCopies && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.receivedCopies}
                    </p>
                  )}
                </div>
              )}

              <div>
                <label
                  htmlFor="selectedRevision"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Select Revision<span style={{ color: "red" }}>*</span>
                </label>

                <SearchDropdownIdVal
                  placeholder={"Select Revision"}
                  backgroundColor={"var(--table-evenrow-color)"}
                  value={formData.selectedRevision}
                  options={
                    currentTab === "received"
                      ? formData.revisions.length > 0
                        ? [
                            // { name: "Select Revision", value: "" },
                            ...(Array.isArray(formData.revisions)
                              ? formData.revisions.map((rev) => ({
                                  name: rev,
                                  value: rev,
                                }))
                              : []),
                          ]
                        : []
                      : [
                          {
                            name:
                              formData.revisions.length === 0
                                ? "R0"
                                : getNextRevision(formData.revisions),
                            value:
                              formData.revisions.length === 0
                                ? "R0"
                                : getNextRevision(formData.revisions),
                          },
                        ]
                  }
                  onOptionSelect={(option) =>
                    handleChange({
                      target: { name: "selectedRevision", value: option.value },
                    })
                  }
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                  readOnly={currentTab !== "received"}
                />

                {errors.selectedRevision && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.selectedRevision}
                  </p>
                )}
              </div>

              {currentTab === "upload" && (
                <>
                  <div>
                    <label
                      htmlFor="drawingFileName"
                      className="fs-10 fw-bold text-start py-2"
                    >
                      upload your file
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="file"
                      accept=".dwg"
                      id="drawingFileName"
                      name="drawingFileName"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        if (file && file.name.endsWith(".dwg")) {
                          handleChange(e);
                        } else {
                          alert("Please select a .dwg file.");
                          e.target.value = null; // Reset the file input if the file is not valid
                        }
                      }}
                      placeholder="Select your drawing file"
                      className="form-control fs-12"
                      style={{ width: "100%" }}
                    />
                    {errors.drawingFileName && (
                      <p style={{ color: "red", fontSize: "12px" }}>
                        {errors.drawingFileName}
                      </p>
                    )}
                  </div>

                  {formData.revisions.length > 1 && (
                    <div>
                      <label
                        htmlFor="note"
                        className="fs-10 fw-bold text-start py-2"
                      >
                        Changes in Revsion
                      </label>
                      <textarea
                        type="text"
                        id="note"
                        name="note"
                        value={formData.note}
                        onChange={handleChange}
                        placeholder="Enter Changes note"
                        className="form-control fs-12"
                        style={{ width: "100%" }}
                      />
                      {errors.note && (
                        <p style={{ color: "red", fontSize: "12px" }}>
                          {errors.note}
                        </p>
                      )}
                    </div>
                  )}
                </>
              )}
              {currentTab === "received" && (
                <div>
                  <label
                    htmlFor="drawingFileName"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    upload your file
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="file"
                    accept=".jpg,.jpeg,.png,.pdf"
                    id="drawingFileName"
                    name="drawingFileName"
                    onChange={handleChange}
                    placeholder="Select your drawing file"
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                  />
                  {errors.drawingFileName && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.drawingFileName}
                    </p>
                  )}
                </div>
              )}
            </div>
          </section>
          {/* } */}
          {/* { siteId &&  */}
          {errorMessage && (
            <p severity="error" onClose={() => setErrorMessage("")}>
              {errorMessage}
            </p>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "45px",
            }}
          >
            <button
              style={{
                padding: "8px",
                borderRadius: "5px",
                border: "1px solid lightgray",
                backgroundColor: "transparent",
                width: "100px",
              }}
              onMouseOver={(e) =>
                (e.target.style.backgroundColor = "lightgray")
              }
              onMouseOut={(e) =>
                (e.target.style.backgroundColor = "transparent")
              }
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary"
              style={{
                marginLeft: "50px",
                padding: "10px",
                borderRadius: "5px",
                border: "1px solid var(--primary-color)",
                width: "100px",
                backgroundColor: "var(--primary-color)",
                color: "white",
              }}
              onClick={handleFormSubmit}
            >
              Submit
            </button>
          </div>
          {/* } */}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddDrawingDialog;
