import React from "react";
import styles from "./Footer.module.css";
import logo from "../../Images/TMLOGO.png";
import LinkedInIcon from "../../icons/linkedin";
import TwittenIcon from "../../icons/twitter";
import InstagramIcon from "../../icons/instagram";
import FacebookIcon from "../../icons/facebook";

const Footer = ({  }) => {
  return (
    <footer
      className="text-white footercontainer"
      style={{
        backgroundColor: "#E47331",
        height: "auto",
        padding: '12px',
      }}
    >
      <div className="container-fluid" style={{ backgroundColor: "#E47331" }}>
        <div className="container d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center">
           <img
              loading="lazy"
              src={logo}
              className={`${styles.footerimage}`}
              alt="Company Logo"
              style={{ maxWidth: "150px" }}
            />
            <div className={styles.footerCopyright}>
              {/* ©companyname2024 @All rights reserved */}
              <a
                href="#"
                className="text-white text-decoration-none footerLinks"
              >
                Copyright
              </a>
              <span className={styles.footerSeperator}>|</span>
              <a
                href="#"
                className="text-white text-decoration-none footerLinks"
              >
                Trademark
              </a>
              <span className={styles.footerSeperator}>|</span>
              <a
                href="/TeamsConditions"
                target="_blank"
                className="text-white text-decoration-none footerLinks"
              >
                Teams of Use
              </a>
              <span className={styles.footerSeperator}>|</span>
              <a
                href="/privacy-policy"
                target="_blank"
                className="text-white text-decoration-none footerLinks"
              >
                Privacy policy
              </a>
              <span className={styles.footerSeperator}>|</span>
              <a
                href="#"
                className="text-white text-decoration-none footerLinks"
              >
                Legal Disclosure
              </a>
            </div>
            <div className={styles.footerSocialIcons}>
              <a href="/">
                <LinkedInIcon/>
              </a>
              <a href="/">
                <TwittenIcon/>
              </a>
              <a href="/">
                <InstagramIcon/>
              </a>
              <a href="/">
                <FacebookIcon/>
              </a>
            </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
