import React from 'react';
import PropTypes from 'prop-types';
import styles from './LogReportStatus.module.css';

const TimePeriodOption = ({ label, isSelected, onSelect }) => {
  return (
    <div className={styles.timePeriodOption} onClick={onSelect}>
      <div className={`${styles.optionCircle} ${isSelected ? styles.selected : ''}`}>
        {isSelected && <div className={styles.innerCircle} />}
      </div>
      <div className={styles.optionLabel}>{label}</div>
    </div>
  );
};

TimePeriodOption.propTypes = {
  label: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default TimePeriodOption;
