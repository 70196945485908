import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import * as Icon from "react-bootstrap-icons";
import { Link, useNavigate } from "react-router-dom";
import {
  BG_COLOUR,
  Button_COLOUR,
  Field_COLOUR,
} from "../../Utilities/Constants";
import { useRecoilState, useSetRecoilState } from "recoil";
import { jwtToken, userInfo, isAuthenticated } from "../../atoms";
import { WebApimanager } from "../../WebApiManager";
import { Loader } from "../../Widgets/notificationFeedbacks";
import Styles from "./Singin.module.css";
import maskgroup from "../../Images/maskgroup.png";
import LoginImg from "./Images/LoginImg.png";
import MobileLogo from "./Images/MobileLogo.png";
const SignIn = () => {
  let webApi = new WebApimanager();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [generalError, setGeneralError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [accessTokenVal, setAccessTokenVal] = useRecoilState(jwtToken);
  const setUserInfoData = useSetRecoilState(userInfo);
  const setIsAuthenticated = useSetRecoilState(isAuthenticated);

  useEffect(() => {
    // Disable scrolling when the component is mounted
    document.body.style.overflow = "hidden";

    // Re-enable scrolling when the component is unmounted
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    return re.test(String(email).toLowerCase());
  };
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  // const validatePassword = (password) => {
  //   return password == email;
  // };
  const handleSubmit = (e) => {
    e.preventDefault();

    // Reset any previous error messages
    setEmailError("");
    setPasswordError("");
    setGeneralError("");

    // Check if email is empty
    if (!email.trim()) {
      setEmailError("Please enter your email address.");
      return;
    }

    // Check if password is empty
    if (!password.trim()) {
      setPasswordError("Please enter your password.");
      return;
    }

    // Validate email format
    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
      return;
    }
    // if (!validatePassword(password)) {
    //   setPasswordError("Please enter a valid password.");
    //   return;
    // }
    setApiProcessing({
      loader: true,
      message: "Logging In...",
    });
    webApi
      .postwithouttoken("api/users/login", {
        email: email,
        password: password,
      })
      .then((response) => {
        const data = response.data;
        console.log("Server Response:", response);
        console.log("Data:", data);
        setApiProcessing({ loader: false, message: "" });
      })
      .catch((error) => {
        console.log("error", error);
      });

    // All validations passed, proceed with form submission
    webApi
      .post("api/users/login", {
        email: email,
        password: password,
      })
      .then((response) => {
        const data = response.data;
        console.log("Server Response:", response);
        console.log("Data:", data);
        setAccessTokenVal(data.token);
        setApiProcessing({ loader: false, message: "" });
        setUserInfoData(data.user);
        console.log("User Info Set:", data.user); // Add logging here
        navigate("/dashboard");
      })
      .catch((error) => {
        setApiProcessing({ loader: false, message: "" });
        setGeneralError("Please check your credentials and try again.");
        console.error("There was an error logging in!", error);
        // Optionally, handle login error, e.g., set a login error state
      });
  };

  return (
    <>
      <Loader open={apiProcessing.loader} message={apiProcessing.message} />
      {/* <div className="w-full h-screen  p-[100px] flex  bg-[#f3f3f3] "> */}
      {/* <div
          className={` w-[150px] h-[150px] rounded-[30px] border-[25px]  rotate-[41.88deg]  mt-[-20px] ml-5 ${Styles.squareBoxUp}`}
        ></div>
        <div
          className={` w-[150px] h-[150px] rounded-[30px] border-[25px]  rotate-[41.88deg]  mt-[-20px] ml-5 absolute bottom-[80px] left-[36%] ${Styles.squareBoxDown}`}
        ></div> */}
      {/* <form onSubmit={handleSubmit} className={Styles.LoginForm}> */}
      {/* <img
            className={Styles.loginImglogo}
            src="logo/logo3.png"
          /> */}
      {/* <h2 className={Styles.loginpageHeading}>Login your account</h2> */}
      {/* <div className={Styles.FieldsDiv}>
            <label className={Styles.LoginHeading}>Email *</label>
            <input
              type="email"
              placeholder="support@mrchams.com"
              className={Styles.LoginTextInput}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoFocus
              required
            />
            {emailError && (
              <div style={{ color: "red", marginTop: "5px" }}>{emailError}</div>
            )}
          </div> */}

      {/* <div className={Styles.FieldsDiv}>
            <label className={Styles.LoginHeading}>Password *</label>
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Enter your password"
              minLength="6"
              className={Styles.LoginTextInput}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />

            <button
              type="button"
              className="butIcons"
              onClick={() => setShowPassword(!showPassword)}
              style={{
                background: "transparent",
                border: "none",
                cursor: "pointer",
              }}
            >
              {showPassword ? <Icon.Eye /> : <Icon.EyeSlash />}
            </button>
            {passwordError && (
              <div style={{ color: "red" }}>{passwordError}</div>
            )}
            <div className={Styles.formCTA}>
              <a
                href="/forgot-password"
                className="text-sm font-semibold text-purple-700 hover:underline focus:text-purple-700"
              >
                Forgot Password?
              </a>
            </div>
          </div> */}
      {/* {generalError && (
            <div style={{ color: "red", marginTop: "10px" }}>
              {generalError}
            </div>
          )} */}

      {/* <button type="submit" className={Styles.loginButton}>
            LOGIN NOW
          </button> */}
      {/* </form> */}
      {/* <div className="flex  absolute top-[100px] left-[100px] bottom-[100px] right-[100px]">
          <form onSubmit={handleSubmit} className={Styles.LoginForm}>
            <img
              className={Styles.loginImglogo}
              src="logo/logo3.png"
              // style={{ height: "100px", width: "300px", marginTop: "280px" }}
            />
            <h2 className={Styles.loginpageHeading}>Login your account</h2>
            <div className={Styles.FieldsDiv}>
              <label className={Styles.LoginHeading}>Email *</label>
              <input
                type="email"
                placeholder="support@mrchams.com"
                className={Styles.LoginTextInput}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoFocus
                required
              />
              {emailError && (
                <div style={{ color: "red", marginTop: "5px" }}>
                  {emailError}
                </div>
              )}
            </div>

            <div className={Styles.FieldsDiv}>
              <label className={Styles.LoginHeading}>Password *</label>
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Enter your password"
                minLength="6"
                className={Styles.LoginTextInput}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />

              <button
                type="button"
                className="butIcons"
                onClick={() => setShowPassword(!showPassword)}
                style={{
                  background: "transparent",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                {showPassword ? <Icon.Eye /> : <Icon.EyeSlash />}
                </button>
                {passwordError && (
                  <p className={Styles.Error}>{passwordError}</p>

                )}
                  {generalError && (
                  <p className={Styles.Error}>
                    {generalError}
                  </p>
                )}
              </div>
              <div className={Styles.ForgetPasswordContainer}>
              <Link to="/forgot-password" className={Styles.ForgetLink}>Forgot Password?</Link>
              </div>
            </div>
            <div className={Styles.SubmitBtnContainer}>
            <button type="submit" className={Styles.SubmitBtn}>Login Now</button>
            </div>
          </form>
          <div className=" w-1/2 h-full  ">
            <img className="w-full h-full" src={maskgroup} />
          </div>
        </div> */}
      {/* </div> */}
      {/* <div className="w-screen h-screen bg-[#ffff]  flex overflow-hidden">
        <div className="py-[80px] pl-[200px]  w-[90%] mr-[300px]">
        <div className="w-[450px]  shadow-lg rounded-md  ">
          <form>
            <div className="h-[140px] w-full pt-[50px]">
              <img className="h-full mx-auto" src="logo/logo3.png" />
            </div>
            <h5 className="px-[50px] mt-[60px] text-[18px]">Login in to your account</h5>
            <div className="px-[50px] bg-white mt-[40px]">
              <div className="w-full flex-col mb-4">
                <label className="text-[16px] mb-3">Email*</label>
                <input type="text" className="w-full h-[40px] rounded-md bg-[#F1F3F6] text-[14px] px-2" 
                placeholder="JohnDoe@gmail.com"/>
              </div>
              <div className="w-full flex-col ">
                <label className="text-[16px] mb-3">Password*</label>
                <input type="password" className="w-full h-[40px] rounded-md bg-[#F1F3F6] text-[14px] px-2" 
                placeholder="Enter your password"
                />
              </div>
            </div>
            <div className="w-full px-[50px] mt-10 ">
            <button type="submit" className="bg-[#E47331] w-full h-[40px] rounded-md text-white text-[16px]">Login Now</button>
            </div>
          </form>
        </div>
        </div>
        
        <LoginGrapics image={LoginImg}/>
      </div> */}
      <div className={Styles.LoginContainer}>
        <div className={Styles.LeftContainer}>
          <form onSubmit={handleSubmit} className={Styles.FormContainer}>
            <div className={Styles.ImageContainer}>
              <img className={Styles.Logo} src="logo/logo3.png" />
            </div>
            <h5 className={Styles.LoginText}>Login in to your account</h5>
            <div className={Styles.InputContainer}>
              <div className={Styles.EmailContent}>
                <label className={Styles.InputLabel}>Email*</label>
                <input
                  type="text"
                  className={Styles.InputFeild}
                  placeholder="Johndoe@gmail.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  autoFocus
                  required
                />
                {emailError && <p className={Styles.Error}>{emailError}</p>}
              </div>
              <div className={Styles.PasswordContent}>
                <label className={Styles.InputLabel}>Password*</label>
                <input
                  type={showPassword ? "text" : "password"}
                  className={Styles.InputFeild}
                  placeholder="Enter your password"
                  minLength="6"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <p
                  onClick={() => setShowPassword(!showPassword)}
                  className={Styles.Eye}
                >
                  {showPassword ? <Icon.Eye /> : <Icon.EyeSlash />}
                </p>
                {passwordError && (
                  <p className={Styles.Error}>{passwordError}</p>
                )}
              </div>
              <div className={Styles.ForgetPasswordContainer}>
                <Link to="/forgot-password" className={Styles.ForgetLink}>
                  Forgot Password?
                </Link>
              </div>
            </div>
            <div className={Styles.SubmitBtnContainer}>
              <button type="submit" className={Styles.SubmitBtn}>
                Login Now
              </button>
              {generalError && (
                <p
                  className={Styles.generalError}
                  style={{ textAlign: "center" }}
                >
                  {generalError}
                </p>
              )}
            </div>
          </form>
        </div>
        <div className={Styles.RightContainer}>
          <img src={LoginImg} alt="" className={Styles.RightContainerImage} />
        </div>
        <div className={Styles.MobileContainer}>
          <div className={Styles.MobileView}>
            <div className={Styles.MobileViewLogo}>
              <img src={MobileLogo} alt="" />
              <p className={Styles.HoldOn}>Hold On!</p>
            </div>
          </div>
          <div className={Styles.MobileMsgContainer}>
            <p className={Styles.MobileMsg}>
              We are currently not supporting Mobile Devices please click the
              below button to Download mobile application
            </p>
            {/* <p className={Styles.MobileMsg}>Please click the below button to Download</p> */}
            {/* <p></p> */}
            <div className={Styles.BtnContainer}>
              <button className={Styles.DownloadBtn}>
                <Link
                  to="https://www.google.com/"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  Download
                </Link>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;
