import React, { useState, useEffect } from "react";
import { WebApimanager } from "../../WebApiManager";
import { siteId } from "../../atoms";
import { useRecoilState } from "recoil";
import SelectSite from "../../Utilities/SelectSite";
import TableWrapper from "../../Utilities/TableWrapper";
import * as Icon from "react-bootstrap-icons";
import "./Notifications.css";

const SystemGenerated = () => {
  const webApi = new WebApimanager();
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [site, setSite] = useRecoilState(siteId);
  const [readNotifications, setReadNotifications] = useState(new Set());
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    setLoading(true);
    webApi
      .get("api/notification")
      .then((response) => {
        if (response.data && Array.isArray(response.data.data.notifications)) {
          setNotifications(response.data.data.notifications);
        } else {
          setError("Unexpected data format");
        }
      })
      .catch((error) => {
        setError("There was an error fetching the data");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div style={{ marginTop: "100px" }}>Error: {error}</div>;

  const handleMouseEnter = (id, isRead) => {
    if (!isRead && !readNotifications.has(id)) {
      webApi
        .put(`api/notification/read/${id}`)
        .then(() => {
          setReadNotifications((prev) => new Set(prev).add(id));
          setNotifications((prevNotifications) =>
            prevNotifications.map((notification) =>
              notification._id === id
                ? { ...notification, isRead: true }
                : notification
            )
          );
        })
        .catch((error) => {
          console.error("There was an error updating the notification!", error);
        });
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredNotifications = notifications.filter(
    (notification) =>
      notification.message.toLowerCase().includes(searchQuery.toLowerCase()) ||
      notification.subject.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const reversedNotifications = [...filteredNotifications].reverse();

  return (
    <div>
      {site === "" ? (
        <SelectSite />
      ) : (
        <div className="m-[60px]">
          <TableWrapper
            children={
              <>
                <div
                  style={{
                    display: "flex",
                    marginLeft: "5px",
                    marginRight: "5px",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h3
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "20px",
                    }}
                  >
                    Notification
                  </h3>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div
                      style={{
                        position: "relative",
                        width: "250px",
                        paddingLeft: "5px",
                        height: "50px",
                        right: "10px",
                      }}
                    >
                      <input
                        type="text"
                        placeholder="Search Notifications"
                        onChange={handleSearchChange}
                        value={searchQuery}
                        style={{
                          paddingLeft: "20px",
                          borderRadius: "6px",
                          border: "1px solid var(--primary-color)",
                          width: "250px",
                          height: "40px",
                        }}
                      />
                      <Icon.Search
                        style={{
                          position: "absolute",
                          right: "-3px",
                          top: "40%",
                          transform: "translateY(-50%)",
                          marginRight: "4px",
                          color: "#ACACAC",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div style={{ overflowY: "scroll", maxHeight: "500px" }}>
                  <table>
                    <thead style={{ position: "sticky", top: 0, zIndex: 1 }}>
                      <tr>
                        <th>Message</th>
                        <th>Subject</th>
                      </tr>
                    </thead>
                    <tbody>
                      {reversedNotifications.map((notification, index) => (
                        <tr
                          key={notification._id}
                          onMouseEnter={() =>
                            handleMouseEnter(
                              notification._id,
                              notification.isRead
                            )
                          }
                          style={{
                            cursor: "pointer",
                            backgroundColor:
                              index % 2 === 0
                                ? "var(--table-oddrow-color)"
                                : "var(--table-evenrow-color)",
                          }}
                        >
                          <td>
                            {notification.message}
                            {!notification.isRead && (
                              <span
                                style={{
                                  display: "inline-block",
                                  marginLeft: "10px",
                                  width: "8px",
                                  height: "8px",
                                  borderRadius: "50%",
                                  backgroundColor: "red",
                                }}
                              ></span>
                            )}
                          </td>
                          <td>{notification.subject}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            }
          />
        </div>
      )}
    </div>
  );
};

export default SystemGenerated;
