import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { WebApimanager } from "../../WebApiManager";
import FormItem from "./PNMAssets/FormItem";
import styles from "../PNM/DailyLogReport/Vehicle&Machinery.module.css";
import st from "../PNM/DailyLogReport/PowerTools.module.css";
import { siteId } from "../../atoms";
import { useRecoilState } from "recoil";
import FormHeadings from "../PNM/PNMAssets/FormHeadings";

const BreakdownReport = () => {
  const webApi = new WebApimanager();
  const [formData, setFormData] = useState({
    siteName: "",
    type: "",
    assetCode: "",
    transitionDate: "",
    subCode: "",
    equipmentType: "",
    breakDownDate: "",
    breakDownTime: "",
    breakDownType: "",
    model: "",
    repairDate: "",
    repairTime: "",
    repairStatus: "",
    actionTaken: "",
    leakages: "",
    engineStatus: "",
    tyreStatus: "",
    radiatorStatus: "",
    batteryStatus: "",
    mechanic: "",
    documentAttached: "",
    firstKmOrHrMeter: "",
    secondKmOrHrMeter: "",
    repeatedProblem: "",
    fuelPumpStatus: "",
    tyreStatus: "",
    gasOrOilCondition: "",
    engineOrMotorStatus: "",
    radiatorStatus: "",
    batteryStatus: "",
    spareCost: "",
    serviceCost: "",
    totalCost: "",
    sparesUsed: "",
    remarks: "",
    mechIncharge: "",
    issue: "",
    // PowerTools
    deadManSwitch: "",
    machineHandleCondition: "",
    machineCableCondition: "",
    wheelGuardCondition: "",
    //Db
    bodyEarthing: "",
    mcbAndRcbCondition: "",
    threePhaseIndicatorLight: "",
    voltageIndicatorDisplay: "",
  });

  const [errors, setErrors] = useState({});
  const [assetCodeData, setAssetCodeData] = useState([]);
  const [nameData, setNameData] = useState([]);
  const [site, setSite] = useRecoilState(siteId);
  const [sites, setDatasites] = useState([]);
  const [id, setId] = useState();
  const [equipment, setEquipment] = useState("");

  const handleSiteChange = (e) => {
    const { name, value } = e.target;

    setSite(e.target.value);
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handlefileChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.files[0] }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    const newValue = name === "kmOrHrReading" ? parseInt(value, 10) : value;

    let newFormData = {
      ...formData,
      [name]: newValue,
    };
    if (name === "equipmentType" && value) {
      setEquipment(value);
    }
    if (name === "assetCode" && value) {
      const assCode = assetCodeData.find(
        (doc, ind) => doc.assetCode === value.split("/")[0]
      );
      if (assCode) {
        setId(assCode._id);
        console.log(assCode.name);
        newFormData = {
          ...newFormData,
          ["subCode"]: assCode.subCode,
          ["equipmentType"]: assCode.type,
        };
      }
    }
    setFormData(newFormData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = {};

    const requiredFields = [
      "siteName",
      "equipmentType",
      "transitionDate",
      "assetCode",
      "type",
      "subCode",
      "model",
      "breakDownType",
      "breakDownDate",
      "breakDownTime",
      "repairDate",
      "repairTime",
      "repairStatus",
      "mechIncharge",
      "issue",
      "actionTaken",
      "mechanic",
      "repeatedProblem",
      "spareCost",
      "serviceCost",
      "totalCost",
      "sparesUsed",
      "remarks",
      "documentAttached",
    ];

    requiredFields.forEach((key) => {
      if (!formData[key]) {
        formErrors[key] = `${key} is required`;
      }
    });

    if (
      formData.equipmentType === "Vehicle" ||
      formData.equipmentType === "Machinery"
    ) {
      const conditionalFields = [
        "firstKmOrHrMeter",
        "secondKmOrHrMeter",
        "leakages",
        "fuelPumpSystem",
        "fuelPumpStatus",
        "tyreStatus",
        "gasOrOilCondition",
        "engineOrMotorStatus",
        "batteryStatus",
        "radiatorStatus",
      ];
      conditionalFields.forEach((key) => {
        if (!formData[key]) {
          formErrors[key] = `${key} is required`;
        }
      });
    } else if (formData.equipmentType === "Power Tools") {
      const conditionalFields = [
        "deadManSwitch",
        "machineHandleCondition",
        "machineCableCondition",
        "wheelGuardCondition",
      ];
      conditionalFields.forEach((key) => {
        if (!formData[key]) {
          formErrors[key] = `${key} is required`;
        }
      });
    } else if (formData.equipmentType === "Distribution Board") {
      const conditionalFields = [
        "bodyEarthing",
        "mcbAndRcbCondition",
        "threePhaseIndicatorLight",
        "voltageIndicatorDisplay",
      ];
      conditionalFields.forEach((key) => {
        if (!formData[key]) {
          formErrors[key] = `${key} is required`;
        }
      });
    }

    console.log("submitted", formData);

    if (Object.keys(formErrors).length) {
      console.log("Form errors:", formErrors);
      setErrors(formErrors);
    } else {
      console.log(formData);
      // const tempFormData = {
      //   siteName: site,
      //   type: formData.type,
      //   assetCode: id,
      //   equipmentType: formData.equipmentType,

      //   startDate: formData.startDate,
      //   endDate: formData.endDate,
      //   differenceDate: formData.differenceDate,
      //   startTime: formData.startTime,
      //   endTime: formData.endTime,
      //   differenceTime: formData.differenceTime,

      //   transitionDate: formData.transitionDate,
      //   scopeOfWorkDetails: formData.scopeOfWorkDetails,
      //   location: formData.location,
      //   remarks: formData.remarks,
      //   debitAmount: Number(formData.debitAmount),
      //   debitReason: formData.debitReason,
      //   model: formData.model,
      //   vendor: formData.vendor,
      //   mobileNo: formData.mobileNo,
      //   phoneNo: formData.phoneNo,
      //   debitNote: formData.debitNote || "No",
      //   employeeType: formData.employeeType,
      //   debitAmount: Number(formData.debitAmount),
      //   debitReason: formData.debitReason,
      //   employeeId: formData.employeeId,
      // };
      let tempFormData = {
        siteName: site,
        transitionDate: formData.transitionDate,
        equipmmentType: formData.equipmentType,
        assetCode: id,
        type: formData.type,
        subCode: formData.subCode,
        model: formData.model,
        breakDownType: formData.breakDownType,
        breakDownDate: formData.breakDownDate,
        breakDownTime: formData.breakDownTime,
        repairDate: formData.repairDate,
        repairTime: formData.repairTime,
        repairStatus: formData.repairStatus,
        mechIncharge: formData.mechIncharge,
        issue: formData.issue,
        actionTaken: formData.actionTaken,
        mechanic: formData.mechanic,
        repeatedProblem: formData.repeatedProblem,
        spareCost: formData.spareCost,
        serviceCost: formData.serviceCost,
        totalCost: formData.totalCost,
        sparesUsed: formData.sparesUsed,
        remarks: formData.remarks,
        documentAttached: formData.documentAttached,
      };
      if (
        formData.equipmentType === "Vehicle" ||
        formData.equipmentType === "Machinery"
      ) {
        tempFormData = {
          ...tempFormData,
          firstKmOrHrMeter: formData.firstKmOrHrMeter,
          secondKmOrHrMeter: formData.secondKmOrHrMeter,
          leakages: formData.leakages,
          fuelPumpSystem: formData.fuelPumpSystem,
          fuelPumpStatus: formData.fuelPumpStatus,
          tyreStatus: formData.tyreStatus,
          gasOrOilCondition: formData.gasOrOilCondition,
          engineOrMotorStatus: formData.engineOrMotorStatus,
          batteryStatus: formData.batteryStatus,
          radiatorStatus: formData.radiatorStatus,
        };
      } else if (formData.equipmentType === "Power Tools") {
        tempFormData = {
          ...tempFormData,
          deadManSwitch: formData.deadManSwitch,
          machineHandleCondition: formData.machineHandleCondition,
          machineCableCondition: formData.machineCableCondition,
          wheelGuardCondition: formData.wheelGuardCondition,
        };
      } else if (formData.equipmentType === "Distribution Board") {
        tempFormData = {
          ...tempFormData,
          bodyEarthing: formData.bodyEarthing,
          mcbAndRcbCondition: formData.mcbAndRcbCondition,
          threePhaseIndicatorLight: formData.threePhaseIndicatorLight,
          voltageIndicatorDisplay: formData.voltageIndicatorDisplay,
        };
      }
      console.log("temp", tempFormData);
      console.log("formData", formData);
      const response = await webApi.post(
        "api/pnms/breakDownReport",
        tempFormData
      );
      console.log(response);
      // window.location.reload();
    }
  };

  useEffect(() => {
    const fetchAssetCodeData = async () => {
      const response = await webApi.get(`api/pnms/assetCode`);
      setAssetCodeData(response.data.data.AssetCodes);
    };

    const fetchSiteData = async () => {
      const response = await webApi.get(`api/sites/AllSites`);
      setNameData(response.data.sites);
    };

    fetchAssetCodeData();
    fetchSiteData();
  }, []);

  useEffect(() => {
    webApi
      .get("api/sites/getAllSitesInfo")
      .then((response) => {
        console.log("API response:", response);
        if (response.data && Array.isArray(response.data.sites)) {
          setDatasites(response.data.sites);
          if (site === "") {
            setSite(response.data.sites[0]._id);
          }
        } else {
          console.error("Unexpected data format:", response.data);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, [site]);

  return (
    <div className={`${styles.VMmainDiv} w-[77vw]`}>
      <div className={` w-full`}>
        <h3 className={styles.VMheading} style={{ paddingBottom: "32px" }}>
          Breakdown Report
        </h3>
        <div style={{ display: "flex" }} className="w-full">
          <div style={{ fontFamily: "Poppins" }} className="w-full">
            <form onSubmit={handleSubmit}>
              <div>
                <div className={`${styles.FormItemsMain} w-full`}>
                  <div className="w-[10%]">
                    <FormHeadings text={"Site Name:"} />
                  </div>
                  <div className="w-[85%]">
                    <select
                      className="w-full"
                      name="siteName"
                      value={formData.siteName}
                      onChange={handleSiteChange}
                      style={{
                        // width: "873px ",
                        height: "30px",
                        flexShrink: "0",
                        // paddingLeft: "20px"
                        border: "1px solid rgba(151, 151, 151, 0.25)",
                        background: "#f3f3f3",
                        color: " #b4b4b4",
                        fontFamily: "Poppins",
                        fontSize: "12px",
                      }}
                    >
                      <option value="" hidden>
                        Select a site
                      </option>
                      {sites &&
                        Array.isArray(sites) &&
                        sites.map((item) => (
                          <option value={item._id} key={item._id}>
                            {item.siteName}
                          </option>
                        ))}
                    </select>
                    <p className="text-danger absolute">{errors.siteName}</p>
                  </div>
                </div>
                {/* ------------------------- */}
                <div className={`${styles.formSecondryFields} `}>
                  <div>
                    <div
                      className={styles.FormItemsMain}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        whiteSpace: "nowrap",
                        justifyContent: "space-between",
                      }}
                    >
                      <FormHeadings text={"Transition ID:"} />
                      <input
                        type="text"
                        className={st.pdropDownSelect}
                        name="transitionID"
                        readOnly
                      />
                    </div>

                    <div
                      className={styles.FormItemsMain}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        whiteSpace: "nowrap",
                        justifyContent: "space-between",
                      }}
                    >
                      <FormHeadings text={"Equipment Type:"} />
                      <div className="relative">
                        <select
                          className={st.pdropDownSelect}
                          name="equipmentType"
                          value={formData.equipmentType}
                          style={{
                            paddingLeft: "5px",
                            marginLeft: "8px",
                            paddingLeft: "20px",
                          }}
                          onChange={handleChange}
                        >
                          <option value="" hidden>
                            Select Equipment Type
                          </option>
                          <option value="Vehicle">Vehicle</option>
                          <option value="Machinery">Machinery</option>
                          <option value="Power Tools">Power Tools</option>
                          <option value="Distribution Board">
                            Distribution Board
                          </option>
                          <option value="Batching Point">Batching Point</option>
                        </select>
                        <p className="text-danger absolute left-[5%]">
                          {errors.equipmentType}
                        </p>
                      </div>
                    </div>
                    <div
                      className={styles.FormItemsMain}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        whiteSpace: "nowrap",
                        justifyContent: "space-between",
                      }}
                    >
                      <FormHeadings text={"Type:"} />
                      <div className="relative">
                        <select
                          className={st.pdropDownSelect}
                          name="type"
                          value={formData.type}
                          onChange={handleChange}
                          style={{
                            paddingLeft: "5px",
                            marginLeft: "8px",
                            paddingLeft: "20px",
                          }}
                        >
                          <option value="" hidden>
                            select Type
                          </option>
                          <option value="Own">Own</option>
                          <option value="Hire">Hire</option>
                        </select>
                        <p className="text-danger absolute left-[5%]">
                          {errors.type}
                        </p>
                      </div>
                    </div>
                    <div
                      className={styles.FormItemsMain}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        whiteSpace: "nowrap",
                        justifyContent: "space-between",
                      }}
                    >
                      <FormHeadings text={"Model:"} />
                      <div>
                        <input
                          type="text"
                          className={st.pdropDownSelect}
                          name="model"
                          value={formData.model}
                          onChange={handleChange}
                          placeholder="Enter Model"
                        />
                        <p className="text-danger absolute">{errors.model}</p>
                      </div>
                    </div>
                    <div
                      className={styles.FormItemsMain}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        whiteSpace: "nowrap",
                        justifyContent: "space-between",
                      }}
                    >
                      <FormHeadings text={"Breakdown Date:"} />
                      <div>
                        <input
                          type="date"
                          className={st.pdropDownSelect}
                          name="breakDownDate"
                          value={formData.breakDownDate}
                          onChange={handleChange}
                        />
                        <p className="text-danger absolute ">
                          {errors.breakDownDate}
                        </p>
                      </div>
                    </div>
                    <div
                      className={styles.FormItemsMain}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        whiteSpace: "nowrap",
                        justifyContent: "space-between",
                      }}
                    >
                      <FormHeadings text={"Repaired Date:"} />
                      <div>
                        <input
                          type="date"
                          className={st.pdropDownSelect}
                          name="repairDate"
                          value={formData.repairDate}
                          onChange={handleChange}
                        />
                        <p className="text-danger absolute">
                          {errors.repairDate}
                        </p>
                      </div>
                    </div>
                    <div
                      className={styles.FormItemsMain}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        whiteSpace: "nowrap",
                        justifyContent: "space-between",
                        marginBottom: "5px",
                      }}
                    >
                      <FormHeadings text={"Repair Status:"} />
                      <div className="relative">
                        <select
                          className={st.pdropDownSelect}
                          name="repairStatus"
                          value={formData.repairStatus}
                          onChange={handleChange}
                          style={{
                            paddingLeft: "5px",
                            marginLeft: "8px",
                            paddingLeft: "20px",
                          }}
                        >
                          <option value="" hidden>
                            select Type
                          </option>
                          <option value="Ok">Ok</option>
                          <option value="Not Ok">Not Ok</option>
                          <option value="N/A">N/A</option>
                        </select>
                        <p className="text-danger absolute left-[5%]">
                          {errors.repairStatus}
                        </p>
                      </div>
                    </div>
                    <div
                      className={styles.FormItemsMain}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        whiteSpace: "nowrap",
                        justifyContent: "space-between",
                      }}
                    >
                      <FormHeadings text={"Issue:"} />
                      <div>
                        <textarea
                          name="issue"
                          id="issue"
                          value={formData.issue}
                          onChange={handleChange}
                          rows="3"
                          style={{
                            backgroundColor: "#f3f3f3",
                            border: "1px solid rgba(151, 151, 151, 0.25)",
                            width: "300px",
                          }}
                        ></textarea>
                        <p className="text-danger absolute">{errors.issue}</p>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div>
                      <div
                        className={`${styles.FormItemsMain} justify-between`}
                      >
                        <FormHeadings text={"Transition Date:"} />
                        <div>
                          <input
                            type="date"
                            className={st.pdropDownSelect}
                            style={{ paddingLeft: "20px" }}
                            name="transitionDate"
                            value={formData.transitionDate}
                            onChange={handleChange}
                            placeholder="---------"
                          />
                          <p className="text-danger absolute">
                            {errors.transitionDate}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="flex mt-6 justify-between ">
                      <FormHeadings text={"Asset code/Name:"} />
                      <div>
                        <select
                          className={st.pdropDownSelect}
                          name="assetCode"
                          value={formData.assetCode}
                          onChange={handleChange}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            whiteSpace: "nowrap",
                            justifyContent: "space-between",
                          }}
                        >
                          <option value="" hidden>
                            Select name
                          </option>
                          {assetCodeData.length &&
                            assetCodeData
                              .filter((item) => item.type === equipment)
                              .map((item) => (
                                <option
                                  value={item.assetCode + "/" + item.name}
                                  key={item._id}
                                >
                                  {item.assetCode + "/" + item.name}
                                </option>
                              ))}
                        </select>
                        <p className="text-danger absolute">
                          {errors.assetCode}
                        </p>
                      </div>
                    </div>
                    <div
                      className={styles.FormItemsMain}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        whiteSpace: "nowrap",
                        justifyContent: "space-between",
                      }}
                    >
                      <FormHeadings text={"Sub code:"} />
                      <div>
                        <input
                          className={st.pdropDownSelect}
                          type="text"
                          placeholder="Enter SubCode"
                          name="subCode"
                          onChange={handleChange}
                          value={formData.subCode}
                        />
                        <p className="text-danger absolute">{errors.subCode}</p>
                      </div>
                    </div>
                    <div
                      className={styles.FormItemsMain}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        whiteSpace: "nowrap",
                        justifyContent: "space-between",
                      }}
                    >
                      <FormHeadings text={"Breakdown Type:"} />
                      <div className="relative">
                        <select
                          className={st.pdropDownSelect}
                          name="breakDownType"
                          value={formData.breakDownType}
                          onChange={handleChange}
                          style={{
                            paddingLeft: "5px",
                            marginLeft: "8px",
                            paddingLeft: "20px",
                          }}
                        >
                          <option value="" hidden>
                            select Type
                          </option>
                          <option value="General Service">
                            General Service
                          </option>
                          <option value="Service">Service</option>
                          <option value="Breakdown">Breakdown</option>
                        </select>
                        <p className="text-danger absolute left-[5%]">
                          {errors.breakDownType}
                        </p>
                      </div>
                    </div>
                    <div
                      className={styles.FormItemsMain}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        whiteSpace: "nowrap",
                        justifyContent: "space-between",
                      }}
                    >
                      <FormHeadings text={"Breakdown Time:"} />
                      <div>
                        <input
                          className={st.pdropDownSelect}
                          type="time"
                          placeholder="Enter SubCode"
                          name="breakDownTime"
                          onChange={handleChange}
                          value={formData.breakDownTime}
                        />
                        <p className="text-danger absolute">
                          {errors.breakDownTime}
                        </p>
                      </div>
                    </div>
                    <div
                      className={styles.FormItemsMain}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        whiteSpace: "nowrap",
                        justifyContent: "space-between",
                      }}
                    >
                      <FormHeadings text={"Repaired Time:"} />
                      <div>
                        <input
                          className={st.pdropDownSelect}
                          type="time"
                          placeholder="Enter"
                          name="repairTime"
                          onChange={handleChange}
                          value={formData.repairTime}
                        />
                        <p className="text-danger absolute">
                          {errors.repairTime}
                        </p>
                      </div>
                    </div>
                    <div
                      className={styles.FormItemsMain}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        whiteSpace: "nowrap",
                        justifyContent: "space-between",
                      }}
                    >
                      <FormHeadings text={"Mech.Incharge:"} />
                      <div>
                        <input
                          className={st.pdropDownSelect}
                          type="text"
                          placeholder="Enter Mech Incharge"
                          name="mechIncharge"
                          onChange={handleChange}
                          value={formData.mechIncharge}
                        />
                        <p className="text-danger absolute">
                          {errors.mechIncharge}
                        </p>
                      </div>
                    </div>
                    <div
                      className={styles.FormItemsMain}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        whiteSpace: "nowrap",
                        justifyContent: "space-between",
                      }}
                    >
                      <FormHeadings text={"Action Taken:"} />
                      <div>
                        <textarea
                          name="actionTaken"
                          id="actionTaken"
                          value={formData.actionTaken}
                          onChange={handleChange}
                          rows="3"
                          style={{
                            backgroundColor: "#f3f3f3",
                            border: "1px solid rgba(151, 151, 151, 0.25)",
                            width: "300px",
                          }}
                        ></textarea>
                        <p className="text-danger absolute">
                          {errors.actionTaken}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.formSeperator}></div>
                {(formData.equipmentType === "Vehicle" ||
                  formData.equipmentType === "Machinery") && (
                  <h3
                    className={styles.VMheading}
                    style={{ paddingBottom: "32px" }}
                  >
                    Vehicle / Machinery - Queries Details
                  </h3>
                )}
                {(formData.equipmentType === "Vehicle" ||
                  formData.equipmentType === "Machinery") && (
                  <div className={`${styles.querySetailsDiv} `}>
                    <div className="w-[31%]">
                      <div className="flex items-center justify-between mb-12">
                        <FormHeadings text={"Mechanic:"} />
                        <div>
                          <input
                            type="text"
                            placeholder="Enter Name"
                            style={{
                              backgroundColor: "#f3f3f3",
                              border: "1px solid rgba(151,151,151,0.25)",
                              width: "150px",
                              height: "30px",
                              fontSize: "12px",
                              fontFamily: "Poppins",
                            }}
                            name="mechanic"
                            onChange={handleChange}
                            value={formData.mechanic}
                          />
                          <p className="text-danger absolute w-[150px] text-wrap">
                            {errors.mechanic}
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center justify-between mb-12">
                        <FormHeadings text={"Repeated Problem:"} />
                        <div>
                          <select
                            style={{
                              backgroundColor: "#f3f3f3",
                              border: "1px solid rgba(151,151,151,0.25)",
                              width: "150px",
                              height: "30px",
                              fontSize: "12px",
                              fontFamily: "Poppins",
                              color: "rgba(180,180,180,1)",
                            }}
                            name="repeatedProblem"
                            onChange={handleChange}
                            value={formData.repeatedProblem}
                          >
                            <option value="" hidden>
                              Select Repeated problem
                            </option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                          <p className="text-danger absolute w-[150px] text-wrap">
                            {errors.repeatedProblem}
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center justify-between mb-12">
                        <FormHeadings text={"Fuel Pump Status:"} />
                        <div>
                          <select
                            style={{
                              backgroundColor: "#f3f3f3",
                              border: "1px solid rgba(151,151,151,0.25)",
                              width: "150px",
                              height: "30px",
                              fontSize: "12px",
                              fontFamily: "Poppins",
                              color: "rgba(180,180,180,1)",
                            }}
                            onChange={handleChange}
                            value={formData.fuelPumpStatus}
                            name="fuelPumpStatus"
                          >
                            <option value="" hidden>
                              Select Fuel Pump Status
                            </option>
                            <option value="Ok">Ok</option>
                            <option value="Not Ok">Not Ok</option>
                            <option value="N/A">N/A</option>
                          </select>
                          <p className="text-danger absolute w-[150px] text-wrap">
                            {errors.fuelPumpStatus}
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center justify-between mb-12">
                        <FormHeadings text={"Eng/Motor Status:"} />
                        <div>
                          <select
                            style={{
                              backgroundColor: "#f3f3f3",
                              border: "1px solid rgba(151,151,151,0.25)",
                              width: "150px",
                              height: "30px",
                              fontSize: "12px",
                              fontFamily: "Poppins",
                              color: "rgba(180,180,180,1)",
                            }}
                            name="engineOrMotorStatus"
                            onChange={handleChange}
                            value={formData.engineOrMotorStatus}
                          >
                            <option value="" hidden>
                              Select Eng/Motor Status
                            </option>
                            <option value="Ok">Ok</option>
                            <option value="Not Ok">Not Ok</option>
                            <option value="N/A">N/A</option>
                          </select>
                          <p className="text-danger absolute w-[150px] text-wrap">
                            {errors.engineOrMotorStatus}
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center justify-between mb-12">
                        <FormHeadings text={"Spares Cost:"} />
                        <div>
                          <input
                            type="Number"
                            placeholder="Enter Cost"
                            style={{
                              backgroundColor: "#f3f3f3",
                              border: "1px solid rgba(151,151,151,0.25)",
                              width: "150px",
                              height: "30px",
                              fontSize: "12px",
                              fontFamily: "Poppins",
                            }}
                            name="spareCost"
                            onChange={handleChange}
                            value={formData.spareCost}
                          />
                          <p className="text-danger absolute w-[150px] text-wrap">
                            {errors.spareCost}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="w-[28%]">
                      <div className="flex items-center justify-between mb-12">
                        <FormHeadings text={"1Km/Hr Meter:"} />
                        <div>
                          <input
                            type="text"
                            placeholder="Enter Name"
                            style={{
                              backgroundColor: "#f3f3f3",
                              border: "1px solid rgba(151,151,151,0.25)",
                              width: "150px",
                              height: "30px",
                              fontSize: "12px",
                              fontFamily: "Poppins",
                            }}
                            name="firstKmOrHrMeter"
                            onChange={handleChange}
                            value={formData.firstKmOrHrMeter}
                          />
                          <p className="text-danger absolute w-[150px] text-wrap">
                            {errors.firstKmOrHrMeter}
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center justify-between mb-12">
                        <FormHeadings text={"Leakeges:"} />
                        <div>
                          <select
                            style={{
                              backgroundColor: "#f3f3f3",
                              border: "1px solid rgba(151,151,151,0.25)",
                              width: "150px",
                              height: "30px",
                              fontSize: "12px",
                              fontFamily: "Poppins",
                              color: "rgba(180,180,180,1)",
                            }}
                            onChange={handleChange}
                            name="leakages"
                            value={formData.leakages}
                          >
                            <option value="" hidden>
                              Leakages?
                            </option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                          <p className="text-danger absolute w-[150px] text-wrap">
                            {errors.leakages}
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center justify-between mb-12">
                        <FormHeadings text={"Tyre Stauts:"} />
                        <div>
                          <select
                            style={{
                              backgroundColor: "#f3f3f3",
                              border: "1px solid rgba(151,151,151,0.25)",
                              width: "150px",
                              height: "30px",
                              fontSize: "12px",
                              fontFamily: "Poppins",
                              color: "rgba(180,180,180,1)",
                            }}
                            onChange={handleChange}
                            name="tyreStatus"
                            value={formData.tyreStatus}
                          >
                            <option value="" hidden>
                              Select Tyre Status
                            </option>
                            <option value="Ok">Ok</option>
                            <option value="Not Ok">Not Ok</option>
                            <option value="N/A">N/A</option>
                          </select>
                          <p className="text-danger absolute w-[150px] text-wrap">
                            {errors.tyreStatus}
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center justify-between mb-12">
                        <FormHeadings text={"Battery Status:"} />
                        <div>
                          <select
                            style={{
                              backgroundColor: "#f3f3f3",
                              border: "1px solid rgba(151,151,151,0.25)",
                              width: "150px",
                              height: "30px",
                              fontSize: "12px",
                              fontFamily: "Poppins",
                              color: "rgba(180,180,180,1)",
                            }}
                            onChange={handleChange}
                            name="batteryStatus"
                            value={formData.batteryStatus}
                          >
                            <option value="" hidden>
                              Select Battery Status
                            </option>
                            <option value="Ok">Ok</option>
                            <option value="Not Ok">Not Ok</option>
                            <option value="N/A">N/A</option>
                          </select>
                          <p className="text-danger absolute w-[150px] text-wrap">
                            {errors.batteryStatus}
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center justify-between mb-12">
                        <FormHeadings text={"Service Cost:"} />
                        <div>
                          <input
                            type="text"
                            placeholder="Enter Cost "
                            style={{
                              backgroundColor: "#f3f3f3",
                              border: "1px solid rgba(151,151,151,0.25)",
                              width: "150px",
                              height: "30px",
                              fontSize: "12px",
                              fontFamily: "Poppins",
                            }}
                            onChange={handleChange}
                            name="serviceCost"
                            value={formData.serviceCost}
                          />
                          <p className="text-danger absolute w-[150px] text-wrap">
                            {errors.serviceCost}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="w-[31%]">
                      <div className="flex items-center justify-between mb-12">
                        <FormHeadings text={"2nd Km/Hr Meter:"} />
                        <div>
                          <input
                            type="text"
                            placeholder="Enter Name"
                            style={{
                              backgroundColor: "#f3f3f3",
                              border: "1px solid rgba(151,151,151,0.25)",
                              width: "150px",
                              height: "30px",
                              fontSize: "12px",
                              fontFamily: "Poppins",
                            }}
                            onChange={handleChange}
                            value={formData.secondKmOrHrMeter}
                            name="secondKmOrHrMeter"
                          />
                          <p className="text-danger absolute w-[150px] text-wrap">
                            {errors.secondKmOrHrMeter}
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center justify-between mb-12">
                        <FormHeadings text={"Fuel Pump System:"} />
                        <div>
                          <select
                            style={{
                              backgroundColor: "#f3f3f3",
                              border: "1px solid rgba(151,151,151,0.25)",
                              width: "150px",
                              height: "30px",
                              fontSize: "12px",
                              fontFamily: "Poppins",
                              color: "rgba(180,180,180,1)",
                            }}
                            name="fuelPumpSystem"
                            onChange={handleChange}
                            value={formData.fuelPumpSystem}
                          >
                            <option value="" hidden>
                              Select Fuel Pump System
                            </option>
                            <option value="Ok">Ok</option>
                            <option value="Not Ok">Not Ok</option>
                            <option value="N/A">N/A</option>
                          </select>
                          <p className="text-danger absolute w-[150px] text-wrap">
                            {errors.fuelPumpSystem}
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center justify-between mb-12">
                        <FormHeadings text={"Gas/Oil Condition:"} />
                        <div>
                          <select
                            style={{
                              backgroundColor: "#f3f3f3",
                              border: "1px solid rgba(151,151,151,0.25)",
                              width: "150px",
                              height: "30px",
                              fontSize: "12px",
                              fontFamily: "Poppins",
                              color: "rgba(180,180,180,1)",
                            }}
                            name="gasOrOilCondition"
                            onChange={handleChange}
                            value={formData.gasOrOilCondition}
                          >
                            <option value="" hidden>
                              Select Gas/Oil Condition
                            </option>
                            <option value="Ok">Ok</option>
                            <option value="Not Ok">Not Ok</option>
                            <option value="N/A">N/A</option>
                          </select>
                          <p className="text-danger absolute w-[150px] text-wrap">
                            {errors.gasOrOilCondition}
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center justify-between mb-12">
                        <FormHeadings text={"Radiator Status:"} />
                        <div>
                          <select
                            style={{
                              backgroundColor: "#f3f3f3",
                              border: "1px solid rgba(151,151,151,0.25)",
                              width: "150px",
                              height: "30px",
                              fontSize: "12px",
                              fontFamily: "Poppins",
                              color: "rgba(180,180,180,1)",
                            }}
                            name="radiatorStatus"
                            onChange={handleChange}
                            value={formData.radiatorStatus}
                          >
                            <option value="" hidden>
                              Select Radiator Status
                            </option>
                            <option value="Ok">Ok</option>
                            <option value="Not Ok">Not Ok</option>
                            <option value="N/A">N/A</option>
                          </select>
                          <p className="text-danger absolute w-[150px] text-wrap">
                            {errors.radiatorStatus}
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center justify-between mb-12">
                        <FormHeadings text={"Total Cost:"} />
                        <div>
                          <input
                            type="text"
                            placeholder="Enter Cost"
                            style={{
                              backgroundColor: "#f3f3f3",
                              border: "1px solid rgba(151,151,151,0.25)",
                              width: "150px",
                              height: "30px",
                              fontSize: "12px",
                              fontFamily: "Poppins",
                            }}
                            name="totalCost"
                            onChange={handleChange}
                            value={formData.totalCost}
                          />
                          <p className="text-danger absolute w-[150px] text-wrap">
                            {errors.totalCost}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {(formData.equipmentType === "Vehicle" ||
                  formData.equipmentType === "Machinery") && (
                  <div>
                    <div>
                      <div className="flex mt-6 justify-between w-full">
                        <FormHeadings text={"Spare Used:"} />
                        <div className="relative">
                          <input
                            name="sparesUsed"
                            placeholder="Enter "
                            value={formData.sparesUsed}
                            onChange={handleChange}
                            type="text"
                            style={{
                              width: "860px",
                              height: "30px",
                              fontSize: "12px",
                              border: "1px solid rgba(151,151,151,0.25)",
                              backgroundColor: "#f3f3f3",
                              color: "#b4b4b4",
                            }}
                          />
                          <p className="text-danger absolute ">
                            {errors.sparesUsed}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="flex mt-6 justify-between">
                        <FormHeadings text={"Remarks:"} />
                        <div className="relative">
                          <input
                            name="remarks"
                            placeholder="Enter Remarks"
                            value={formData.remarks}
                            onChange={handleChange}
                            type="text"
                            style={{
                              width: "860px",
                              height: "30px",
                              fontSize: "12px",
                              border: "1px solid rgba(151,151,151,0.25)",
                              backgroundColor: "#f3f3f3",
                              color: "#b4b4b4",
                            }}
                          />
                          <p className="text-danger absolute ">
                            {errors.remarks}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {formData.equipmentType === "Power Tools" && (
                  <h3
                    className={styles.VMheading}
                    style={{ paddingBottom: "32px" }}
                  >
                    Power Tools - Queries Details
                  </h3>
                )}
                {formData.equipmentType === "Power Tools" && (
                  <div className="w-full flex justify-between  gap-4">
                    <div className="w-[46%] ">
                      <div className="flex justify-between  mb-6">
                        <FormHeadings text={"Mechanic:"} />
                        <div>
                          <input
                            type="text"
                            className={st.pdropDownSelect}
                            placeholder="Mechanic"
                            name="mechanic"
                            onChange={handleChange}
                            value={formData.mechanic}
                          />
                          <p className="text-danger absolute">
                            {errors.mechanic}
                          </p>
                        </div>
                      </div>
                      <div className="flex  justify-between mb-6">
                        <FormHeadings text={"Dead Man Switch:"} />
                        <div>
                          <input
                            type="text"
                            className={st.pdropDownSelect}
                            placeholder="Mechanic"
                            name="deadManSwitch"
                            onChange={handleChange}
                            value={formData.deadManSwitch}
                          />
                          <p className="text-danger absolute">
                            {errors.deadManSwitch}
                          </p>
                        </div>
                      </div>
                      <div className="flex  justify-between mb-6">
                        <FormHeadings text={"Repeated Problem:"} />
                        <div>
                          <select
                            type="text"
                            className={st.pdropDownSelect}
                            placeholder=""
                            name="repeatedProblem"
                            onChange={handleChange}
                            value={formData.repeatedProblem}
                          >
                            <option value="">Select Yes/No</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                          <p className="text-danger absolute">
                            {errors.repeatedProblem}
                          </p>
                        </div>
                      </div>
                      <div className="flex  justify-between mb-6">
                        <FormHeadings text={"Spares Cost:"} />
                        <div>
                          <input
                            type="text"
                            className={st.pdropDownSelect}
                            placeholder="Mechanic"
                            name="spareCost"
                            onChange={handleChange}
                            value={formData.spareCost}
                          />
                          <p className="text-danger absolute">
                            {errors.spareCost}
                          </p>
                        </div>
                      </div>
                      <div className="flex  justify-between mb-6">
                        <FormHeadings text={"Total Cost:"} />
                        <div>
                          <input
                            type="text"
                            className={st.pdropDownSelect}
                            placeholder="Mechanic"
                            name="totalCost"
                            onChange={handleChange}
                            value={formData.totalCost}
                          />
                          <p className="text-danger absolute">
                            {errors.totalCost}
                          </p>
                        </div>
                      </div>
                      <div className="flex  justify-between mb-6">
                        <FormHeadings text={"Remarks:"} />
                        <div>
                          <input
                            type="text"
                            className={st.pdropDownSelect}
                            placeholder="remarks"
                            name="remarks"
                            onChange={handleChange}
                            value={formData.remarks}
                            style={{ height: "40px" }}
                          />
                          <p className="text-danger absolute">
                            {errors.remarks}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="w-[53%]">
                      <div className="flex justify-between  mb-6">
                        <FormHeadings text={"Machine Handle Condition:"} />
                        <div>
                          <select
                            type="text"
                            className={st.pdropDownSelect}
                            name="machineHandleCondition"
                            onChange={handleChange}
                            value={formData.machineHandleCondition}
                          >
                            <option value="" hidden>
                              Select option
                            </option>
                            <option value="Ok">Ok</option>
                            <option value="Not Ok">Not Ok</option>
                            <option value="N/A">N/A</option>
                          </select>
                          <p className="text-danger absolute">
                            {errors.machineHandleCondition}
                          </p>
                        </div>
                      </div>
                      <div className="flex justify-between  mb-6">
                        <FormHeadings text={"Machine Cable Condition:"} />
                        <div>
                          <select
                            type="text"
                            className={st.pdropDownSelect}
                            placeholder="Mechanic"
                            name="machineCableCondition"
                            onChange={handleChange}
                            value={formData.machineCableCondition}
                          >
                            <option value="" hidden>
                              Select Cable Condition
                            </option>
                            <option value="Ok">Ok</option>
                            <option value="Not Ok">Not Ok</option>
                            <option value="N/A">N/A</option>
                          </select>
                          <p className="text-danger absolute">
                            {errors.machineCableCondition}
                          </p>
                        </div>
                      </div>
                      <div className="flex justify-between  mb-6">
                        <FormHeadings text={"Wheel guard Condition:"} />
                        <div>
                          <select
                            type="text"
                            className={st.pdropDownSelect}
                            placeholder="Mechanic"
                            name="wheelGuardCondition"
                            onChange={handleChange}
                            value={formData.wheelGuardCondition}
                          >
                            <option value="" hidden>
                              Select Cable Condition
                            </option>
                            <option value="Ok">Ok</option>
                            <option value="Not Ok">Not Ok</option>
                            <option value="N/A">N/A</option>
                          </select>
                          <p className="text-danger absolute">
                            {errors.wheelGuardCondition}
                          </p>
                        </div>
                      </div>
                      <div className="flex justify-between  mb-6">
                        <FormHeadings text={"Service Cost:"} />
                        <div>
                          <input
                            type="text"
                            className={st.pdropDownSelect}
                            placeholder="Enter Cost "
                            name="serviceCost"
                            onChange={handleChange}
                            value={formData.serviceCost}
                          />
                          <p className="text-danger absolute">
                            {errors.serviceCost}
                          </p>
                        </div>
                      </div>
                      <div className="flex justify-between  mb-6">
                        <FormHeadings text={"Spares Used:"} />
                        <div>
                          <input
                            type="text"
                            className={st.pdropDownSelect}
                            placeholder="Enter"
                            name="sparesUsed"
                            onChange={handleChange}
                            value={formData.sparesUsed}
                          />
                          <p className="text-danger absolute">
                            {errors.sparesUsed}
                          </p>
                        </div>
                      </div>
                      <div className="flex justify-between  mb-6">
                        <FormHeadings text={"Document Attached:"} />
                        <div>
                          <input
                            type="file"
                            placeholder="Mechanic"
                            name="documentAttached"
                            onChange={handlefileChange}
                            style={{
                              width: "290px",
                              border: "1px solid rgba(151,151,151,0.25)",
                              backgroundColor: "#f3f3f3",
                              color: "#b4b4b4",
                              fontSize: "12px",
                              height: "40px",
                              paddingTop: "0px !important",
                              paddingBottom: "0px !important"
                            }}
                          />
                          <p className="text-danger absolute">
                            {errors.documentAttached}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {formData.equipmentType === "Distribution Board" && (
                  <h3
                    className={styles.VMheading}
                    style={{ paddingBottom: "32px" }}
                  >
                    Distribution Board - Queries Details
                  </h3>
                )}
                {formData.equipmentType === "Distribution Board" && (
                  <div className="w-full flex justify-between   ">
                    <div className="w-[46%] ">
                      <div className="flex justify-between  mb-6">
                        <FormHeadings text={"Mechanic:"} />
                        <div>
                          <input
                            type="text"
                            className={st.pdropDownSelect}
                            placeholder="Mechanic"
                            name="mechanic"
                            onChange={handleChange}
                            value={formData.mechanic}
                          />
                          <p className="text-danger absolute">
                            {errors.mechanic}
                          </p>
                        </div>
                      </div>
                      <div className="flex  justify-between mb-6">
                        <FormHeadings text={"Body Earthing:"} />
                        <div>
                          <input
                            type="text"
                            className={st.pdropDownSelect}
                            placeholder="Mechanic"
                            name="bodyEarthing"
                            onChange={handleChange}
                            value={formData.bodyEarthing}
                          />
                          <p className="text-danger absolute">
                            {errors.bodyEarthing}
                          </p>
                        </div>
                      </div>
                      <div className="flex  justify-between mb-6">
                        <FormHeadings text={"Repeated Problem:"} />
                        <div>
                          <input
                            type="text"
                            className={st.pdropDownSelect}
                            placeholder="Enter"
                            name="repeatedProblem"
                            onChange={handleChange}
                            value={formData.repeatedProblem}
                          />
                          <p className="text-danger absolute">
                            {errors.repeatedProblem}
                          </p>
                        </div>
                      </div>
                      <div className="flex  justify-between mb-6">
                        <FormHeadings text={"Spares Cost:"} />
                        <div>
                          <input
                            type="text"
                            className={st.pdropDownSelect}
                            placeholder="Enter Cost "
                            name="spareCost"
                            onChange={handleChange}
                            value={formData.spareCost}
                          />
                          <p className="text-danger absolute">
                            {errors.spareCost}
                          </p>
                        </div>
                      </div>
                      <div className="flex  justify-between mb-6">
                        <FormHeadings text={"Total Cost:"} />
                        <div>
                          <input
                            type="text"
                            className={st.pdropDownSelect}
                            placeholder="Enter Cost "
                            name="totalCost"
                            onChange={handleChange}
                            value={formData.totalCost}
                          />
                          <p className="text-danger absolute">
                            {errors.totalCost}
                          </p>
                        </div>
                      </div>
                      <div className="flex  justify-between mb-6">
                        <FormHeadings text={"Remarks:"} />
                        <div>
                          <input
                            type="text"
                            className={st.pdropDownSelect}
                            placeholder="remarks"
                            name="remarks"
                            onChange={handleChange}
                            value={formData.remarks}
                            style={{ height: "40px" }}
                          />
                          <p className="text-danger absolute">
                            {errors.remarks}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="w-[52%]">
                      <div className="flex justify-between  mb-6">
                        <FormHeadings text={"MCB & RCB Condition:"} />
                        <div>
                          <select
                            type="text"
                            className={st.pdropDownSelect}
                            name="mcbAndRcbCondition"
                            onChange={handleChange}
                            value={formData.mcbAndRcbCondition}
                          >
                            <option value="" hidden>
                              Select option
                            </option>
                            <option value="Ok">Ok</option>
                            <option value="Not Ok">Not Ok</option>
                            <option value="N/A">N/A</option>
                          </select>
                          <p className="text-danger absolute">
                            {errors.mcbAndRcbCondition}
                          </p>
                        </div>
                      </div>
                      <div className="flex justify-between  mb-6">
                        <FormHeadings text={"3 Phase Indicator Light:"} />
                        <div>
                          <select
                            type="text"
                            className={st.pdropDownSelect}
                            placeholder="Mechanic"
                            name="threePhaseIndicatorLight"
                            onChange={handleChange}
                            value={formData.threePhaseIndicatorLight}
                          >
                            <option value="" hidden>
                              Select
                            </option>
                            <option value="Ok">Ok</option>
                            <option value="Not Ok">Not Ok</option>
                            <option value="N/A">N/A</option>
                          </select>
                          <p className="text-danger absolute">
                            {errors.threePhaseIndicatorLight}
                          </p>
                        </div>
                      </div>
                      <div className="flex justify-between  mb-6">
                        <FormHeadings text={"Voltage Indicator Display:"} />
                        <div>
                          <select
                            type="text"
                            className={st.pdropDownSelect}
                            name="voltageIndicatorDisplay"
                            onChange={handleChange}
                            value={formData.voltageIndicatorDisplay}
                          >
                            <option value="" hidden>
                              Select
                            </option>
                            <option value="Ok">Ok</option>
                            <option value="Not Ok">Not Ok</option>
                            <option value="N/A">N/A</option>
                          </select>
                          <p className="text-danger absolute">
                            {errors.voltageIndicatorDisplay}
                          </p>
                        </div>
                      </div>
                      <div className="flex justify-between  mb-6">
                        <FormHeadings text={"Service Cost:"} />
                        <div>
                          <input
                            type="text"
                            className={st.pdropDownSelect}
                            placeholder="Enter Cost "
                            name="serviceCost"
                            onChange={handleChange}
                            value={formData.serviceCost}
                          />
                          <p className="text-danger absolute">
                            {errors.serviceCost}
                          </p>
                        </div>
                      </div>
                      <div className="flex justify-between  mb-6">
                        <FormHeadings text={"Spare Used:"} />
                        <div>
                          <input
                            type="text"
                            className={st.pdropDownSelect}
                            placeholder="Enter Cost"
                            name="spareUsed"
                            onChange={handleChange}
                            value={formData.sparesUsed}
                          />
                          <p className="text-danger absolute">
                            {errors.sparesUsed}
                          </p>
                        </div>
                      </div>
                      <div className="flex justify-between  mb-6">
                        <FormHeadings text={"Document Attached:"} />
                        <div>
                          <input
                            type="file"
                            placeholder="Mechanic"
                            name="documentAttached"
                            onChange={handlefileChange}
                            style={{
                              width: "300px",
                              border: "1px solid rgba(151,151,151,0.25)",
                              backgroundColor: "#f3f3f3",
                              color: "#b4b4b4",
                              fontSize: "12px",
                              height: "40px",
                            }}
                          />
                          <p className="text-danger absolute">
                            {errors.documentAttached}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {(formData.equipmentType === "Vehicle" ||
                formData.equipmentType === "Machinery") && (
                <div className="flex mt-8 gap-4">
                  <FormHeadings text={"Document Attached:"} />
                  <div>
                    <input
                      type="file"
                      name="documentAttached"
                      onChange={handlefileChange}
                      style={{
                        width: "325px",
                        border: "1px solid rgba(151,151,151,0.25)",
                        backgroundColor: "#f3f3f3",
                        color: "#b4b4b4",
                        fontSize: "12px",
                        height: "40px",
                      }}
                    />
                    <p className="text-danger absolute">
                      {errors.documentAttached}
                    </p>
                  </div>
                </div>
              )}
              <div style={{ paddingTop: "32px" }}>
                <button type="submit" className={styles.submitBtn}>
                  Upload
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BreakdownReport;
