import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { WebApimanager } from "../../../WebApiManager";
import { siteId } from "../../../atoms";
import { useRecoilState } from "recoil";
import styles from "./Vehicle&Machinery.module.css";
import st from "./PowerTools.module.css";
import FormItem from "../PNMAssets/FormItem";
import FormHeadings from "../PNMAssets/FormHeadings";

const PowerTools = () => {
  let webApi = new WebApimanager();
  //equipmentType
  const [formData, setFormData] = useState({
    type: "",
    siteName: "",
    assetCode: "",
    equipmentType: "Power Tools",
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    differenceTime: "",
    differenceDate: "",
    oil: "",
    oilIn: "",
    transitionDate: "",
    scopeOfWorkDetails: "",
    location: "",
    remarks: "",
    subCode: "",

    fuelType: "",
    debitNote: "",
    employeeType: "",
    employeeId: "",
    debitAmount: "",
    debitReason: "",
    employeeType: "",
    employeeId: "",
  });

  const [errors, setErrors] = useState({});
  const [assetCodeData, setAssetCodeData] = useState([]);
  const [nameData, setNameData] = useState([]);
  const [showInputs, setShowInputs] = useState(false);
  const [showdebitInputs, setShowDebitInputs] = useState(false);
  const [site, setSite] = useRecoilState(siteId);
  const [sites, setDatasites] = useState([]);
  const [id, setId] = useState();

  function calculateDateDifference(startDate, endDate) {
    const [startYear, startMonth, startDay] = startDate.split("-").map(Number);
    const [endYear, endMonth, endDay] = endDate.split("-").map(Number);
    console.log(startYear, endYear);
    console.log(startMonth, endMonth);
    console.log(startDay, endDay);

    const start = new Date(startYear, startMonth - 1, startDay);
    const end = new Date(endYear, endMonth - 1, endDay);

    if (end >= start) {
      const diffTime = end.getTime() - start.getTime();
      const diffDays = diffTime / (1000 * 60 * 60 * 24);
      return Math.round(diffDays);
    } else {
      return -1;
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newFormData = {
      ...formData,
      [name]: value,
    };

    if (name === "assetCode" && value) {
      const assCode = assetCodeData.find(
        (doc, ind) => doc.assetCode === value.split("/")[0]
      );
      if (assCode) {
        setId(assCode._id);
        console.log(assCode.name);
        newFormData = {
          ...newFormData,
          ["subCode"]: assCode.subCode,
          // ["equipmentType"]: assCode.type,
        };
        setFormData(newFormData);
      }
    }

    setFormData(newFormData);

    // Calculate meter difference

    if (name === "startDate" || name === "endDate") {
      if (newFormData.startDate !== "" && newFormData.endDate !== "") {
        const diff = calculateDateDifference(
          newFormData.startDate,
          newFormData.endDate
        );
        setFormData({ ...newFormData, differenceDate: diff });
        if (diff < 0) {
          errors.differenceDate = "start date should be less than end Date";
          setFormData({
            ...newFormData,
            differenceDate: "",
          });
        } else {
          setFormData({ ...newFormData, differenceDate: diff });
          errors.differenceDate = "";
        }
      } else {
        setFormData({ ...newFormData, differenceDate: "" });
      }
    }

    // Calculate Engine2 readings difference

    // Calculate time difference
    if (name === "startTime" || name === "endTime") {
      if (newFormData.startTime && newFormData.endTime) {
        const start = new Date(`1970-01-01T${newFormData.startTime}`);
        const end = new Date(`1970-01-01T${newFormData.endTime}`);
        let difference = (end.getTime() - start.getTime()) / 1000; // Difference in seconds

        const hours = Math.floor(difference / 3600);
        difference = difference % 3600;
        const minutes = Math.floor(difference / 60);

        const formattedHours = hours < 10 ? `0${hours}` : hours;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        const formattedDifference = `${formattedHours}:${formattedMinutes}`;
        if (start >= end) {
          errors.differenceTime = "Start Time should be less than End Date";
          setFormData({
            ...newFormData,
            differenceTime: "",
          });
        } else {
          errors.differenceTime = "";
        }
        setFormData({
          ...newFormData,
          differenceTime: formattedDifference,
        });
      } else {
        setFormData({
          ...newFormData,
          differenceTime: "",
        });
      }
    }
  };
  const filteredNames = assetCodeData.filter(
    (item) => item._id === formData.assetCode
  );
  const filteredSubcodes = assetCodeData.filter(
    (item) => item._id === formData.assetCode && formData.name
  );

  const handleSiteChange = (e) => {
    const { name, value } = e.target;

    setSite(e.target.value);
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    webApi
      .get("api/sites/getAllSitesInfo")
      .then((response) => {
        console.log("API response:", response);
        if (response.data && Array.isArray(response.data.sites)) {
          setDatasites(response.data.sites);
          if (site === "") {
            setSite(response.data.sites[0]._id);
          }
        } else {
          console.error("Unexpected data format:", response.data);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, [site]);

  const matchedSite = sites.find((siteItem) => siteItem._id === site);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = {};
    const requiredFields = [
      "type",
      "equipmentType",
      "siteName",
      "assetCode",
      "startDate",
      "endDate",
      "differenceDate",
      "startTime",
      "endTime",
      "differenceTime",
      "oil",
      "oilIn",
      "transitionDate",
      "scopeOfWorkDetails",
      "location",
      "remarks",
      "subCode",
      "fuelType",
      "debitNote",
    ];

    requiredFields.forEach((key) => {
      if (!formData[key]) {
        formErrors[key] = `${key} is required`;
      }
    });

    if (formData.debitNote === "Yes") {
      const conditionalDebitNoteFields = [
        "employeeType",
        "employeeId",
        "debitAmount",
        "debitReason",
      ];
      conditionalDebitNoteFields.forEach((key) => {
        if (!formData[key]) {
          formErrors[key] = `${key} is required`;
        }
      });
    }

    // if (formData.workareadetails === "yes") {
    //   const conditionalWorkAreaFields = ["floor", "flat", "room"];
    //   conditionalWorkAreaFields.forEach((key) => {
    //     if (!formData[key]) {
    //       formErrors[key] = `${key} is required`;
    //     }
    //   });
    // }

    if (Object.keys(formErrors).length > 0) {
      console.log("Form errors:", formErrors); // Log form errors for debugging
      setErrors(formErrors);
    } else {
      console.log(formData);
      const tempFormData = {
        siteName: site,
        type: formData.type,
        assetCode: id,
        equipmentType: formData.equipmentType,
        employeeType: formData.employeeType,
        employeeId: formData.employeeId,
        startDate: formData.startDate,
        endDate: formData.endDate,
        differenceDate: formData.differenceDate,
        startTime: formData.startTime,
        endTime: formData.endTime,
        differenceTime: formData.differenceTime,
        oil: Number(formData.oil),
        oilIn: formData.oilIn,
        transitionDate: formData.transitionDate,
        scopeOfWorkDetails: formData.scopeOfWorkDetails,
        location: formData.location,
        remarks: formData.remarks,
        subCode: formData.subCode,
        fuelType: formData.fuelType,
        debitAmount: Number(formData.debitAmount),
        debitReason: formData.debitReason,
        debitNote: formData.debitNote || "No",
        employeeType: formData.employeeType,
        employeeId: formData.employeeId,
      };
      console.log(tempFormData);
      const response = await webApi.post("api/dailyLogReport/pt", tempFormData);
      console.log(response, "ressssssss");
      // window.location.reload();
    }
  };

  useEffect(() => {
    const fetchAssetCodeData = async () => {
      let response = await webApi.get(`api/pnms/assetCode`);
      setAssetCodeData(response.data.data.AssetCodes);
    };

    // const fetchSiteData = async () => {
    //   let response = await webApi.get(`api/sites/AllSites`)
    //   console.log("dd", response.data.sites)
    //   setNameData(response.data.sites)
    // }

    fetchAssetCodeData();
    // fetchSiteData()
  }, []);

  return (
    <>
      <div className={styles.VMmainDiv}>
        <div className={styles.PTheading}>
          <h3 className={styles.VMheading} style={{ marginBottom: "30px" }}>
            Daily Log Report For PowerTools
          </h3>
          <div style={{ display: "flex" }}>
            <form onSubmit={handleSubmit}>
              <div>
                <div className={styles.FormItemsMain}>
                  <FormHeadings text={"Site Name:"} />
                  <div className="relative">
                    <select
                      className={st.pdropDownSelect}
                      name="siteName"
                      value={formData.siteName}
                      onChange={handleSiteChange}
                      style={{
                        marginLeft: "18px",
                        width: "825px",
                        paddingLeft: "20px",
                      }}
                    >
                      <option value="" hidden>
                        Select a site
                      </option>
                      {sites &&
                        Array.isArray(sites) &&
                        sites.map((item) => (
                          <option value={item._id} key={item._id}>
                            {item.siteName}
                          </option>
                        ))}
                    </select>
                    <p className=" text-danger absolute left-[2%]">
                      {errors.siteName}
                    </p>
                  </div>
                </div>
                <div className={styles.formSecondryFields}>
                  <div>
                    <div
                      className={styles.FormItemsMain}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        whiteSpace: "nowrap",
                        justifyContent: "space-between",
                      }}
                    >
                      {/* <FormItem
                        label="Transition ID:"
                        name="transitionId"
                        value=""
                        onChange={handleChange}
                        style={{
                          paddingLeft: "20px",
                          paddingLeft: "20px",
                          width: "300px",
                        }}
                        readOnly
                      /> */}
                      <FormHeadings text={"Transition ID:"} />
                      <input
                        type="text"
                        className={st.pdropDownSelect}
                        name="transitionID"
                        readonly
                      />
                    </div>

                    <div
                      className={styles.FormItemsMain}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {/* <p style={{ marginRight: '5px' }}>Asset Code:</p> */}
                      <FormHeadings text={"Equipment Type:"} />
                      <div className="relative">
                        <input
                          className={st.pdropDownSelect}
                          name="equipmentType"
                          value={formData.equipmentType}
                          style={{
                            paddingLeft: "5px",
                            marginLeft: "8px",
                            paddingLeft: "20px",
                          }}
                          readOnly
                        />
                        <p className="text-danger absolute left-[2%]">
                          {errors.assetCode}
                        </p>
                      </div>
                    </div>
                    <div
                      className={styles.FormItemsMain}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        whiteSpace: "nowrap",
                        justifyContent: "space-between",
                      }}
                    >
                      <FormHeadings text={"Type:"} />
                      <div className="relative">
                        <select
                          className={st.pdropDownSelect}
                          name="type"
                          value={formData.type}
                          onChange={handleChange}
                          style={{
                            paddingLeft: "5px",
                            marginLeft: "8px",
                            paddingLeft: "20px",
                          }}
                        >
                          <option value="" hidden>
                            select Type
                          </option>
                          <option value="Own">Own</option>
                          <option value="Hire">Hire</option>
                        </select>
                        <p className="text-danger absolute left-[2%]">
                          {errors.type}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div>
                      <div
                        className={`${styles.FormItemsMain} justify-between`}
                      >
                        <FormHeadings text={"Transition Date:"} />
                        <div>
                          <input
                            type="date"
                            className={st.pdropDownSelect}
                            style={{ paddingLeft: "20px" }}
                            name="transitionDate"
                            value={formData.transitionDate}
                            onChange={handleChange}
                            placeholder="---------"
                          />
                          <p className="text-danger absolute">
                            {errors.transitionDate}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="flex mt-6 justify-between ">
                      <FormHeadings text={"Asset code/Name:"} />
                      <div>
                        <select
                          className={st.pdropDownSelect}
                          name="assetCode"
                          value={formData.assetCode}
                          onChange={handleChange}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            whiteSpace: "nowrap",
                            justifyContent: "space-between",
                          }}
                        >
                          <option value="" hidden>
                            Select name
                          </option>
                          {assetCodeData.length &&
                            assetCodeData
                              .filter((item) => item.type === "Power Tools")
                              .map((item) => (
                                <option
                                  value={item.assetCode + "/" + item.name}
                                  key={item._id}
                                >
                                  {item.assetCode + "/" + item.name}
                                </option>
                              ))}
                        </select>
                        <p className="text-danger absolute">
                          {errors.assetCode}
                        </p>
                      </div>
                    </div>
                    <div
                      className={styles.FormItemsMain}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        whiteSpace: "nowrap",
                        justifyContent: "space-between",
                      }}
                    >
                      <FormHeadings text={"Sub code:"} />
                      <div>
                        <input
                          className={st.pdropDownSelect}
                          type="text"
                          placeholder="Enter SubCode"
                          name="subCode"
                          onChange={handleChange}
                          value={formData.subCode}
                        />
                        <p className="text-danger absolute">{errors.subCode}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.formSeperator}></div>
                <h3
                  className={styles.VMheading}
                  style={{ paddingBottom: "0", paddingLeft: "0px" }}
                >
                  Issued To
                </h3>
                <div className={styles.VMFormSecondsection}>
                  <div className={`${styles.formSecondryFields} w-full mb-5`}>
                    <div className={`${styles.FormItemsMain} `}>
                      <FormHeadings text={"Employee Type:"} />
                      <div className="relative">
                        <input
                          name="employeeType"
                          placeholder="Enter Employee Type"
                          value={formData.employeeType}
                          onChange={handleChange}
                          style={{ paddingLeft: "20px" }}
                          className={styles.dropDownSelect}
                        />
                        <p className="text-danger absolute">
                          {errors.employeeType}
                        </p>
                      </div>
                    </div>
                    <div className={`${styles.FormItemsMain} `}>
                      <FormHeadings text={"Employee ID:"} />
                      <div>
                        <input
                          placeholder="Enter employee Id"
                          name="employeeId"
                          value={formData.employeeId}
                          style={{ paddingLeft: "20px" }}
                          onChange={handleChange}
                          className={styles.dropDownSelect}
                        />
                        <p className="text-danger absolute">
                          {errors.employeeId}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.VWFormSecond} w-full mb-10`}>
                    <div className="w-[10%]">
                      <p
                        className={styles.VMsecondheading}
                        style={{ fontWeight: "500", textAlign: "left" }}
                      >
                        Date
                      </p>
                    </div>
                    <div
                      className="flex justify-between"
                      style={{ width: "90%" }}
                    >
                      <div className={styles.FormItemsSecondry}>
                        <label className="form-label fs-6"></label>
                        <FormHeadings text={"Start:"} />
                        <div>
                          <input
                            placeholder="Enter Date"
                            type="date"
                            className={styles.dropDownSelectSeconf}
                            name="startDate"
                            value={formData.startDate}
                            onChange={handleChange}
                          />
                          <p className="text-danger absolute">
                            {errors.startDate}
                          </p>
                        </div>
                      </div>
                      <div className={styles.FormItemsSecondry}>
                        <label className="form-label fs-6"></label>
                        <FormHeadings text={"End:"} />
                        <div>
                          <input
                            placeholder="Enter"
                            type="date"
                            className={styles.dropDownSelectSeconf}
                            name="endDate"
                            value={formData.endDate}
                            onChange={handleChange}
                          />
                          <p className="text-danger absolute">
                            {errors.endDate}
                          </p>
                        </div>
                      </div>
                      <div className={styles.FormItemsSecondry}>
                        <label className="form-label fs-6"></label>
                        <FormHeadings text={"Difference:"} />
                        <div>
                          <input
                            type="number"
                            className={styles.dropDownSelectSeconf}
                            name="differenceDate"
                            value={formData.differenceDate}
                            onChange={handleChange}
                          />
                          <p className="text-danger absolute">
                            {errors.differenceDate}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.VWFormSecond} w-full mb-10`}>
                    <div className="w-[10%]">
                      <p
                        className={styles.VMsecondheading}
                        style={{ fontWeight: "500", textAlign: "left" }}
                      >
                        Clock
                      </p>
                    </div>
                    <div
                      className="flex justify-between"
                      style={{ width: "90%", paddingLeft: "24.5px" }}
                    >
                      <div className={styles.FormItemsSecondry}>
                        <label className="form-label fs-6"></label>
                        <FormHeadings text={"In:"} />
                        <div>
                          <input
                            placeholder="Enter Date"
                            type="time"
                            className={styles.dropDownSelectSeconf}
                            name="startTime"
                            value={formData.startTime}
                            onChange={handleChange}
                          />
                          <p className="text-danger absolute">
                            {errors.startTime}
                          </p>
                        </div>
                      </div>
                      <div className={styles.FormItemsSecondry}>
                        <label className="form-label fs-6"></label>
                        <FormHeadings text={"Out:"} />
                        <div>
                          <input
                            placeholder="Enter"
                            type="time"
                            className={styles.dropDownSelectSeconf}
                            name="endTime"
                            value={formData.endTime}
                            onChange={handleChange}
                          />
                          <p className="text-danger absolute">
                            {errors.endTime}
                          </p>
                        </div>
                      </div>
                      <div className={styles.FormItemsSecondry}>
                        <label className="form-label fs-6"></label>
                        <FormHeadings text={"Difference:"} />
                        <div>
                          <input
                            type="time"
                            className={styles.dropDownSelectSeconf}
                            name="differenceTime"
                            value={formData.differenceTime}
                            onChange={handleChange}
                          />
                          <p className="text-danger absolute">
                            {errors.differenceTime}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.formSeperator}></div>
                <div className={styles.formSecondryFields}>
                  <div>
                    <div className={styles.FormItemsMain}>
                      <FormHeadings text={"Fuel Type"} />
                      <div className="relative">
                        <select
                          className={styles.dropDownSelect}
                          name="fuelType"
                          value={formData.fuelType}
                          onChange={handleChange}
                          style={{ marginLeft: "24px", paddingLeft: "20px" }}
                        >
                          <option value="">Select Fuel Type</option>
                          <option value="Diesel">Diesel</option>
                          <option value="Petrol">Petrol</option>
                        </select>
                        <p className="text-danger absolute left-[7%]">
                          {errors.fuelType}
                        </p>
                      </div>
                    </div>

                    <div className={styles.FormItemsMain}>
                      <FormHeadings text={"Debit Note:"} />
                      <div className="relative">
                        <select
                          className={styles.dropDownSelect}
                          name="debitNote"
                          value={formData.debitNote || "No"}
                          onChange={handleChange}
                          style={{ marginLeft: "14px", paddingLeft: "20px" }}
                        >
                          <option value="No">No</option>
                          <option value="Yes">Yes</option>
                        </select>
                        <p className="text-danger absolute left-[5%]">
                          {errors.debitNote}
                        </p>
                      </div>
                    </div>

                    {/* Conditionally render these fields based on the debitNote state */}
                    {formData.debitNote === "Yes" && (
                      <>
                        <div className={`${styles.FormItemsMain} mb-3`}>
                          <FormHeadings text={"Employee:"} />
                          <div className="relative">
                            <input
                              placeholder="Enter"
                              type="text"
                              className={styles.dropDownSelect}
                              name="IssuedEmployeeType"
                              value={formData.employeeType}
                              onChange={handleChange}
                              style={{ paddingLeft: "20px" }}
                            />
                            <p className="text-danger absolute">
                              {errors.employeeType}
                            </p>
                          </div>
                        </div>

                        <div className={styles.FormItemsMain}>
                          <FormHeadings text={"Debit Reason:"} />
                          <div>
                            <input
                              className={styles.dropDownSelect}
                              placeholder="Enter Debit Reason"
                              name="debitReason"
                              value={formData.debitReason}
                              onChange={handleChange}
                              style={{
                                paddingLeft: "20px",
                              }}
                            />
                            <p className="text-danger absolute">
                              {errors.debitReason}
                            </p>
                          </div>
                        </div>
                      </>
                    )}
                  </div>

                  <div>
                    <div
                      className={`${styles.FormItemsMain}  flex gap-5 mb-[79px]`}
                    >
                      <FormHeadings text={"Issued oil:"} />
                      <div>
                        <input
                          type="number"
                          className={
                            formData.oil
                              ? "w-[200px] h-[30px] bg-[#f3f3f3] "
                              : styles.dropDownSelect
                          }
                          name="oil"
                          placeholder="Enter Number"
                          value={formData.oil}
                          onChange={handleChange}
                          style={{ marginLeft: "-10px" }}
                        />
                        <p className="text-danger absolute">{errors.oil}</p>
                      </div>
                      {formData.oil && (
                        <div className="relative">
                          <select
                            className={`h-[30px] w-[100px] bg-[#f3f3f3] text-[#b4b4b4] text-[12px] ${
                              formData.debitNote === "Yes"
                                ? "ml-[-78px]"
                                : "ml-[-48px]"
                            }`}
                            name="oilIn"
                            value={formData.oilIn}
                            onChange={handleChange}
                          >
                            <option value="" hidden>
                              Litre/Gallon
                            </option>
                            <option value="Litre">Ltr</option>
                            <option value="Gallon">Gal</option>
                          </select>
                          <p className="text-danger absolute right-0">
                            {errors.oilIn}
                          </p>
                        </div>
                      )}
                    </div>
                    {formData.debitNote === "Yes" && (
                      <>
                        <div
                          className={`${styles.FormItemsMain} `}
                          // style={{ paddingTop: "80px" }}
                        >
                          <FormHeadings text={"Employee ID:"} />
                          <div>
                            <input
                              placeholder="Enter Employee ID"
                              type="text"
                              className={styles.dropDownSelect}
                              name="employeeId"
                              value={formData.employeeId}
                              onChange={handleChange}
                              style={{ paddingLeft: "20px" }}
                            />
                            <p className="text-danger absolute">
                              {errors.employeeId}
                            </p>
                          </div>
                        </div>

                        <div>
                          <div className={`${styles.FormItemsMain} gap-3 `}>
                            <FormHeadings text={"Debit amount:"} />
                            <div>
                              <input
                                type="number"
                                placeholder="Enter Debit Amount"
                                className={styles.dropDownSelect}
                                name="debitAmount"
                                value={formData.debitAmount}
                                onChange={handleChange}
                                style={{ marginLeft: "-10px" }}
                              />
                              <p className="text-danger absolute">
                                {errors.debitAmount}
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className={styles.formSeperator}></div>
                <div className={`${styles.FormItemsMain} my-3`}>
                  <FormHeadings text={"Scope of work Details:"} />
                  <div>
                    <input
                      type="text"
                      placeholder="Enter Scope of work"
                      className={styles.dropDownSelect}
                      name="scopeOfWorkDetails"
                      value={formData.scopeOfWorkDetails}
                      onChange={handleChange}
                      style={{ width: "792px", paddingLeft: "20px" }}
                    />
                    <p className="text-danger absolute">
                      {errors.scopeOfWorkDetails}
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div className={`${styles.FormItemsMain} my-3`}>
                    <FormHeadings text={"Location:"} />
                    <div className="relative">
                      <input
                        type="text"
                        className={styles.dropDownSelect}
                        name="location"
                        placeholder="Enter Work Location"
                        value={formData.location}
                        onChange={handleChange}
                        style={{ marginLeft: "80px", paddingLeft: "20px" }}
                      />
                      <p className="text-danger absolute left-[20%]">
                        {errors.location}
                      </p>
                    </div>
                  </div>
                  <div className={`${styles.FormItemsMain} my-3 `}>
                    <FormHeadings text={"Remarks:"} />
                    <div>
                      <input
                        type="text"
                        className={styles.dropDownSelect}
                        name="remarks"
                        placeholder="Enter Remarks"
                        value={formData.remarks}
                        onChange={handleChange}
                      />
                      <p className="text-danger absolute">{errors.remarks}</p>
                    </div>
                  </div>
                </div>
                <div style={{ paddingTop: "32px" }}>
                  <button type="submit" className={styles.submitBtn}>
                    SUBMIT
                  </button>
                </div>
                {/* <div style={{ padding: '20px' }}> */}
                {/* <div className="row mb-2 d-flex justify-content-around"> */}
                {/* <div className="col-md-5 mb-3">
          <label className="form-label fs-6 ">Site Name:</label>
          <select className="form-select " name="sitename" value={formData.sitename} onChange={handleChange}>
            <option value="">Select Site Name</option>
            {nameData && Array.isArray(nameData) && nameData.map(item => (
              <option value={item._id} key={item._id}>{item.siteName}</option>
            ))}
          </select>
          <p className="text-danger absolute">{errors.sitename}</p>
        </div> */}
                {/* <div className="col-md-5 mb-3">
          <label className="form-label fs-6 ">Transition ID:</label>
          <input type="text" className="form-control" name="transitionId" placeholder="---------" readOnly />
        </div> */}
                {/* <div className="col-md-5 mb-3">
          <label className="form-label fs-6">Transition Date:</label>
          <input type="date" className="form-control" name="transitionDate" value={formData.transitionDate} onChange={handleChange} />
          <p className="text-danger">{errors.transitionDate}</p>
        </div> */}
                {/* <div className="col-md-5 mb-3">
          <label className="form-label fs-6">Type:</label>
          <select className="form-select" name="type" value={formData.type} onChange={handleChange}>
            <option value="">Select Type</option>
            <option value="Own">Own</option>
            <option value="Hire">Hire</option>
          </select>
          <p className="text-danger">{errors.type}</p>
        </div> */}
                {/* <div className="col-md-5 mb-3">
          <label className="form-label fs-6">Asset Code:</label>
          <select className="form-select" name="assetCode" value={formData.assetCode} onChange={handleChange}>
            <option value="">Select Asset Code</option>
            {assetCodeData && Array.isArray(assetCodeData) && assetCodeData.map(item => (
              <option value={item._id} key={item._id}>{item.assetCode}</option>
            ))}
          </select>
          <p className="text-danger">{errors.assetCode}</p>
        </div> */}
                {/* <div className="col-md-5 mb-3">
          <label className="form-label fs-6">Name:</label>
          <select className="form-select" name="name" value={formData.name} onChange={handleChange}>
            <option value="">Select Name</option>
          {filteredNames.length > 0 && filteredNames.map(item => (
            <option value={item._id} key={item._id} selected={formData.name === item._id}>{item.name}</option>
          ))}
          </select>
          <p className="text-danger">{errors.name}</p>
        </div> */}
                {/* <div className="col-md-5 mb-3">
          <label className="form-label fs-6">SubCode:</label>
          <select 
          className="form-select" 
          name="subCode" 
          value={formData.subCode} 
          onChange={handleChange}
        >
          <option value="">Select SubCode</option>
          {filteredSubcodes.length > 0 && filteredSubcodes.map(item => (
            <option value={item._id} key={item._id} selected={formData.subCode === item._id}>{item.subCode? item.subCode : 'NA'}</option>
          ))}
        </select>
          <p className="text-danger">{errors.subCode}</p>
        </div> */}
                {/* <div className="col-md-5 mb-3">
          <label className="form-label fs-6">Scope Of Work Details:</label>
          <input type="text" className="form-control" name="scopeOfWorkDetails" value={formData.scopeOfWorkDetails} placeholder="Enter Scope of work" onChange={handleChange} />
          <p className="text-danger">{errors.scopeOfWorkDetails}</p>
        </div> */}
                {/* <div className="col-md-5 mb-3">
          <label className="form-label fs-6">Location:</label>
          <input type="text" className="form-control" name="location" value={formData.location} placeholder="Enter Work Location" onChange={handleChange} />
          <p className="text-danger">{errors.location}</p>
        </div> */}
                {/* <div className="col-md-5 mb-3">
          <label className="form-label fs-6">Remarks:</label>
          <input type="text" className="form-control" name="remarks" value={formData.remarks} placeholder="Enter Remarks" onChange={handleChange} />
          <p className="text-danger">{errors.remarks}</p>
        </div> */}

                {/* <div className="col-md-5 mb-3 ">
          <label className="form-label fs-6">Work Area Details:</label>
          <select className="form-select" name="workareadetails" value={formData.workareadetails} onChange={handleChange}>
            <option value="">Select Yes/No</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
          <p className="text-danger">{errors.workareadetails}</p>
        </div> */}
                {/* <div className="col-md-5 mb-3 ">
        <label className="form-label fs-6" style={{ marginRight: '40px', minWidth: '150px' }}>Debit Note:</label>
            <select className="form-select" name="debitNote" value={formData.debitNote} onChange={handleChange} style={{ flex: '1' }}>
              <option value="">Select Yes/No</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          <p className="text-danger">{errors.workareadetails}</p>
        </div> */}

                {/* {showInputs && (
        <div className="row mb-3">
          <div className="col-md-4 mb-3">
            <label className="form-label fs-6">Floor:</label>
            <input type="text" className="form-control" name="floor" placeholder="Enter Floor" value={formData.floor} onChange={handleChange} />
            <p className="text-danger">{errors.floor}</p>
          </div>
          <div className="col-md-4 mb-3">
            <label className="form-label fs-6">Flat:</label>
            <input type="text" className="form-control" name="flat" placeholder="Enter Flat" value={formData.flat} onChange={handleChange} />
            <p className="text-danger">{errors.flat}</p>
          </div>
          <div className="col-md-4 mb-3">
            <label className="form-label fs-6">Room:</label>
            <input type="text" className="form-control" name="room" placeholder="Enter Room" value={formData.room} onChange={handleChange} />
            <p className="text-danger">{errors.room}</p>
          </div>
        </div>
      )} */}

                {/*{showdebitInputs && (
            <div style={{ display: 'flex', justifyContent: 'space-between',gap:'10px',margin: '0 auto' }}>
              <div className="form-group" style={{ flex: '1', marginRight: '10px' }}>
                <label className="form-label fs-6">Employee Type:</label>
                <input
                type="text"
                className="form-control"
                name="employeeType" value={formData.employeeType} onChange={handleChange} style={{ flex: '1' }}
                 />

                {/* <select className="form-select" name="employeeType" value={formData.employeeType} onChange={handleChange} style={{ flex: '1' }}>
              <option value="">Select Employee Type</option>
              <option value="GM">GM</option>
              <option value="LL">LL</option>
            </select> */}
                {/* <p className="text-danger">{errors.employeeType}</p> */}
                {/* </div> */}
                {/* <div className="form-group" style={{ flex: '1', marginRight: '10px' }}>
                <label className="form-label fs-6">Employee ID:</label>
                <input 
                type="text" 
                className="form-control" 
                name="emplyid" 
                placeholder='Enter Employee ID'
                value={formData.emplyid} onChange={handleChange} />
                <p className="text-danger">{errors.emplyid}</p>
              </div> */}
                {/* <div className="form-group" style={{ flex: '1' }}>
                <label className="form-label fs-6">Debit Amount:</label>
                <input type="text" 
                className="form-control"
                 name="debitAmount" 
                 placeholder='Enter Debit Amount'
                 value={formData.debitAmount} onChange={handleChange} />
                <p className="text-danger">{errors.debitAmount}</p>
              </div> */}
                {/* <div className="form-group" style={{ flex: '1' }}>
                <label className="form-label fs-6">Debit Reason:</label>
                <input type="text" 
                className="form-control"
                 name="debitReason"
                 placeholder='Enter Debit Reason'
                 value={formData.debitReason} onChange={handleChange} />
                <p className="text-danger">{errors.debitReason}</p>
              </div> */}
                {/* </div> */}
                {/* )} */}

                {/* </div> */}
                {/* </div> */}

                {/*<div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '10px', alignItems: 'center', marginBottom: '20px' }}>
  <div>
    <p className="mb-3" style={{fontWeight:'500'}}>Issued To Details:</p>
  </div>
  <div className="mb-3">
    <label className="form-label">Employee Type:</label>
    <input
                type="text"
                className="form-control"
                name="TypeissuedEmployee" value={formData.employeeType} onChange={handleChange} style={{ flex: '1' }}
                 />
    {/* <select className="form-select" name="employeeType" value={formData.employeeType} onChange={handleChange} style={{ flex: '1' }}>
      <option value="">Select Employee Type</option>
      <option value=""></option>
      <option value=""></option>
    </select> */}
                {/* <p className="text-danger">{errors.employeeType}</p>
  </div>
  <div className="mb-3">
    <label className="form-label">Employee ID:</label>
    <input type="text" className="form-control" name="employeeId" placeholder='Enter Employee ID' value={formData.employeeId} onChange={handleChange} />
    <p className="text-danger">{errors.employeeId}</p>
  </div>
    </div>*/}
                {/* <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: '10px',alignItems:'center',  margin: '0 auto' }}>
  <div>
    <p className="mb-3" style={{fontWeight:'500'}}>Clock:</p>
  </div>
  <div className="mb-3">
                <label className="form-label fs-6">Start Time</label>
                <input type="time" className="form-control" name="startTime" value={formData.startTime} onChange={handleChange} />
                <p className="text-danger">{errors.startTime}</p>
            </div>
            <div className="mb-3">
                <label className="form-label fs-6">End Time</label>
                <input type="time" className="form-control" name="endTime" value={formData.endTime} onChange={handleChange} />
                <p className="text-danger">{errors.endTime}</p>
            </div>
            <div className="mb-3">
                <label className="form-label fs-6">Time Difference</label>
                <input placeholder='Time Difference' type="text" className="form-control" name="differenceTime" value={formData.differenceTime} onChange={handleChange} />
                <p className="text-danger">{errors.differenceTime}</p>
            </div>
    </div> */}

                {/* <h6 className='ml-4'>Date:</h6> */}
                {/* <div className='row mb-2 d-flex justify-content-around'>
        <div className="col-md-5 mb-3">
          <label className="form-label fs-6">Start Date</label>
          <input type="date" className="form-control" name="startDate" value={formData.startDate} onChange={handleChange} />
          <p className="text-danger">{errors.startDate}</p>
        </div>
        <div className="col-md-5 mb-3">
          <label className="form-label fs-6">End Date</label>
          <input type="date" className="form-control" name="endDate" value={formData.endDate} onChange={handleChange} />
          <p className="text-danger">{errors.endDate}</p>
        </div>
          </div> */}

                {/* <div className='row mb-2 d-flex justify-content-around'>
        <div className="col-md-5 mb-3">
          <label className="form-label fs-6">Fuel Type</label>
          <select className="form-select" name="fuelType" value={formData.fuelType} onChange={handleChange}>
            <option value="">Select Fuel Type</option>
            <option value="Diesel">Diesel</option>
            <option value="Petrol">Petrol</option>
          </select>
          <p className="text-danger">{errors.fuelType}</p>
        </div>
        <div className="col-md-5 mb-3">
          <label className="form-label fs-6">Oil (in liters)</label>
          <input type="number" className="form-control" name="oil" placeholder="Enter Oil (in liters)" value={formData.oil} onChange={handleChange} />
          <p className="text-danger">{errors.oil}</p>
        </div>
          </div> */}
                {/* <Button variant="primary" type="submit">
          Submit
        </Button> */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default PowerTools;
