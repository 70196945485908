import AppRoutes from "./Components/Navigation/Routes/Routes";
import { BrowserRouter as Router } from "react-router-dom";
import { RecoilRoot } from "recoil";
import RecoilNexus from "recoil-nexus";
import React from 'react';
import './styles/variables.module.css'

const App = () => {
  return (
    // <div>
    //   <p>You clicked {count} times</p>
    //   <button onClick={() => setCount(count + 1)}>Click me</button>
    // </div>
      <RecoilRoot>
         <Router> 
           <RecoilNexus />
        
             <AppRoutes />
         </Router> 
       </RecoilRoot> 
        //  <Router> 
        //      <AppRoutes />
        //  </Router> 
 
      
  );
};

export default App;

//  <RecoilRoot>
//    <Router>
//      <RecoilNexus />
//    <Home/>
//        <AppRoutes />
//    </Router>
//  </RecoilRoot>
