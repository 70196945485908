import React from 'react';
import * as Icon from "react-bootstrap-icons";
import { useEffect } from 'react';

const FormTable = (props) => {

  const { handleEditDialogOpen, editData, webApi, setEditData, setError, setLoading } = props

  useEffect(() => {
    webApi.get( "api/checkListForm/Allforms?type=form")
      .then((response) => {
        // console.log("API response:", response);
        if (response.data && Array.isArray(response.data.data.data)) {
          setEditData(response.data.data.data);
          console.log(response.data.data.data);
        } else {
          setError("Unexpected data format");
          console.error("Unexpected data format:", response.data);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
        setError("There was an error fetching the data");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '100px', marginTop: '20px', marginRight: '100px' }}>
    <table style={{ width: '100%' }}>
      <thead style={{ backgroundColor: 'lightblue', textAlign: 'center', height: '45px' }} >
        <tr>
          <th style={{ width: '10%' }}>S.No</th>
          <th style={{ width: '20%' }}>Heading</th>
          <th style={{ width: '15%' }}>Actions</th>
         
        </tr>
      </thead>
      <tbody style={{ textAlign: 'center', boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)' }}>
        {editData.map((item, index) => (
          <tr key={item._id} style={{ border: '1px solid black',height:'55px' }}>
            <td>{index + 1}</td>
            <td>{item.heading}</td>
            <td>
              <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
              <Icon.Pen size={25} style={{ marginRight: '30px' }} onClick={() => {
    // console.log(item._id)
      const id = item._id
    handleEditDialogOpen(id)
    }} />
                <Icon.EyeFill size={25} style={{ marginRight: '30px' }}/>
                 <Icon.Download size={25} style={{ marginRight: '30px' }} />
                </div>
              
            </td>
            
          </tr>
        ))}
      </tbody>
    </table>
  </div>
  )
}

export default FormTable