import React from 'react'
import Styles from './index.module.css'
import MobileLogo from '../../Images/MobileLogo.png'

const MobileDownload = () => {
  return (
    <div className={Styles.MobileContainer}>
        <div className={Styles.MobileView}>
            <div className={Styles.MobileViewLogo}>
            <img src={MobileLogo} alt="" />
            <p className={Styles.HoldOn}>Hold On!</p>
            </div> 
        </div>
        <div className={Styles.MobileMsgContainer}>
            <p className={Styles.MobileMsg}>We are currently not supporting Mobile Devices please click the below button to Download mobile application</p>
        </div>
        <div className={Styles.BtnContainer}>
            <button className={Styles.DownloadBtn}>
                <a href="www.google.com" style={{textDecoration:"none",color:"white"}}>Download</a>
            </button>
       </div>
    </div>
  )
}

export default MobileDownload