import React from "react";
import { Tabs, Tab, Typography, Box } from "@mui/material";
import { useRecoilState } from "recoil";
import { WebApimanager } from "../../WebApiManager";
import SelectSite from "../../Utilities/SelectSite";
import { siteId } from "../../atoms";
import Employee from "./Employee";
import Profile from "./Profile";
import OrganisationChart from "./OrganisationChart";

const Users = () => {
  let webApi = new WebApimanager();
  const [value, setValue] = React.useState(0);
  const [site, setSite] = useRecoilState(siteId);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (site === "") {
    return <SelectSite />;
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px",
          marginTop: "5px",
        }}
      >
        <Box
          sx={{
            border: "1px solid var(--primary-color)",
            width: "100%",
            maxWidth: "720px", // Adjust max-width as needed
          }}
        >
          <Tabs
            id="controlled-tab-example"
            value={value}
            onChange={handleChange}
            sx={{
              "& .MuiTabs-indicator": {
                display: "none", // Hide default indicator
              },
              "& .MuiTab-root": {
                textTransform: "none",
                borderBottom: "none",
                borderLeft: "1px solid var(--primary-color)",
                minWidth: "240px",
                fontWeight: "bold",
                color: "var(--primary-color)",
                "&:first-of-type": {
                  borderLeft: "none",
                },
                "&:last-of-type": {
                  borderRight: "none",
                },
              },
              "& .Mui-selected": {
                backgroundColor: "var(--primary-color)", // Background color for selected tab
                color: "white ! important",
                borderBottom: "1px solid var(--primary-color)", // Maintain border at the bottom of selected tab
              },
              "& .MuiTab-root:not(.Mui-selected)": {
                color: "var(--primary-color)", // Text color for non-selected tabs
              },
            }}
          >
            <Tab label="Employee" />
            <Tab label="Profile" />
            <Tab label="Organisation Chart" />
          </Tabs>
        </Box>
      </div>

      <TabPanel value={value} index={0}>
        <Employee />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Profile />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <OrganisationChart />
      </TabPanel>
    </>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {value === index && (
        <Box p={3} style={{ textAlign: "center", width: "100%" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default Users;
