import React, { useState, useEffect } from 'react';

const SearchableDropdown = ({ options, onOptionSelect }) => {
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [inputValue, setInputValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setFilteredOptions(
      options.filter(option =>
        option.toLowerCase().includes(inputValue.toLowerCase())
      )
    );
  }, [inputValue, options]);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    onOptionSelect(value);
    setIsOpen(true);
  };

  const handleOptionClick = (option) => {
    setInputValue(option);
    onOptionSelect(option);
    setIsOpen(false);
  };

  const handleBlur = () => {
    setTimeout(() => setIsOpen(false), 100); // Delay to allow click to register
  };

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onFocus={() => setIsOpen(true)}
        onBlur={handleBlur}
        style={{
          width: '100%',
          padding: '8px',
          fontSize: '12px', // Font size for consistency
          backgroundColor: '#F3F3F3', // Gray background
          color: 'black', // Black text color
          border: '1px solid #ccc', // Light gray border
          boxSizing: 'border-box', // Ensure padding and border are included in width
        }}
      />
      {isOpen && (
        <ul
          style={{
            position: 'absolute',
            width: '100%',
            marginTop: '4px', // Space between input and dropdown
            maxHeight: '150px',
            overflowY: 'auto',
            border: '1px solid #ccc', // Light gray border
            boxSizing: 'border-box',
            margin: 0,
            padding: 0,
            zIndex: 2,
            listStyleType: 'none',
            backgroundColor: '#F3F3F3', // Gray background for dropdown
            color: 'black', // Black text color for options
          }}
        >
          {filteredOptions.map((option, index) => (
            <li
              key={index}
              onMouseDown={() => handleOptionClick(option)} // onMouseDown to prevent blur before click
              style={{
                padding: '8px',
                cursor: 'pointer',
                fontSize: '12px', // Font size for options
                backgroundColor: inputValue === option ? '#e9e9e9' : '#F3F3F3', // Gray background with hover effect
              }}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SearchableDropdown;
