import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

function CreateChecklist () {
    const location = useLocation();
    const { heading, title } = location.state || {};

   
    const [checklistItems, setChecklistItems] = useState([
        { id: 1, question: "Toilet duct plastering & ventilator rear side plastering completed?", status: '' },
        { id: 2, question: "Waterproofing activities of above floor completed?", status: '' },
        { id: 3, question: "Checked waterproofing of this floor & found ok? Any damage record here....Rectified?", status: '' },
        { id: 4, question: "Toilet plumbing line. Pressure Leak test conducted @ 10bar for 24hrs & records generated?", status: '' },
        { id: 5, question: "Toilet horizontal plumbing line blockout packed properly, applied with curing compound & ceiling, wall", status: '' },
        // Add more checklist items as needed
      ]);
    
      const handleStatusChange = (id, status) => {
        const updatedItems = checklistItems.map(item => {
          if (item.id === id) {
            return { ...item, status: status };
          }
          return item;
        });
        setChecklistItems(updatedItems);
      };

    return (
    <div style={{marginTop:'100px'}}>
      <h1 style={{ textAlign: 'center' }}>{heading}Check List</h1>
      <h3 style={{margin: '0 auto', borderCollapse: 'collapse', width: '80%', textAlign: 'center', border: '2px solid black'}}>
        SR SUMADHURA INFRACON (P) LTD.<br/>
CKB Plaza, Site No.43, 02nd floor, Varthur Main road, Marathahalli, <br/>
Bangalore-560037, Phone (off)-080-42161470
        </h3>
        <h3 style={{margin: '0 auto', borderCollapse: 'collapse', width: '80%', textAlign: 'center', border: '2px solid black'}}>
        CHECKLIST
        </h3>
        <h3 style={{margin: '0 auto', borderCollapse: 'collapse', width: '80%', textAlign: 'center', border: '2px solid black'}}>
        Activity-{title}
        </h3>
        <table style={{margin: '0 auto', borderCollapse: 'collapse', width: '80%', textAlign: 'center', border: '2px solid black'}}>
        <thead>
          <tr>
            <th style={{border: '1px solid black',width:'10%',height:'50px'}}>Project</th>
            <th style={{width:'70%'}}>
            <input style={{width: '98%', height: '50px',border:'none',fontSize:'20px'}}></input>
            </th>
            <th style={{border: '1px solid black'}}>System SIPL/CIVIL/034</th>
          </tr>
        </thead>
        </table>
        <table style={{margin: '0 auto', borderCollapse: 'collapse', width: '80%', textAlign: 'center', border: '2px solid black'}}>
        <thead>
          <tr style={{height:'50px'}}>
            <th style={{border: '1px solid black',width:'10%'}}>Block:</th>
            <th style={{border: '1px solid black',width:'10%'}}>
            <input style={{width: '95%',border:'none',fontSize:'20px'}}></input>
            </th>
            <th style={{border: '1px solid black',width:'10%'}}>Floor:</th>
            <th style={{border: '1px solid black',width:'10%'}}>
            <input style={{width: '95%', border:'none',fontSize:'20px'}}></input>
            </th>
            <th style={{border: '1px solid black',width:'10%'}}>Flat:</th>
            <th style={{border: '1px solid black',width:'10%'}}>
            <input style={{width: '95%', border:'none',fontSize:'20px'}}></input>
            </th>
            <th style={{border: '1px solid black',width:'20%'}}>Checking Start Date & Time </th>
            <th style={{border: '1px solid black',width:'25%'}}>
            <input style={{width: '98%', border:'none',fontSize:'20px'}}></input>
            </th>
          </tr>
        </thead>
        </table>
        <table style={{margin: '0 auto', borderCollapse: 'collapse', width: '80%', textAlign: 'center', border: '2px solid black'}}>
        <thead>
          <tr style={{height:'50px'}}>
            <th style={{border: '1px solid black',width:'10%'}}>Grid</th>
            <th style={{border: '1px solid black',width:'50%'}}>
            <input style={{width: '98%', border:'none',fontSize:'20px'}}></input>
            </th>
            <th style={{border: '1px solid black',width:'20%'}}>Checking End Date & Time</th>
            <th style={{border: '1px solid black',width:'25%'}}>
            <input style={{width: '95%',fontSize:'20px',border:'none'}}></input>
            </th>
          </tr>
        </thead>
        </table>
        <table style={{margin: '0 auto', borderCollapse: 'collapse', width: '80%', textAlign: 'center', border: '2px solid black'}}>
        <thead>
          <tr style={{height:'50px'}}>
            <th style={{border: '1px solid black',width:'25%'}}>Ref.Org (Architectural floor pian) </th>
            <th style={{border: '1px solid black',width:'55%'}}>
            <input style={{width: '98%',fontSize:'20px',border:'none'}}></input>
            </th>
            <th style={{border: '1px solid black',width:'10%'}}>Rev.No, date</th>
            <th style={{border: '1px solid black',width:'25%'}}>
            <input style={{width: '95%',fontSize:'20px',border:'none'}}></input>
            </th>
          </tr>
        </thead>
        </table>

      <table style={{ margin: '0 auto', borderCollapse: 'collapse', width: '80%', textAlign: 'center', border: '2px solid black' }}>
        <thead>
          <tr style={{height:'50px'}} >
            <th style={{ border: '1px solid black', width: '10%' }}>SI.No</th>
            <th style={{ border: '1px solid black', width: '35%' }}>Description</th>
            <th style={{ border: '1px solid black', width: '15%' }}>Status</th>
            <th style={{ border: '1px solid black' }}>Remarks</th>
          </tr>
        </thead>
        <tbody>
          {checklistItems.map((item) => (
            <tr key={item.id} >
              <td style={{ border: '1px solid black' }}>{item.id}.</td>
              <td style={{ border: '1px solid black' }}>{item.question}</td>
              <td style={{ border: '1px solid black'}}>
                <div style={{display:'flex',justifyContent:'space-evenly'}}>
                <button style={{ backgroundColor: item.status === 'yes' ? 'green' : '' ,color: item.status === 'yes' ? 'white' : 'black',borderRadius:'10px'}} onClick={() => handleStatusChange(item.id, 'yes')}>Yes</button>
                <button style={{ backgroundColor: item.status === 'no' ? 'red' : '',borderRadius:'10px',color: item.status === 'no' ? 'white' : 'black' }} onClick={() => handleStatusChange(item.id, 'no')}>No</button>
                <button style={{ backgroundColor: item.status === 'na' ? 'darkgray' : '',borderRadius:'10px', color: item.status === 'na' ? 'white' : 'black' }} onClick={() => handleStatusChange(item.id, 'na')}>NA</button>
                </div>
              </td>
              <td style={{ border: '1px solid black' }}>
                <input style={{ width: '98%', height: '40px', border: 'none',fontSize:'20px' }}></input>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>

    );
}

export default CreateChecklist;