import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import Navbar from "../Navbar/Navbar";
import axios from "axios";
import { BACKEND_URL } from "../../Utilities/Constants";
import * as Icon from "react-bootstrap-icons";
import { RxCross1 } from "react-icons/rx";
import CloseIcon from "@mui/icons-material/Close";
import { useRecoilState } from "recoil";
import { siteId, userInfo } from "../../atoms";
import SelectSite from "../../Utilities/SelectSite";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import "./Employee.module.css";
import {
  Dialog,
  Stepper,
  StepLabel,
  Step,
  DialogContent,
  DialogActions,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { WebApimanager } from "../../WebApiManager";
import { Loader } from "../../Widgets/notificationFeedbacks";
import MultiSelectSearch from "../../Utilities/MultiSelectSearch";
import ToggleSwitch from "../Sites/ToggleSwitch";
import "./users.css";
import { Label } from "@mui/icons-material";
import Select from "react-select";
import CustomDropdown from "./CustomDropdown";

const Employee = () => {
  let webApi = new WebApimanager();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedWorkModel, setSelectedWorkModel] = useState("");
  const [roles, setRoles] = useState([]);
  const [workModels, setWorkModels] = useState([]);
  const [datasites, setDataSites] = useState([]);
  const [site, setSite] = useRecoilState(siteId);
  const [currentUserInfo, setCurrentUserInfo] = useRecoilState(userInfo);
  const [userId, setUserId] = useState(currentUserInfo._id || "");

  const [formValues, setFormValues] = useState({
    firstname: "",
    lastname: "",
    contactnumber: "",
    emailid: "",
    bloodGroup: "",
    emergencyContactnumber: "",
    reportingEmpIdName: "",
    employeeId: "",
    selectedDepartment: "",
    selectedRole: "",
    selectedWorkModel: "",
    permittedSite: [],
    reportingEmpId: "",
    reportingUserId: "",
  });
  const [errors, setErrors] = useState({
    firstname: "",
    lastname: "",
    contactnumber: "",
    emailid: "",
    bloodGroup: "",
    emergencyContactnumber: "",

    reportingEmpIdName: "",
    employeeId: "",
    selectedDepartment: "",
    selectedRole: "",
    selectedWorkModel: "",
    permittedSite: [],
  });

  const [selectedSiteIds, setSelectedSiteIds] = useState([]);
  const [enabledModules, setEnableModules] = useState();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNext = () => {
    const isValid = validateFields();

    if (!isValid) {
      return;
    }

    if (!selectedDepartment) {
      errors.selectedDepartment = "Department is required";
    }
    if (!selectedRole) {
      errors.selectedRole = "Role is required";
    }
    console.log(activeStep, "activeStep");

    console.log("selected ID ", selectedSiteIds);
    if (activeStep === 1) {
      console.log("modules Data", ModulesData);

      let a = [];
      const sites = datasites;

      for (const site of sites) {
        const siteModules = ModulesData[site.id] || [];

        for (const module of siteModules) {
          if (!a.includes(module)) {
            // Check if module is already in `a`
            a.push(module); // Add module to `a` if it's not already included
          }
        }
      }

      setEnableModules(a); // Update state with the array of enabled modules

      console.log("Filtered enabled modules:", a); // Log the final result
    }

    // Check if there are any errors
    if (Object.keys(errors).length === 0) {
      // If the active step is 3, log the data and navigate to the next step
      if (activeStep === 2) {
        // Add any additional data you need to log
      } else {
        // Navigate to the next step without logging if activeStep is not 3
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }

    if (activeStep === 2) {
      setFormValues((prevValues) => ({
        ...prevValues,
        permittedSite: selectedSiteIds,
        selectedDepartment: selectedDepartment,
        selectedRole: selectedRole,
      }));
    }
    if (activeStep === 3) {
      // If it's the last step, submit the form
      handleSubmit();
    } else {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };
  const departmentData = {
    Management: {
      roles: [
        "Company Managing Director",
        "Director",
        "Technical President",
        "Senior Vice President",
        "Vice President",
        "Assistant Vice President",
      ],

      workModels: [""],
    },
    "Design Consultant": {
      roles: [
        "Archiectural",
        "Structural",
        "MEP",
        "Facade",
        "Landscape and Lighting",
        "Vertical Transportation",
        "Traffic and Parking",
      ],
      workModels: [""],
    },

    Execution: {
      roles: [
        "Deputy Project Manager",
        "Assistant Project Manager",
        "Assistant Manager",
        "Sr.Project Engineer",
        "Project Engineer",
        "Jr.Project Engineer",
        "Senior Engineer",
        "Engineer",
        "Junior Engineer",
        "GTE",
      ],
      workModels: ["Structural", "Finishes"],
    },
    Planning: {
      roles: [
        "Head",
        "Manager",
        "Deputy Manager",
        "Assistant Manager",
        "Sr.Engineer",
        "Engineer",
        "Jr.Engineer",
        "GTE",
      ],
      workModels: [""],
    },
    SiteManagement: {
      role: [
        "General Manager",
        "Assistant General Manager",
        "Senior Project Manager",
        "Project Manager",
      ],
      workModels: [""],
    },
    QualitySurveyorBilling: {
      roles: [
        "Head",
        "Manager",
        "Deputy Manager",
        "Assistant Manager",
        "Sr.Engineer",
        "Engineer",
        "Jr.Engineer",
        "GTE",
      ],
      workModels: ["BBS", "Billing", "Contractor", "QS"],
    },
    MEP: {
      roles: [
        "Head",
        "Manager",
        "Deputy Manager",
        "Assistant Manager",
        "Sr.Engineer",
        "Engineer",
        "Jr.Engineer",
        "GTE",
        "Supervisors",
      ],
      workModels: ["Electrical", "Plumbing", "Fire Fighting", "Ele & HVAC"],
    },
    EHS: {
      roles: [
        "Head",
        "Manager",
        "Assistant Manager",
        "Safety Incharge",
        "Sr.Engineer",
        "Engineer",
        "Jr.Engineer",
        "GTE",
        "Steve Ward",
        "Male Nurse",
      ],
      workModels: [""],
    },
    CRMTECH: {
      roles: [
        "Head",
        "Manager",
        "Deputy Manager",
        "Assistant Manager",
        "Sr.Engineer",
        "Engineer",
        "Jr.Engineer",
        "GTE",
      ],
      workModels: [""],
    },
    Store: {
      roles: [
        "Head",
        "Store Manager",
        "Assistant Store Manager",
        "Store Officer",
        "Store Incharge",
        "Sr.Store Keeper",
        "Store Keeper/ Executive",
        "Sr.Store Asst",
        "Store Asst",
        "Trainee Store",
      ],
      workModels: [""],
    },
    Survey: {
      roles: ["Sr.Engineer", "Engineer", "Jr.Engineer", "GTE", "Helper"],
    },
    Accounts: {
      roles: [
        "Head",
        "Manager",
        "Sr.Accountant",
        "Accountant",
        "Jr.Accountant",
      ],
      workModels: [""],
    },
    Admin: {
      roles: [
        "Head",
        "Project Admin",
        "Ass Admin",
        "Time Keeper",
        "Colony Admin",
      ],
      workModels: [""],
    },
  };
  const handleDepartmentChange = (e) => {
    const selectedDepartment = e.target.value;
    setSelectedDepartment(selectedDepartment);

    // Reset role and work model selections
    setSelectedRole("");
    setSelectedWorkModel("");

    // Fetch roles and work models for the selected department
    if (selectedDepartment) {
      const departmentDetails = departmentData[selectedDepartment];
      setRoles(departmentDetails.roles || []);
      setWorkModels(departmentDetails.workModels || []);
    }
  };

  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async () => {
    setApiProcessing({
      loader: true,
      message: "Submitting data...",
    });

    try {
      // Prepare the permittedSites array
      const permittedSites = selectedSiteIds
        .map((siteId) => {
          // Find the site data for the current siteId
          const siteData = allData.find((data) => data.siteId === siteId);

          // If no site data is found for the current siteId, skip this iteration
          if (!siteData) {
            return null;
          }

          // Extract the enabledModules based on the toggle states
          const enableModules = {};
          siteData.enabledModules.forEach((module) => {
            if (formValues[module]) {
              enableModules[module] = true; // Only include modules that are toggled true
            }
          });

          return {
            siteId,
            enableModules,
          };
        })
        .filter((site) => site !== null); // Filter out null values

      const reportEmpName = (
        formValues.reportingEmpIdName.includes("|")
          ? formValues.reportingEmpIdName.split("|")[1]
          : formValues.reportingEmpIdName
      ).replace(/\s+/g, "");
      const reportingEmpId = formValues.reportingEmpId.replace(/\s+/g, "");
      const reportingUserId = formValues.reportingUserId.replace(/\s+/g, "");

      // Submit the user data
      const userResponse = await webApi.post("api/users", {
        firstName: formValues.firstname,
        lastName: formValues.lastname,
        email: formValues.emailid,
        workMode: selectedWorkModel,
        bloodGroup: formValues.bloodGroup,
        emergencyContact: formValues.emergencyContactnumber,
        contactNumber: formValues.contactnumber,
        department: formValues.selectedDepartment,
        role: formValues.selectedRole,
        empId: formValues.employeeId,
        permittedSites: permittedSites,
        reportingEmpId: reportingEmpId,
        reportingEmpIdName: reportEmpName,
        reportingUserId: reportingUserId,
      });

      console.log("Data to be submitted:", userResponse);
      console.log("Reporting Employee ID:", userResponse.reportingEmpId);
      console.log(
        "Reporting Employee ID/Name:",
        userResponse.reportingEmpIdName
      );
      console.log("Reporting User ID:", userResponse.reportingUserId);

      if (userResponse.status === 201) {
        // If user data submission is successful, proceed with file uploads
        const userId = userResponse.data._id;
      } else if (userResponse.status === 200) {
        const responseData = userResponse.data;
        if (responseData.status === "warning") {
          // Handle warning messages from the response
          setErrorMessage(responseData.msg || "Warning occurred.");
          console.log("Warning:", responseData.msg);
        } else {
          // Handle general validation errors
          setErrorMessage(responseData.message || "Validation error occurred.");
          console.log("Validation error:", responseData.message);
        }
      } else {
        // Handle unexpected server errors
        setErrorMessage("An unexpected error occurred.");
        console.log("Unexpected response:", userResponse);
      }
    } catch (error) {
      // Handle network or unexpected errors
      if (error.response) {
        // Server responded with an error
        console.log("error responce", error.response);

        const errorResponse = error.response.data;

        if (error.response.status === 400) {
          setErrorMessage(
            errorResponse.msg || "Bad request: check your input data"
          );
        }
        if (errorResponse.status === "error") {
          setErrorMessage(errorResponse.msg || "Warning occurred.");
          console.log("Warning:", errorResponse.msg);
        } else {
          setErrorMessage(errorResponse.msg || "Error occurred.");
          console.log("Error:", errorResponse.msg);
        }
      } else {
        // Network error or unexpected issue
        setErrorMessage("Failed to submit data.");
        console.error("Submission error:", error.message);
      }
    } finally {
      // Reset loading state
      setApiProcessing({ loader: false, message: "" });
    }
  };

  const validateFields = () => {
    const newErrors = {};
    if (activeStep === 0) {
      newErrors.firstname = formValues.firstname
        ? ""
        : "Please enter First Name.";
      newErrors.lastname = formValues.lastname ? "" : "Please enter Last Name.";
      newErrors.contactnumber = formValues.contactnumber
        ? ""
        : "Please enter Contact Number.";
      newErrors.emailid = formValues.emailid ? "" : "Please enter Email ID.";
      newErrors.bloodGroup = formValues.bloodGroup
        ? ""
        : "Please select Blood Group.";
      newErrors.emergencyContactnumber = formValues.emergencyContactnumber
        ? ""
        : "Please enter Emergency Contact Number.";
    } else if (activeStep === 1) {
      newErrors.reportingEmpIdName = formValues.reportingEmpIdName
        ? ""
        : "Please enter Reporting Employee ID / Name.";
      newErrors.selectedDepartment = selectedDepartment
        ? ""
        : "Please enter Department.";
      newErrors.employeeId = formValues.employeeId
        ? ""
        : "Please enter employeeId.";
      newErrors.selectedRole = selectedRole ? "" : "Please enter Role.";
      // newErrors.selectedWorkModel = selectedWorkModel
      //   ? ""
      //   : "Please enter workModel.";
    } else if (activeStep === 2) {
      newErrors.permittedSite = formValues.permittedSite
        ? ""
        : "Please enter permittedSite.";
    }

    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === "");
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  useEffect(() => {
    console.log(formValues);
  }, [formValues]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // console.log("value", value, name);

    if (name === "reportingEmpIdName") {
      const [reportingUserId, reportingEmpIdName, reportingEmpId] =
        value.split("|");

      setFormValues((prevValues) => ({
        ...prevValues,
        reportingEmpIdName: reportingEmpIdName.trim(), // Display value
        reportingUserId: reportingUserId, // ID to pass to the API
        reportingEmpId: reportingEmpId, // EmpId to pass to the API
      }));
    } else {
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));

      console.log(`Selected value for ${name}: ${value}`);
    }

    let errorMessage = "";

    // Validation logic for each field
    switch (name) {
      case "firstname":
      case "lastname":
        const isValidName = /^[a-zA-Z]*$/.test(value);
        errorMessage = value.trim()
          ? isValidName
            ? ""
            : `${
                name.charAt(0).toUpperCase() + name.slice(1)
              } should contain only alphabets.`
          : `Please enter ${name.charAt(0).toUpperCase() + name.slice(1)}.`;
        break;
      case "emailid":
      case "bloodGroup":
      case "permittedSite":
        errorMessage = value.trim()
          ? ""
          : `Please enter ${name.charAt(0).toUpperCase() + name.slice(1)}.`;
        break;
      case "contactnumber":
        const isValidContactNumber = /^\d{10}$/.test(value);
        errorMessage = value.trim()
          ? isValidContactNumber
            ? ""
            : "Please enter a valid Contact Number."
          : "Please enter Contact Number.";
        break;
      case "emergencyContactnumber":
        const isValidEmergencyContactNumber = /^\d{10}$/.test(value);
        errorMessage = value.trim()
          ? isValidEmergencyContactNumber
            ? ""
            : "Please enter a valid Emergency Contact Number."
          : "Please enter Emergency Contact Number.";
        break;
      default:
        break;
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage,
    }));

    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  useEffect(() => {
    webApi
      .get("api/sites/AllSites")
      .then((response) => {
        if (response.data && Array.isArray(response.data.sites)) {
          const sitesList = response.data.sites.map((permittedSite) => ({
            id: permittedSite._id,
            name: permittedSite.siteName,
          }));
          setDataSites(sitesList);

          console.log("All sites data:", response.data.sites);
        } else {
          setError("Unexpected data format");
          console.error("Unexpected data format:", response.data);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
        setError("There was an error fetching the data");
      });
  }, []);

  const [ModulesData, SetModulesData] = useState([]);
  const [allData, setAllData] = useState(null);
  const [selectedFavorite, setSelectedFavorite] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleCardClick = (item) => {
    setSelectedFavorite(item);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedFavorite(null);
  };

  useEffect(() => {
    // Fetch data from the API
    const fetchData = async () => {
      try {
        const response = await webApi.get("api/sites/getAll");
        const fetchedData = response.data;

        // Update state with fetched data
        setAllData(fetchedData.data);

        // Update state with fetched data
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Fetch modules data only if there are selected site IDs
    if (selectedSiteIds.length > 0) {
      fetchData();
    }
  }, [selectedSiteIds]); // Trigger fetch when selectedSiteIds change

  useEffect(() => {
    webApi
      .get("api/users")
      .then((response) => {
        // console.log("API response:", response);
        if (response.data && Array.isArray(response.data.data.allUsers)) {
          setData(response.data.data.allUsers);
          const allUsers = response.data.data.allUsers;

          // Separate users into favorites and everyone
          const favoriteUsers = allUsers.filter(
            (user) => user.favouriteUsers.length > 0
          );
          //
          const loggedInUserId = userId;
          const allOtherUsers = allUsers.filter(
            (user) =>
              !favoriteUsers.includes(user._id) && user._id !== loggedInUserId
          );

          setData(allUsers);
          setFavorites(favoriteUsers);
          setEveryone(allOtherUsers);

          console.log("ALL USERS", setData);
          setFavorites(favoriteUsers);
          setEveryone(allOtherUsers);
        } else {
          setError("Unexpected data format");
          console.error("Unexpected data format:", response.data);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error.meg);
        setError(
          error.response?.data?.msg || "There was an error fetching the data"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const [searchTerm, setSearchTerm] = useState("");
  const [favorites, setFavorites] = useState([]);
  // Your existing state variables and functions...

  const [everyone, setEveryone] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isVisible, setIsVisible] = useState(false);

  // Toggle function
  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  // const handleViewClickOpen = (userId) => {
  //   webApi
  //     .get(`api/users/${userId}`)
  //     .then((response) => {
  //       const fetchedData = response.data.data.user;
  //       console.log(fetchedData); // Log the fetched data to the console
  //       setSelectedUser(fetchedData);
  //       setOpenDialog(true);
  //     })
  //     .catch((error) => {
  //       console.error("There was an error fetching the data!", error);
  //       setError("There was an error fetching the data");
  //     });
  // };

  const removeFavorite = async (favoriteUserId) => {
    try {
      // Remove user from favorites
      const response = await webApi.delete(
        `api/users/${userId}/removeFavouriteUser/${favoriteUserId}`
      );
      window.location.reload();
      if (response.status === 200 && response.data.status === "success") {
        const updatedFavorites = favorites.filter(
          (user) => user._id !== favoriteUserId
        );
        const userToAdd = favorites.find((user) => user._id === favoriteUserId);

        setCurrentUserInfo({
          ...currentUserInfo,
          favouriteUsers: currentUserInfo.favouriteUsers.filter(
            (id) => id !== favoriteUserId
          ),
        });
        setFavorites(updatedFavorites);
        if (userToAdd) {
          setEveryone([...everyone, userToAdd]);
        }
      }
    } catch (error) {
      console.error("Error removing favorite:", error);
    }
  };

  const addFavorite = async (favoriteUserId) => {
    try {
      // Add user to favorites
      const response = await webApi.put(`api/users/${userId}`, {
        favouriteUserId: favoriteUserId,
      });
      window.location.reload();
      if (response.status === 200 && response.data.status === "success") {
        const userToAdd = everyone.find((user) => user._id === favoriteUserId);
        if (userToAdd) {
          setCurrentUserInfo({
            ...currentUserInfo,
            favouriteUsers: [...currentUserInfo.favouriteUsers, favoriteUserId],
          });
          setFavorites([...favorites, userToAdd]);
          setEveryone(everyone.filter((user) => user._id !== favoriteUserId));
        }
      }
    } catch (error) {
      console.error("Error adding favorite:", error);
    }
  };

  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredFavorites = favorites.filter((user) =>
    `${user.firstName} ${user.lastName} ${user.empId}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const filteredEveryone = everyone.filter(
    (user) =>
      !currentUserInfo.favouriteUsers.includes(user._id) &&
      `${user.firstName} ${user.lastName} ${user.empId}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    if (currentUserInfo) {
      setFavorites(
        everyone.filter((user) =>
          currentUserInfo.favouriteUsers.includes(user._id)
        )
      );
    }
  }, [everyone, currentUserInfo]);

  const handleToggle = (moduleName) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [moduleName]: !prevValues[moduleName],
    }));
  };

  const options = data.map((user) => ({
    value: `${user._id} | ${user.firstName} ${user.lastName} | ${user.empId}`,
    label: (
      <div className="containerSearch">
        <div className="iconContainer">
          <Icon.PersonCircle size={23} />
        </div>
        <div className="infoContainer">
          <p className="name">
            {user.firstName} {user.lastName}
          </p>
          <p className="empId">{user.empId}</p>
        </div>
      </div>
    ),
  }));

  const customStyles = {
    menu: (base) => ({
      ...base,
      zIndex: 9999, // Adjust the z-index value as needed
    }),
    menuList: (base) => ({
      ...base,
      zIndex: 9999, // Ensure the menu list also respects the z-index
    }),
    option: (base) => ({
      ...base,
      backgroundColor: "white", // Background color for options
      color: "black", // Text color for options
    }),
    control: (base) => ({
      ...base,
      padding: "0", // Remove padding
      cursor: "default", // Hide the cursor when hovering over the control
    }),
  };

  const stapNames = [
    "Personal Details",
    "Employee Working Details",
    "Enable Modules",
    "Confirm Details",
  ];

  return (
    <>
      <div>
        {site === "" ? (
          <>
            <SelectSite />
          </>
        ) : (
          <>
            <div
              style={{
                marginTop: "50px",
                marginRight: "50px",
                marginLeft: "50px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                borderRadius: "12px",
                backgroundColor: "white",
                // padding: "10px",
                // paddingTop: "20px",
                fontFamily: "Poppins",
                fontWeight: "400",
                fontSize: "18px",
              }}
            >
              <div
                onClick={toggleVisibility}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  height: "80px",
                  padding: "20px 20px",
                  cursor: "pointer",
                }}
              >
                <div>
                  <p className="favouriteTitle">FAVOURITE</p>
                </div>
                <div className="favorateIcons">
                  {isVisible ? (
                    <FaAngleUp size={25} />
                  ) : (
                    <FaAngleDown size={25} />
                  )}
                </div>
              </div>
              {isVisible && (
                <div
                  className="favorites-box"
                  style={{
                    backgroundColor: "white",
                    padding: "20px",
                    borderRadius: "5px",
                    marginBottom: "20px",
                  }}
                >
                  {filteredFavorites.length === 0 ? (
                    <p>No favorites added yet.</p>
                  ) : (
                    <div
                      style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}
                    >
                      {filteredFavorites.map((favorite, index) => (
                        <div
                          className="favresponse"
                          key={favorite._id}
                          style={{
                            backgroundColor:
                              index % 6 < 3
                                ? "rgba(243, 246, 248, 1)"
                                : "rgba(253, 248, 244, 0.5)",
                            color: "black",
                            padding: "15px 25px",
                            borderRadius: "5px",
                            border: "none",
                            flex: "1 1 calc(33.33% - 20px)",
                            maxWidth: "425px",
                            minWidth: "350px",
                            height: "141px",
                            boxSizing: "border-box",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <p className="favFullname">
                              {favorite.firstName} {favorite.lastName}
                            </p>
                            <Icon.StarFill
                              size={20}
                              style={{
                                cursor: "pointer",
                                stroke: "#E47331",
                                strokeWidth: "0.5px",
                                color: "#E47331",
                              }}
                              onClick={(e) => {
                                e.stopPropagation(); // Prevents the parent div click event
                                removeFavorite(favorite._id);
                              }}
                            />
                          </div>

                          <p className="favEmpID">{favorite.empId}</p>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>

            <div
              style={{
                marginTop: "50px",
                marginRight: "50px",
                marginLeft: "50px",
                boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
                borderRadius: "12px",
                backgroundColor: "white",
                padding: "15px",
                paddingTop: "20px",
                fontFamily: "Poppins",
                fontWeight: "400",
                fontSize: "18px",
                marginBottom: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  marginLeft: "10px",
                  marginRight: "10px",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p className="everyonetitel">EVERYONE</p>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div
                    style={{
                      position: "relative",
                      width: "250px",
                      paddingLeft: "20px",
                      marginLeft: "600px",
                      height: "50px",
                    }}
                  >
                    {" "}
                    {/* Adjust width as needed */}
                    <input
                      className="serchPlaceholder"
                      placeholder="Search Users"
                      value={searchTerm}
                      onChange={handleSearchInputChange}
                      style={{
                        borderRadius: "6px",
                        border: "1px solid black",
                        width: "250px",
                        height: "40px",
                        borderColor: "#D62323",
                      }}
                    />
                    <Icon.Search
                      style={{
                        position: "absolute",
                        right: "-9px",
                        top: "40%",
                        transform: "translateY(-50%)",
                      }}
                    />
                  </div>
                  <Button
                    className="favaddnew"
                    style={{
                      height: "40px",
                      marginLeft: "30px",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={handleClickOpen}
                  >
                    <Icon.PlusCircle size={20} style={{ marginRight: "5px" }} />{" "}
                    Add New
                  </Button>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "20px",
                }}
              >
                {filteredEveryone.map((item, index) => (
                  <div
                    onClick={() => handleCardClick(item)}
                    key={item._id}
                    style={{
                      backgroundColor:
                        index % 6 < 3
                          ? "rgba(243, 246, 248, 1)"
                          : "rgba(253, 248, 244, 0.5)",
                      color: "black",
                      padding: "15px 25px",
                      textAlign: "left",
                      borderRadius: "5px",
                      border: "none",
                      flex: "1 0 30%",
                      maxWidth: "425px",
                      minWidth: "350px",
                      height: "141px",
                      fontWeight: "400",
                      boxSizing: "border-box",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p className="everyOneFullName">
                        {item.firstName} {item.lastName}
                      </p>
                      <Icon.StarFill
                        size={20}
                        style={{
                          cursor: "pointer",
                          stroke: "#E47331",
                          strokeWidth: "0.5px",
                          color: currentUserInfo.favouriteUsers.includes(
                            item._id
                          )
                            ? "#E47331"
                            : "white",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          addFavorite(item._id);
                        }}
                      />
                    </div>
                    <p className="everyoneEmpID">{item.empId}</p>
                  </div>
                ))}
              </div>
            </div>

            {/* Modal for displaying selected item details */}

            <Modal show={showModal} onHide={handleCloseModal} size="lg">
              <Modal.Header
                closeButton
                style={{
                  borderBottom: "1px dashed #4C4C4C",
                  padding: "10px",
                  width: "90%",
                  marginLeft: "30px",
                }}
              >
                <Modal.Title className="favTitleVIEW">View</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {selectedFavorite ? (
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr 1fr",

                      padding: "0px 20px",
                    }}
                  >
                    <div>
                      <p className="ViewLables">Full Name</p>
                    </div>
                    <div>
                      <p style={{ marginLeft: "90px" }}>:</p>
                    </div>
                    <div style={{ textAlign: "start" }}>
                      <p>
                        {selectedFavorite.firstName} {selectedFavorite.lastName}
                      </p>
                    </div>
                    <div>
                      <p style={{ width: "200px" }} className="ViewLables">
                        Contact Number
                      </p>
                    </div>
                    <div>
                      <p style={{ marginLeft: "90px" }}>:</p>
                    </div>
                    <div style={{ textAlign: "start" }}>
                      <p>{selectedFavorite.contactNumber}</p>
                    </div>
                    <div>
                      <p className="ViewLables">Role</p>
                    </div>
                    <div>
                      <p style={{ marginLeft: "90px" }}>:</p>
                    </div>
                    <div style={{ textAlign: "start" }}>
                      <p>{selectedFavorite.role}</p>
                    </div>
                    <div>
                      <p className="ViewLables">Email</p>
                    </div>
                    <div>
                      <p style={{ marginLeft: "90px" }}>:</p>
                    </div>
                    <div style={{ textAlign: "start" }}>
                      <p>{selectedFavorite.email}</p>
                    </div>
                    <div>
                      <p className="ViewLables">Employee ID</p>
                    </div>
                    <div>
                      <p style={{ marginLeft: "90px" }}>:</p>
                    </div>
                    <div style={{ textAlign: "start" }}>
                      <p>{selectedFavorite.empId}</p>
                    </div>
                    <div>
                      <p className="ViewLables">Reporting Employee Name</p>
                    </div>
                    <div>
                      <p style={{ marginLeft: "90px" }}>:</p>
                    </div>
                    <div style={{ textAlign: "start" }}>
                      <p>{selectedFavorite.reportingEmpIdName}</p>
                    </div>
                  </div>
                ) : (
                  <p>No data available</p>
                )}
              </Modal.Body>
            </Modal>

            <Dialog
              open={open}
              onClose={handleClose}
              maxWidth="lg"
              fullWidth
              PaperProps={{
                style: { borderRadius: 20, fontFamily: "Poppins" },
              }}
            >
              <>
                <div
                  style={{
                    height: "40px",
                    display: "flex",
                    backgroundColor: "#4D4D4D",
                  }}
                >
                  {" "}
                  <p
                    style={{
                      fontSize: "15px",
                      margin: "10px auto",
                      color: "#fff",
                    }}
                  >
                    {stapNames[activeStep]}
                  </p>
                  <RxCross1
                    size={16}
                    style={{
                      cursor: "pointer",
                      marginRight: "20px",
                      color: "#fff",
                      marginTop: "10px",
                    }}
                    onClick={handleClose}
                  />
                </div>

                <Stepper
                  activeStep={activeStep}
                  alternativeLabel
                  className="my-4 "
                  style={{
                    borderBottom: "1px dashed lightgray",
                    paddingBottom: "10px",
                    width: "90%",
                    margin: "auto",
                  }}
                >
                  <Step>
                    <StepLabel
                      StepIconComponent={() => (
                        <div
                          style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                            border: `2px solid #4C4C4C`,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "14px",
                            color: activeStep === 0 ? "#fff" : "#4C4C4C",
                            backgroundColor:
                              activeStep === 0 ? "#4C4C4C" : "#fff",
                          }}
                        >
                          1
                        </div>
                      )}
                    >
                      Personal Details
                    </StepLabel>
                  </Step>
                  <Step>
                    <StepLabel
                      StepIconComponent={() => (
                        <div
                          style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                            border: `2px solid #4C4C4C`,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "14px",
                            color: activeStep === 1 ? "#fff" : "#4C4C4C",
                            backgroundColor:
                              activeStep === 1 ? "#4C4C4C" : "transparent",
                          }}
                        >
                          2
                        </div>
                      )}
                    >
                      Employee Working Details
                    </StepLabel>
                  </Step>
                  <Step>
                    <StepLabel
                      StepIconComponent={() => (
                        <div
                          style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                            border: `2px solid #4C4C4C`,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "14px",
                            color: activeStep === 2 ? "#fff" : "#4C4C4C",
                            backgroundColor:
                              activeStep === 2 ? "#4C4C4C" : "transparent",
                          }}
                        >
                          3
                        </div>
                      )}
                    >
                      Enable Modules
                    </StepLabel>
                  </Step>
                  <Step>
                    <StepLabel
                      StepIconComponent={() => (
                        <div
                          style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                            border: `2px solid #4C4C4C`,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "14px",
                            color: activeStep === 3 ? "#fff" : "#4C4C4C",
                            backgroundColor:
                              activeStep === 3 ? "#4C4C4C" : "transparent",
                          }}
                        >
                          4
                        </div>
                      )}
                    >
                      Confirm Details
                    </StepLabel>
                  </Step>
                </Stepper>
              </>

              <DialogContent
                style={{
                  marginLeft: "80px",
                  marginRight: "80px",
                  overflowX: "hidden",
                  paddingTop: "0px",
                }}
              >
                <Loader
                  open={apiProcessing.loader}
                  message={apiProcessing.message}
                />
                <form className="flex flex-col justify-center items-center">
                  {activeStep === 0 ? (
                    <>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <section className="grid grid-cols-2 gap-x-16 gap-y-6  mb-4 md:gap-x-8 md:w-11/12 mt-4">
                          <div>
                            <label
                              htmlFor="firstname"
                              className="fs-10 fw-bold text-start py-2 userlableName"
                              style={{ fontSize: "14px" }}
                            >
                              First Name
                              <a style={{ color: "red" }}>*</a>
                            </label>
                            <br />
                            <input
                              type="text"
                              id="firstname"
                              name="firstname"
                              value={formValues.firstname}
                              placeholder="Enter your firstname"
                              className="form-control   fs-12"
                              style={{
                                width: "400px",
                                height: "50px",
                                boxSizing: "border-box",
                                fontSize: "14px",
                              }}
                              onChange={handleInputChange}
                            />
                            <span style={{ color: "red" }}>
                              {errors.firstname}
                            </span>
                          </div>
                          <div>
                            <label
                              htmlFor="lastname"
                              className="fs-10 fw-bold text-start py-2 userlableName"
                              style={{ fontSize: "14px" }}
                            >
                              Last Name
                              <a style={{ color: "red" }}>*</a>
                            </label>
                            <br />
                            <input
                              type="text"
                              id="lastname"
                              name="lastname"
                              placeholder="Enter your lastname"
                              className="form-control   fs-12"
                              value={formValues.lastname}
                              style={{
                                width: "400px",
                                height: "50px",
                                boxSizing: "border-box",
                                fontSize: "14px",
                              }}
                              onChange={handleInputChange}
                            />
                            <span style={{ color: "red" }}>
                              {errors.lastname}
                            </span>
                          </div>

                          <div>
                            <label
                              htmlFor="contactnumber"
                              className="fs-10 fw-bold text-start py-2 userlableName"
                              style={{ fontSize: "14px" }}
                            >
                              Contact Number
                              <a style={{ color: "red" }}>*</a>
                            </label>
                            <br />
                            <input
                              type="tel"
                              id="phone"
                              name="contactnumber"
                              pattern="\d{10}"
                              maxLength="10"
                              placeholder="Enter your Contact Number"
                              value={formValues.contactnumber}
                              className={`form-control ${
                                errors.contactnumber ? "is-invalid" : ""
                              }`}
                              style={{
                                width: "400px",
                                height: "50px",
                                boxSizing: "border-box",
                                fontSize: "14px",
                              }}
                              onInput={(e) => {
                                e.target.value = e.target.value.replace(
                                  /\D/,
                                  ""
                                ); // Replace non-digit characters
                                handleInputChange(e); // Call the existing change handler
                              }}
                            />
                            <div className="invalid-feedback">
                              {errors.contactnumber}
                            </div>
                          </div>

                          <div>
                            <label
                              htmlFor="emailid"
                              className="fs-10 fw-bold text-start py-2 userlableName"
                              style={{ fontSize: "14px" }}
                            >
                              Email ID
                              <a style={{ color: "red" }}>*</a>
                            </label>
                            <br />
                            <input
                              type="text"
                              id="emailid"
                              name="emailid"
                              placeholder="Enter your Email"
                              className="form-control fs-12"
                              value={formValues.emailid}
                              style={{
                                width: "400px",
                                height: "50px",
                                boxSizing: "border-box",
                                fontSize: "14px",
                              }}
                              onChange={handleInputChange}
                            />
                            <span style={{ color: "red" }}>
                              {errors.emailid}
                            </span>
                          </div>

                          <div>
                            <label
                              htmlFor="bloodGroup"
                              className="fs-10 fw-bold text-start py-2 userlableName"
                              style={{ fontSize: "14px" }}
                            >
                              Blood Group
                              <a style={{ color: "red" }}>*</a>
                            </label>
                            <br />
                            <select
                              id="bloodGroup"
                              name="bloodGroup"
                              className="form-control fs-12"
                              style={{
                                width: "400px",
                                height: "50px",
                                padding: "10px",
                                boxSizing: "border-box",
                                fontSize: "14px",
                              }}
                              onChange={handleInputChange}
                            >
                              <option value="">Select Blood Group</option>
                              <option value="A+">A+</option>
                              <option value="A-">A-</option>
                              <option value="B+">B+</option>
                              <option value="B-">B-</option>
                              <option value="O+">O+</option>
                              <option value="O-">O-</option>
                              <option value="AB-">AB-</option>
                              <option value="AB+">AB+</option>
                            </select>
                            <span style={{ color: "red" }}>
                              {errors.bloodGroup}
                            </span>
                          </div>

                          <div>
                            <label
                              htmlFor="emergencyContactnumber"
                              className="fs-10 fw-bold text-start py-2 userlableName"
                              style={{ width: "500px", fontSize: "14px" }}
                            >
                              Emergency Contact Number
                              <a style={{ color: "red" }}>*</a>
                            </label>
                            <br />
                            <input
                              type="tel"
                              id="emergencyContactnumber"
                              name="emergencyContactnumber"
                              placeholder="Enter your Emergency Contact Number"
                              className={`form-control ${
                                errors.emergencyContactnumber
                                  ? "is-invalid"
                                  : ""
                              }`}
                              pattern="\d{10}"
                              maxLength="10"
                              style={{
                                width: "400px",
                                height: "50px",
                                boxSizing: "border-box",
                                fontSize: "14px",
                              }}
                              onInput={(e) => {
                                e.target.value = e.target.value.replace(
                                  /\D/,
                                  ""
                                ); // Replace non-digit characters
                                handleInputChange(e); // Call the existing change handler
                              }}
                            />
                            <div className="invalid-feedback">
                              {errors.emergencyContactnumber}
                            </div>
                          </div>
                        </section>
                      </div>
                    </>
                  ) : activeStep === 1 ? (
                    <>
                      {/* Employee Working Details */}

                      <div className="container">
                        <div>
                          {/* <div>
                            <label
                              htmlFor="reportingEmpIdName"
                              className="fs-10 fw-bold text-start userlableName"
                            >
                              Reporting Employee ID / Name
                              <a style={{ color: "red" }}>*</a>
                            </label>
                            <br />

                            <Select
                              options={options}
                              placeholder="Select an option"
                              readonly
                              onChange={(selectedOption) =>
                                handleInputChange({
                                  target: {
                                    name: "reportingEmpIdName",
                                    value: selectedOption.value,
                                  },
                                })
                              }
                              // className="basic-single"
                              // classNamePrefix="select"
                              // isClearable
                              // isSearchable
                              name="reportingEmpIdName"
                              styles={customStyles}
                            />

                            <span style={{ color: "red" }}>
                              {errors.reportingEmpIdName}
                            </span>
                          </div> */}

                          <CustomDropdown
                            options={options}
                            handleInputChange={handleInputChange}
                            errors={errors}
                          />

                          <div style={{ marginTop: "100px" }}>
                            <label
                              htmlFor="employeeId"
                              className="fs-10 fw-bold text-start py-2 userlableName"
                              style={{ fontSize: "14px" }}
                            >
                              Employee Id
                            </label>
                            <br />
                            <input
                              type="text"
                              id="employeeId"
                              name="employeeId"
                              value={formValues.employeeId}
                              placeholder="Enter Employee Id"
                              className="form-control fs-12"
                              style={{
                                boxSizing: "border-box",
                                fontSize: "14px",
                                height: "50px",
                              }}
                              onChange={handleInputChange}
                            />
                            <span style={{ color: "red" }}>
                              {errors.employeeId}
                            </span>
                          </div>

                          <section className="grid grid-cols-2 gap-x-16 gap-y-6 w-5/6 mb-4 md:gap-x-8 md:w-11/12 mt-4">
                            <div>
                              <MultiSelectSearch
                                mandatory={true}
                                heading={"Site"}
                                listItems={datasites}
                                selectedIds={selectedSiteIds}
                                setSelectedIds={setSelectedSiteIds}
                                handleSelectedPropertyOption={(data) => {
                                  if (selectedSiteIds.includes(data.id)) {
                                    setSelectedSiteIds(
                                      selectedSiteIds.filter(
                                        (id) => id !== data.id
                                      )
                                    );
                                  } else {
                                    setSelectedSiteIds([
                                      ...selectedSiteIds,
                                      data.id,
                                    ]);
                                  }
                                }}
                                clearAll={() => {
                                  setSelectedSiteIds([]);
                                }}
                                addAll={() => {
                                  const allIds = datasites.map(
                                    (permittedSite) => permittedSite.id
                                  );
                                  setSelectedSiteIds(allIds);
                                }}
                              />
                            </div>

                            <div>
                              <label
                                htmlFor="department"
                                className="fs-10 fw-bold text-start py-2 userlableName"
                              >
                                Department
                              </label>
                              <br />
                              <select
                                value={selectedDepartment}
                                className="form-control fs-12"
                                style={{
                                  width: "400px",
                                  height: "50px",
                                }}
                                onChange={handleDepartmentChange}
                              >
                                <option value="">Select</option>
                                {Object.keys(departmentData).map(
                                  (department) => (
                                    <option key={department} value={department}>
                                      {department}
                                    </option>
                                  )
                                )}
                              </select>
                              <span style={{ color: "red" }}>
                                {errors.selectedDepartment}
                              </span>
                            </div>

                            {selectedDepartment && (
                              <div>
                                <label
                                  htmlFor="selectRole"
                                  className="fs-10 fw-bold text-start py-2 userlableName"
                                >
                                  Select Role
                                </label>
                                <br />
                                <select
                                  id="selectRole"
                                  name="selectRole"
                                  className="form-select fs-12"
                                  style={{
                                    width: "400px",
                                  }}
                                  value={selectedRole}
                                  onChange={(e) => {
                                    setSelectedRole(e.target.value);
                                  }}
                                >
                                  <option value="">Select Role</option>
                                  {roles.map((role) => (
                                    <option key={role} value={role}>
                                      {role}
                                    </option>
                                  ))}
                                </select>
                                <span style={{ color: "red" }}>
                                  {errors.selectedRole}
                                </span>
                              </div>
                            )}

                            {selectedRole && workModels.length > 0 && (
                              <div>
                                <label
                                  htmlFor="workModel"
                                  className="fs-10 fw-bold text-start py-2 userlableName"
                                >
                                  Select Work Model
                                </label>
                                <br />
                                <select
                                  id="workModel"
                                  name="workModel"
                                  className="form-select fs-12"
                                  style={{
                                    width: "400px",
                                  }}
                                  value={selectedWorkModel}
                                  onChange={(e) =>
                                    setSelectedWorkModel(e.target.value)
                                  }
                                >
                                  <option value="">Select Work Model</option>
                                  {workModels.map((workModel) => (
                                    <option key={workModel} value={workModel}>
                                      {workModel}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            )}
                          </section>
                        </div>
                      </div>
                    </>
                  ) : activeStep === 2 ? (
                    <>
                      <div className="container">
                        <div>
                          <h3>Enable Modules</h3>
                          {selectedSiteIds.map((siteId) => {
                            // Find the site data for the current siteId
                            const siteData = allData.find(
                              (data) => data.siteId === siteId
                            );

                            // If no site data is found for the current siteId, skip this iteration
                            if (!siteData) {
                              return null;
                            }

                            const { siteName, enabledModules } = siteData;

                            const moduleLabels = {
                              drawing: "Drawing",
                              pAndM: "P & M",
                              qaAndQc: "QA/QC",
                              ehs: "EHS",
                              qs: "QS",
                              hr: "HR",
                              user: "USER",
                              store: "STORE",
                            };

                            return (
                              <div
                                key={siteId}
                                className="flex flex-col mt-5 pb-10"
                              >
                                <h4>{siteName}</h4>
                                <div
                                  className="formData-grid"
                                  style={{
                                    display: "grid",
                                    gridTemplateColumns: "repeat(4, 1fr)",
                                    gap: "30px",
                                    alignItems: "center",
                                    justifyItems: "center",
                                  }}
                                >
                                  {enabledModules.map((module) => (
                                    <ToggleSwitch
                                      key={module}
                                      label={moduleLabels[module] || module}
                                      isChecked={formValues[module] || false}
                                      onToggle={() => handleToggle(module)}
                                    />
                                  ))}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </>
                  ) : activeStep === 3 ? (
                    <>
                      <section className="grid grid-cols-2 gap-x-16 gap-y-6  mb-4 md:gap-x-8 md:w-11/12 mt-4 w-100">
                        <div className="py-3">
                          <div className="row">
                            <div className="col-5">Full Name</div>
                            <div className="col-2">:</div>
                            <div className="col-5 text-start">
                              {formValues.firstname} {formValues.lastname}
                            </div>
                          </div>

                          <div className="row mt-3">
                            <div className="col-5">Contact Number</div>
                            <div className="col-2">:</div>
                            <div className="col-5 text-start">
                              {formValues.contactnumber}
                            </div>
                          </div>

                          <div className="row mt-3">
                            <div className="col-5">Email ID</div>
                            <div className="col-2">:</div>
                            <div className="col-5 text-start">
                              {formValues.emailid}
                            </div>
                          </div>

                          <div className="row mt-3">
                            <div className="col-5">Blood Group</div>
                            <div className="col-2">:</div>
                            <div className="col-5 text-start">
                              {formValues.bloodGroup}
                            </div>
                          </div>

                          <div className="row mt-3">
                            <div className="col-5">Enable Modules</div>
                            <div className="col-2">:</div>
                            <div className="col-5 text-start">
                              {Object.entries(formValues)
                                .filter(
                                  ([key, value]) =>
                                    value &&
                                    [
                                      "drawing",
                                      "pm",
                                      "qaqc",
                                      "ehs",
                                      "qs",
                                      "hr",
                                      "user",
                                      "store",
                                    ].includes(key)
                                )
                                .map(
                                  ([key]) =>
                                    key.charAt(0).toUpperCase() + key.slice(1)
                                )
                                .join(", ") || "None"}
                            </div>
                          </div>
                        </div>
                        <div className="">
                          <div className="row ">
                            <div className="col-5">
                              Reporting Employee ID / Name
                            </div>
                            <div className="col-2">:</div>
                            <div className="col-5 text-start">
                              {formValues.reportingEmpIdName
                                ? formValues.reportingEmpIdName.split("|")[1] ||
                                  "N/A"
                                : "N/A"}{" "}
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-5">Employee ID</div>
                            <div className="col-2">:</div>
                            <div className="col-5 text-start">
                              {formValues.employeeId}
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-5">Site</div>
                            <div className="col-2">:</div>
                            <div className="col-5 text-start">
                              {formValues.permittedSite.length} Sites Selected
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-5">Department</div>
                            <div className="col-2">:</div>
                            <div className="col-5 text-start">
                              {selectedDepartment}
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-5">Role</div>
                            <div className="col-2">:</div>
                            <div className="col-5 text-start">
                              {selectedRole}
                            </div>
                          </div>

                          {selectedWorkModel && (
                            <div className="row mt-3">
                              <div className="col-5">Work Module</div>
                              <div className="col-2">:</div>
                              <div className="col-5 text-start">
                                {selectedWorkModel}
                              </div>
                            </div>
                          )}
                        </div>
                      </section>
                    </>
                  ) : null}
                  {errorMessage && (
                    <div
                      style={{
                        color: "red",
                        fontSize: "15px",
                        textAlign: "center",
                      }}
                    >
                      {errorMessage}
                    </div>
                  )}
                  <div className="flex justify-between w-full mt-8">
                    <button
                      type="button"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className="  py-2 px-4"
                      style={{
                        border: "1px solid #E47331",
                        color: "#E47331",
                        fontSize: "20px",
                      }}
                    >
                      BACK
                    </button>
                    <button
                      type="button"
                      onClick={handleNext}
                      className=" text-white py-2 px-4"
                      style={{
                        backgroundColor: "#E47331",
                        color: "#fff",
                      }}
                    >
                      {activeStep === 3 ? "Finish" : "Next"}
                    </button>
                  </div>
                </form>
              </DialogContent>
            </Dialog>
          </>
        )}
      </div>
    </>
  );
};

export default Employee;
