import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

function PagesHeroSection({ imageUrl, title }) {
  return (
    <section className="position-relative text-center">
      <style>
        {`
          .pageHeroBackground {
            object-fit: cover;
          }

          .productTitle {
            color: #fff;
            font-size: 45px;
            font-weight: 500;
            font-style: normal;
          }
          
          .productTitleContainer{
            margin-left: 70px;
          }

          @media (min-width: 980px) {
          .productTitleContainer{
            margin-left: 108px;
          }
          }

          @media (max-width: 567px) {
            .productTitle {
              font-size: 20px;
            }
          }
            @media only screen and (min-width: 567px) and (max-width: 768px){
             .productTitle {
              font-size: 25px;
            }
              .pageHeroBackground{
              max-width: 100%;
              height: auto;
              }
            }
            @media only screen and (min-width: 769px) and (max-width: 992px){
             .productTitle {
              font-size: 30px;
            }
              .pageHeroBackground{
              max-width: 100%;
              height: auto;
              }
            }
            @media only screen and (min-width: 993px) and (max-width: 1200px){
             .productTitle {
              font-size: 38px;
            }
              .pageHeroBackground{
              max-width: 100%;
              height: auto;
              }
            }
        `}
      </style>

      <img
        src={imageUrl}
        alt="Hero Background"
        className="img-fluid w-100 pageHeroBackground"
      />
      <div
        className="position-absolute top-50 start-25 translate-start productTitleContainer"
      >
        <p className="text-white productTitle">{title}</p>
      </div>
    </section>
  );
}

export default PagesHeroSection;
