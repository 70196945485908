import { WebApimanager } from "../../WebApiManager";
import { SUCESS_STATUS, FAILED_STATUS } from "../../Utilities/Constants";

export const getRegisterData = async (site) => {
    let webApi = new WebApimanager();
    let response = await webApi.get(`api/architectureToRoRegister/getRegisterBySiteId?siteId=${site}`)
    return response
}

export const updateRegister = async (setRegister, site) => {
    const response = await getRegisterData(site)
    if(response.data && response.data.data && response.data.data.registers){
      setRegister(response.data.data.registers)
      return {
        status: SUCESS_STATUS,
        registers: response.data.data.registers
      }
    }else{
      return {
        status: FAILED_STATUS,
        registers: []
      }
    }
}

export const processFile = async (response, action, navigate)  => {
  const contentType = response.headers["content-type"];
  const fileBlob = new Blob([response.data], { type: contentType });
  console.log('here', contentType)

  if (contentType.includes("application/json"))  {
    // Handle JSON response (DWG file case)
    try {
      // Create a text reader for the Blob
      const text = await fileBlob.text();
      // Parse the text as JSON
      const jsonData = JSON.parse(text);

      console.log('data', jsonData);
      const urn = jsonData.data.urn;
      const token = jsonData.data.token;
      console.log('urn', urn);

      if (urn && token) {
        // Navigate to DWG viewer
        navigate(`/dwgviewer?urn=${urn}&token=${token}`);
        return; // Exit function after navigation
      }
    } catch (error) {
      console.error("Failed to parse JSON from Blob:", error);
    }
  } else if (
    contentType.startsWith("application/octet-stream") ||
    contentType.startsWith("image/") ||
    contentType.startsWith("application/pdf")
  ) {
    if (action === "view") {
      const fileBlob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      if (contentType.startsWith("image/")) {
        // Handle image files
        const url = window.URL.createObjectURL(fileBlob);
        const link = document.createElement("a");
        link.href = url;
        link.target = "_blank"; // Open link in a new tab
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);

      } else if (contentType.startsWith("application/pdf")) {
        // Handle PDF files
        const pdfUrl = window.URL.createObjectURL(fileBlob);
        window.open(pdfUrl, "_blank"); // Open the PDF in a new tab
        window.URL.revokeObjectURL(pdfUrl); // Revoke the object URL after it's opened

      }
    } else if (action === "download") {
      // Extract file name and type from the response headers or assume defaults
      const contentDisposition = response.headers["content-disposition"];
      let fileName = "downloaded_file";
      let mimeType = contentType || "application/octet-stream"; // Default MIME type

      // Map MIME types to file extensions
      const mimeTypeToExtension = {
        "image/jpeg": "jpg",
        "image/png": "png",
        "image/gif": "gif",
        "image/vnd.dwg": "dwg",
        "application/vnd.dwg": "dwg",
        "application/x-autocad": "dwg",
        "application/pdf": "pdf",
        "text/plain": "txt",
        "application/zip": "zip",
        // Add other MIME types and their extensions as needed
      };

      // Determine the file extension based on MIME type
      const fileExtension = mimeTypeToExtension[mimeType] || "bin"; // Default to 'bin' if not found

      // Adjust file name based on the file extension
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch) {
          fileName = fileNameMatch[1];
        }
      }

      // Ensure the file name has the correct extension
      if (!fileName.toLowerCase().endsWith(`.${fileExtension}`)) {
        fileName = `${fileName}.${fileExtension}`;
      }

      // Create a URL for the Blob and initiate download
      const url = window.URL.createObjectURL(fileBlob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    }
  } else {
    console.error("Unexpected content type:", contentType);
  }
};
