import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../ApplyPartner/ApplyPartner.css";
import { WebApimanager } from "../../WebApiManager";
import { useNavigate } from "react-router-dom";

const ApplyPartnership = () => {
  const [formData, setFormData] = useState({
    name: "",
    contactNo: "",
    jobTitle: "",
    partnerType: "",
    solutionFocus: "",
    email: "",
    companyName: "",
    websiteUrl: "",
    industryFocus: "",
    numberOfEmployees: "",
    // numberOfConsultants: "",
  });

  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();

  const WebApi = new WebApimanager();

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Validate mobile number to allow only numeric input
    if (name === "contactNo" && !/^\d*$/.test(value)) {
      return; // Prevent setting value if it's not numeric
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    let formErrors = {};

    // Check required fields
    Object.keys(formData).forEach((field) => {
      if (!formData[field]) {
        formErrors[field] = "This field is required";
      }
    });

    // Additional validations
    if (formData.contactNo && !/^\d{10}$/.test(formData.contactNo)) {
      formErrors.contactNo = "Please enter a valid 10-digit mobile number";
    }

    // More validation logic can be added here

    setErrors(formErrors);

    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        const dataToSent = {
          name: formData.name,
          email: formData.email,
          contactNo: formData.contactNo,
          companyName: formData.companyName,
          jobTitle: formData.jobTitle,
          websiteUrl: formData.websiteUrl,
          partnerType: formData.partnerType,
          industryFocus: formData.industryFocus,
          solutionFocus: formData.solutionFocus,
          numberOfEmployees: formData.numberOfEmployees,
          // numberOfConsultants: formData.numberOfConsultants,
        };
        const ApplyPartenerShip = await WebApi.post("api/partners", dataToSent);
        console.log("Form submitted successfully!", ApplyPartenerShip.data);

        // Reset form and errors
        setFormData({
          name: "",
          contactNo: "",
          jobTitle: "",
          partnerType: "",
          solutionFocus: "",
          email: "",
          companyName: "",
          websiteUrl: "",
          industryFocus: "",
          numberOfEmployees: "",
          // numberOfConsultants: "",
        });

        setErrors({});

        setSuccessMessage("submitted successfully");

        setTimeout(() => {
          navigate("/partners");
        }, 2000);
      } catch (error) {
        if (error.response) {
          // Server responded with a status other than 2xx
          console.error("Server Error:", error.response.data);
        } else if (error.request) {
          // Request was made but no response received
          console.error("Network Error:", error.message);
        } else {
          // Something else happened
          console.error("Error:", error.message);
        }
      }
    } else {
      console.log("Form has errors.");
    }
  };

  return (
    <div className="container-fluid p-5" style={{ backgroundColor: "#f0f0f0" }}>
      <div className="container">
        <form onSubmit={handleSubmit}>
          <div className="partnerContainer">
            <div className="d-flex justify-content-center">
              <div className="partnerHeading text-center">
                <p>
                  We appreciate you showing interest in helping our company
                  globally.<br/>
                  We just need a few details to aid us know you better.
                </p>
              </div>
            </div>
            <div className="row mt-3 partnerForm">
              <div className="col-12 col-lg-6 mt-3">
                <div className="mb-3">
                  <label className="form-label d-block">
                    Name <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    className="form-control custom-input mx-auto"
                    placeholder="Enter your name"
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                  {errors.name && (
                    <small className="text-danger errorSMS">
                      {errors.name}
                    </small>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label d-block">
                    Contact No <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="tel"
                    name="contactNo"
                    className="form-control custom-input mx-auto"
                    placeholder="Enter your contact number"
                    value={formData.contactNo}
                    onChange={handleInputChange}
                  />
                  {errors.contactNo && (
                    <small className="text-danger errorSMS">
                      {errors.contactNo}
                    </small>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label d-block">
                    Job Title <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    name="jobTitle"
                    className="form-control custom-input mx-auto"
                    placeholder="Enter your job title"
                    value={formData.jobTitle}
                    onChange={handleInputChange}
                  />
                  {errors.jobTitle && (
                    <small className="text-danger errorSMS">
                      {errors.jobTitle}
                    </small>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label d-block">
                    Partner Type <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    name="partnerType"
                    className="form-control custom-input mx-auto"
                    value={formData.partnerType}
                    onChange={handleInputChange}
                  >
                    <option value="">Select partner type</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                  {errors.partnerType && (
                    <small className="text-danger errorSMS">
                      {errors.partnerType}
                    </small>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label d-block">
                    Solution Focus <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    name="solutionFocus"
                    className="form-control custom-input mx-auto"
                    placeholder="Enter solution focus"
                    value={formData.solutionFocus}
                    onChange={handleInputChange}
                  />
                  {errors.solutionFocus && (
                    <small className="text-danger errorSMS">
                      {errors.solutionFocus}
                    </small>
                  )}
                </div>
              </div>

              {/* Right Column */}
              <div className="col-12 col-md-6 mt-3">
                <div className="mb-3">
                  <label className="form-label d-block">
                    Email <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="email"
                    name="email"
                    className="form-control custom-input mx-auto"
                    placeholder="Enter your email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                  {errors.email && (
                    <small className="text-danger errorSMS">
                      {errors.email}
                    </small>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label d-block">
                    Company Name <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    name="companyName"
                    className="form-control custom-input mx-auto"
                    placeholder="Enter company name"
                    value={formData.companyName}
                    onChange={handleInputChange}
                  />
                  {errors.companyName && (
                    <small className="text-danger errorSMS">
                      {errors.companyName}
                    </small>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label d-block">
                    Website URL <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    name="websiteUrl"
                    className="form-control custom-input mx-auto"
                    placeholder="Enter website URL"
                    value={formData.websiteUrl}
                    onChange={handleInputChange}
                  />
                  {errors.websiteUrl && (
                    <small className="text-danger errorSMS">
                      {errors.websiteUrl}
                    </small>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label d-block">
                    Industry Focus <span style={{ color: "red" }}>*</span>
                  </label>

                  <select
                    name="industryFocus"
                    className="form-control custom-input mx-auto"
                    value={formData.industryFocus}
                    onChange={handleInputChange}
                  >
                    <option value="">Select industry focus</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>

                  {errors.industryFocus && (
                    <small className="text-danger errorSMS">
                      {errors.industryFocus}
                    </small>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label d-block">
                    Number of Employees <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    name="numberOfEmployees"
                    className="form-control custom-input mx-auto"
                    placeholder="Enter number of employees"
                    value={formData.numberOfEmployees}
                    onChange={handleInputChange}
                  />
                  {errors.numberOfEmployees && (
                    <small className="text-danger errorSMS">
                      {errors.numberOfEmployees}
                    </small>
                  )}
                </div>
              </div>

              {/* <div className="mb-3 mt-2">
                <label className="form-label d-block">
                  Number of Consultants <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="numberOfConsultants"
                  className="form-control mx-auto numberofEmployees"
                  placeholder="Enter number of consultants"
                  value={formData.numberOfConsultants}
                  onChange={handleInputChange}
                />
                {errors.numberOfConsultants && (
                  <small className="text-danger errorSMS">
                    {errors.numberOfConsultants}
                  </small>
                )}
              </div> */}

              <div className="d-flex justify-content-center mb-3 mt-4">
                <div className="p-2 allfieldsmark">
                  All fields marked in * are mandatory
                </div>
              </div>

              {successMessage && (
                <div className="alert alert-success text-center">
                  {successMessage}
                </div>
              )}

              {/* Apply for Partnership button */}
              <div className="d-flex justify-content-center mb-3 mt-3">
                <button className="p-2 ApplyButton" type="submit">
                  Apply for Partnership
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ApplyPartnership;
