import React, { useState } from "react";
import { Link } from "react-router-dom";
import AssetCodeCreation from "../AssetCodeCreation";
import AddnewPnm from "../AddnewPnm/AddnewPnm";

const Options = () => {
  const [assetStyle, setAssetStyle] = useState({
    backgroundColor: "#f3f3f3",
    text: "#E47331",
    display: true,
  });

  const [newPnMStyle, setNewPnMStyle] = useState({
    backgroundColor: "#f3f3f3",
    text: "#E47331",
    display: false,
  });

  const [transferStyle, setTransferStyle] = useState({
    backgroundColor: "#f3f3f3",
    text: "#E47331",
    display: false,
  });

  return (
    <div className="flex no-underline w-screen h-screen justify-center">
      <Link
        to="#"
        className={` no-underline  text-[18px] w-[325px] border-[#E47331] h-[50px] text-center border-[1px] py-2.5 ${
          assetStyle.display
            ? "bg-[#E47331] text-white"
            : "bg-[#f3f3f3] text-[#E47331]"
        }   `}
        onClick={() => {
          setAssetStyle({
            backgroundColor: "#E47331",
            text: "#f3f3f3",
            display: true,
          });
          setNewPnMStyle({
            backgroundColor: "#f3f3f3",
            text: "#E47331",
            display: false,
          });
          setTransferStyle({
            backgroundColor: "#f3f3f3",
            text: "#E47331",
            display: false,
          });
        }}
      >
        <AssetCodeCreation assetStyle={assetStyle} />
      </Link>
      <Link
        to="#"
        className={` no-underline  text-[18px] w-[325px] h-[50px] text-center border-[1px] border-[#E47331] py-2.5 
          ${
            newPnMStyle.display
              ? "bg-[#E47331] text-white"
              : "bg-[#f3f3f3] text-[#E47331]"
          }
         `}
        onClick={() => {
          setAssetStyle({
            backgroundColor: "#f3f3f3",
            text: "#E47331",
            display: false,
          });
          setNewPnMStyle({
            backgroundColor: "#E47331",
            text: "#f3f3f3",
            display: true,
          });
          setTransferStyle({
            backgroundColor: "#f3f3f3",
            text: "#E47331",
            display: false,
          });
        }}
      >
        <AddnewPnm newPnMStyle={newPnMStyle} />
      </Link>
      <Link
        to="#"
        className={` no-underline border-[#E47331] text-[18px] w-[325px] h-[50px] text-center border-[1px] py-2.5 bg-[${
          transferStyle.display
            ? "bg-[#E47331] text-white"
            : "bg-white text-[#E47331]"
        }]  `}
        onClick={() => {
          setAssetStyle({
            backgroundColor: "#f3f3f3",
            text: "#E47331",
            display: false,
          });
          setNewPnMStyle({
            backgroundColor: "#f3f3f3",
            text: "#E47331",
            display: false,
          });
          setTransferStyle({
            backgroundColor: "#E47331",
            text: "#f3f3f3",
            display: true,
          });
        }}
      >
        Transfer
      </Link>
    </div>
  );
};

export default Options;
