import React, { useState, useEffect } from "react";
import { Tabs, Tab, Typography, Box } from "@mui/material";
import ROtoSiteLevel from "./ROtoSiteLevel/ROtoSiteLevel";
import ArchitectureToRo from "./ArichtectureToRo/ArchitectureToRo";
import SiteLevel from "./SiteLevel/SiteLevel";
import { useRecoilState } from "recoil";
import { drawingTab, registerAtom, siteId } from "../../atoms";
import SitesDropdown from "../../Utilities/SitesDropdown";
import { WebApimanager } from "../../WebApiManager";
import "./drawing.module.css";
import { Loader } from "../../Widgets/notificationFeedbacks";

function Drawing() {
  let webApi = new WebApimanager();
  const [value, setValue] = useRecoilState(drawingTab);
  const [site, setSite] = useRecoilState(siteId);
  const [register, setRegister] = useRecoilState(registerAtom);
  const [sites, setDatasites] = useState([]);
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });
  const handleSiteChange = async (e) => {
    setSite(e.target.value);
    setRegister([]);
    setApiProcessing({
      loader: true,
      message: "Loading...",
    });

    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setApiProcessing({
        loader: false,
        message: "",
      });
    } catch (error) {
      console.error("Error occurred while changing the site:", error);
      setApiProcessing({
        loader: false,
        message: "Failed to load site data",
      });
    }
  };

  // const handleSiteChange = (e) => {
  //   setSite(e.target.value);
  //   setRegister([]);
  //   setApiProcessing({
  //     loader: true,
  //     message: "Loading...",
  //   });
  //   setTimeout(() => {}, 1000);
  //   setApiProcessing({ loader: false, message: "" });
  // };

  useEffect(() => {
    webApi
      .get("api/sites/getAllSitesInfo")
      .then((response) => {
        console.log("API response:", response);
        if (response.data && Array.isArray(response.data.sites)) {
          setDatasites(response.data.sites);
          if (site === "") {
            setSite(response.data.sites[0]._id);
          }
        } else {
          console.error("Unexpected data format:", response.data);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      {/* <Loader open={apiProcessing.loader} message={apiProcessing.message} /> */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "20px 20px 0px 20px",
          marginTop: "12px",
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            marginLeft: "200px",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            sx={{
              "& .MuiTabs-indicator": {
                backgroundColor: "var(--primary-color)", // Custom color for active tab indicator
              },
              "& .MuiTab-root": {
                textTransform: "none", // Prevents text from being transformed to uppercase
                color: "#131313", // Text color for all tabs
                borderBottom: "none",
                fontWeight: "bold",
              },
              "& .Mui-selected": {
                color: "#131313 !important", // Text color for selected tab
              },
              "& .MuiTab-root:not(.Mui-selected)": {
                color: "#131313", // Text color for non-selected tabs
              },
            }}
          >
            <Tab label="Architect->Ro" />
            <Tab label="RO->Site" />
            <Tab label="SiteLevel" />
          </Tabs>
        </div>
        <div style={{ marginLeft: "auto" }}>
          <SitesDropdown
            siteId={site}
            handleSiteChange={handleSiteChange}
            sites={sites}
            defaultValue={"Select Site"}
          />
        </div>
      </div>

      <TabPanel value={value} index={0}>
        <ArchitectureToRo />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ROtoSiteLevel />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <SiteLevel />
      </TabPanel>
    </>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <>
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
        style={{
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {value === index && (
          <Box p={3} style={{ textAlign: "center", width: "100%" }}>
            <Typography style={{ width: "100%" }}>{children}</Typography>
          </Box>
        )}
      </div>
      <style jsx>{`
        .MuiBox-root {
          width: 100%;
        }
      `}</style>
    </>
  );
}

export default Drawing;
