import { React, useState } from "react";
import styles from "./ContactHeroSection.module.css";
import Header from "../Components/Navbar/Header";
import ContactUsImg from "./ContactUsImg.png"
import PagesHeroSection from "../Components/constants-comp/pagesHerosection";
import email from "./email.png"
import Group from "./Group.png"
import location from "./location.png"
import { WebApimanager } from "../WebApiManager";
import Vector from "./Vector.png"

function ContactHeroSection() {
  const webApi = new WebApimanager();
  const [formData, setFormData] = useState({
    fullName: "",
    mobileNumber: "",
    email: "",
    message: "",
    role:"",
    companyName:"",
    checkbox:false
  });

  const [errors, setErrors] = useState({
    fullName: "",
    mobileNumber: "",
    email: "",
    message: "",
    role:"",
    companyName:"",
    checkbox:""
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    let valid = true;
    let newErrors = { ...errors };

    if (!formData.fullName.trim()) {
      newErrors.fullName = "Full Name is required.";
      valid = false;
    } else {
      newErrors.fullName = "";
    }

    const mobileRegex =/^[789]\d{9}$/;
    if(!formData.mobileNumber){
      newErrors.mobileNumber="Mobile Number is required"
      valid = false;
    }
     else if (!mobileRegex.test(formData.mobileNumber)) {
      newErrors.mobileNumber = "Enter a valid 10-digit mobile number.";
      valid = false;
    } else {
      newErrors.mobileNumber = "";
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if(!formData.email){
      newErrors.email = "email address is required.";
      valid = false;
    }
    else if (!emailRegex.test(formData.email)) {
      newErrors.email = "Enter a valid email address.";
      valid = false;
    } else {
      newErrors.email = "";
    }
    if(!formData.companyName.trim()){
      newErrors.companyName ="company Name is required"
      valid=false;
    }
    else{
      newErrors.companyName=""
    }
   
    if(!formData.role){
      newErrors.role="role is required"
      valid=false
    } else {
      newErrors.role=""
    }
    if(!formData.message){
      newErrors.message="Message is required"
      valid=false;
    } else {
      newErrors.message=""
    }
    if(!formData.inquiryType){
      newErrors.inquiryType="inquiryType is required"
      valid=false;
    }
    else{
      newErrors.inquiryType=""
    }
    if(!formData.checkbox){
      newErrors.checkbox="checkBox is required"
      valid=false;
    }
    else{
      newErrors.checkbox=""
    }
    setErrors(newErrors);

    if (valid && formData.checkbox) {
      console.log("Form submitted:", formData);
      const submissionData ={
        name: formData.fullName,
        email: formData.email,
        phoneNumber: formData.mobileNumber,
        comapanyName: formData.companyName,
        role: formData.role,
        inquiryType:formData.inquiryType ,
        message:formData.message 
      }
      const response = await webApi.post("api/contactUs", submissionData);
      
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if(name==="checkBox"){
      setFormData({...formData,[name]:true})
    }
    
      setFormData({ ...formData, [name]: value });
    
  };
  const redirectToWatsapp=()=>{
    const phoneNumber = +919491116000; 
            const message = encodeURIComponent('Hello, I would like to chat with you!'); 
            window.location.href = `https://wa.me/${phoneNumber}?text=${message}`;
  }
  const openMap =()=>{
    const latitude = 17.441557; 
            const longitude = 78.445641; 
            
            const mapUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
            
            window.location.href = mapUrl;
  }

  return (
    <>
      <Header />
      <PagesHeroSection
        imageUrl={ContactUsImg}
        title={"Contact Us"}
      />
      <main classsName={styles.MainContainer}>
        <div className={styles.ContactDetailsContainer}>
            <div className={styles.ContentContainer}>
            <div className={styles.DetailsContainer} onClick={()=>window.location.href = 'tel:+919491116000'}>
              <img style={{marginBottom:"10px",height:"45px",width:"41px"}} src={Vector} alt="Phn" />
              <p className={styles.Text} style={{fontSize:"12px"}}>Contact Us</p>
              <p  className={styles.Text} style={{fontSize:"14px"}}>+919491116000</p>
            </div>
            <div className={styles.DetailsContainer} onClick={()=>redirectToWatsapp()}>
            <img style={{marginBottom:"10px"}} src={Group} alt="Watsapp" />

              <p  className={styles.Text} style={{fontSize:"12px"}}>Watsapp Us</p>
              <p  className={styles.Text} style={{fontSize:"14px"}}>+919491116000</p>
            </div>
            </div>
            <div className={styles.ContentContainer}>
            <div className={styles.DetailsContainer} onClick={()=>{const email = 'example@example.com'; 
                                                                window.location.href = `mailto:${email}`}}>
              <img style={{marginBottom:"10px"}} src={email} alt="Email" />
              <p  className={styles.Text} style={{fontSize:"12px"}}>Email Us</p>
              <p  className={styles.Text} style={{fontSize:"14px",textWrap:"wrap"}}>Ranjith@gmail.com</p>
            </div>
            <div className={styles.DetailsContainer} onClick={()=>openMap()}>
              <img style={{marginBottom:"10px"}} src={location} alt="Location" />
              <p  className={styles.Text} style={{fontSize:"12px"}}>Location</p>
              <p  className={styles.Text} style={{fontSize:"14px"}}>Hyderabad</p>
            </div>
            </div>
            
        </div>
        <div className={styles.FormContainer}>
          <form onSubmit={handleSubmit} className={styles.FormDetails}>
              <div className={styles.InputsContainer}>
              <h4 className={styles.FormHeading}>Contact Form</h4>
              <div className={styles.InputFieldContainer}>
                <div className={styles.CuConent}>
                <input onChange={handleChange} name="fullName" value={formData.fullName} className={styles.InputField} type="text" placeholder="Full Name" />
                <p className={styles.CuError}>{errors.fullName}</p>
                </div>
                <div className={styles.CuConent}>
                <input onChange={handleChange} name="email" value={formData.email} className={styles.InputField} type="email" placeholder="Email"/>
                <p className={styles.CuError}>{errors.email}</p>
                </div>
                



              </div>
              <div className={styles.InputFieldContainer}>
                <div className={styles.CuConent}>
                <input onChange={handleChange}  name="mobileNumber" value={formData.mobileNumber} className={styles.InputField} type="number" placeholder="Phone Number" />
                <p className={styles.CuError}>{errors.mobileNumber}</p>
                </div>
                <div className={styles.CuConent}>
                <input onChange={handleChange} name="companyName" value={formData.companyName} className={styles.InputField} type="text" placeholder="Company Name"/>
                <p className={styles.CuError}>{errors.companyName}</p>
                </div>
                
                
              </div>
              <div className={styles.InputFieldContainer}>
                <div className={styles.CuConent}>
                <input onChange={handleChange} name="role" value={formData.role} className={styles.InputField} type="text" placeholder="Role" />
                <p className={styles.CuError}>{errors.role}</p>
                </div>
                <div className={styles.CuConent}>
                <select className={styles.OptionField} name="inquiryType" value={formData.inquiryType} onChange={handleChange}>
                  <option   value="">Select Inquiry Type</option>
                  <option   value="Demo">Demo</option>
                  <option   value="More Info">More Info</option>
                  <option   value="Try">Try</option>
                  

                </select>
                <p className={styles.CuError}>{errors.inquiryType}</p>
                </div>
                
              
              
              </div>
              
              <textarea  name="message"  value={formData.message} id="message" placeholder="Message" className={styles.TextMessage} onChange={handleChange}></textarea>
              <p  style={{fontSize:"12px",color:"red",marginBottom:"10px"}}>{errors.message}</p>
              </div>
              <div className={styles.CheckboxContainer}>
                <input type="checkbox" style={{height:"14px"}} name="checkbox" onChange={handleChange}/>
                <p style={{fontSize:"12px",marginBlock:"auto"}}>I consent above filled information is by my own</p>
              </div>
                
                {errors.checkbox && <p style={{fontSize:"12px",color:"red"}}>{errors.checkbox}</p>}

              <div className={styles.ButtonContainer}>
                <button className={styles.Btn}>Send</button>
              </div>


              
          </form>
        </div>
      </main>
     
  
    </>
  );
}

export default ContactHeroSection;
