import React from 'react'
import Styles from "./VerifyCode.module.css"
import MobileLogo from "../Images/MobileLogo.png"
import VerifyCodeImg from "../Images/VerifyCodeImg.png"
import { Link } from 'react-router-dom'
const VerifyCode = () => {
  return (
    <div className={Styles.LoginContainer}>
    <div className={Styles.LeftContainer}>
     
      <form  className={Styles.FormContainer}>
      <div className={Styles.ImageContainer}>
              <img className={Styles.Logo} src="logo/logo3.png" />
            </div>
             <h5 className={Styles.LoginText}>Verify Code</h5>
             <p className={Styles.FormDescription}>
           An authentication code has been sent to your email
           </p>
             <div className={Styles.InputContainer}>
              <div className={Styles.EmailContent}>
                <label className={Styles.InputLabel}>Enter Code</label>
                <input
               type="text"
               id="emailOrPhone"
               className={Styles.InputFeild}
               placeholder="XXXXXXXX"
               aria-label="Enter your email or phone number"
             />
                {/* {emailError && (
            <p  className={Styles.Error}>
              {emailError}
            </p>
          )} */}
                <p style={{marginTop:"10px",fontSize:"14px",textAlign:"center"}}>Didn't recieve a code ?
                    <span style={{color:"#E47331", }}> Resend</span>
                </p>
              </div>
              

            </div>
            <div className={Styles.SubmitBtnContainer}>
                
            <button type="submit" className={Styles.SubmitBtn}>Verify</button>
            </div>
            <div className={Styles.HelpContainer}>
                    <h6 className={Styles.HelpHeading} style={{color:"#E47331", fontSize:"14px"}}>Need Help ?</h6>
                    <p style={{fontSize:"14px"}}>If you didnot recieve the code or If you changed your email or phone no. <span style={{color:"#E47331", }}>Try Different Way</span></p>
            </div>
      </form>
    </div>
    <div className={Styles.RightContainer} >
    <img src={VerifyCodeImg} alt="" className={Styles.RightContainerImage} />
    </div>
    <div className={Styles.MobileContainer}>
    <div className={Styles.MobileView}>
        <div className={Styles.MobileViewLogo}>
          <img src={MobileLogo} alt="" />
          <p className={Styles.HoldOn}>Hold On!</p>
        </div> 
    </div>
    <div className={Styles.MobileMsgContainer}>
          <p className={Styles.MobileMsg}>We are currently not supporting Mobile Devices please click the below button to Download mobile application</p>
          {/* <p className={Styles.MobileMsg}>Please click the below button to Download</p> */}
          {/* <p></p> */}
    </div>
    <div className={Styles.BtnContainer}>
    <button className={Styles.DownloadBtn}>
        <a href="www.google.com" style={{textDecoration:"none",color:"white"}}>Download</a>
  </button>

    </div>
    </div>
  </div>
  )
}

export default VerifyCode