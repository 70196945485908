import React from "react";
import PropTypes from "prop-types";
import styles from './TabButton.module.css';

function TabButton({ buttonText, selected, onClick }) {
  return (
    <button
      className={`${styles.tabButton} ${styles.hireButton} ${selected ? styles.selectedButton : ''}`}
      onClick={onClick}
    >
      {buttonText}
    </button>
  );
}

TabButton.propTypes = {
  buttonText: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

TabButton.defaultProps = {
  selected: false,
};

export default TabButton;
