import React from "react";
import ToggleSwitch from "../Sites/ToggleSwitch";
import "./users.css";

const subModuleDetails = {
  architectureToRo: {
    analysis: "Analysis",
    drawing: "Drawing",
    pending: "Pending",
    register: "Register",
    rfi: "RFI",
  },
  roToSiteLevel: {
    analysis: "Analysis",
    drawing: "Drawing",
    pending: "Pending",
    register: "Register",
    rfi: "RFI",
  },
  siteToSiteLevel: {
    drawing: "Drawing",
    pending: "Pending",
  },
  employee: {
    // Add details if necessary
  },
  profile: {
    // Add details if necessary
  },
  organizationChart: {
    // Add details if necessary
  },
};

const SubModuleModal = ({
  show,
  onHide,
  subModuleKey,
  pageType,
  selectedUser,
  setSelectedUser,
}) => {
  const details = subModuleDetails[subModuleKey];
  const modules = selectedUser?.modules?.[pageType]?.[subModuleKey] || {};

  const handleToggle = (detailKey) => {
    setSelectedUser((prevData) => ({
      ...prevData,
      modules: {
        ...prevData.modules,
        [pageType]: {
          ...prevData.modules[pageType],
          [subModuleKey]: {
            ...prevData.modules[pageType][subModuleKey],
            [detailKey]: !prevData.modules[pageType][subModuleKey]?.[detailKey],
          },
        },
      },
    }));
  };

  return (
    show && (
      <div className="modal-container">
        <div className="modal-content submoduleContent">
          <div className="modal-header useModuleModal">
            <p>{`${subModuleKey} Details`}</p>
            <span className="close-btn toggleBtn" onClick={onHide}>
              &times;
            </span>
          </div>

          <div className="modal-body modalBody">
            {details ? (
              Object.entries(details).map(([detailKey, detailLabel], index) => (
                <div
                  key={detailKey}
                  className={`toggle-wrapper toggle-${index}`}
                >
                  <ToggleSwitch
                    label={detailLabel}
                    isChecked={modules?.[detailKey] || false}
                    onToggle={() => handleToggle(detailKey)}
                  />
                </div>
              ))
            ) : (
              <p>No details available for this submodule.</p>
            )}
          </div>
          <div className="modal-footer FooterBtnUser">
            <button className=" cancleBtnUsers" onClick={onHide}>
              CANCLE
            </button>
            <button className=" saveBtnUsers">Save</button>
          </div>
        </div>
      </div>
    )
  );
};

export default SubModuleModal;
