import React from 'react'

const LinkedInIcon = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M25 12.5C25 5.59644 19.4036 0 12.5 0C5.59644 0 0 5.59644 0 12.5C0 19.4036 5.59644 25 12.5 25C19.4036 25 25 19.4036 25 12.5Z" fill="#0A66C2"/>
      <mask id="mask0_2690_7734" maskUnits="userSpaceOnUse" x="5" y="5" width="15" height="15">
      <path d="M19.5312 5.46875H5.46875V19.5312H19.5312V5.46875Z" fill="white"/>
      </mask>
      <g mask="url(#mask0_2690_7734)">
      <path d="M5.46875 7.34468C5.46875 6.89222 5.62712 6.51893 5.94384 6.22484C6.26055 5.93074 6.6723 5.78369 7.17905 5.78369C7.67677 5.78369 8.07945 5.92847 8.38711 6.21805C8.70383 6.51668 8.86219 6.90579 8.86219 7.3854C8.86219 7.81977 8.70836 8.18173 8.4007 8.47131C8.08398 8.76993 7.66771 8.91922 7.15191 8.91922H7.13834C6.64062 8.91922 6.23794 8.76993 5.93026 8.47131C5.62259 8.17269 5.46875 7.79714 5.46875 7.34468ZM5.64521 19.2218V10.1545H8.65859V19.2218H5.64521ZM10.3282 19.2218H13.3416V14.1588C13.3416 13.842 13.3778 13.5977 13.4502 13.4258C13.5769 13.1181 13.7691 12.8579 14.027 12.6452C14.2849 12.4326 14.6084 12.3263 14.9976 12.3263C16.0111 12.3263 16.5179 13.0095 16.5179 14.3759V19.2218H19.5312V14.0231C19.5312 12.6838 19.2145 11.668 18.5811 10.9757C17.9477 10.2834 17.1105 9.93727 16.0699 9.93727C14.9026 9.93727 13.9931 10.4395 13.3416 11.444V11.4711H13.328L13.3416 11.444V10.1545H10.3282C10.3463 10.4441 10.3553 11.3445 10.3553 12.8556C10.3553 14.3669 10.3463 16.4889 10.3282 19.2218Z" fill="white"/>
      </g>
    </svg>
  )
}

export default LinkedInIcon