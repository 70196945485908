import React, { useState, useEffect } from 'react';
import './Calender.css';
import Navbar from '../Navbar/Header';
import { Calendar as Calender, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { WebApimanager } from '../../WebApiManager';
import { userInfo, siteId } from '../../atoms';
import { useRecoilState } from "recoil";
import SitesDropdown from '../../Utilities/SitesDropdown';
import SelectSite from '../../Utilities/SelectSite';
import Footer from '../Footer/Footer';

const localizer = momentLocalizer(moment);

const Calendar = () => {
    let webApi = new WebApimanager();
    const [currentUserInfo, setCurrentUserInfo] = useRecoilState(userInfo); 
    // const[userId,setUserId] = useState(currentUserInfo._id || ""); 
    const [site, setSite] = useRecoilState(siteId);
    const [sites, setDatasites] = useState([]);
    const [events, setEvents] = useState([
        // {
        //     title: 'Brick Work',
        //     unitNumber: '101',
        //     start: new Date(2024, 6, 5, 10, 0),
        //     end: new Date(2024, 6, 5, 11, 0),
        // },
        // {
        //     title: 'Machinary work',
        //     unitNumber: '102',
        //     start: new Date(2024, 6, 11, 12, 0),
        //     end: new Date(2024, 6, 11, 14, 0),
        // },
        // {
        //     title: 'Brick work',
        //     unitNumber: '103',
        //     start: new Date(2024, 6, 20, 12, 0),
        //     end: new Date(2024, 6, 20, 14, 0),
        // },
        // {
        //     title: 'Machine Work',
        //     unitNumber: '104',
        //     start: new Date(2024, 7, 20, 12, 0),
        //     end: new Date(2024, 7, 20, 14, 0),
        // },
        // {
        //     title: 'Lifts Work',
        //     unitNumber: '105',
        //     start: new Date(2024, 8, 20, 12, 0),
        //     end: new Date(2024, 8, 21, 14, 0),
        // },
        // {
        //     title: 'Lifts Work',
        //     unitNumber: '106',
        //     start: new Date(2024, 12, 20, 12, 0),
        //     end: new Date(2024, 12, 20, 14, 0),
        // },
    ]);

    const [selectedFilter, setSelectedFilter] = useState('Today'); // State for selected filter option

    // Handle change in filter dropdown
    const handleFilterChange = (e) => {
        setSelectedFilter(e.target.value);
    };

    // Filter events based on selected option
    const filteredEvents = events.filter(event => {
        switch (selectedFilter) {
            //TODO: uncomment when startdate and endates are given properly
            // case 'Today':
            //     return moment(event.task.startDate).isSame(moment(), 'day');
            // case 'Next 7 Days':
            //     return moment(event.task.startDate).isSameOrAfter(moment()) && moment(event.task.startDate).isBefore(moment().add(7, 'days'));
            // case 'Next 30 Days':
            //     return moment(event.task.startDate).isSameOrAfter(moment()) && moment(event.task.startDate).isBefore(moment().add(30, 'days'));
            // case 'Next 3 Months':
            //     return moment(event.task.startDate).isSameOrAfter(moment()) && moment(event.task.startDate).isBefore(moment().add(3, 'months'));
            // case 'Next 6 Months':
            //     return moment(event.task.startDate).isSameOrAfter(moment()) && moment(event.task.startDate).isBefore(moment().add(6, 'months'));
            default:
                return true; // Show all tasks for 'Today' or default
        }
    });

    const fetchData = async () => {
        try{
            let response = await webApi.get(`api/Task/userId/${currentUserInfo._id}`)
            console.log(response.data.data.tasks)
            if(response.data)
                setEvents(response.data.data.tasks)
            else 
                setEvents([])
        }catch(e){
            setEvents([])
        }
    }

    const handleSiteChange = (e) => {
        setSite(e.target.value);
    };

    useEffect(() => {
        webApi.get("api/sites/getAllSitesInfo")
          .then((response) => {
            console.log("API response:", response);
            if (response.data && Array.isArray(response.data.sites)) {
              setDatasites(response.data.sites);
              if(site===''){
                setSite(response.data.sites[0]._id)
              }
            } else {
              console.error("Unexpected data format:", response.data);
            }
          })
          .catch((error) => {
            console.error("There was an error fetching the data!", error);
          });
    }, []);

    useEffect(()=>{
        fetchData()
    },[])

    return (
        <>
  <div>
        { !currentUserInfo._id ? (
        <>
         <SelectSite/>
        </>
      ) : (
        <>   
            <div style={{ fontFamily: 'Poppins' }}>
                {/* <div style={{ margin: '16px', display: 'flex', flexDirection: 'row', gap: '16px' }}>
                    <h4>Select Sitegg</h4>
                    <SitesDropdown siteId={site} handleSiteChange={handleSiteChange} sites={sites} defaultValue={'Select Site'} />
                </div> */}
                <div style={{ backgroundColor: 'white', margin: '20px', padding: '20px', borderRadius: '10px' }}>
                    <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'space-between', marginBottom: '12px' }}>
                        <h3>Tasks</h3>
                        <select style={{ width: '180px' }} className="form-select w-5" aria-label="Default select example" onChange={handleFilterChange} value={selectedFilter}>
                            <option value="Today">Today</option>
                            <option value="Next 7 Days">Next 7 Days</option>
                            <option value="Next 30 Days">Next 30 Days</option>
                            <option value="Next 3 Months">Next 3 Months</option>
                            <option value="Next 6 Months">Next 6 Months</option>
                        </select>
                    </div>
                    <ul className="list-group">
                        {filteredEvents.map((event, index) => (
                            <li key={index} className="list-group-item">
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}><h5><b>Work:</b> {event.task.workSequenceId.name}</h5><button className='btn btn-primary'>Submit</button></div>
                                <p><strong>Unit Number:</strong> {event.task.unitId.name}</p>
                                <div style={{ display: 'flex', flexDirection: 'row', gap: '40px' }}>
                                    <p><strong>Start Date:</strong> {moment(event.task.startDate).format('MMMM Do YYYY')}</p>
                                    <p><strong>End Date:</strong> {moment(event.task.endDate).format('MMMM Do YYYY')}</p>
                                </div>
                                
                            </li>
                        ))}
                        {filteredEvents.length === 0 && (
                            <li className="list-group-item">
                                No tasks found.
                            </li>
                        )}
                    </ul>
                </div>
                <div style={{ backgroundColor: 'white', margin: '20px', padding: '20px', borderRadius: '10px' }}>
                    <div style={{ padding: '20px' }}>
                        <Calender
                            localizer={localizer}
                            events={events}
                            startAccessor="start"
                            endAccessor="end"
                            style={{ height: 500 }}
                            views={['month']}
                        />
                    </div>
                </div>
            </div>
        </>
  )}
  </div> 
</>
    );
};

export default Calendar;
