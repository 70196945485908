import React from 'react';
import styles from './FormHeadings.module.css'

const FormHeadings = ({ text }) => {
  return (
    <p className={styles.FormHeadings} >{text}</p>
  );
};

export default FormHeadings;
