import { useEffect, React, useState } from 'react';

const Approved = (props) => {

  const { webApi, setError, setLoading } = props

  const [approvedData, setApprovedData] = useState([]);
  useEffect(() => {
    webApi.get( "api/checkListSubmit/fetchApproved")
      .then((response) => {
        console.log("API response:", response);
        if (response.data && Array.isArray(response.data.data)) {
          setApprovedData(response.data.data);
          console.log(response.data.data);
        } else {
          setError("Unexpected data format");
          console.error("Unexpected data format:", response.data);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
        setError("There was an error fetching the data");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '100px', marginTop: '20px', marginRight: '100px' }}>
    <table style={{ width: '100%' }}>
      <thead style={{ backgroundColor: 'lightblue', textAlign: 'center', height: '45px' }} >
        <tr>
          <th style={{ width: '10%' }}>S.No</th>
          <th style={{ width: '20%' }}>Floor</th>
          <th style={{ width: '15%' }}>Flat</th>
          <th style={{ width: '15%' }}>Approved By</th>
        </tr>
      </thead>
      <tbody style={{ textAlign: 'center', boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)' }}>
        {approvedData.map((item, index) => (
          <tr key={item._id} style={{ border: '1px solid black',height:'55px' }}>
            <td>{index + 1}</td>
            <td>{item['floor']}</td>
            <td>{item['flat']??''}</td>
            <td>{item.approvedBy && item.approvedBy.length > 0 
              ? `${item.approvedBy[0].firstName} ${item.approvedBy[0].lastName || ''}`.trim() 
              : ''}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
  )
}

export default Approved