import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { WebApimanager } from "../../../WebApiManager";
import * as Icon from "react-bootstrap-icons";
import { useRecoilState } from "recoil";
import { siteId } from "../../../atoms";
import { todayDate } from "../../../Utilities/DateUtils";
import { Loader } from "../../../Widgets/notificationFeedbacks";
import SearchDropdownIdVal from "../../../Widgets/SearchDropdownIdVal";

const AddRegister = (props) => {
  const { numDrawings, typeCallBack } = props;
  const today = new Date();
  today.setDate(today.getDate() + 1);
  const todayDateString = today.toISOString().split("T")[0];
  const maxYear = "9999-12-31";

  let webApi = new WebApimanager();

  const [existingConsultants, setExistingConsultants] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [noDrawings, setNumDrawings] = useState(numDrawings);
  const [addData, setAddData] = useState([]);
  const [errors, setErrors] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [site, setSite] = useRecoilState(siteId);
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  const handleTextFieldChange = (val, index, field) => {
    const today = todayDate();
    const updatedErrors = [...errors];
    const updatedaddData = addData.map((item, idx) => {
      if (idx === index) {
        return {
          ...item,
          [field]: val,
        };
      }
      return item;
    });

    // Initialize errors for the current index
    updatedErrors[index] = updatedErrors[index] || {};
    if (
      field === "drawingNumber" ||
      field === "drawingTitle" ||
      field === "consultant" ||
      field === "category"
    ) {
      if (!val.trim()) {
        updatedErrors[index][field] = `${field.replace(
          /([A-Z])/g,
          " $1"
        )} is required`;
      } else {
        updatedErrors[index][field] = "";
      }
    }

    if (field === "R0date" || field === "sitedate") {
      // Validate that dates are in the future
      if (val && val < today) {
        updatedErrors[index][field] = "Date must be in the future";
      } else {
        updatedErrors[index][field] = "";
      }

      // Check if R0date is less than sitedate
      const R0date = updatedaddData[index]?.R0date;
      const sitedate = updatedaddData[index]?.sitedate;

      if (R0date && sitedate) {
        if (new Date(R0date) >= new Date(sitedate)) {
          updatedErrors[index] = {
            ...updatedErrors[index],
            R0date: "R0 submission date must be less than site submission date",
            sitedate:
              "Site submission date must be greater than R0 submission date",
          };
        } else {
          updatedErrors[index] = {
            ...updatedErrors[index],
            R0date: "",
            sitedate: "",
          };
        }
      }
    } else {
      // Clear error for other fields
      updatedErrors[index][field] = "";
    }

    setAddData(updatedaddData);
    setErrors(updatedErrors);
  };

  const handleChange = (e, index, field) => {
    const { value } = e.target;
    const today = todayDate();
    const updatedErrors = [...errors];
    const updatedaddData = addData.map((item, idx) => {
      if (idx === index) {
        return {
          ...item,
          [field]: value,
        };
      }
      return item;
    });

    // Initialize errors for the current index
    updatedErrors[index] = updatedErrors[index] || {};
    if (
      field === "drawingNumber" ||
      field === "drawingTitle" ||
      field === "consultant" ||
      field === "category"
    ) {
      if (!value.trim()) {
        updatedErrors[index][field] = `${field.replace(
          /([A-Z])/g,
          " $1"
        )} is required`;
      } else {
        updatedErrors[index][field] = "";
      }
    }

    if (field === "R0date" || field === "sitedate") {
      // Validate that dates are in the future
      if (value && value < today) {
        updatedErrors[index][field] = "Date must be in the future";
      } else {
        updatedErrors[index][field] = "";
      }

      // Check if R0date is less than sitedate
      const R0date = updatedaddData[index]?.R0date;
      const sitedate = updatedaddData[index]?.sitedate;

      if (R0date && sitedate) {
        if (new Date(R0date) >= new Date(sitedate)) {
          updatedErrors[index] = {
            ...updatedErrors[index],
            R0date: "R0 submission date must be less than site submission date",
            sitedate:
              "Site submission date must be greater than R0 submission date",
          };
        } else {
          updatedErrors[index] = {
            ...updatedErrors[index],
            R0date: "",
            sitedate: "",
          };
        }
      }
    } else {
      // Clear error for other fields
      updatedErrors[index][field] = "";
    }

    setAddData(updatedaddData);
    setErrors(updatedErrors);
  };

  const handleSubmit = async () => {
    let valid = true;
    const newErrors = [];
    const today = new Date().toISOString().split("T")[0];

    addData.forEach((drawing, index) => {
      const drawingErrors = {};
      if (!drawing.drawingNumber?.trim()) {
        drawingErrors.drawingNumber = "Drawing Number is required";
        valid = false;
      }
      if (!drawing.drawingTitle?.trim()) {
        drawingErrors.drawingTitle = "Drawing Title is required";
        valid = false;
      }
      if (!drawing.consultant?.trim()) {
        drawingErrors.consultant = "Consultant is required";
        valid = false;
      }
      if (!drawing.category?.trim()) {
        drawingErrors.category = "Category is required";
        valid = false;
      }
      if (!drawing.R0date) {
        drawingErrors.R0date = "R0 Date is required";
        valid = false;
      } else if (drawing.R0date < today) {
        drawingErrors.R0date = "R0 Date must be in the future";
        valid = false;
      }

      if (!drawing.sitedate) {
        drawingErrors.sitedate = "Site Date is required";
        valid = false;
      } else if (drawing.sitedate < today) {
        drawingErrors.sitedate = "Site Date must be in the future";
        valid = false;
      }

      if (
        drawing.R0date &&
        drawing.sitedate &&
        new Date(drawing.R0date) >= new Date(drawing.sitedate)
      ) {
        drawingErrors.R0date =
          "R0 submission date must be less than site submission date";
        drawingErrors.sitedate =
          "Site submission date must be greater than R0 submission date";
        valid = false;
      }

      newErrors[index] = drawingErrors;
    });

    if (!valid) {
      setErrors(newErrors);
      return;
    }

    setApiProcessing({
      loader: true,
      message: "Submitting...",
    });

    const tempaddData = {
      siteId: site,
      drawings: addData.map((drawing) => ({
        drawingNo: drawing.drawingNumber,
        drawingTitle: drawing.drawingTitle,
        designDrawingConsultant: drawing.consultant,
        category: drawing.category,
        acceptedROSubmissionDate: drawing.R0date,
        acceptedSiteSubmissionDate: drawing.sitedate,
      })),
    };

    try {
      const response = await webApi.post(
        "api/architectureToRoRegister/create",
        tempaddData
      );
      if (response.status === 201) {
        setApiProcessing({ loader: false, message: "" });
        setNumDrawings(0);
        setAddData([]);
        setErrors([]);
        window.location.reload();
      } else if (response.status === 400 || response.status === 200) {
        // Specific error handling for drawing number uniqueness
        if (response.data && response.data.message) {
          setErrorMessage(response.data.message);
        }
        // else {
        //   setErrorMessage('Drawing number must be unique.');
        // }
        setApiProcessing({ loader: false, message: "" });
      } else {
        setErrorMessage("An unexpected error occurred.");
        setApiProcessing({ loader: false, message: "" });
      }
    } catch (error) {
      setApiProcessing({ loader: false, message: "" });
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setErrorMessage(error.response.data.message);
      } else {
        return;
      }
    }
  };
  useEffect(() => {
    webApi
      .get(`api/designDrawingConsultant/getAll?siteId=${site}`)
      .then((response) => {
        if (response) {
          const { users } = response.data.data;
          setExistingConsultants(users); // Set users as consultants

          // Create an array of unique categories
          const categories = users.flatMap((user) =>
            user.categories.map((category) => ({
              categoryId: category._id, // Adjust according to the actual field name
              categoryName: category.name, // Adjust according to the actual field name
            }))
          );

          console.log("Extracted Categories:", categories);
          setFilteredCategories(categories);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [site]);

  const handleConsultantSelect = (consultantId, index) => {
    if (!consultantId) {
      console.error("Invalid consultant ID");
      return;
    }

    // Create a copy of the current addData
    const updatedFormData = [...addData];

    // Update form data with the selected consultant and reset category
    updatedFormData[index] = {
      ...updatedFormData[index],
      consultant: consultantId,
      category: "", // Reset category when consultant changes
    };
    setAddData(updatedFormData);

    // Find the consultant based on consultantId
    const consultant =
      existingConsultants &&
      existingConsultants.find((c) => c.role && c._id === consultantId);

    // Handle case where consultant is not found
    if (!consultant) {
      console.error("Consultant not found");
      return;
    }

    // Extract categories for the found consultant
    const categoriesForConsultant = consultant.categories || [];

    // Update filtered categories based on the addData
    const updatedFilteredCategories = addData.map((data, idx) =>
      idx === index ? categoriesForConsultant : data.categories || []
    );
    setFilteredCategories(updatedFilteredCategories);

    // Update addData with the categories for the selected consultant
    const updatedFormDataWithCategories = updatedFormData.map((data, idx) =>
      idx === index ? { ...data, categories: categoriesForConsultant } : data
    );
    setAddData(updatedFormDataWithCategories);
  };

  const handleDeleteRow = (index) => {
    typeCallBack(index);
    const updatedaddData = [...addData];
    updatedaddData.splice(index, 1);
    setAddData(updatedaddData);
    setNumDrawings(updatedaddData.length);
  };

  const numColumns = 6;
  const handleKeyDown = (e, index, field) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const inputs = Array.from(document.querySelectorAll("input"));
      const currentIndex = inputs.indexOf(e.target);
      const nextInput =
        inputs[currentIndex + 1] ||
        document.querySelector('button[type="submit"]');
      if (nextInput) nextInput.focus();
    }

    if (
      ["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab"].includes(e.key)
    ) {
      return; // Let the browser handle these keys
    }
  };
  return (
    <>
      <Loader open={apiProcessing.loader} message={apiProcessing.message} />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          marginTop: "10px",
          padding: "10px",
        }}
      >
        <div style={{ maxHeight: "330px", overflowY: "auto" }}>
          {numDrawings > 0 && (
            <table
              style={{
                border: "1px solid #DCDCDC",
                borderCollapse: "collapse",
                width: "100%",
              }}
            >
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Drawing Number</th>
                  <th>Design Consultant</th>
                  <th>Drawing Category</th>
                  <th>Drawing Title</th>
                  <th>Accepted RO Submission Date</th>
                  <th>Accepted Site Submission Date</th>
                  <th style={{ paddingRight: "15px" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {[...Array(numDrawings)].map((_, index) => (
                  <tr key={index}>
                    <td
                      className="form-control"
                      style={{
                        width: "100%",
                        textTransform: "uppercase",
                        backgroundColor: "var(--table-evenrow-color)",
                        marginTop: "12px",
                        marginLeft: "5px",
                        textAlign: "center",
                      }}
                    >
                      {index + 1}
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter The Drawing"
                        style={{
                          width: "100%",
                          textTransform: "uppercase",
                          backgroundColor: "var(--table-oddrow-color)",
                        }}
                        onChange={(e) =>
                          handleChange(e, index, "drawingNumber")
                        }
                        onKeyDown={(e) =>
                          handleKeyDown(e, index, "drawingNumber")
                        }
                        tabIndex={index * numColumns + 1}
                      />
                      {errors[index]?.drawingNumber && (
                        <div style={{ color: "red", fontSize: "0.8rem" }}>
                          {errors[index].drawingNumber}
                        </div>
                      )}
                    </td>

                    <td>
                      <SearchDropdownIdVal
                        placeholder={"ENTER CONSULTANT"}
                        backgroundColor={"var(--table-evenrow-color)"}
                        options={
                          (existingConsultants &&
                            existingConsultants?.map((consultant) => ({
                              name: consultant.role,
                              value: consultant._id,
                            }))) ||
                          []
                        }
                        onOptionSelect={(option) =>
                          handleConsultantSelect(option.value, index)
                        }
                        onKeyDown={(e) => handleKeyDown(e, index, "consultant")}
                        tabIndex={index * numColumns + 2}
                      />
                      {errors[index]?.consultant && (
                        <div style={{ color: "red", fontSize: "0.8rem" }}>
                          {errors[index].consultant}
                        </div>
                      )}
                    </td>
                    <td>
                      <SearchDropdownIdVal
                        placeholder={"ENTER CATEGORY"}
                        backgroundColor={"var(--table-oddrow-color)"}
                        options={
                          filteredCategories &&
                          filteredCategories[index] &&
                          Array.isArray(filteredCategories[index])
                            ? filteredCategories[index].map((item) => ({
                                name: item.category,
                                value: item._id,
                              }))
                            : []
                        }
                        onOptionSelect={(option) =>
                          handleTextFieldChange(option.value, index, "category")
                        }
                        onKeyDown={(e) => handleKeyDown(e, index, "category")}
                        tabIndex={index * numColumns + 3}
                      />
                      {errors[index]?.category && (
                        <div style={{ color: "red", fontSize: "0.8rem" }}>
                          {errors[index].category}
                        </div>
                      )}
                    </td>

                    <td>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter The Drawing Title"
                        style={{
                          width: "100%",
                          backgroundColor: "var(--table-evenrow-color)",
                        }}
                        onChange={(e) => handleChange(e, index, "drawingTitle")}
                        onKeyDown={(e) =>
                          handleKeyDown(e, index, "drawingTitle")
                        }
                        tabIndex={index * numColumns + 4}
                      />
                      {errors[index]?.drawingTitle && (
                        <div style={{ color: "red", fontSize: "0.8rem" }}>
                          {errors[index].drawingTitle}
                        </div>
                      )}
                    </td>
                    <td>
                      <input
                        type="date"
                        className="form-control"
                        max={maxYear}
                        min={todayDateString}
                        style={{
                          width: "80%",
                          backgroundColor: "var(--table-oddrow-color)",
                        }}
                        onChange={(e) => handleChange(e, index, "R0date")}
                        onKeyDown={(e) => handleKeyDown(e, index, "R0date")}
                        tabIndex={index * numColumns + 5}
                      />
                      {errors[index]?.R0date && (
                        <div style={{ color: "red", fontSize: "0.8rem" }}>
                          {errors[index].R0date}
                        </div>
                      )}
                    </td>
                    <td>
                      <input
                        type="date"
                        className="form-control"
                        max={maxYear}
                        min={todayDateString}
                        style={{
                          width: "80%",
                          backgroundColor: "var(--table-evenrow-color)",
                        }}
                        onChange={(e) => handleChange(e, index, "sitedate")}
                        onKeyDown={(e) => handleKeyDown(e, index, "sitedate")}
                        tabIndex={index * numColumns + 6}
                      />
                      {errors[index]?.sitedate && (
                        <div style={{ color: "red", fontSize: "0.8rem" }}>
                          {errors[index].sitedate}
                        </div>
                      )}
                    </td>
                    <td>
                      <Icon.DashCircle
                        className="form-control"
                        size={30}
                        style={{
                          cursor: "pointer",
                          color: "red",
                          width: "100%",
                          backgroundColor: "var(--table-oddrow-color)",
                        }}
                        onClick={() => handleDeleteRow(index)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          {errorMessage && (
            <div style={{ color: "red", fontSize: "15px" }}>{errorMessage}</div>
          )}
          {props.numDrawings > 0 && (
            <div style={{ marginTop: "8px", textAlign: "center" }}>
              <button
                style={{
                  padding: "10px",
                  borderRadius: "5px",
                  border: "1px solid  var(--primary-color)",
                  backgroundColor: " var(--primary-color)",
                  color: "white",
                  width: "100px",
                }}
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AddRegister;
