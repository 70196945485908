// import React, { useState ,useEffect} from 'react';
// import { Dialog, DialogContent } from '@mui/material';
// import * as Icon from 'react-bootstrap-icons';

// function VillaTypeDialog({ open, onClose, typeCallBack ,fvillaType,fnoOfCellar,fnoOfFloors}) {

//   const [rows, setRows] = useState([
//     { Type: '1',  villaType: fvillaType|| '', noOfCellar:fnoOfCellar || '', noOfFloors:fnoOfFloors || '' },
//   ]);
//     useEffect(() => {
//       setRows([
//         { Type: '1', villaType: fvillaType || '', noOfCellar: fnoOfCellar || '', noOfFloors: fnoOfFloors || '' },
//       ]);
//     }, [fvillaType, fnoOfCellar, fnoOfFloors]); // Dependencies to trigger effect

//   const [errors, setErrors] = useState({});

//   // Regular expression to validate villaType
//   const villaTypeRegex = /^[1-9](\.\d)?(BHK|bhk)(-\d)?[1-9]?$/;

//   const validateRows = () => {
//     const errorMessages = {};
//     const seenValues = new Map();

//     rows.forEach((row, index) => {
//       const key = `${row.villaType}-${row.noOfCellar}-${row.noOfFloors}`;

//       if (key === '') return; // Skip rows with empty values

//       // Validate villaType
//       if (row.villaType && (row.villaType.length > 8 || !villaTypeRegex.test(row.villaType))) {
//         errorMessages[index] = 'Villa Type must be in the format "1BHK" or "1.5BHK" and up to 8 characters.';
//       }

//       // Check for duplicates
//       if (seenValues.has(key)) {
//         const existingIndex = seenValues.get(key);
//         errorMessages[existingIndex] = 'Duplicate row found with the same Villa Type, Cellar, and Floors. Please enter another type.';
//         errorMessages[index] = 'Duplicate row found with the same Villa Type, Cellar, and Floors. Please enter another type.';
//       } else {
//         seenValues.set(key, index);
//       }
//     });

//     setErrors(errorMessages);
//     return Object.keys(errorMessages).length === 0;
//   };

//   const handleAddRow = () => {
//     const newRow = {
//       Type: ` ${rows.length + 1}`, // Initialize Type value
//      villaType: fvillaType || '',
//       noOfCellar: fnoOfCellar || '',
//       noOfFloors: fnoOfFloors || ''

//     };
//     setRows([...rows, newRow]);
//   };

//   const handleRemoveRow = (index) => {
//     // Remove the specified row
//     const updatedRows = rows.filter((_, i) => i !== index);

//     // Renumber the remaining rows
//     const renumberedRows = updatedRows.map((row, i) => ({
//       ...row,
//       Type: ` ${i + 1}` // Reassign Type values sequentially
//     }));

//     setRows(renumberedRows);

//     // Clear errors related to the removed row
//     const newErrors = { ...errors };
//     delete newErrors[index];
//     setErrors(newErrors);
//   };

//   const handleChange = (index, field, value) => {
//     const updatedRows = [...rows];
//     updatedRows[index][field] = value;

//     setRows(updatedRows);
//     validateRows(); // Validate on each change
//   };

//   const handleSave = () => {
//     if (validateRows()) {
//       if (typeof typeCallBack === 'function') {
//         typeCallBack(rows);
//       } else {
//         console.error('typeCallBack is not a function');
//       }
//       onClose();
//     }
//   };

//   return (
//     <Dialog open={open} onClose={() => {
//       if (typeof typeCallBack === 'function') {
//         typeCallBack(rows);
//       } else {
//         console.error('typeCallBack is not a function');
//       }
//       onClose();
//     }} maxWidth="lg" fullWidth>

//       <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#4D4D4D', margin: '0px' }}>
//         <p style={{ color: 'white', textAlign: 'center', margin: '5px 500px' }}>Add Villa Type Details</p>
//         <Icon.XCircle size={20} style={{ marginLeft: 'auto', cursor: 'pointer', color: 'white', marginRight: '10px' }} onClick={onClose} />
//       </div>
//       <DialogContent style={{ padding: '25px' }}>
//         <table style={{ border: '1px solid #DCDCDC', borderCollapse: 'collapse', width: '100%' }}>
//           <thead>
//             <tr>
//               <th>S.No</th>
//               <th>Villas Type</th>
//               <th>Cellar</th>
//               <th>Floors</th>
//               <th>Action</th>
//             </tr>
//           </thead>
//           <tbody>
//             {rows.map((row, rowIndex) => (
//               <React.Fragment key={rowIndex}>
//                 <tr>
//                   <td
//                     style={{ textAlign: 'center', backgroundColor: 'var(--table-evenrow-color)', padding: '5px', marginTop: '15px' }}
//                     className="form-control"
//                   >
//                     {row.Type}
//                   </td>
//                   <td>
//                     <input
//                       name={`villaType-${rowIndex}`}
//                       className="form-control"
//                       placeholder='Enter the Villa Type'
//                       value={row.villaType}
//                       maxLength={8}
//                       style={{ backgroundColor: 'var(--table-oddrow-color)' }}
//                       onChange={(e) => handleChange(rowIndex, 'villaType', e.target.value)}
//                     />
//                     {errors[rowIndex] && <div style={{ color: 'red' }}>{errors[rowIndex]}</div>}
//                   </td>
//                   <td>
//                     <input
//                       type="number"
//                       className="form-control"
//                       placeholder="Enter The No of Cellar"
//                       value={row.noOfCellar}
//                       style={{ backgroundColor: 'var(--table-oddrow-color)' }}
//                       onChange={(e) => handleChange(rowIndex, 'noOfCellar', e.target.value)}
//                     />
//                   </td>
//                   <td>
//                     <input
//                       type="number"
//                       className="form-control"
//                       placeholder="Enter The No of Floors"
//                       value={row.noOfFloors}
//                       style={{ backgroundColor: 'var(--table-oddrow-color)' }}
//                       onChange={(e) => handleChange(rowIndex, 'noOfFloors', e.target.value)}
//                     />
//                   </td>
//                   <td style={{ textAlign: 'center' }}>
//                     {rowIndex === 0 ? (
//                       <Icon.PlusCircle
//                         size={30}
//                         style={{ cursor: 'pointer', color: 'green', backgroundColor: 'var(--table-oddrow-color)' }}
//                         onClick={handleAddRow}
//                         className="form-control"
//                       />
//                     ) : (
//                       <Icon.DashCircle
//                         size={30}
//                         style={{ cursor: 'pointer', color: 'red', backgroundColor: 'var(--table-oddrow-color)' }}
//                         onClick={() => handleRemoveRow(rowIndex)}
//                         className="form-control"
//                       />
//                     )}
//                   </td>
//                 </tr>
//               </React.Fragment>
//             ))}
//           </tbody>
//         </table>

//         <div style={{ marginTop: '8px', textAlign: 'center' }}>
//           <button
//             style={{
//               padding: '10px',
//               borderRadius: '5px',
//               border: '1px solid var(--primary-color)',
//               backgroundColor: 'var(--primary-color)',
//               color: 'white',
//               width: '100px'
//             }}
//             onClick={handleSave}
//           >
//             SAVE
//           </button>
//         </div>
//       </DialogContent>
//     </Dialog>
//   );
// }

// export default VillaTypeDialog;

import React, { useState, useEffect } from "react";
import { Dialog, DialogContent } from "@mui/material";
import * as Icon from "react-bootstrap-icons";

function VillaTypeDialog({
  open,
  onClose,
  typeCallBack,
  fvillaType,
  fnoOfCellar,
  fnoOfFloors,
}) {
  const [rows, setRows] = useState([
    {
      Type: "1",
      villaType: fvillaType || "",
      noOfCellar: fnoOfCellar || "",
      noOfFloors: fnoOfFloors || "",
    },
  ]);
  useEffect(() => {
    setRows([
      {
        Type: "1",
        villaType: fvillaType || "",
        noOfCellar: fnoOfCellar || "",
        noOfFloors: fnoOfFloors || "",
      },
    ]);
  }, [fvillaType, fnoOfCellar, fnoOfFloors]); // Dependencies to trigger effect

  const [errors, setErrors] = useState({});

  // Regular expression to validate villaType
  const villaTypeRegex = /^[1-9](\.\d)?(BHK|bhk)(-\d)?[1-9]?$/;

  const validateRows = () => {
    const errorMessages = {};
    const seenValues = new Map();

    rows.forEach((row, index) => {
      const key = `${row.villaType}-${row.noOfCellar}-${row.noOfFloors}`;

      if (key === "") return; // Skip rows with empty values

      // Validate villaType
      if (
        row.villaType &&
        (row.villaType.length > 8 || !villaTypeRegex.test(row.villaType))
      ) {
        errorMessages[index] =
          'Villa Type must be in the format "1BHK" or "1.5BHK" or "1bhk-1" or "1.5bhk-4" and up to 8 characters.';
      }

      // Check for duplicates
      if (seenValues.has(key)) {
        const existingIndex = seenValues.get(key);

        // Set the duplicate error message for both the current and existing rows
        const duplicateMessage =
          "Duplicate row found with the same Villa Type, Cellar, and Floors. Please enter another type.";
        errorMessages[existingIndex] = duplicateMessage;
        errorMessages[index] = duplicateMessage;

        // Suggest adding a "-number" suffix to one of the villa types
        const suggestionMessage =
          'Consider adding a "-number" suffix to the Villa Type to differentiate them.';
        errorMessages[existingIndex] += ` ${suggestionMessage}`;
        errorMessages[index] += ` ${suggestionMessage}`;
      } else {
        seenValues.set(key, index);
      }
    });

    setErrors(errorMessages);
    return Object.keys(errorMessages).length === 0;
  };

  const handleAddRow = () => {
    const newRow = {
      Type: ` ${rows.length + 1}`, // Initialize Type value
      villaType: fvillaType || "",
      noOfCellar: fnoOfCellar || "",
      noOfFloors: fnoOfFloors || "",
    };
    setRows([...rows, newRow]);
  };

  const handleRemoveRow = (index) => {
    // Remove the specified row
    const updatedRows = rows.filter((_, i) => i !== index);

    // Renumber the remaining rows
    const renumberedRows = updatedRows.map((row, i) => ({
      ...row,
      Type: ` ${i + 1}`, // Reassign Type values sequentially
    }));

    setRows(renumberedRows);

    // Clear errors related to the removed row
    const newErrors = { ...errors };
    delete newErrors[index];
    setErrors(newErrors);
  };

  const handleChange = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;

    setRows(updatedRows);
    validateRows(); // Validate on each change
  };

  const handleSave = () => {
    if (validateRows()) {
      if (typeof typeCallBack === "function") {
        typeCallBack(rows);
      } else {
        console.error("typeCallBack is not a function");
      }
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (typeof typeCallBack === "function") {
          typeCallBack(rows);
        } else {
          console.error("typeCallBack is not a function");
        }
        onClose();
      }}
      maxWidth="lg"
      fullWidth
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "#4D4D4D",
          margin: "0px",
        }}
      >
        <p style={{ color: "white", textAlign: "center", margin: "5px 500px" }}>
          Add Villa Type Details
        </p>
        <Icon.XCircle
          size={20}
          style={{
            marginLeft: "auto",
            cursor: "pointer",
            color: "white",
            marginRight: "10px",
          }}
          onClick={onClose}
        />
      </div>
      <DialogContent style={{ padding: "25px" }}>
        <table
          style={{
            border: "1px solid #DCDCDC",
            borderCollapse: "collapse",
            width: "100%",
          }}
        >
          <thead>
            <tr>
              <th>S.No</th>
              <th>Villas Type</th>
              <th>Cellar</th>
              <th>Floors</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, rowIndex) => (
              <React.Fragment key={rowIndex}>
                <tr>
                  <td
                    style={{
                      textAlign: "center",
                      backgroundColor: "var(--table-evenrow-color)",
                      padding: "5px",
                      marginTop: "15px",
                    }}
                    className="form-control"
                  >
                    {row.Type}
                  </td>
                  <td>
                    <input
                      name={`villaType-${rowIndex}`}
                      className="form-control"
                      placeholder="Enter the Villa Type"
                      value={row.villaType}
                      maxLength={8}
                      style={{ backgroundColor: "var(--table-oddrow-color)" }}
                      onChange={(e) =>
                        handleChange(rowIndex, "villaType", e.target.value)
                      }
                    />
                    {errors[rowIndex] && (
                      <div style={{ color: "red" }}>{errors[rowIndex]}</div>
                    )}
                  </td>
                  <td>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter The No of Cellar"
                      value={row.noOfCellar}
                      style={{ backgroundColor: "var(--table-oddrow-color)" }}
                      onChange={(e) =>
                        handleChange(rowIndex, "noOfCellar", e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter The No of Floors"
                      value={row.noOfFloors}
                      style={{ backgroundColor: "var(--table-oddrow-color)" }}
                      onChange={(e) =>
                        handleChange(rowIndex, "noOfFloors", e.target.value)
                      }
                    />
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {rowIndex === 0 ? (
                      <Icon.PlusCircle
                        size={30}
                        style={{
                          cursor: "pointer",
                          color: "green",
                          backgroundColor: "var(--table-oddrow-color)",
                        }}
                        onClick={handleAddRow}
                        className="form-control"
                      />
                    ) : (
                      <Icon.DashCircle
                        size={30}
                        style={{
                          cursor: "pointer",
                          color: "red",
                          backgroundColor: "var(--table-oddrow-color)",
                        }}
                        onClick={() => handleRemoveRow(rowIndex)}
                        className="form-control"
                      />
                    )}
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>

        <div style={{ marginTop: "8px", textAlign: "center" }}>
          <button
            style={{
              padding: "10px",
              borderRadius: "5px",
              border: "1px solid var(--primary-color)",
              backgroundColor: "var(--primary-color)",
              color: "white",
              width: "100px",
            }}
            onClick={handleSave}
          >
            SAVE
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default VillaTypeDialog;
