import React, { useState,useEffect } from 'react';
import * as Icon from "react-bootstrap-icons";
import { Dialog, DialogContent } from "@mui/material";
import { useNavigate } from 'react-router-dom'; 
import { Button } from 'react-bootstrap'; 
import Navbar from '../Navbar/Header';
import { WebApimanager } from '../../WebApiManager';
import { Loader } from '../../Widgets/notificationFeedbacks';
import { useRecoilState } from "recoil";
import { siteId } from '../../atoms';
import SelectSite from '../../Utilities/SelectSite';


const TaskTracker = () => {
  let webApi = new WebApimanager();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [datasites,setDataSites] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [remarksDialogOpen, setRemarksDialogOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [remarks, setRemarks] = useState('');
  const [formData, setFormData] = useState({
    task: '',
    duration: 0,
    startDate: '',
    completionDate: '',
    assignedName: '',
    siteName:'',
    block:'',
    floor:'',
    unit:'',
    category:'',
    selectAll: false,
    selectSingleBlock: false,
    selectSingleUnit: false
  });
  const [errors, setErrors] = useState({});
  const [plannerList, setPlannerList] = useState([]); 

  const [site, setSite] = useRecoilState(siteId)

  useEffect (() =>{
  if(site===''){
    return <div>
      <Navbar/>
      <SelectSite></SelectSite>
    </div>
  }
},[]);
  

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Copy formData to a new object
    let newFormData = { ...formData, [name]: value };

    if (name === 'startDate' || name === 'duration') {
      newFormData = {
        ...newFormData,
        completionDate: calculateCompletionDate(newFormData.startDate, newFormData.duration)
      };
    } else if (name === 'completionDate') {
      newFormData = {
        ...newFormData,
        duration: calculateDuration(newFormData.startDate, newFormData.completionDate)
      };
    }

    setFormData(newFormData);
  };

  const calculateCompletionDate = (startDate, duration) => {
    if (!startDate || !duration) return '';
    const start = new Date(startDate);
    const end = new Date(start);
    end.setDate(start.getDate() + parseInt(duration));
    return end.toISOString().split('T')[0];
  };

  const calculateDuration = (startDate, completionDate) => {
    if (!startDate || !completionDate) return 0;
    const start = new Date(startDate);
    const end = new Date(completionDate);
    const diffTime = Math.abs(end - start);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  };


  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = () => {

    webApi.get( "api/planner")
      .then((response) => {
        if (response.data && Array.isArray(response.data.data.allPlans)) {
          setData(response.data.data.allPlans);
          console.log(response.data.data.allPlans);
        } else {
          setError("Unexpected data format");
          console.error("Unexpected data format:", response.data);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
        setError("There was an error fetching the data");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
  webApi.get( "api/sites/AllSites")
  .then((response) => {
    if (response.data && Array.isArray(response.data.sites)) {
      setDataSites(response.data.sites);
      console.log(response.data.sites);
    } else {
      setError("Unexpected data format");
      console.error("Unexpected data format:", response.data);
    }
  })
  .catch((error) => {
    console.error("There was an error fetching the data!", error);
    setError("There was an error fetching the data");
  })
  .finally(() => {
    setLoading(false);
  });
},[]);

  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };
 
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const openRemarksDialog = (index, selectedStatus) => {
    setSelectedIndex(index);
    setSelectedStatus(selectedStatus);
    setRemarksDialogOpen(true);
  };

  const closeRemarksDialog = () => {
    setRemarksDialogOpen(false);
    setRemarks('');
  };
  const submitRemarks = () => {
    const updatedData = [...data];
    updatedData[selectedIndex].status = selectedStatus;
    updatedData[selectedIndex].remarks = remarks;

    setData(updatedData);
    const itemIdToUpdate = updatedData[selectedIndex]._id;
    const updatedStatus = { status: selectedStatus, remarks };;

  webApi.put(`${itemIdToUpdate}`, updatedStatus)
    .then(response => {
      console.log('Status updated successfully:', response.data);
    })
    .catch(error => {
      console.error('Error updating status:', error);
    });
  }
  


  // Filter data based on search term
  useEffect(() => {
    const filtered = data.filter((item) =>
      `${item.taskTitle}
      ${item.duration}
      ${new Date(item.startDate).toLocaleDateString()}
      ${new Date(item.completionDate).toLocaleDateString()}
      ${item.AssignedName}
      ${item.site_name}
      ${item.block}
      ${item.floor}`
      .toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(filtered);
  }, [data, searchTerm]);

  useEffect(()=>{
   console.log(datasites)
  },[datasites])

  return (
    <>
        <Navbar/>
        <div style={{height: '100vh', margin:'0'}}>
        <div style={{marginTop:'50px'}}>
        <div style={{ display: 'flex',  justifyContent: 'space-around' }}>
                <h3 style={{ color: 'brown' }}>TaskTracker Lists</h3>
                <div style={{display:'flex',flexDirection:'row'}} >
                   <div style={{ position: 'relative', width: '250px' ,paddingLeft:'20px',marginLeft:'200px',height:'50px'}}> {/* Adjust width as needed */}
                    <input 
                    placeholder='Search TaskTracker Lists'
                    value={searchTerm}
                    onChange={handleSearchInputChange}
                    style={{ paddingLeft: '20px', borderRadius: '10px', boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)', border: 'none',width:'250px',height:'100%' }} />
                    <Icon.Search style={{ position: 'absolute',right:'1px', top: '50%', transform: 'translateY(-50%)' }} />
                  </div>
                  {/* <Button style={{ background: 'none', color: 'blue', height: '50px',marginLeft:'30px',display:'flex' ,alignItems:'center'}} 
                    onClick={handleClickOpen}><Icon.PlusCircle size={20} style={{marginRight:'5px'}} /> Add New</Button> */}
                </div> 
        </div>
        <div style={{marginRight:'50px',marginLeft:'50px',boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)'}}>
          <table style={{ width: '100%',marginTop:'10px'}}>
    <thead style={{backgroundColor:'lightslategrey',textAlign:'center',height:'45px'}} >
    <tr>
      <th style={{ width: '5%' }}>S.No</th>
      <th style={{ width: '15%' }}>Task</th>
      <th style={{ width: '10%' }}>Duration</th>
      <th style={{ width: '10%' }}>Start<br/> Date</th>
      <th style={{ width: '10%' }}>Completion<br/> Date</th>
      <th style={{ width: '15%' }}>Assigned<br/> Name</th>
      <th style={{ width: '10%' }}>Site<br/>Name</th>
      <th style={{ width: '5%' }}>Block</th>
      <th style={{ width: '5%' }}>Floor</th>
      <th style={{ width: '15%' }}>Actions</th> 
    
    </tr>
    </thead>
    <tbody style={{ textAlign: 'center', boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)' }}>
    {filteredData.map((item, index) => (
                <tr key={item._id} style={{ borderBottom: '1px solid black', padding: '10px',height:'60px', boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)'}}>
                   <td>{index + 1}</td>
                    <td>{item.taskTitle}</td>
                    <td>{item.duration ?? ''}</td>
                    <td>{new Date(item.startDate).toLocaleDateString()}</td>
                    <td>{new Date(item.completionDate).toLocaleDateString()}</td>
                    <td>{item.AssignedName && item.AssignedName.length > 0 ? `${item.AssignedName[0]?.firstName ?? ''} ${item.AssignedName[0]?.lastName ?? ''}` : ''}</td>
                    <td>{item.site_name}</td>
                    <td>{item.block}</td>
                    <td>{item.floor}</td>
                  <td style={{ justifyContent: 'center', alignItems: 'center' }}>
                    <Icon.Pen onClick={()=>
                      openRemarksDialog(index, '')
                    } size={20} style={{marginLeft:'70px'}} />
                  </td>
                </tr>
              ))}
            </tbody>
  </table>
  </div>
  </div>
  <Dialog open={remarksDialogOpen} onClose={closeRemarksDialog}>
          <DialogContent>
            <div>
            <label className="fs-10 fw-bold text-start py-2">Completion Of Task</label>
            <input
        type="number"
        min="1"
        max="100"
        placeholder="Enter progress of the task"
        className="form-control fs-12"
        style={{ width: '100%', height: '40px', boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)' }}
        value={selectedStatus}
        onChange={(e) => {
          const value = Math.max(1, Math.min(100, Number(e.target.value)));
          setSelectedStatus(value);
        }}
      />
              <h4>Enter Remarks</h4>
              <textarea
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
                rows="4"
                style={{ width: '100%' }}
              />
              <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'flex-end' }}>
                <button onClick={closeRemarksDialog} style={{ marginRight: '10px' }}>Cancel</button>
                <button onClick={submitRemarks}>Submit</button>
              </div>
            </div>
          </DialogContent>
        </Dialog>

        </div>           
    </>
             
  )
}

export default TaskTracker



