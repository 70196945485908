import React, { useState } from "react";
import ToggleSubModuleSwitch from "./ToggleSubModuleSwitch";
import SubModuleModal from "../Users/SubModuleModal";

const getPageType = (label) => {
  if (label === "Drawing") {
    return "drawingDetails";
  } else if (label === "USER") {
    return "userDetails";
  } else {
    return "";
  }
};

const ToggleSwitchWithModules = ({
  label,
  isChecked,
  onToggle,
  selectedUser,
  setSelectedUser,
}) => {
  const drawingPages = {
    "Architecture To Ro": "architectureToRo",
    "Ro To Sitelevel": "roToSiteLevel",
    "Site To Sitelevel": "siteToSiteLevel",
  };

  const userPages = {
    employee: "employee",
    profile: "profile",
    organizationChart: "organizationChart",
  };

  const pageType = getPageType(label);

  const modules = selectedUser?.modules?.[pageType] || {};

  const [showSubModuleModal, SetShowSubModuleModal] = useState(false);
  const [selectedSubModule, setSelectedSubModule] = useState(null);

  const handleIconClick = (subModuleKey) => {
    setSelectedSubModule(subModuleKey);
    SetShowSubModuleModal(true);
  };

  const handleCloseSubModal = () => {
    SetShowSubModuleModal(false);
    setSelectedSubModule(null);
  };

  return (
    <div className="toggle-switch-with-modules">
      <div style={{ display: "flex", flexDirection: "row" }}>
        <span>{label}</span>
        <label className="switch">
          <input type="checkbox" checked={isChecked} onChange={onToggle} />
          <span className="slider round"></span>
        </label>
      </div>
      {pageType && Object.keys(modules).length > 0 && (
        <div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {Object.entries(
              pageType === "drawingDetails" ? drawingPages : userPages
            ).map(([title, value]) => (
              <ToggleSubModuleSwitch
                key={value}
                isChecked={modules[value] || false}
                label={title}
                id={value}
                onToggle={() => {
                  setSelectedUser((prevData) => ({
                    ...prevData,
                    modules: {
                      ...prevData.modules,
                      [pageType]: {
                        ...prevData.modules[pageType],
                        [value]: !prevData.modules[pageType][value],
                      },
                    },
                  }));
                }}
                onIconClick={() => handleIconClick(value)}
              />
            ))}
          </div>
        </div>
      )}

      {showSubModuleModal && (
        <SubModuleModal
          show={showSubModuleModal}
          onHide={handleCloseSubModal}
          subModuleKey={selectedSubModule}
          pageType={pageType}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
        />
      )}
    </div>
  );
};

export default ToggleSwitchWithModules;
