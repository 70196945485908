// src/components/Tab.jsx
import React from "react";
import PropTypes from "prop-types";

const Tab = ({ children }) => {
  return <>{children}</>;
};

Tab.propTypes = {
  eventKey: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Tab;
