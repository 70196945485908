import React, { useState, useEffect, useCallback } from "react";
import * as Icon from "react-bootstrap-icons";
import { Dialog, DialogContent } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { WebApimanager } from "../../WebApiManager";
import styles from "./PnmTable.module.css";

function PnmTable({ filterByOwnOrHire, filterByType }) {
  const webApi = new WebApimanager();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [formData, setFormData] = useState({
    sCode: "",
    subCode: "",
    Name: "",
    brandName: "",
    fuelornot: "",
    type: "",
    ownorHire: "",
    preServiceDate: "",
    nextServiceDate: "",
  });
  const [errors, setErrors] = useState({});
  const [PnmList, setPnmList] = useState([]);
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    let Valid = true;
    const newErrors = {};
    if (!formData.sCode.trim()) {
      newErrors.sCode = "SCode  is required";
      Valid = false;
    }
    if (!formData.subCode.trim()) {
      newErrors.subCode = "SubCode  is required";
      Valid = false;
    }
    if (!formData.Name.trim()) {
      newErrors.Name = "Name is required";
      Valid = false;
    }
    if (!formData.brandName.trim()) {
      newErrors.brandName = "Brand Name is required";
      Valid = false;
    }
    if (!formData.fuelornot.trim()) {
      newErrors.fuelornot = "Fuel Or Not Field is required";
      Valid = false;
    }
    if (!formData.type.trim()) {
      newErrors.type = "Type Field is required";
      Valid = false;
    }
    if (!formData.ownorHire.trim()) {
      newErrors.ownorHire = "Own Or Hire Field is required";
      Valid = false;
    }
    if (!formData.preServiceDate.trim()) {
      newErrors.preServiceDate = "PreService Date is required";
      Valid = false;
    }
    if (!formData.nextServiceDate.trim()) {
      newErrors.nextServiceDate = "NextService Date is required";
      Valid = false;
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    }

    if (Valid) {
      setApiProcessing({ loader: true, message: "Submitting..." });

      const requestPayload = {
        SCode: formData.sCode,
        SubCode: formData.subCode,
        Name: formData.Name,
        BrandName: formData.brandName,
        FuelOrNot: formData.fuelornot,
        Type: formData.type,
        OwnOrHire: formData.ownorHire,
        PreService: formData.preServiceDate,
        NextService: formData.nextServiceDate,
      };

      webApi
        .post("api/pnm", requestPayload)
        .then((response) => {
          setOpen(false);
          setApiProcessing({ loader: false, message: "" });
        })
        .catch((error) => {
          console.error("There was an error!", error);
          setApiProcessing({ loader: false, message: "" });
        });
    } else {
      const newPnmItem = { ...formData };
      setPnmList([...PnmList, newPnmItem]);
      setFormData({
        sCode: "",
        subCode: "",
        Name: "",
        brandName: "",
        fuelornot: "",
        type: "",
        ownorHire: "",
        preServiceDate: "",
        nextServiceDate: "",
      });
      setErrors({});
      setOpen(false);
      window.location.reload();
    }
  };

  useEffect(() => {
    webApi
      .get("api/pnm")
      .then((response) => {
        if (response.data && Array.isArray(response.data.data.machinary)) {
          setData(response.data.data.machinary);
        } else {
          setError("Unexpected data format");
          console.error("Unexpected data format:", response.data);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
        setError("There was an error fetching the data");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  // Your existing state variables and functions...

  // Function to handle search input change
  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    const filtered = data.filter((item) =>
      `${item.scode}
  ${item.subCode}
  ${item.Name}
  ${item.brandName}
  ${item.fuelornot}
  ${item.type}
  ${item.ownorHire}
    ${new Date(item.preServiceDate).toLocaleDateString()}
    ${new Date(item.nextServiceDateServiceDate).toLocaleDateString()}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
    setFilteredData(filtered);
  }, [data, searchTerm]);

  return (
    <>
      <div>
        <div>
          <table style={{ width: "100%" }}>
            <thead
              style={{
                backgroundColor: "lightslategrey",
                textAlign: "center",
                height: "45px",
              }}
            >
              <tr>
                <th className={styles.tableTH} style={{ width: "5%" }}>
                  S.No
                </th>
                <th style={{ width: "11%" }}>Asset Code</th>
                <th style={{ width: "11%" }}>Sub Code</th>
                <th style={{ width: "11%" }}>Name</th>
                <th style={{ width: "11%" }}>Make Name</th>
                <th style={{ width: "11%" }}>Fuel/Not</th>
                <th style={{ width: "11%" }}>Next Service</th>
                <th style={{ width: "11%" }}>Next Inspection</th>
                <th style={{ width: "11%" }}>Actions</th>
              </tr>
            </thead>
            <tbody style={{ textAlign: "center" }}>
              {filteredData
                .filter(
                  (item) =>
                    item.OwnOrHire === filterByOwnOrHire &&
                    item.Type === filterByType
                )
                .map((item, index) => {
                  const backgroundColor =
                    index % 2 === 0 ? "#FDF8F4" : "#F3F6F8";

                  return (
                    <tr
                      key={item._id}
                      style={{
                        borderBottom: "1px solid black",
                        padding: "10px",
                        backgroundColor,
                      }}
                    >
                      <td>{index + 1}</td>
                      <td>{item.SCode}</td>
                      <td>{item.SubCode}</td>
                      <td>{item.Name}</td>
                      <td>{item.BrandName}</td>
                      <td>{item.FuelOrNot}</td>
                      <td>{item.Type}</td>
                      <td>{item.OwnOrHire}</td>
                      <td>{new Date(item.PreService).toLocaleDateString()}</td>
                      <td>{new Date(item.NextService).toLocaleDateString()}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#7C1034",
            margin: "0px",
          }}
        >
          <p
            style={{ color: "white", textAlign: "center", margin: "5px 250px" }}
          >
            Add PNM List
          </p>
          <Icon.XCircle
            size={20}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              color: "white",
              marginRight: "10px",
            }}
            onClick={handleClose}
          />
        </div>
        <DialogContent
          style={{ padding: "25px", minWidth: "300px", minHeight: "300px" }}
        >
          <section
            className="grid gap-y-6 w-5/6 mb-4 md:w-11/12 mt-4"
            style={{ marginLeft: "80px", marginRight: "80px" }}
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "20px",
              }}
            >
              <div>
                <label
                  htmlFor="sCode"
                  className="fs-10 fw-bold text-start py-2"
                >
                  SCode
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  id="sCode"
                  name="sCode"
                  value={formData.sCode}
                  onChange={handleChange}
                  placeholder="Enter your SCode"
                  className="form-control fs-12"
                  style={{
                    width: "100%",
                    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
                  }}
                />
                {errors.sCode && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.sCode}
                  </p>
                )}
              </div>
              <div>
                <label
                  htmlFor="subCode"
                  className="fs-10 fw-bold text-start py-2"
                >
                  SubCode
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  id="subCode"
                  name="subCode"
                  value={formData.subCode}
                  onChange={handleChange}
                  placeholder="Enter your SubCode"
                  className="form-control fs-12"
                  style={{
                    width: "100%",
                    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
                  }}
                />
                {errors.subCode && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.subCode}
                  </p>
                )}
              </div>

              <div>
                <label htmlFor="Name" className="fs-10 fw-bold text-start py-2">
                  Name
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  id="Name"
                  name="Name"
                  value={formData.Name}
                  onChange={handleChange}
                  placeholder="Enter your Machinery Name"
                  className="form-control fs-12"
                  style={{
                    width: "100%",
                    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
                  }}
                />
                {errors.Name && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.Name}
                  </p>
                )}
              </div>
              <div>
                <label
                  htmlFor="brandName"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Brand Name
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  id="brandName"
                  name="brandName"
                  value={formData.brandName}
                  onChange={handleChange}
                  placeholder="Enter your Brand Name"
                  className="form-control fs-12"
                  style={{
                    width: "100%",
                    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
                  }}
                />
                {errors.brandName && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.brandName}
                  </p>
                )}
              </div>
              <div>
                <label
                  htmlFor="fuelornot"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Fuel/Not
                  <span style={{ color: "red" }}>*</span>
                </label>
                <select
                  type="text"
                  id="fuelornot"
                  name="fuelornot"
                  value={formData.machineryName}
                  onChange={handleChange}
                  className="form-control fs-12"
                  style={{
                    width: "100%",
                    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
                  }}
                >
                  <option value="">Select A Option</option>
                  <option value="Fuel"> Fuel</option>
                  <option value="NotFuel">Not Fuel</option>
                </select>
                {errors.fuelornot && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.fuelornot}
                  </p>
                )}
              </div>
              <div>
                <label htmlFor="type" className="fs-10 fw-bold text-start py-2">
                  Type
                  <span style={{ color: "red" }}>*</span>
                </label>
                <select
                  type="text"
                  id="type"
                  name="type"
                  value={formData.type}
                  onChange={handleChange}
                  className="form-control fs-12"
                  style={{
                    width: "100%",
                    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
                  }}
                >
                  <option value="">Select A Option</option>
                  <option value="Machinery"> Machinery</option>
                  <option value="Vechile">Vechile</option>
                  <option value="HandTools">Hand Tools</option>
                </select>
                {errors.type && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.type}
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="ownorHire"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Own/Hire
                  <span style={{ color: "red" }}>*</span>
                </label>
                <select
                  type="text"
                  id="ownorHiire"
                  name="ownorHire"
                  value={formData.ownorHire}
                  onChange={handleChange}
                  className="form-control fs-12"
                  style={{
                    width: "100%",
                    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
                  }}
                >
                  <option value="">Select A Option</option>
                  <option value="Own"> Own</option>
                  <option value="Hire">Hire</option>
                </select>
                {errors.ownorHire && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.ownorHire}
                  </p>
                )}
              </div>
              <div>
                <label
                  htmlFor="preServiceDate"
                  className="fs-10 fw-bold text-start py-2"
                >
                  PreService Date
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="date"
                  id="preServiceDate"
                  name="preServiceDate"
                  value={formData.preServiceDate}
                  onChange={handleChange}
                  className="form-control fs-12"
                  style={{
                    width: "100%",
                    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
                  }}
                />
                {errors.preServiceDate && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.preServiceDate}
                  </p>
                )}
              </div>
              <div>
                <label
                  htmlFor="nextServiceDate"
                  className="fs-10 fw-bold text-start py-2"
                >
                  NextService Date
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="date"
                  id="nextServiceDate"
                  name="nextServiceDate"
                  value={formData.nextServiceDate}
                  onChange={handleChange}
                  className="form-control fs-12"
                  style={{
                    width: "100%",
                    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
                  }}
                />
                {errors.nextServiceDate && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.nextServiceDate}
                  </p>
                )}
              </div>

              <div
                style={{
                  marginTop: "45px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <button
                  style={{
                    padding: "8px",
                    borderRadius: "5px",
                    border: "1px solid lightgray",
                    backgroundColor: "transparent",
                    width: "100px",
                  }}
                  onMouseOver={(e) =>
                    (e.target.style.backgroundColor = "lightgray")
                  }
                  onMouseOut={(e) =>
                    (e.target.style.backgroundColor = "transparent")
                  }
                  onClick={handleClose}
                >
                  Cancel
                </button>
                <button
                  style={{
                    marginLeft: "50px",
                    padding: "10px",
                    borderRadius: "5px",
                    border: "1px solid lightgray",
                    backgroundColor: "brown",
                    width: "100px",
                  }}
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </section>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default PnmTable;
