import React, { useState, useEffect, useRef } from 'react';

const SearchDropdownIdVal = ({ options,value, onOptionSelect, backgroundColor, placeholder, onKeyDown, tabIndex }) => {
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [inputValue, setInputValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const dropdownRef = useRef(null);

  useEffect(() => {
    setFilteredOptions(
      options.filter(option => {
        if (typeof option.name === 'string') {
          return option.name.toLowerCase().includes(inputValue.toLowerCase());
        }
        return false;
      })
    );
  }, [inputValue, options]);

  useEffect(()=>{
    if(value===''){
      console.log('called', placeholder)
      setInputValue('')
    }
  },[value])

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    setIsOpen(true);
    setSelectedIndex(-1);
    if (value === '') {
      onOptionSelect({
        value: ''
      });
    }
  };

  const handleOptionClick = (option) => {
    setInputValue(option.name);
    onOptionSelect(option);
    setIsOpen(false);
    setSelectedIndex(-1);
  };


  const handleKeyDown = (event) => {
    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault();
        setSelectedIndex((prevIndex) => Math.min(prevIndex + 1, filteredOptions.length - 1));
        break;
      case 'ArrowUp':
        event.preventDefault();
        setSelectedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
        break;
      case 'Enter':
        if (selectedIndex >= 0) {
          handleOptionClick(filteredOptions[selectedIndex]);
        }
        break;
      case 'Escape':
        setIsOpen(false);
        break;
      default:
        break;
    }

    if (onKeyDown) {
      onKeyDown(event);
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      <input
        type="text"
        placeholder={placeholder}
        value={inputValue}
        onChange={handleInputChange}
        onFocus={() => setIsOpen(true)}
        onKeyDown={handleKeyDown}
        style={{ width: '100%', padding: '8px', boxSizing: 'border-box', backgroundColor: backgroundColor, border: 'var(--bs-border-width) solid var(--bs-border-color)', borderRadius: 'var(--bs-border-radius)' }}
        tabIndex={tabIndex}
      />
      {isOpen && (
        <ul
          ref={dropdownRef}
          style={{
            position: 'absolute',
            width: '100%',
            marginTop: '30px',
            maxHeight: '100px',
            overflowY: 'auto',
            border: '1px solid #ccc',
            boxSizing: 'border-box',
            margin: 0,
            padding: 0,
            zIndex: 3000,
            listStyleType: 'none',
            backgroundColor: 'white'
          }}
        >
          {filteredOptions.map((option, index) => (
            <li
              key={index}
              onMouseDown={() => handleOptionClick(option)}
              style={{
                padding: '8px',
                cursor: 'pointer',
                backgroundColor: selectedIndex === index ? '#e9e9e9' : 'white'
              }}
            >
              {option.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SearchDropdownIdVal;