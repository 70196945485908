import React from 'react';
import { Button_COLOUR } from "../../Utilities/Constants";

const NavbarTile = (props) => {
  const { title, path, activeItem, handleItemClick } = props;

  const isActive = activeItem === path;
  const tileStyle = {
    color: isActive ? 'var(--primary-color)' : '#4D4D4D',
    fontWeight: isActive ? 'bold' : 'normal',
    textTransform: isActive ? 'uppercase' : 'none',
    textDecoration: 'none',
    fontSize: '15px'
  };

  return (
    <a 
      href={`/${path}`} 
      className='no-underline focus:outline-none' 
      style={tileStyle}
      onClick={() => handleItemClick(path)}
    >
      {title}
    </a>
  );
};

export default NavbarTile;
