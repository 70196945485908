import React, { Component } from "react";
import PropTypes from "prop-types";

const { Autodesk } = window;

class Viewer extends Component {
  constructor(props) {
    super(props);
    this.container = null;
    this.viewer = null;
  }

  componentDidMount() {
    console.log("Component mounted, initializing viewer...");
    this.initializeViewer();
  }

  componentDidUpdate(prevProps) {
    if (
      this.viewer &&
      (this.props.urn !== prevProps.urn || this.props.token !== prevProps.token)
    ) {
      console.log("URN or token changed, reloading model...");
      this.loadModel();
    }
  }

  componentWillUnmount() {
    if (this.viewer) {
      console.log("Component unmounting, cleaning up viewer...");
      this.viewer.removeEventListener(
        Autodesk.Viewing.CAMERA_CHANGE_EVENT,
        this.onCameraChange
      );
      this.viewer.removeEventListener(
        Autodesk.Viewing.SELECTION_CHANGED_EVENT,
        this.onSelectionChange
      );
      this.viewer.finish();
      this.viewer = null;
    }
  }

  initializeViewer = () => {
    const options = {
      env: "AutodeskProduction",
      api: "derivativeV2",
      getAccessToken: (onTokenReady) => {
        const token = this.props.token;
        console.log("Retrieved Token:", token);
        const timeInSeconds = 3600;
        onTokenReady(token, timeInSeconds);
      },
    };

    console.log("Initializing Autodesk Viewer...");
    Autodesk.Viewing.Initializer(options, () => {
      console.log("Autodesk Viewer initialized");
      const config3d = {
        extensions: ["Autodesk.DocumentBrowser"],
        toolbar: { visible: true },
        homebutton: true,
        fullscreenbutton: true,
        viewcubeui: {
          navCube: true,
          homeButton: true,
        },
      };
      this.viewer = new Autodesk.Viewing.GuiViewer3D(this.container, config3d);
      console.log("Viewer instance created");
      this.viewer.start();
      this.viewer.setTheme("light-theme");
      this.viewer.addEventListener(
        Autodesk.Viewing.CAMERA_CHANGE_EVENT,
        this.onCameraChange
      );
      this.viewer.addEventListener(
        Autodesk.Viewing.SELECTION_CHANGED_EVENT,
        this.onSelectionChange
      );
      this.viewer.navigation.setReverseZoomDirection();
      this.loadModel();
    });
  };

  loadModel = () => {
    if (this.viewer && this.props.urn) {
      const documentId = "urn:" + this.props.urn;
      console.log("Loading Model URN:", documentId);
      Autodesk.Viewing.Document.load(
        documentId,
        this.onDocumentLoadSuccess,
        this.onDocumentLoadFailure
      );
    } else {
      console.error("Viewer or URN not available");
    }
  };

  onDocumentLoadSuccess = (doc) => {
    console.log("Document loaded successfully");
    const viewables = doc.getRoot().getDefaultGeometry();
    this.viewer.loadDocumentNode(doc, viewables).then(() => {
      console.log("Model loaded successfully");
      this.viewer.resize();
      this.viewer.fitToView();
      if (this.props.onLoadSuccess) {
        this.props.onLoadSuccess();
      }
    });
  };

  onDocumentLoadFailure = (errorCode, errorMsg) => {
    console.error("Failed to load document.", errorCode, errorMsg);
  };

  onCameraChange = () => {
    if (this.props.onCameraChange) {
      this.props.onCameraChange({
        viewer: this.viewer,
        camera: this.viewer.getCamera(),
      });
    }
  };

  onSelectionChange = () => {
    if (this.props.onSelectionChange) {
      this.props.onSelectionChange({
        viewer: this.viewer,
        ids: this.viewer.getSelection(),
      });
    }
  };

  zoomIn = () => {
    if (this.viewer) {
      const camera = this.viewer.navigation.getCamera();
      const direction = camera.getWorldDirection(); // Move in the opposite direction for zooming in
      camera.position.add(direction);
      this.viewer.navigation.setCamera(camera);
      this.viewer.navigation.update();
    }
  };

  zoomOut = () => {
    if (this.viewer) {
      const camera = this.viewer.navigation.getCamera();
      const direction = camera.getWorldDirection().multiplyScalar(-1); // Move in the same direction for zooming out
      camera.position.add(direction);
      this.viewer.navigation.setCamera(camera);
      this.viewer.navigation.update();
    }
  };

  render() {
    return (
      <div
        ref={(ref) => (this.container = ref)}
        style={{
          width: "100%",
          height: "calc(100% - 50px)",
          position: "absolute",
          top: 0,
          left: 0,
          margin: 0,
          padding: 0,
        }}
      ></div>
    );
  }
}

Viewer.propTypes = {
  urn: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  onCameraChange: PropTypes.func,
  onSelectionChange: PropTypes.func,
  onLoadSuccess: PropTypes.func,
};

export default Viewer;
