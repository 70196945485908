import React from 'react';
import styles from './PartnershipBenefits.module.css';
import BenefitCard from './BenefitCard';

const benefitsData = [
  {
    iconSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/e06549119ca3c7cd20e12d659ce8a46a580588a184d1160b0f147b9f2ab8a1ff?placeholderIfAbsent=true&apiKey=860967788028437b8a0095d5a96bdd20",
    title: "Marketing <br /> Assistance",
    altText: "Marketing Assistance Icon"
  },
  {
    iconSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/779a9b0733f115a8ff6d9ae51ef98fbd7a16a5316b130101aca91aacd1cfc320?placeholderIfAbsent=true&apiKey=860967788028437b8a0095d5a96bdd20",
    title: "Sales-Enablement <br />& Training",
    altText: "Sales-Enablement & Training Icon"
  },
  {
    iconSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/3d3813e15421b8b3eb501cefa4faa78a0ce3c225d0dec91d7503714a633f3997?placeholderIfAbsent=true&apiKey=860967788028437b8a0095d5a96bdd20",
    title: "Increased <br /> Revenues",
    altText: "Increased Revenues Icon"
  }
];

function PartnershipBenefits() {
  return (
    <section className={styles.container}>
      <div className={styles.content}>
        <div className={styles.textColumn}>
          <div className={styles.textContent}>
            <h2 className={styles.title}>Why partner with us?</h2>
            <p className={styles.description}>
              Partner with us for unparalleled expertise, top-quality craftsmanship, and a commitment to sustainable practices. Our client-focused approach ensures your vision becomes reality, with safety and innovation at the forefront of every project.
            </p>
            <div className={styles.benefitsContainer}>
              <div className={styles.benefitsGrid}>
                {benefitsData.map((benefit, index) => (
                  <BenefitCard key={index} {...benefit} />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.imageColumn}>
          <div className={styles.imageWrapper}>
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/a07ce62809750bd4ad77d98c2382fc60862f65ba0e13293498f9ec2bf146e218?placeholderIfAbsent=true&apiKey=860967788028437b8a0095d5a96bdd20" className={styles.backgroundImage} alt="Partnership background" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default PartnershipBenefits;