import React, { useState, useEffect } from "react";
import { WebApimanager } from "../../WebApiManager";
import { Dialog, DialogContent } from "@mui/material";
import { AiOutlineCloseCircle } from "react-icons/ai";
import swal from "sweetalert";
import { userInfo } from "../../atoms";
import { useRecoilValue } from "recoil";
import InputField, { InputTextArea } from "../../Widgets/Forms/InputFields";
import {
  ButtonBlue,
  ButtonGrayOutline,
  ButtonIcon,
} from "../../Widgets/Buttons";
import { DetailsTile } from "../../Widgets/Forms/FormReviewTiles";
import { useRecoilState } from "recoil";
import { RxCross1 } from "react-icons/rx";
const CreateNotification = (props) => {
  let webApi = new WebApimanager();
  const [formErrors, setFormErrors] = useState({});
  const [currentUserInfo, setCurrentUserInfo] = useRecoilState(userInfo);
  const [userId, setuserId] = useState(currentUserInfo._id || "");
  const [subject, setSubject] = useState("");
  const [notifMessage, setNotifMessage] = useState("");
  const [usersList, setUsersList] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [selectedUserProfile, setSelectedUserProfile] = useState("");
  const [emailError, setEmailError] = useState("");

  useEffect(() => {
    getUsersList();
  }, []);

  const getUsersList = () => {
    webApi
      .get("api/users")
      .then((res) => {
        if (res.data.errorCode) {
          console.log("Failure", res.data.errormessage, "error");
        } else {
          setUsersList(res.data.data.allUsers);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Unable to get Users list, Please try again later.`,
            "error"
          );
        }
      });
  };

  const validate = () => {
    const errors = {};
    if (!selectedUserId) {
      errors.selectedUserId = "Send To is required!";
    }
    if (!subject) {
      errors.subject = "Subject is required!";
    }
    if (!notifMessage) {
      errors.notifMessage = "Message is required!";
    }
    setFormErrors(errors); // Update state with validation errors
    return errors;
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const errors = validate();

    if (Object.keys(errors).length === 0) {
      const data = {
        message: notifMessage,
        userID: userId,
        subject: subject,
        sentBy: userId,
        sentTo: selectedUserId,
      };

      webApi
        .post("api/mail", data)
        .then((res) => {
          if (res.data.status === "error") {
            // Display the specific error message from the API
            if (
              res.data.message.includes(
                "Hostname/IP does not match certificate's altnames"
              )
            ) {
              setEmailError(
                "Failed to send email. Please check your SMTP settings."
              );
            } else {
              swal("Failure", res.data.message, "error");
            }
          } else {
            props.getAllNotifs();
            swal("Success", "Notification sent successfully!", "success");
            props.setTrigger(false);
          }
        })
        .catch((error) => {
          // Display the specific error message from the catch block
          if (error.response && error.response.data) {
            const errorMessage =
              error.response.data.message ||
              "Oops! Failed to send notification. Please try again later.";
            if (
              errorMessage.includes(
                "Hostname/IP does not match certificate's altnames"
              )
            ) {
              setEmailError(
                "Failed to send email. Please check your SMTP settings."
              );
            } else {
              swal("Error", errorMessage, "error");
            }
          } else {
            swal(
              "Error",
              "Oops! Failed to send notification. Please try again later.",
              "error"
            );
          }
        });
    }
  };

  return props.trigger ? (
    <Dialog
      open={true}
      aria-labelledby="Create Notification Form"
      fullWidth
      maxWidth="lg"
      PaperProps={{ sx: { height: "65%" } }}
      // scroll="body"
      className="flex flex-col justify-center items-center"
    >
      {/* <div className="flex justify-between items-center bg-[#4D4D4D] color-white-700 font-semibold text-center"> */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "#4D4D4D",
          margin: "0px",
          color: "white",
        }}
      >
        <span className="flex-grow text-center">New Message</span>
        <ButtonIcon
          title="Close Portfolio Form"
          onClick={() => props.setTrigger(false)}
          icon={<RxCross1 size={20} />}
          className="hover:text-red-600 hover:bg-gray-600 rounded-full-border"
        />
      </div>

      <DialogContent dividers>
        {emailError && (
          <div className="p-4 bg-red-100 text-red-800 border border-red-300 rounded">
            {emailError}
          </div>
        )}
        <div className="flex flex-col gap-y-3">
          <DetailsTile
            label={
              <p className="flex pb-4">
                Send To<span className="text-red-600">*</span>
              </p>
            }
            data={
              <div className="w-full">
                <label
                  htmlFor="users"
                  className="text-gray-700 w-full text-sm font-semibold"
                >
                  {/* <span className="text-red-600">*</span> */}
                  <select
                    name="users"
                    id="users"
                    data-cy="users"
                    required
                    value={selectedUserId}
                    onChange={(e) => {
                      const selectedOption =
                        e.target.options[e.target.selectedIndex];
                      setSelectedUserId(e.target.value);
                      setSelectedUserProfile(
                        selectedOption.getAttribute("data-profile")
                      );
                    }}
                    className="mt-1 p-2.5 w-full bg-gray-50 border rounded-md text-gray-900 text-sm focus:border-blue-52"
                  >
                    <option value="" disabled>
                      Select a User
                    </option>
                    {usersList.map((option, index) => (
                      <option
                        key={index}
                        value={option._id}
                        data-profile={option.profile}
                      >
                        {`${option.firstName} ${option.lastName}`}
                      </option>
                    ))}
                  </select>
                  <span className="text-red-500 text-sm">
                    {formErrors.selectedUserId}
                  </span>
                </label>
              </div>
            }
            className=""
            dataClassName="text-gray-500 font-semibold mb-4"
            labelClassName="font-semibold"
          />
          <DetailsTile
            label={
              <p className="flex pb-6">
                Subject<span className="text-red-600">*</span>
              </p>
            }
            data={
              <InputField
                name="subject"
                id="subject"
                type="text"
                dataCy="subject"
                placeholder="Enter Subject here..."
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                errorText={formErrors.subject}
                style="w-96 mb-4"
              />
            }
            className="mr-auto"
            dataClassName="text-gray-500 text-sm font-semibold mb-2"
            labelClassName="font-semibold"
          />

          <DetailsTile
            label={
              <p className="flex">
                Message<span className="text-red-600">*</span>
              </p>
            }
            data={
              <InputTextArea
                name="notifMessage"
                id="notifMessage"
                dataCy="notifMessage"
                type="text"
                placeholder="Enter Message here..."
                value={notifMessage}
                onChange={(e) => setNotifMessage(e.target.value)}
                errorText={formErrors.notifMessage}
                style="w-96 h-44 rounded-lg"
              />
            }
            className="mr-auto"
            dataClassName="text-gray-500"
            labelClassName="font-semibold"
          />
        </div>
        <div className="flex justify-center items-center gap-16">
          <ButtonGrayOutline
            id="cancel"
            btnName="cancel"
            dataCy="cancel"
            name="Cancel"
            onClick={() => props.setTrigger(false)}
            className="mt-1 justify-self-end bg-gray-500 text-white hover:bg-gray-700"
          />
          <button
            id="send"
            btnName="send"
            dataCy="send"
            name="Send"
            className="mt-1 "
            onClick={handleSubmit}
            style={{
              width: "100px",
              height: "40px",
              marginLeft: "50px",
              padding: "5px",
              borderRadius: "5px",
              border: "1px solid var(--primary-color)",
              backgroundColor: "var(--primary-color)",
              color: "white",
              textAlign: "center",
            }}
          >
            Send
          </button>
        </div>
      </DialogContent>
    </Dialog>
  ) : (
    ""
  );
};

export default CreateNotification;
