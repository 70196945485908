import React from "react";
const XlsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="25"
      viewBox="0 0 20 25"
      fill="none"
    >
      <path
        d="M2.5801 18.3447V20.8838H1.26955C1.10222 20.8838 0.936532 20.8507 0.782026 20.7865C0.62752 20.7222 0.487242 20.628 0.369259 20.5094C0.251276 20.3907 0.157916 20.2499 0.0945482 20.095C0.0311806 19.9401 -0.000944266 19.7743 2.11295e-05 19.6069C3.21934e-05 19.5652 0.00231421 19.5234 0.00685707 19.4819C0.0395651 19.1698 0.186702 18.8809 0.419879 18.6709C0.653056 18.4609 0.955748 18.3447 1.26955 18.3447H2.5801Z"
        fill="#00A649"
      />
      <path
        d="M19.9277 17.8711V18.3408L3.5791 18.3447H1.27051C0.956628 18.3447 0.653873 18.461 0.420684 18.6711C0.187496 18.8812 0.0404077 19.1702 0.0078125 19.4824L0.0292969 10.5229C0.0292969 10.1862 0.163051 9.86334 0.401134 9.62526C0.639217 9.38717 0.962128 9.25342 1.29883 9.25342H2.58105L19.9277 9.25V17.8711Z"
        fill="#05DB5C"
      />
      <path
        d="M19.9268 18.3408V22.4487C19.9268 23.1252 19.658 23.774 19.1797 24.2524C18.7013 24.7308 18.0525 24.9995 17.376 24.9995H5.13135C4.79633 24.9996 4.46459 24.9336 4.15506 24.8055C3.84552 24.6773 3.56427 24.4894 3.32736 24.2526C3.09044 24.0157 2.90251 23.7345 2.77429 23.425C2.64607 23.1155 2.58008 22.7837 2.58008 22.4487V18.3447H3.57812L19.9268 18.3408Z"
        fill="#E7E7E9"
      />
      <path
        d="M15.3369 0.116211C15.4964 0.181267 15.6436 0.272898 15.7725 0.387207L19.415 3.62549C19.6714 3.85412 19.8433 4.1623 19.9033 4.50049H16.8662C16.4604 4.50049 16.0711 4.33926 15.7841 4.05228C15.4972 3.7653 15.3359 3.37607 15.3359 2.97021V0.116211H15.3369Z"
        fill="#A3A2A3"
      />
      <path
        d="M14.7524 5.36156e-07C14.9525 -0.000167488 15.1506 0.0391585 15.3354 0.115723V2.96973C15.3354 3.37558 15.4967 3.76481 15.7837 4.05179C16.0706 4.33878 16.4599 4.5 16.8657 4.5H19.9028C19.9189 4.58861 19.9269 4.6785 19.9268 4.76856V9.25049L2.58008 9.25391V3.06104C2.58008 2.24928 2.90251 1.47077 3.47646 0.89673C4.05041 0.322688 4.82887 0.000130023 5.64062 5.36156e-07H14.7524Z"
        fill="#E7E7E9"
      />
      <path
        d="M9.87964 16.4069C9.45679 16.4069 9.42993 16.402 9.38892 16.3937C9.27299 16.3714 9.16843 16.3095 9.09322 16.2185C9.01801 16.1275 8.97685 16.0132 8.97681 15.8951V11.8102C8.9747 11.7421 8.98628 11.6744 9.01086 11.6109C9.03544 11.5474 9.07253 11.4895 9.11991 11.4406C9.1673 11.3917 9.22402 11.3529 9.28671 11.3263C9.3494 11.2998 9.41679 11.2861 9.48486 11.2861C9.55294 11.2861 9.62032 11.2998 9.68301 11.3263C9.74571 11.3529 9.80243 11.3917 9.84981 11.4406C9.8972 11.4895 9.93428 11.5474 9.95887 11.6109C9.98345 11.6744 9.99503 11.7421 9.99292 11.8102V15.3908C10.2322 15.3908 10.53 15.3908 10.8108 15.3873H10.8142C10.9463 15.391 11.0717 15.4458 11.164 15.5404C11.2563 15.6349 11.3081 15.7616 11.3086 15.8937C11.309 16.0258 11.258 16.1529 11.1664 16.2481C11.0747 16.3432 10.9496 16.3989 10.8176 16.4034C10.3816 16.4069 10.0842 16.4069 9.87964 16.4069Z"
        fill="white"
      />
      <path
        d="M14.2838 16.4068C13.5939 16.4068 12.9205 16.1177 12.4816 15.6334C12.4367 15.5839 12.402 15.5261 12.3794 15.4632C12.3569 15.4003 12.347 15.3336 12.3502 15.2669C12.3535 15.2002 12.3698 15.1347 12.3983 15.0743C12.4269 15.0139 12.467 14.9597 12.5165 14.9149C12.5659 14.87 12.6238 14.8353 12.6866 14.8127C12.7495 14.7902 12.8162 14.7803 12.883 14.7835C12.9497 14.7868 13.0151 14.8031 13.0755 14.8316C13.1359 14.8602 13.1901 14.9003 13.235 14.9498C13.484 15.2247 13.8761 15.3892 14.2838 15.3892C14.6803 15.3892 14.9596 15.1519 15.0006 14.9219C15.0553 14.6158 14.6427 14.4283 14.4635 14.3619C13.8287 14.128 13.2662 13.8775 13.2428 13.8673C13.2183 13.8561 13.1946 13.8433 13.172 13.8287C12.9623 13.6931 12.7962 13.4998 12.6937 13.2721C12.5911 13.0444 12.5565 12.7919 12.5939 12.545C12.672 12.0001 13.0709 11.5562 13.6339 11.3868C14.7496 11.0504 15.6681 11.8072 15.7067 11.8399C15.8103 11.9263 15.8753 12.0503 15.8875 12.1846C15.8997 12.319 15.858 12.4526 15.7716 12.5562C15.6852 12.6598 15.5612 12.7249 15.4269 12.737C15.2926 12.7492 15.1589 12.7075 15.0553 12.6212C15.0397 12.6085 14.5045 12.1866 13.9274 12.3604C13.6632 12.44 13.6105 12.6177 13.5997 12.6905C13.5915 12.7403 13.5965 12.7914 13.6143 12.8387C13.6322 12.8859 13.6622 12.9276 13.7013 12.9595C13.8448 13.0225 14.3126 13.2242 14.8151 13.4097C15.8453 13.7891 16.1036 14.5328 16.0021 15.1016C15.8663 15.8585 15.1437 16.4068 14.2838 16.4068Z"
        fill="white"
      />
      <path
        d="M7.49191 16.4065C7.41033 16.4066 7.32993 16.387 7.25752 16.3494C7.1851 16.3118 7.12281 16.2574 7.0759 16.1906L4.20041 12.1018C4.12271 11.9917 4.09192 11.8553 4.11481 11.7225C4.1377 11.5897 4.2124 11.4715 4.32248 11.3938C4.43255 11.3161 4.56899 11.2853 4.70176 11.3082C4.83454 11.3311 4.95279 11.4058 5.03049 11.5158L7.90646 15.6047C7.95999 15.6808 7.99161 15.7701 7.99789 15.8629C8.00416 15.9557 7.98484 16.0485 7.94203 16.1311C7.89922 16.2137 7.83457 16.2829 7.75511 16.3313C7.67566 16.3797 7.58445 16.4054 7.49142 16.4055L7.49191 16.4065Z"
        fill="white"
      />
      <path
        d="M4.40835 16.4072C4.31525 16.4071 4.22394 16.3815 4.14441 16.3331C4.06488 16.2847 4.00017 16.2154 3.95735 16.1327C3.91452 16.05 3.89523 15.9572 3.90157 15.8643C3.90791 15.7714 3.93965 15.682 3.99331 15.6059L6.87417 11.5171C6.95376 11.4119 7.07111 11.3417 7.20145 11.3215C7.33179 11.3012 7.4649 11.3324 7.57266 11.4085C7.68043 11.4845 7.7544 11.5995 7.77895 11.7291C7.80351 11.8587 7.77672 11.9928 7.70425 12.103L4.82339 16.1919C4.77661 16.2585 4.71446 16.3128 4.64222 16.3503C4.56997 16.3878 4.48975 16.4073 4.40835 16.4072Z"
        fill="white"
      />
    </svg>
  );
};
export default XlsIcon;