import * as Icon from "react-bootstrap-icons";
import { useEffect, React, useState } from 'react';
import { Dialog, DialogContent } from "@mui/material";
import { useRecoilState } from "recoil";
import { userInfo } from '../../atoms';

const ApprovalPending = (props) => {

  const { webApi, setError, setLoading } = props

  const [approvedData, setApprovedData] = useState([]);
  const [editData, setEditData] = useState([]);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null); 
  const [currentUserInfo, setCurrentUserInfo] = useRecoilState(userInfo); 


  useEffect(() => {
    setLoading(true);
    webApi.get("api/checkListSubmit/AllFormsSumitted")
      .then((response) => {
        console.log("API response:", response);
        if (response.data && Array.isArray(response.data.data.checklists)) {
          setApprovedData(response.data.data.checklists);
          console.log(response.data.data.checklists);
        } else {
          setError("Unexpected data format");
          console.error("Unexpected data format:", response.data);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
        setError("There was an error fetching the data");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);


  const handleApprovalDialogOpen = (id) => {
    const selectedItem = approvedData.find(item => item._id === id);
    setSelectedItem(selectedItem); // Set the selected item data
    setSelectedId(id);
    setEditDialogOpen(true);
  };

  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
    setSelectedId(null);
    setSelectedItem(null); // Clear the selected item data
  };

  const handleApprovalClose = () => {
    setEditDialogOpen(false);
  };

 const handleApprovalSubmit = () => {
    if (!selectedItem) return;

    setLoading(true);

    // Update the current user info via PUT request
    const updatedUserInfo = {
      ...currentUserInfo,
     
    };
    console.log(updatedUserInfo);
    webApi.put(`api/checkListSubmit/AllFormsSumitted/${currentUserInfo.id}`, updatedUserInfo)

    .then((response) => {
      const data = response.data;
      console.log("Server Response:", response); 
      console.log("Data:", data);
    
      window.location.reload();
    })
    .catch((error) => {
      console.error("There was an error logging in!", error);
      setError("There was an error logging in");
    })
    .finally(() => {
      setLoading(false);  // Stop loader
    });
  }

    
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '100px', marginTop: '20px', marginRight: '100px' }}>
      <table style={{ width: '100%' }}>
        <thead style={{ backgroundColor: 'lightblue', textAlign: 'center', height: '45px' }}>
          <tr>
            <th style={{ width: '10%' }}>S.No</th>
            <th style={{ width: '20%' }}>Floor</th>
            <th style={{ width: '15%' }}>Flat</th>
            <th style={{ width: '15%' }}>Added By</th>
            <th style={{ width: '15%' }}>Actions</th>
          </tr>
        </thead>
        <tbody style={{ textAlign: 'center', boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)' }}>
          {approvedData.map((item, index) => (
            <tr key={item._id} style={{ border: '1px solid black', height: '55px' }}>
              <td>{index + 1}</td>
              <td>{item['floor']}</td>
              <td>{item['flat'] ?? ''}</td>
              <td>{item.addedBy
                ? `${item.addedBy.firstName} ${item.addedBy.lastName || ''}`.trim()
                : ''}</td>
              <td>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Icon.Pen size={25} style={{ marginRight: '30px' }} onClick={() => handleApprovalDialogOpen(item._id)} />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {selectedItem && (
        <Dialog open={editDialogOpen} onClose={handleEditDialogClose} maxWidth="xl" fullWidth>
          <DialogContent>
            <Icon.X size={25} onClick={handleEditDialogClose} style={{ marginLeft: '1050px' }} />
            <h3 style={{ margin: '0 auto', borderCollapse: 'collapse', width: '80%', textAlign: 'center', border: '2px solid black' }}>{selectedItem.siteName?.localityName}</h3>
            <table style={{ margin: '0 auto', borderCollapse: 'collapse', width: '80%', textAlign: 'center', border: '2px solid black' }}>
              <thead>
                <tr>
                  <th style={{ border: '1px solid black', width: '10%', height: '50px' }}>Project</th>
                  <th style={{ width: '70%' }}>{selectedItem.project}</th>
                  <th style={{ border: '1px solid black' }}>System SIPL/CIVIL/034</th>
                </tr>
              </thead>
            </table>
            <table style={{ margin: '0 auto', borderCollapse: 'collapse', width: '80%', textAlign: 'center', border: '2px solid black' }}>
              <thead>
                <tr style={{ height: '50px' }}>
                  <th style={{ border: '1px solid black', width: '10%' }}>Block:</th>
                  <th style={{ border: '1px solid black', width: '10%' }}>{selectedItem.block}</th>
                  <th style={{ border: '1px solid black', width: '10%' }}>Floor:</th>
                  <th style={{ border: '1px solid black', width: '10%' }}>{selectedItem.floor}</th>
                  <th style={{ border: '1px solid black', width: '10%' }}>Flat:</th>
                  <th style={{ border: '1px solid black', width: '10%' }}>{selectedItem.flat}</th>
                  <th style={{ border: '1px solid black', width: '20%' }}>Checking Start Date & Time</th>
                  <th style={{ border: '1px solid black', width: '25%' }}>{new Date(selectedItem.startDate).toLocaleString()}</th>
                </tr>
              </thead>
            </table>
            <table style={{ margin: '0 auto', borderCollapse: 'collapse', width: '80%', textAlign: 'center', border: '2px solid black' }}>
              <thead>
                <tr style={{ height: '50px' }}>
                  <th style={{ border: '1px solid black', width: '10%' }}>Grid</th>
                  <th style={{ border: '1px solid black', width: '50%' }}>{selectedItem.grid}</th>
                  <th style={{ border: '1px solid black', width: '20%' }}>Checking End Date & Time</th>
                  <th style={{ border: '1px solid black', width: '25%' }}>{new Date(selectedItem.endDate).toLocaleString()}</th>
                </tr>
              </thead>
            </table>
            <table style={{ margin: '0 auto', borderCollapse: 'collapse', width: '80%', textAlign: 'center', border: '2px solid black' }}>
              <thead>
                <tr style={{ height: '50px' }}>
                  <th style={{ border: '1px solid black', width: '25%' }}>Ref.Org (Architectural floor plan)</th>
                  <th style={{ border: '1px solid black', width: '55%' }}>{selectedItem.refOrg}</th>
                  <th style={{ border: '1px solid black', width: '10%' }}>Rev.No, date</th>
                  <th style={{ border: '1px solid black', width: '25%' }}>{new Date(selectedItem.revNoDate).toLocaleDateString()}</th>
                </tr>
              </thead>
            </table>
            <table style={{ margin: '0 auto', borderCollapse: 'collapse', width: '80%', textAlign: 'center', border: '2px solid black' }}>
              <thead>
                <tr style={{ height: '50px' }}>
                  <th style={{ border: '1px solid black', width: '10%' }}>SI.No</th>
                  <th style={{ border: '1px solid black', width: '35%' }}>Description</th>
                  <th style={{ border: '1px solid black', width: '15%' }}>Status</th>
                  <th style={{ border: '1px solid black', width: '35%' }}>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {selectedItem.description.map((description, index) => (
                  <tr key={index}>
                    <td style={{ border: '1px solid black' }}>{index + 1}</td>
                    <td style={{ border: '1px solid black', width: '50%', height: '40px', fontSize: '20px' }}>{description.heading}</td>
                    <td style={{ border: '1px solid black' }}>
                      {description.value}
                    </td>
                    <td style={{ border: '1px solid black', width: '98%', height: '40px', fontSize: '20px' }}>
                      {description.remarks}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
              <label className="fs-10 fw-bold text-start py-2">
                Request For Approval <a style={{ color: "red" }}>*</a>:
              </label>
            
                <p style={{
                  width: "200px",
                  height: "45px",
                  padding: "10px",
                  boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
                  className:"form-control fs-12",
                }}>
                  {selectedItem.addedBy
                ? `${selectedItem.addedBy.firstName} ${selectedItem.addedBy.lastName || ''}`.trim()
                : ''}
                </p>
              
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '15px' }}>
              <button style={{ backgroundColor: '#7C1034', color: 'white', width: '100px', border: 'none', marginLeft: '10px' }} onClick={handleApprovalClose}>Cancel</button>
              <button style={{ backgroundColor: '#7C1034', color: 'white', width: '100px', border: 'none', marginLeft: '10px' }} onClick={handleApprovalSubmit}>Approve</button>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}

export default ApprovalPending;

