import React, { useEffect, useState } from "react";
import "./Dialog.module.css";
import { WebApimanager } from "../../WebApiManager";
import { Loader } from "../../Widgets/notificationFeedbacks";
import FloorDetails from "./FloorDetails";
import VillaTypeDialog from "./VillaTypeDialog";
import SubmitButton from "./SiteComponents/SubmitButton";
import AmenityContainer from "./SiteComponents/AmenityContainer";
import VillaPreciseDetails from "./SiteComponents/VillaPreciseDetails";
import VillaTypeDetails from "./SiteComponents/VillaTypeDetails";
import ApartmentTowerDetails from "./SiteComponents/ApartmentTowerDetails";
import CommonClubHouseDetails from "./SiteComponents/CommonClubHouseDetails";
import BuildingDetails from "./SiteComponents/BuildingDetails";
const AddSiteDetailsDialog = (props) => {
  const { close, id } = props;
  // console.log("props id" , id);
  const webApi = new WebApimanager();
  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [amenities, setAmenities] = useState([]);
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });
  const [formData, setFormData] = useState({
    ventureType: "",
    BObasementsBuilding: "",
    AbasementscommonTowers: "",
    VnoOfClubHouses: "",
    BOnoOfbasements: "",
    BOnoOfPouresbasements: "",
    AnoOfBasements: "",
    AnoOfPouresBasements: "",
    VnoOfVillas: "",
    villas: [],
    VnoOfFloorVillas: "",
    Vceller: "",
    AnoOfTowers: "",
    AnoOfClubHouses: "",
    VnoOfCellar: "",
    VcommonFloor: "",
    VnoOfVillas: "",
    BOamenity: "",
    VnoOfCellar: "",
    VcommonFloor: "",
    VnoOfVilBOamenity: "",
    AnoOfFloors: "",
    AnoOfPouresFloor: "",
    AnoOfBasements: "",
    AnoOfPouresBasements: "",
    BOnoOfFloors: "",
    BOnoOfPouresFloor: "",
    ACHnoOfFloors: "",
    ACHnoOfPouresFloor: "",
    ACHnoOfPouresBasement: "",
    ACHnoOfFloors: "",
    ACHnoOfPouresFloor: "",
    VCHnoOfPouresBasement: "",
    VCHnoOfFloors: "",
    VCHnoOfPouresFloor: "",
    towers: [],
    floors: [],
    clubs: [],
    noOfFloors: "",
    noOfPouresFloor: "",
    CHnoOfBasements: "",
    CHnoOfPouresBasement: "",
    AbasementsApartments: "",
  });

  const [rowsData, setRowsData] = useState([]);

  const [villaTypes, setVillaTypes] = useState([]);
  useEffect(() => {
    if (rowsData) {
      // Map to collect unique villa types and their details
      const uniqueVillaTypes = Array.from(
        new Map(
          rowsData.map((row) => [
            row.Type,
            {
              // Type: row.Type,
              villaType: row.villaType,
              noOfCellar: row.noOfCellar,
              noOfFloors: row.noOfFloors,
            },
          ])
        ).values()
      );

      setVillaTypes(uniqueVillaTypes);
      console.log("Villa Types with Details", uniqueVillaTypes);
    }
  }, [rowsData]);

  const [enableNoofVillas, setEnableNoofVillas] = useState(false);

  useEffect(() => {
    if (
      (formData.Vtypecommon === "Yes" &&
        formData.Vceller === "Yes" &&
        formData.VcommonFloor === "Yes" &&
        formData.VcommonCellar === "Yes" &&
        formData.VtypeHK !== "" &&
        formData.VnoOfCellar !== "" &&
        formData.VnoOfFloorVillas !== "") ||
      (formData.Vtypecommon === "No" &&
        formData.Vceller === "No" &&
        formData.VcommonFloor === "No") ||
      (formData.Vtypecommon === "Yes" &&
        formData.VtypeHK !== "" &&
        formData.Vceller === "No" &&
        formData.VcommonFloor === "No") ||
      (formData.Vtypecommon === "Yes" &&
        formData.VtypeHK !== "" &&
        formData.Vceller === "Yes" &&
        formData.VcommonCellar === "Yes" &&
        formData.VnoOfCellar !== "" &&
        formData.VcommonFloor === "No") ||
      (formData.Vtypecommon === "Yes" &&
        formData.VtypeHK !== "" &&
        formData.Vceller === "Yes" &&
        formData.VcommonCellar === "No" &&
        formData.VcommonFloor === "No") ||
      (formData.Vtypecommon === "Yes" &&
        formData.VtypeHK !== "" &&
        formData.Vceller === "No" &&
        formData.VcommonFloor === "Yes" &&
        formData.VnoOfFloorVillas !== "") ||
      (formData.Vtypecommon === "No" &&
        formData.Vceller === "Yes" &&
        formData.VcommonCellar === "Yes" &&
        formData.VnoOfCellar !== "" &&
        formData.VcommonFloor === "Yes" &&
        formData.VnoOfFloorVillas !== "") ||
      (formData.Vtypecommon === "No" &&
        formData.Vceller === "Yes" &&
        formData.VcommonCellar === "Yes" &&
        formData.VnoOfCellar !== "" &&
        formData.VcommonFloor === "No") ||
      (formData.Vtypecommon === "No" &&
        formData.Vceller === "Yes" &&
        formData.VcommonCellar === "No" &&
        formData.VcommonFloor === "Yes" &&
        formData.VnoOfFloorVillas !== "") ||
      (formData.Vtypecommon === "No" &&
        formData.Vceller === "No" &&
        formData.VcommonFloor === "Yes" &&
        formData.VnoOfFloorVillas !== "")
    ) {
      setEnableNoofVillas(true);
    }
  }, [
    formData.Vtypecommon,
    formData.Vceller,
    formData.VcommonFloor,
    formData.VcommonCellar,
    formData.VtypeHK,
    formData.VnoOfCellar,
    formData.VnoOfFloorVillas,
  ]);

  const validateForm = () => {
    const errors = {};

    if (!formData.ventureType) {
      errors.ventureType = "Venture Type is required";
    }

    if (formData.ventureType === "Building or Office") {
      if (!formData.BObasementsBuilding) {
        errors.BObasementsBuilding = "Basements Is there field is required";
      }
      if (!formData.BOnoOfFloors) {
        errors.BOnoOfFloors = "Number of floors field is required";
      }
      if (!formData.BOnoOfPouresFloor) {
        errors.BOnoOfPouresFloor =
          "Number of poures for floor filed is required";
      }
      if (formData.BObasementsBuilding === "Yes") {
        if (!formData.BOnoOfbasements) {
          errors.BOnoOfbasements = "Number of Basements is required";
        }
        if (!formData.BOnoOfPouresbasements) {
          errors.BOnoOfPouresbasements =
            "Number of Poures for a Basement is required";
        }
      }
      if (!formData.BOamenity) {
        errors.BOamenity = "Amenities there  field is required";
      }
      if (!formData.BOamenity === "Yes") {
        if (!amenities) {
          errors.amenity = "Amenities field is required";
        }
      }
      if (formData.BOnoOfFloors > 0) {
        if (formData.floors.length > 0) {
          formData.floors.forEach((floor, floorIndex) => {
            console.log("show the error for floor", floorIndex);

            // Validate Number of Units
            if (!floor.numberOfUnits) {
              errors[
                `numberOfUnits-${floorIndex}`
              ] = `Number of Units is required for  floor ${floorIndex}`;
            }
            // Validate Manual or Automatic selection starting from floorIndex 1
            if (floorIndex > 0) {
              console.log("floorindex inside", floorIndex);
              if (!floor.type) {
                errors[
                  `type-${floorIndex}`
                ] = `Manual or Automatic selection is required for floor ${
                  floorIndex + 1
                }`;
              }
            }
            // Validate each unit in the floor
            if (floor.units && floor.units.length > 0) {
              floor.units.forEach((unit, unitIndex) => {
                // Validate Unit Number
                if (!unit.unitNumber) {
                  errors[
                    `unitNumber-${floorIndex}-${unitIndex}`
                  ] = `Unit Number is required for floor ${floorIndex} unit ${unitIndex}`;
                }

                // Validate Unit Type
                if (!unit.unitType) {
                  errors[
                    `unitType-${floorIndex}-${unitIndex}`
                  ] = `Unit Type is required for  floor ${floorIndex} unit ${unitIndex}`;
                }
              });
            }
          });
        }
      }
    }
    if (formData.ventureType === "Apartments") {
      if (!formData.AbasementsApartments) {
        errors.AbasementsApartments =
          "Basements common for every Appartments is required";
      }
      if (formData.AbasementsApartments === "Yes") {
        if (!formData.AbasementscommonTowers) {
          errors.AbasementscommonTowers =
            "Basements common for every Towers is required";
        }
      }
      if (formData.AbasementscommonTowers === "Yes") {
        if (!formData.AnoOfBasements) {
          errors.AnoOfBasements = "Number of Basements is required";
        }
        if (!formData.AnoOfPouresBasements) {
          errors.AnoOfPouresBasements =
            "Number of Poures for a Basement is required";
        }
      }

      if (!formData.AnoOfTowers) {
        errors.AnoOfTowers = "Number of towers is required";
      }
      if (!formData.AnoOfClubHouses) {
        errors.AnoOfClubHouses = "Club Houses is required";
      }

      // For towers
      if (formData.AbasementscommonTowers === "Yes") {
        if (formData.towers.length > 0) {
          formData.towers.forEach((tower, towerindex) => {
            // Validate Number of Floors
            if (!tower.AnoOfFloors) {
              errors[
                `AnoOfFloors-${towerindex}`
              ] = `Number of Floors is required for tower ${towerindex + 1}`;
            }

            // Validate Number of Poures for a Floor
            if (!tower.AnoOfPouresFloor) {
              errors[
                `AnoOfPouresFloor-${towerindex}`
              ] = `Number of Poures for a Floor is required for tower ${
                towerindex + 1
              }`;
            }
            if (tower.floors && tower.floors.length > 0) {
              tower.floors.forEach((floor, floorIndex) => {
                console.log("show the error for floor", floorIndex);

                // Validate Number of Units
                if (!floor.numberOfUnits) {
                  errors[
                    `numberOfUnits-${towerindex}-${floorIndex}`
                  ] = `Number of Units is required for tower ${
                    towerindex + 1
                  } floor ${floorIndex}`;
                }
                if (floorIndex > 0) {
                  // Validate Manual or Automatic selection
                  if (!floor.type) {
                    errors[
                      `type-${towerindex}-${floorIndex}`
                    ] = `Manual or Automatic selection is required for tower ${
                      towerindex + 1
                    } floor ${floorIndex}`;
                  }
                }
                console.log("before the loop", floor.units);
                // Validate each unit in the floor
                if (floor.units && floor.units.length > 0) {
                  console.log("entering the loop", floor.units);
                  floor.units.forEach((unit, unitIndex) => {
                    // Validate Unit Number
                    if (!unit.unitNumber) {
                      console.log(
                        "entering the loop unit",
                        unit.unitNumber,
                        tower.floors[floorIndex]
                      );
                      errors[
                        `unitNumber-${towerindex}-${floorIndex}-${unitIndex}`
                      ] = `Unit Number is required for tower ${
                        towerindex + 1
                      } floor ${floorIndex} unit ${unitIndex}`;
                    }

                    // Validate Unit Type
                    if (!unit.unitType) {
                      errors[
                        `unitType-${towerindex}-${floorIndex}-${unitIndex}`
                      ] = `Unit Type is required for tower ${
                        towerindex + 1
                      } floor ${floorIndex} unit ${unitIndex}`;
                    }
                  });
                }
              });
            }
          });
        }
      }
      if (formData.AbasementscommonTowers === "No") {
        if (formData.towers.length > 0) {
          formData.towers.forEach((tower, towerindex) => {
            // Validate Number of Floors
            if (!tower.AnoOfFloors) {
              errors[
                `AnoOfFloors-${towerindex}`
              ] = `Number of Floors is required for tower ${towerindex + 1}`;
            }

            // Validate Number of Poures for a Floor
            if (!tower.AnoOfPouresFloor) {
              errors[
                `AnoOfPouresFloor-${towerindex}`
              ] = `Number of Poures for a Floor is required for tower ${
                towerindex + 1
              }`;
            }

            // Validate Number of Basements
            if (!tower.AnoOfBasement) {
              errors[
                `AnoOfBasement-${towerindex}`
              ] = `Number of Basements is required for tower ${towerindex + 1}`;
            }

            // Validate Number of Poures for a Basement
            if (!tower.AnoOfPouresBasements) {
              errors[
                `AnoOfPouresBasements-${towerindex}`
              ] = `Number of Poures for a Basement is required for tower ${
                towerindex + 1
              }`;
            }
            if (tower.floors && tower.floors.length > 0) {
              tower.floors.forEach((floor, floorIndex) => {
                console.log("show the error for floor", floorIndex);

                // Validate Number of Units
                if (!floor.numberOfUnits) {
                  errors[
                    `numberOfUnits-${towerindex}-${floorIndex}`
                  ] = `Number of Units is required for tower ${
                    towerindex + 1
                  } floor ${floorIndex}`;
                }
                if (floorIndex > 0) {
                  // Validate Manual or Automatic selection
                  if (!floor.type) {
                    errors[
                      `type-${towerindex}-${floorIndex}`
                    ] = `Manual or Automatic selection is required for tower ${
                      towerindex + 1
                    } floor ${floorIndex}`;
                  }
                }
                // Validate each unit in the floor
                if (floor.units && floor.units.length > 0) {
                  floor.units.forEach((unit, unitIndex) => {
                    // Validate Unit Number
                    if (!unit.unitNumber) {
                      errors[
                        `unitNumber-${towerindex}-${floorIndex}-${unitIndex}`
                      ] = `Unit Number is required for tower ${
                        towerindex + 1
                      } floor ${floorIndex} unit ${unitIndex}`;
                    }

                    // Validate Unit Type
                    if (!unit.unitType) {
                      errors[
                        `unitType-${towerindex}-${floorIndex}-${unitIndex}`
                      ] = `Unit Type is required for tower ${
                        towerindex + 1
                      } floor ${floorIndex} unit ${unitIndex}`;
                    }
                  });
                }
              });
            }
          });
        }
      }

      // For ClubHouses
      if (formData.AbasementscommonTowers === "Yes") {
        if (formData.clubs.length > 0) {
          formData.clubs.forEach((club, clubIndex) => {
            // Validate Number of Floors
            if (!club.noOfFloors) {
              errors[
                `noOfFloors-${clubIndex}`
              ] = `Number of Floors is required for clubHouse ${clubIndex + 1}`;
            }

            // Validate Number of Poures for a Floor
            if (!club.noOfPouresFloor) {
              errors[
                `noOfPouresFloor-${clubIndex}`
              ] = `Number of Poures for a Floor is required for clubHouse ${
                clubIndex + 1
              }`;
            }
            if (club.floors && club.floors.length > 0) {
              club.floors.forEach((floor, floorIndex) => {
                console.log("show the error for floor", floorIndex);

                // Validate Number of Units
                if (!floor.numberOfUnits) {
                  errors[
                    `numberOfUnits-${clubIndex}-${floorIndex}`
                  ] = `Number of Units is required for clubHouse ${
                    clubIndex + 1
                  } floor ${floorIndex}`;
                }
                if (floorIndex > 0) {
                  // Validate Manual or Automatic selection
                  if (!floor.type) {
                    errors[
                      `type-${clubIndex}-${floorIndex}`
                    ] = `Manual or Automatic selection is required for clubHouse ${
                      clubIndex + 1
                    } floor ${floorIndex}`;
                  }
                }
                // Validate each unit in the floor
                if (floor.units && floor.units.length > 0) {
                  floor.units.forEach((unit, unitIndex) => {
                    // Validate Unit Number
                    if (!unit.unitNumber) {
                      errors[
                        `unitNumber-${clubIndex}-${floorIndex}-${unitIndex}`
                      ] = `Unit Number is required for clubHouse ${
                        clubIndex + 1
                      } floor ${floorIndex} unit ${unitIndex}`;
                    }

                    // Validate Unit Type
                    if (!unit.unitType) {
                      errors[
                        `unitType-${clubIndex}-${floorIndex}-${unitIndex}`
                      ] = `Unit Type is required for clubHouse ${
                        clubIndex + 1
                      } floor ${floorIndex} unit ${unitIndex}`;
                    }
                  });
                }
              });
            }
          });
        }
      }
      if (formData.AbasementscommonTowers === "No") {
        if (formData.clubs.length > 0) {
          formData.clubs.forEach((club, clubIndex) => {
            // Validate Number of Floors
            if (!club.noOfFloors) {
              errors[
                `noOfFloors-${clubIndex}`
              ] = `Number of Floors is required for clubHouse ${clubIndex + 1}`;
            }

            // Validate Number of Poures for a Floor
            if (!club.noOfPouresFloor) {
              errors[
                `noOfPouresFloor-${clubIndex}`
              ] = `Number of Poures for a Floor is required for clubHouse ${
                clubIndex + 1
              }`;
            }

            // Validate Number of Basements
            if (!club.CHnoOfBasement) {
              errors[
                `CHnoOfBasement-${clubIndex}`
              ] = `Number of Basements is required for clubHouse ${
                clubIndex + 1
              }`;
            }

            // Validate Number of Poures for a Basement
            if (!club.CHnoOfPouresBasement) {
              errors[
                `CHnoOfPouresBasement-${clubIndex}`
              ] = `Number of Poures for a Basement is required for clubHouse ${
                clubIndex + 1
              }`;
            }
            if (club.floors && club.floors.length > 0) {
              club.floors.forEach((floor, floorIndex) => {
                console.log("show the error for floor", floorIndex);

                // Validate Number of Units
                if (!floor.numberOfUnits) {
                  errors[
                    `numberOfUnits-${clubIndex}-${floorIndex}`
                  ] = `Number of Units is required for clubHouse ${
                    clubIndex + 1
                  } floor ${floorIndex}`;
                }
                if (floorIndex > 0) {
                  // Validate Manual or Automatic selection
                  if (!floor.type) {
                    errors[
                      `type-${clubIndex}-${floorIndex}`
                    ] = `Manual or Automatic selection is required for clubHouse ${
                      clubIndex + 1
                    } floor ${floorIndex}`;
                  }
                }
                // Validate each unit in the floor
                if (floor.units && floor.units.length > 0) {
                  floor.units.forEach((unit, unitIndex) => {
                    // Validate Unit Number
                    if (!unit.unitNumber) {
                      errors[
                        `unitNumber-${clubIndex}-${floorIndex}-${unitIndex}`
                      ] = `Unit Number is required for clubHouse ${
                        clubIndex + 1
                      } floor ${floorIndex} unit ${unitIndex}`;
                    }

                    // Validate Unit Type
                    if (!unit.unitType) {
                      errors[
                        `unitType-${clubIndex}-${floorIndex}-${unitIndex}`
                      ] = `Unit Type is required for clubHouse ${
                        clubIndex + 1
                      } floor ${floorIndex} unit ${unitIndex}`;
                    }
                  });
                }
              });
            }
          });
        }
      }
    }

    if (formData.ventureType === "Villas") {
      if (!formData.VnoOfClubHouses) {
        errors.VnoOfClubHouses = "Number of Club Houses is required";
      }
      if (!formData.Vtypecommon) {
        errors.Vtypecommon = "Villa Types common is required";
      }
      if (formData.Vtypecommon === "Yes" && !formData.VtypeHK) {
        errors.VtypeHK = "Select Villa Type is required";
      }
      if (!formData.VnoOfVillas) {
        errors.VnoOfVillas = "Number of Villas is required";
      }
      if (!formData.Vceller) {
        errors.Vceller = "Cellar presence is required";
      }
      if (formData.Vceller === "Yes") {
        if (!formData.VcommonCellar) {
          errors.VcommonCellar = "Common Cellar is required";
        }
      }
      if (formData.VcommonCellar === "Yes") {
        if (!formData.VnoOfCellar) {
          errors.VnoOfCellar = "Number of Cellars is required";
        }
      }
      if (!formData.VcommonFloor) {
        errors.VcommonFloor = "Floors common for every Villas is required";
      }
      if (formData.VcommonFloor === "Yes" && !formData.VnoOfFloorVillas) {
        errors.VnoOfFloorVillas = "Number of Floors for villas is required";
      }

      if (formData.clubs.length > 0) {
        formData.clubs.forEach((club, clubIndex) => {
          // Validate Number of Floors
          if (!club.noOfFloors) {
            errors[
              `noOfFloors-${clubIndex}`
            ] = `Number of Floors is required for clubHouse ${clubIndex + 1}`;
          }

          // Validate Number of Poures for a Floor
          if (!club.noOfPouresFloor) {
            errors[
              `noOfPouresFloor-${clubIndex}`
            ] = `Number of Poures for a Floor is required for clubHouse ${
              clubIndex + 1
            }`;
          }
          console.log("outside checking basements", club.CHnoOfBasement);
          // Validate Number of Basements
          // if (!club.CHnoOfBasement) {
          //   console.log("inside checking basements",club.CHnoOfBasement)
          //   errors[`CHnoOfBasement-${clubIndex}`] = `Number of Basements is required for clubHouse ${clubIndex +1}`;
          // }
          if (!club.CHnoOfBasements) {
            errors[
              `CHnoOfBasements-${clubIndex}`
            ] = `Number of  Basements is required for clubHouse ${
              clubIndex + 1
            }`;
          }
          // Validate Number of Poures for a Basement
          if (!club.CHnoOfPouresBasement) {
            errors[
              `CHnoOfPouresBasement-${clubIndex}`
            ] = `Number of Poures for a Basement is required for clubHouse ${
              clubIndex + 1
            }`;
          }
          if (club.floors && club.floors.length > 0) {
            club.floors.forEach((floor, floorIndex) => {
              console.log("show the error for floor", floorIndex);

              // Validate Number of Units
              if (!floor.numberOfUnits) {
                errors[
                  `numberOfUnits-${clubIndex}-${floorIndex}`
                ] = `Number of Units is required for clubHouse ${
                  clubIndex + 1
                } floor ${floorIndex}`;
              }
              if (floorIndex > 0) {
                // Validate Manual or Automatic selection
                if (!floor.type) {
                  errors[
                    `type-${clubIndex}-${floorIndex}`
                  ] = `Manual or Automatic selection is required for clubHouse ${
                    clubIndex + 1
                  } floor ${floorIndex}`;
                }
              }

              // Validate each unit in the floor
              if (floor.units && floor.units.length > 0) {
                floor.units.forEach((unit, unitIndex) => {
                  // Validate Unit Number
                  if (!unit.unitNumber) {
                    errors[
                      `unitNumber-${clubIndex}-${floorIndex}-${unitIndex}`
                    ] = `Unit Number is required for clubHouse ${
                      clubIndex + 1
                    } floor ${floorIndex} unit ${unitIndex}`;
                  }

                  // Validate Unit Type
                  if (!unit.unitType) {
                    errors[
                      `unitType-${clubIndex}-${floorIndex}-${unitIndex}`
                    ] = `Unit Type is required for clubHouse ${
                      clubIndex + 1
                    } floor ${floorIndex} unit ${unitIndex}`;
                  }
                });
              }
            });
          }
        });
      }
      if (formData.villas.length > 0 && villaTypes.length > 0) {
        formData.villas.forEach((villa, villaIndex) => {
          if (!villa.selectedType) {
            errors[
              `Villa selectedType-${villaIndex}`
            ] = `Type of Villa is required for villa ${villaIndex}`;
          }
        });
      }
    }

    setErrors(errors);
    console.log("overall errors", errors);
  };

  const [openDialogIndex, setOpenDialogIndex] = useState(null);

  const handleOpenAddDialog = () => {
    setOpenDialogIndex(true);
  };

  const handleCloseAddDialog = () => {
    setOpenDialogIndex(null);
  };

  const handleChange = (
    e,
    floorIndex,
    unitIndex,
    towerIndex,
    clubIndex,
    villaIndex
  ) => {
    const { name, value } = e.target;
    console.log("name", name);
    console.log("value", value);
    console.log("called", floorIndex, unitIndex, towerIndex, name, clubIndex);
    setErrors((prevData) => {
      return {
        ...prevData,
        [name]: "",
      };
    });

    setFormData((prevData) => {
      const updatedData = { ...prevData };

      // Handle villas
      if (name === "VnoOfVillas") {
        const newNumberOfVillas = Number(value);
        updatedData.VnoOfVillas = value;

        if (
          formData.Vtypecommon === "Yes" &&
          formData.Vceller === "Yes" &&
          formData.VcommonFloor === "Yes" &&
          formData.VcommonCellar === "Yes"
        ) {
          console.log("2");
          console.log(formData);
          updatedData.villas = Array.from(
            { length: newNumberOfVillas },
            (_, idx) => ({
              villaName: `Villa ${idx + 1}`, // Initialize villaName based on index
              villaType: formData.VtypeHK,
              cellars: formData.VnoOfCellar,
              floors: formData.VnoOfFloorVillas,
            })
          );
        } else {
          console.log("3");
          updatedData.villas = Array.from(
            { length: newNumberOfVillas },
            (_, idx) => ({
              villaName: `Villa ${idx + 1}`, // Initialize villaName based on index
              villaType: "",
              cellars: "",
              floors: "",
              selectedType: "", // Add this field to capture the selected type
            })
          );
        }
      } else if (villaIndex !== null && villaIndex !== undefined) {
        console.log("1");

        // Split the selected value to extract villaType, cellars, and floors
        const [villaType, cellars, floors] = value
          .split("-")
          .map((str) => str.trim());

        // Update the villa properties based on the selected type
        updatedData.villas[villaIndex] = {
          ...updatedData.villas[villaIndex],
          villaName: `Villa ${villaIndex + 1}`, // Assign villaName based on index
          villaType,
          cellars,
          floors,
          selectedType: value,
        };
      }
      // Handle floors for Building or Office
      else if (name === "BOnoOfFloors") {
        console.log("loop2", name, value);
        updatedData.BOnoOfFloors = value;
        const numberOfFloors = parseInt(value, 10);
        updatedData.floors = Array.from({ length: numberOfFloors + 1 }, () => ({
          numberOfUnits: "",
          type: "",
          units: [],
        }));
      }
      // Handle number of units in floors for Apartments or Building or Office
      else if (name === "numberOfUnits" && floorIndex !== undefined) {
        console.log("loop3", name, value);
        const numberOfUnits = parseInt(value, 10);
        if (
          (prevData.ventureType === "Apartments" ||
            prevData.ventureType === "Villas") &&
          clubIndex !== undefined
        ) {
          console.log("loop31", name, value);
          if (!updatedData.clubs[clubIndex]) {
            updatedData.clubs[clubIndex] = { floors: [] };
          }
          if (!updatedData.clubs[clubIndex].floors[floorIndex]) {
            updatedData.clubs[clubIndex].floors[floorIndex] = { units: [] };
          }
          updatedData.clubs[clubIndex].floors[floorIndex].numberOfUnits = value;
          updatedData.clubs[clubIndex].floors[floorIndex].units = Array.from(
            { length: numberOfUnits },
            () => ({
              unitNumber: "",
              unitType: "",
            })
          );
        } else if (
          prevData.ventureType === "Apartments" &&
          towerIndex !== undefined
        ) {
          console.log("loop32", name, value);
          if (!updatedData.towers[towerIndex]) {
            updatedData.towers[towerIndex] = { floors: [] };
          }
          if (!updatedData.towers[towerIndex].floors[floorIndex]) {
            updatedData.towers[towerIndex].floors[floorIndex] = { units: [] };
          }
          updatedData.towers[towerIndex].floors[floorIndex].numberOfUnits =
            value;
          updatedData.towers[towerIndex].floors[floorIndex].units = Array.from(
            { length: numberOfUnits },
            () => ({
              unitNumber: "",
              unitType: "",
            })
          );
        } else if (prevData.ventureType === "Building or Office") {
          console.log("loop33", name, value);
          if (!updatedData.floors[floorIndex]) {
            updatedData.floors[floorIndex] = { units: [] };
          }
          updatedData.floors[floorIndex].numberOfUnits = value;
          updatedData.floors[floorIndex].units = Array.from(
            { length: numberOfUnits },
            () => ({
              unitNumber: "",
              unitType: "",
            })
          );
        }
      }
      // Handle type for Automatic or Manual selection in floors
      else if (
        name === "type" &&
        floorIndex !== undefined &&
        unitIndex === null
      ) {
        console.log("loop4", name, value);
        if (prevData.ventureType === "Building or Office") {
          console.log("loop41", name, value);
          if (!updatedData.floors[floorIndex]) {
            updatedData.floors[floorIndex] = { units: [] };
          }
          updatedData.floors[floorIndex].type = value;
          console.log("checking data", updatedData.floors[floorIndex].type);
          if (value === "Automatic") {
            const numberOfUnits =
              updatedData.floors[floorIndex].numberOfUnits || 0;
            console.log("automatic calling", numberOfUnits);
            updatedData.floors[floorIndex].units = Array.from(
              { length: numberOfUnits },
              (_, i) => ({
                unitNumber: `${floorIndex}0${i + 1}`,
                unitType: "2BHK",
              })
            );
          } else if (value === "Manual") {
            // Reset unitNumber and unitType for Manual selection
            updatedData.floors[floorIndex].units = updatedData.floors[
              floorIndex
            ].units.map(() => ({
              unitNumber: "",
              unitType: "",
            }));
          }
        } else if (
          (prevData.ventureType === "Apartments" ||
            prevData.ventureType === "Villas") &&
          clubIndex !== undefined
        ) {
          console.log("loop42", name, value);
          if (!updatedData.clubs[clubIndex]) {
            updatedData.clubs[clubIndex] = { floors: [] };
          }
          if (!updatedData.clubs[clubIndex].floors[floorIndex]) {
            updatedData.clubs[clubIndex].floors[floorIndex] = { units: [] };
          }
          updatedData.clubs[clubIndex].floors[floorIndex].type = value;

          if (value === "Automatic") {
            const numberOfUnits =
              updatedData.clubs[clubIndex].floors[floorIndex].numberOfUnits ||
              0;
            updatedData.clubs[clubIndex].floors[floorIndex].units = Array.from(
              { length: numberOfUnits },
              (_, i) => ({
                unitNumber: `${floorIndex + 1}0${i + 1}`,
                unitType: "2BHK",
              })
            );
          } else if (value === "Manual") {
            // Reset unitNumber and unitType for Manual selection
            updatedData.clubs[clubIndex].floors[floorIndex].units =
              updatedData.clubs[clubIndex].floors[floorIndex].units.map(() => ({
                unitNumber: "",
                unitType: "",
              }));
          }
        } else if (
          prevData.ventureType === "Apartments" &&
          towerIndex !== undefined
        ) {
          console.log("loop44", name, value);
          if (!updatedData.towers[towerIndex]) {
            updatedData.towers[towerIndex] = { floors: [] };
          }
          if (!updatedData.towers[towerIndex].floors[floorIndex]) {
            updatedData.towers[towerIndex].floors[floorIndex] = { units: [] };
          }
          updatedData.towers[towerIndex].floors[floorIndex].type = value;

          if (value === "Automatic") {
            const numberOfUnits =
              updatedData.towers[towerIndex].floors[floorIndex].numberOfUnits ||
              0;
            updatedData.towers[towerIndex].floors[floorIndex].units =
              Array.from({ length: numberOfUnits }, (_, i) => ({
                unitNumber: `${floorIndex + 1}0${i + 1}`,
                unitType: "2BHK",
              }));
          } else if (value === "Manual") {
            // Reset unitNumber and unitType for Manual selection
            updatedData.towers[towerIndex].floors[floorIndex].units =
              updatedData.towers[towerIndex].floors[floorIndex].units.map(
                () => ({
                  unitNumber: "",
                  unitType: "",
                })
              );
          }
        }
      }
      // Handle individual unit updates
      else if (
        unitIndex !== undefined &&
        floorIndex !== undefined &&
        unitIndex !== null &&
        floorIndex !== null
      ) {
        console.log("loop5", name, value);
        if (prevData.ventureType === "Building or Office") {
          console.log("loop51", name, value);
          if (
            updatedData.floors[floorIndex] &&
            updatedData.floors[floorIndex].units
          ) {
            updatedData.floors[floorIndex].units[unitIndex][name] = value;
          }
        } else if (
          (prevData.ventureType === "Apartments" ||
            prevData.ventureType === "Villas") &&
          clubIndex !== undefined
        ) {
          console.log("loop52", name, value);
          if (
            updatedData.clubs[clubIndex] &&
            updatedData.clubs[clubIndex].floors[floorIndex] &&
            updatedData.clubs[clubIndex].floors[floorIndex].units
          ) {
            updatedData.clubs[clubIndex].floors[floorIndex].units[unitIndex][
              name
            ] = value;
          }
        } else if (
          prevData.ventureType === "Apartments" &&
          towerIndex !== undefined
        ) {
          console.log("loop53", name, value);
          if (
            updatedData.towers[towerIndex] &&
            updatedData.towers[towerIndex].floors[floorIndex] &&
            updatedData.towers[towerIndex].floors[floorIndex].units
          ) {
            updatedData.towers[towerIndex].floors[floorIndex].units[unitIndex][
              name
            ] = value;
          }
        }
      }
      // Handle number of Club Houses
      else if (name === "AnoOfClubHouses" || name === "VnoOfClubHouses") {
        console.log("loop6", name, value);
        const numberOfClubHouses = parseInt(value, 10);

        // Update the corresponding field in updatedData
        if (name === "AnoOfClubHouses") {
          console.log("loop61", name, value);
          updatedData.AnoOfClubHouses = value;
        } else if (name === "VnoOfClubHouses") {
          console.log("loop62", name, value);
          updatedData.VnoOfClubHouses = value;
        }

        // Update the clubs array

        updatedData.clubs = Array.from({ length: numberOfClubHouses }, () => {
          if (
            formData.AbasementscommonTowers === "No" ||
            formData.AbasementsApartments === "No" ||
            name === "VnoOfClubHouses"
          ) {
            return {
              noOfFloors: "",
              noOfPouresFloor: "",
              CHnoOfBasements: "",
              CHnoOfPouresBasement: "",
              floors: [],
            };
          } else {
            return {
              noOfFloors: "",
              noOfPouresFloor: "",
              floors: [],
            };
          }
        });

        console.log("Updated Data for club houses:", updatedData);
      }
      // Handle number of floors in clubhouses
      else if (name === "noOfFloors" && clubIndex !== undefined) {
        console.log("loop7", name, value);
        console.log(updatedData.clubs.length, "check");

        // Ensure that the club exists in the array
        if (!updatedData.clubs[clubIndex]) {
          updatedData.clubs[clubIndex] = { floors: [] };
        }

        console.log("loop71", name, value);
        const numberOfFloors = parseInt(value, 10);

        // Create the updated floors array with the new number of floors
        const newFloors = Array.from(
          { length: numberOfFloors + 1 }, // Add 1 extra floor
          (_, index) =>
            updatedData.clubs[clubIndex].floors[index] || {
              numberOfUnits: "",
              type: "",
              units: [],
            }
        );

        updatedData.clubs[clubIndex].floors = newFloors;
        updatedData.clubs[clubIndex].noOfFloors = value;

        console.log("checking floors", updatedData.clubs[clubIndex].floors);
      }
      // Handle pour of floors in clubhouses
      else if (name === "noOfPouresFloor" && clubIndex !== undefined) {
        console.log("loop8", name, value);
        if (!updatedData.clubs[clubIndex]) {
          updatedData.clubs[clubIndex] = {};
        }
        updatedData.clubs[clubIndex].noOfPouresFloor = value;
      }
      // else if (name === "CHnoOfBasements" && clubIndex !== undefined) {
      //   console.log("loop81",name,value);
      //   if (!updatedData.clubs[clubIndex]) {
      //     updatedData.clubs[clubIndex] = {};
      //   }
      //   updatedData.clubs[clubIndex].CHnoOfBasements = value;
      // }
      else if (name === "CHnoOfPouresBasement" && clubIndex !== undefined) {
        console.log("loop8", name, value);
        if (!updatedData.clubs[clubIndex]) {
          updatedData.clubs[clubIndex] = {};
        }
        updatedData.clubs[clubIndex].CHnoOfPouresBasement = value;
      } else if (name === "CHnoOfBasements" && clubIndex !== undefined) {
        console.log("loop8", name, value);
        if (!updatedData.clubs[clubIndex]) {
          updatedData.clubs[clubIndex] = {};
        }
        updatedData.clubs[clubIndex].CHnoOfBasements = value;
      }
      // Handle number of towers AnoOfBasement,CHnoOfBasement
      else if (name === "AnoOfTowers") {
        console.log("loop9", name, value);
        const numberOfTowers = parseInt(value, 10);
        updatedData.AnoOfTowers = value;
        updatedData.towers = Array.from({ length: numberOfTowers }, () => ({
          AnoOfFloors: "",
          AnoOfPouresFloor: "",
          ...(formData.AbasementscommonTowers === "No" ||
          formData.AbasementsApartments === "No"
            ? {
                AnoOfBasements: "",
                AnoOfPouresBasements: "",
              }
            : {}),
          floors: [],
        }));
      }
      // Handle number of floors in towers
      else if (name === "AnoOfFloors" && towerIndex !== undefined) {
        console.log("loop10", name, value);
        // Ensure the tower exists in the updated data
        if (!updatedData.towers[towerIndex]) {
          updatedData.towers[towerIndex] = { floors: [] };
        }

        // Set the number of floors
        updatedData.towers[towerIndex].AnoOfFloors = value;
        const numberOfFloors = parseInt(value, 10);

        // Create the floors array based on the number of floors
        updatedData.towers[towerIndex].floors = Array.from(
          { length: numberOfFloors },
          () => ({
            numberOfUnits: "",
            type: "",
            units: [],
          })
        );

        // Add one more floor to the array
        updatedData.towers[towerIndex].floors.push({
          numberOfUnits: "",
          type: "",
          units: [],
        });
      }
      // Handle pour of floors in towers
      else if (name === "AnoOfPouresFloor" && towerIndex !== undefined) {
        console.log("loop11", name, value);
        if (!updatedData.towers[towerIndex]) {
          updatedData.towers[towerIndex] = {};
        }
        updatedData.towers[towerIndex].AnoOfPouresFloor = value;
      } else if (name === "AnoOfPouresBasements" && towerIndex !== undefined) {
        console.log("loop11", name, value);
        if (!updatedData.towers[towerIndex]) {
          updatedData.towers[towerIndex] = {};
        }
        updatedData.towers[towerIndex].AnoOfPouresBasements = value;
      } else if (name === "AnoOfBasements" && towerIndex !== undefined) {
        console.log("loop11", name, value);
        if (!updatedData.towers[towerIndex]) {
          updatedData.towers[towerIndex] = {};
        }
        updatedData.towers[towerIndex].AnoOfBasements = value;
      } else if (name === "AnoOfPouresBasements" && towerIndex !== undefined) {
        console.log("loop11", name, value);
        if (!updatedData.towers[towerIndex]) {
          updatedData.towers[towerIndex] = {};
        }
        updatedData.towers[towerIndex].AnoOfPouresBasements = value;
      }
      // Handle any other general field update
      else {
        updatedData[name] = value;
      }

      console.log("Updated Form Data in handleChange:", updatedData);
      return updatedData;
    });
  };

  useEffect(() => {
    // Update units array for each floor based on the number of units
    setFormData((prevData) => ({
      ...prevData,
      floors: prevData.floors.map((floor, index) => ({
        ...floor,
        units: Array.from(
          { length: parseInt(floor.numberOfUnits, 10) || 0 },
          (_, unitIndex) => ({
            unitNumber: floor.units[unitIndex]?.unitNumber || "",
            unitType: floor.units[unitIndex]?.unitType || "",
          })
        ),
      })),
    }));
  }, [formData.floors.map((floor) => floor.numberOfUnits).join("-")]); // Depend on numberOfUnits changes

  const handleAddAmenity = () => {
    setAmenities([...amenities, ""]);
  };

  const handleAmenityChange = (index, e) => {
    const value = e.target.value;
    const newAmenities = [...amenities];
    newAmenities[index] = value;
    setAmenities(newAmenities);
  };
  const handleRemoveAmenity = (index) => {
    const newAmenities = amenities.filter((_, i) => i !== index);
    setAmenities(newAmenities);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("after submit errors", errors);
    // Assuming validateForm sets an errors object in the state
    validateForm();

    // Access the errors state to check for validation errors
    if (Object.keys(errors).length === 0) {
      setApiProcessing({ loader: true, message: "Loading..." });

      try {
        // Construct the data object
        const data = {
          ventureType: formData.ventureType,
        };

        if (formData.ventureType === "Building or Office") {
          data.buildingsDetails = {
            basements: formData.BObasementsBuilding,
            amenityAreThere: formData.BOamenity,
          };

          if (formData.BObasementsBuilding === "Yes") {
            data.buildingsDetails.noOfBasements = formData.BOnoOfbasements;
            data.buildingsDetails.noOfPouresForBasement =
              formData.BOnoOfPouresbasements;
          }

          data.buildingsDetails.towers = {
            floors: formData.floors.map((floor, floorIndex) => ({
              name: `Floor ${floorIndex + 1}`,
              numUnits: floor.numberOfUnits || 0,
              manualOrAutomatic: floor.type || "",
              units: floor.units.map((unit, unitIndex) => ({
                name: unit.unitNumber || "",
                type: unit.unitType || "",
              })),
            })),
          };
          if (formData.BOamenity === "Yes") {
            data.buildingsDetails.amenities = amenities;
          }
        }

        if (formData.ventureType === "Apartments") {
          data.apartmentsDetails = {
            basements: formData.AbasementsApartments,
            noOfTowers: formData.AnoOfTowers,
            noOfClubHouses: formData.AnoOfClubHouses,
            amenities: amenities,
          };
          if (formData.AbasementsApartments === "Yes") {
            data.apartmentsDetails.basementsAreCommonForEveryTower =
              formData.AbasementscommonTowers;
          }
          if (
            (formData.AbasementscommonTowers === "Yes" &&
              formData.AbasementsApartments === "No") ||
            formData.AbasementsApartments === "No"
          ) {
            data.apartmentsDetails.towers = formData.towers.map((tower) => ({
              numBasements: tower.AnoOfBasements || "",
              noOfPouresForABasement: tower.AnoOfPouresBasements || "",
              numFloors: tower.AnoOfFloors || "",
              noOfPouresForAFloor: tower.AnoOfPouresFloor || "",
              floors: tower.floors.map((floor, floorIndex) => ({
                name: `Floor ${floorIndex + 1}`,
                numUnits: floor.numberOfUnits || 0,
                manualOrAutomatic: floor.type || "",
                units: floor.units.map((unit) => ({
                  name: unit.unitNumber || "",
                  type: unit.unitType || "",
                })),
              })),
            }));
          } else if (formData.AbasementscommonTowers === "Yes") {
            data.apartmentsDetails.noOfBasements = formData.AnoOfBasements;
            data.apartmentsDetails.noOfPouresForBasement =
              formData.AnoOfPouresBasements;

            data.apartmentsDetails.towers = formData.towers.map((tower) => ({
              numBasements: tower.AnoOfBasements || "",
              noOfPouresForABasement: tower.AnoOfPouresBasements || "",
              numFloors: tower.AnoOfFloors || "",
              noOfPouresForAFloor: tower.AnoOfPouresFloor || "",
              floors: tower.floors.map((floor, floorIndex) => ({
                name: `Floor ${floorIndex + 1}`,
                numUnits: floor.numberOfUnits || 0,
                manualOrAutomatic: floor.type || "",
                units: floor.units.map((unit) => ({
                  name: unit.unitNumber || "",
                  type: unit.unitType || "",
                })),
              })),
            }));
          }
          if (
            (formData.AbasementscommonTowers === "Yes" &&
              formData.AbasementsApartments === "No") ||
            formData.AbasementsApartments === "No"
          ) {
            data.apartmentsDetails.clubhouse = formData.clubs.map((club) => ({
              numBasements: club.CHnoOfBasements || "",
              noOfPouresForABasement: club.CHnoOfPouresBasement || "",
              numFloors: club.noOfFloors || "",
              noOfPouresForAFloor: club.noOfPouresFloor || "",
              floors: club.floors.map((floor, floorIndex) => ({
                name: `Floor ${floorIndex + 1}`,
                numUnits: floor.numberOfUnits || 0,
                manualOrAutomatic: floor.type || "",
                units: floor.units.map((unit) => ({
                  name: unit.unitNumber || "",
                  type: unit.unitType || "",
                })),
              })),
            }));
          } // Handle Clubhouses based on the value of AbasementscommonTowers
          else if (formData.AbasementscommonTowers === "Yes") {
            data.apartmentsDetails.clubhouse = formData.clubs.map((club) => ({
              // numBasements: club.noOfBasements || '',
              // noOfPouresForABasement: club.noOfPouresBasement || '',
              numFloors: club.noOfFloors || "",
              noOfPouresForAFloor: club.noOfPouresFloor || "",
              floors: club.floors.map((floor, floorIndex) => ({
                name: `Floor ${floorIndex + 1}`,
                numUnits: floor.numberOfUnits || 0,
                manualOrAutomatic: floor.type || "",
                units: floor.units.map((unit) => ({
                  name: unit.unitNumber || "",
                  type: unit.unitType || "",
                })),
              })),
            }));
          }
        }

        if (formData.ventureType === "Villas") {
          let villasDetails = {
            villaTypesAreCommonForEveryVillas: formData.Vtypecommon,
            noOfClubHouses: formData.VnoOfClubHouses,
            noOfVillas: formData.VnoOfVillas,
            cellarIsThereForVillas: formData.Vceller,
            floorsAreCommonForEveryVillas: formData.VcommonFloor,
            amenities: amenities,
            villaTypeDetails: formData.villas.map((villa) => ({
              villaName: villa.villaName,
              villaType: villa.villaType,
              cellar: villa.cellars,
              floor: villa.floors,
            })),
          };
          // Add conditional properties
          if (formData.Vceller === "Yes") {
            villasDetails.cellarAreCommonForEveryVillas =
              formData.VcommonCellar;

            if (formData.VcommonCellar === "Yes") {
              villasDetails.noOfCellarsForVillas = formData.VnoOfCellar;
            }
          }

          if (formData.VcommonFloor === "Yes") {
            villasDetails.noOfFloorsForVillas = formData.VnoOfFloorVillas;
          }
          if (formData.Vtypecommon === "Yes") {
            villasDetails.selectVillaType = formData.VtypeHK;
          }
          data.villasDetails = villasDetails;
          data.villasDetails.clubhouse = formData.clubs.map((club) => ({
            numBasements: club.CHnoOfBasements || "",
            noOfPouresForABasement: club.CHnoOfPouresBasement || "",
            numFloors: club.noOfFloors || "",
            noOfPouresForAFloor: club.noOfPouresFloor || "",
            floors: club.floors.map((floor, floorIndex) => ({
              name: `Floor ${floorIndex + 1}`,
              numUnits: floor.numberOfUnits || 0,
              manualOrAutomatic: floor.type || "",
              units: floor.units.map((unit) => ({
                name: unit.unitNumber || "",
                type: unit.unitType || "",
              })),
            })),
          }));
        }
        const sitesData = JSON.stringify(data);
        // Send the request
        const response = await webApi.put(`api/sites/update/${id}`, sitesData);

        if (response.status === 201) {
          setApiProcessing({ loader: false, message: "" });
          window.location.reload();
        } else {
          setErrorMessage("Validation error occurred.");
        }
      } catch (error) {
        setErrorMessage("Failed to submit data.");
      } finally {
        setApiProcessing({ loader: false, message: "" });
      }
    }
  };
  const handleClose = () => {
    close();
  };
  return (
    <>
      <Loader open={apiProcessing.loader} message={apiProcessing.message} />
      <form onSubmit={handleSubmit}>
        <>
          <div style={{ margin: "20px" }}>
            <div>
              <h3>Site Details</h3>
              <div
                style={{
                  borderBottom: "1px dotted lightgray",
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "20px",
                  paddingBottom: "40px",
                }}
              >
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "20px",

                      marginRight: "80px",
                    }}
                  >
                    <label>
                      Venture Type<a style={{ color: "red" }}>*</a>
                    </label>
                    <select
                      name="ventureType"
                      value={formData.ventureType}
                      onChange={handleChange}
                    >
                      <option value="">Select Type</option>
                      <option value="Building or Office">
                        Building/Office
                      </option>
                      <option value="Apartments">Apartments</option>
                      <option value="Villas">Villas</option>
                    </select>
                    {errors.ventureType && (
                      <p style={{ color: "red" }}>{errors.ventureType}</p>
                    )}
                  </div>

                  {formData.ventureType === "Building or Office" && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "20px",
                      }}
                    >
                      <label>
                        Basements Is there for building?
                        <a style={{ color: "red" }}>*</a>
                      </label>
                      <select
                        name="BObasementsBuilding"
                        value={formData.BObasementsBuilding}
                        onChange={handleChange}
                      >
                        <option value="">Select Yes/No</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                      {errors.BObasementsBuilding && (
                        <p style={{ color: "red" }}>
                          {errors.BObasementsBuilding}
                        </p>
                      )}
                    </div>
                  )}

                  {formData.ventureType === "Apartments" && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginTop: "20px",
                        }}
                      >
                        <label>
                          Basements are there for Apartments?
                          <a style={{ color: "red" }}>*</a>
                        </label>
                        <select
                          name="AbasementsApartments"
                          value={formData.AbasementsApartments}
                          onChange={handleChange}
                        >
                          <option value="">Select Yes/No</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                        {errors.AbasementsApartments && (
                          <p style={{ color: "red" }}>
                            {errors.AbasementsApartments}
                          </p>
                        )}
                      </div>
                    </>
                  )}
                  {formData.ventureType === "Villas" && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "20px",
                      }}
                    >
                      <label>
                        Villa Types are common for every Villas?
                        <a style={{ color: "red" }}>*</a>
                      </label>
                      <select
                        name="Vtypecommon"
                        value={formData.Vtypecommon}
                        onChange={handleChange}
                        disabled={!!formData.VnoOfVillas}
                      >
                        <option value="">Select Yes/No</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                      {errors.Vtypecommon && (
                        <p style={{ color: "red" }}>{errors.Vtypecommon}</p>
                      )}
                    </div>
                  )}
                </div>

                {formData.BObasementsBuilding === "Yes" && (
                  <div
                    style={{
                      display: "flex",
                      marginTop: "16px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginRight: "80px",
                      }}
                    >
                      <label>
                        Number of Basements<a style={{ color: "red" }}>*</a>
                      </label>
                      <input
                        type="number"
                        min="1"
                        max="100"
                        name="BOnoOfbasements"
                        placeholder="Enter Number of Basements"
                        value={formData.BOnoOfbasements}
                        onChange={handleChange}
                      />
                      {errors.BOnoOfbasements && (
                        <p style={{ color: "red" }}>{errors.BOnoOfbasements}</p>
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <label>
                        Number of Poures for a Basement
                        <a style={{ color: "red" }}>*</a>
                      </label>
                      <input
                        type="number"
                        min="1"
                        max="100"
                        name="BOnoOfPouresbasements"
                        placeholder="Enter Number of Poures for a Basement"
                        value={formData.BOnoOfPouresbasements}
                        onChange={handleChange}
                      />
                      {errors.BOnoOfPouresbasements && (
                        <p style={{ color: "red" }}>
                          {errors.BOnoOfPouresbasements}
                        </p>
                      )}
                    </div>
                  </div>
                )}
                {formData.ventureType === "Building or Office" && (
                  <div
                    style={{
                      display: "flex",
                      marginTop: "16px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginRight: "80px",
                      }}
                    >
                      <label>
                        Amenity are there?<a style={{ color: "red" }}>*</a>
                      </label>
                      <select
                        name="BOamenity"
                        value={formData.BOamenity}
                        onChange={handleChange}
                      >
                        <option value="">Select Yes/No</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                      {errors.BOamenity && (
                        <p style={{ color: "red" }}>{errors.BOamenity}</p>
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    ></div>
                  </div>
                )}

                <div
                  style={{
                    display: "flex",
                    marginTop: "16px",
                  }}
                >
                  {(formData.AbasementsApartments === "Yes" ||
                    formData.AbasementsApartments === "No") && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginRight: "80px",
                      }}
                    >
                      <label>
                        Please Enter Number of Towers
                        <a style={{ color: "red" }}>*</a>
                      </label>
                      <input
                        type="number"
                        min="1"
                        max="100"
                        name="AnoOfTowers"
                        placeholder="Enter Number of Towers"
                        value={formData.AnoOfTowers}
                        onChange={handleChange}
                      />
                      {errors.AnoOfTowers && (
                        <p style={{ color: "red" }}>{errors.AnoOfTowers}</p>
                      )}
                    </div>
                  )}
                  {formData.AbasementsApartments === "Yes" && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <label>
                        Basements are common for every Towers ?
                        <a style={{ color: "red" }}>*</a>
                      </label>
                      <select
                        name="AbasementscommonTowers"
                        value={formData.AbasementscommonTowers}
                        onChange={handleChange}
                      >
                        <option value="">Select Yes/No</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                      {errors.AbasementscommonTowers && (
                        <p style={{ color: "red" }}>
                          {errors.AbasementscommonTowers}
                        </p>
                      )}
                    </div>
                  )}
                </div>

                <div
                  style={{
                    display: "flex",
                    marginTop: "16px",
                  }}
                >
                  {formData.AbasementscommonTowers === "Yes" && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginRight: "80px",
                        }}
                      >
                        <label>
                          Number of Basements<a style={{ color: "red" }}>*</a>
                        </label>
                        <input
                          type="number"
                          min="1"
                          max="100"
                          name="AnoOfBasements"
                          placeholder="Enter Number of Basements"
                          value={formData.AnoOfBasements}
                          onChange={handleChange}
                        />
                        {errors.AnoOfBasements && (
                          <p style={{ color: "red" }}>
                            {errors.AnoOfBasements}
                          </p>
                        )}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <label>
                          Number of Poures for a Basement
                          <a style={{ color: "red" }}>*</a>
                        </label>
                        <input
                          type="number"
                          min="1"
                          max="100"
                          name="AnoOfPouresBasements"
                          placeholder="Enter Number of Poures Basements"
                          value={formData.AnoOfPouresBasements}
                          onChange={handleChange}
                        />
                        {errors.AnoofPouresBasements && (
                          <p style={{ color: "red" }}>
                            {errors.AnoofPouresBasements}
                          </p>
                        )}
                      </div>
                    </>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  {(formData.AbasementsApartments === "Yes" ||
                    formData.AbasementsApartments === "No") && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginRight: "80px",
                      }}
                    >
                      <label>
                        Club Houses<a style={{ color: "red" }}>*</a>
                      </label>
                      <input
                        type="number"
                        min="1"
                        max="100"
                        name="AnoOfClubHouses"
                        placeholder="Enter Number Of Club Houses "
                        value={formData.AnoOfClubHouses}
                        onChange={handleChange}
                      />
                      {errors.AnoOfClubHouses && (
                        <p style={{ color: "red" }}>{errors.AnoOfClubHouses}</p>
                      )}
                    </div>
                  )}
                </div>

                {formData.ventureType === "Villas" && (
                  <VillaPreciseDetails
                    formData={formData}
                    handleChange={handleChange}
                    errors={errors}
                    enableNoofVillas={enableNoofVillas}
                  />
                )}
              </div>
            </div>

            {/* secondstep */}

            {formData.ventureType === "Apartments" &&
              formData.AbasementscommonTowers === "Yes" && (
                <div style={{ marginTop: "20px" }}>
                  {formData.towers.length > 0 &&
                    formData.towers.map((tower, index) => (
                      <div key={index}>
                        <h3>Enter Details for Tower {index + 1}</h3>
                        <div
                          style={{
                            borderBottom: "1px dotted lightgray",
                            display: "flex",
                            marginTop: "16px",
                            paddingBottom: "40px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginRight: "80px",
                            }}
                          >
                            <label>
                              Number of Floors<a style={{ color: "red" }}>*</a>
                            </label>
                            <input
                              type="number"
                              min="1"
                              max="100"
                              name="AnoOfFloors"
                              placeholder="Enter Number of Floors"
                              value={tower.AnoOfFloors}
                              onChange={(e) =>
                                handleChange(e, null, null, index)
                              }
                            />
                            {errors[`AnoOfFloors-${index}`] && (
                              <p style={{ color: "red" }}>
                                {errors[`AnoOfFloors-${index}`]}
                              </p>
                            )}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <label>
                              Number of Poures for a Floor
                              <a style={{ color: "red" }}>*</a>
                            </label>
                            <input
                              type="number"
                              min="1"
                              max="100"
                              name="AnoOfPouresFloor"
                              placeholder="Enter Number Of Poures for a Floor"
                              value={tower.AnoOfPouresFloor}
                              onChange={(e) =>
                                handleChange(e, null, null, index)
                              }
                            />
                            {errors[`AnoOfPouresFloor-${index}`] && (
                              <p style={{ color: "red" }}>
                                {errors[`AnoOfPouresFloor-${index}`]}
                              </p>
                            )}
                          </div>
                        </div>

                        {/* Render Floor Details for each Tower */}
                        {tower.AnoOfFloors > 0 && (
                          <FloorDetails
                            floors={tower.floors}
                            errors={errors}
                            handleChange={handleChange}
                            ventureType={formData.ventureType}
                            towerIndex={index}
                          />
                        )}
                      </div>
                    ))}
                </div>
              )}
            {formData.VnoOfVillas > 0 && (
              <VillaTypeDetails
                formData={formData}
                handleOpenAddDialog={handleOpenAddDialog}
                handleChange={handleChange}
                villaTypes={villaTypes}
                errors={errors}
              />
            )}
            <div style={{ marginTop: "20px" }}>
              {
                formData.ventureType === "Apartments" &&
                  (formData.AbasementscommonTowers === "No" ||
                    formData.AbasementsApartments === "No") &&
                  formData.towers.length > 0 && (
                    <ApartmentTowerDetails
                      formData={formData}
                      handleChange={handleChange}
                      errors={errors}
                    />
                  )
                // <p>Selected</p>
                //  TODO: ASK FOR PROPER NAME
              }
            </div>

            <div style={{ marginTop: "20px" }}>
              {formData.ventureType === "Building or Office" && (
                <BuildingDetails
                  formData={formData}
                  handleChange={handleChange}
                  errors={errors}
                />
              )}
            </div>
            {formData.ventureType === "Apartments" &&
              formData.AbasementsApartments === "Yes" &&
              formData.AbasementscommonTowers === "Yes" && (
                <div style={{ marginTop: "20px" }}>
                  {formData.clubs.length > 0 &&
                    formData.clubs.map((club, index) => (
                      <div key={index}>
                        <h3>Club House {index + 1}</h3>
                        <div
                          style={{
                            borderBottom: "1px dotted lightgray",
                            display: "flex",
                            marginTop: "16px",
                            paddingBottom: "40px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginRight: "80px",
                            }}
                          >
                            <label>
                              Number of Floors<a style={{ color: "red" }}>*</a>
                            </label>
                            <input
                              type="number"
                              min="1"
                              max="100"
                              name="noOfFloors"
                              placeholder="Enter Number of Floors"
                              value={club.noOfFloors}
                              onChange={(e) =>
                                handleChange(e, null, null, null, index)
                              }
                            />
                            {errors[`noOfFloors-${index}`] && (
                              <p style={{ color: "red" }}>
                                {errors[`noOfFloors-${index}`]}
                              </p>
                            )}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <label>
                              Number of Poures for a Floor
                              <a style={{ color: "red" }}>*</a>
                            </label>
                            <input
                              type="number"
                              min="1"
                              max="100"
                              name="noOfPouresFloor"
                              placeholder="Enter Number Of Poures for a Floor"
                              value={club.noOfPouresFloor}
                              onChange={(e) =>
                                handleChange(e, null, null, null, index)
                              }
                            />
                            {errors[`noOfPouresFloor-${index}`] && (
                              <p style={{ color: "red" }}>
                                {errors[`noOfPouresFloor-${index}`]}
                              </p>
                            )}
                          </div>
                        </div>

                        {/* Render Floor Details for each Tower */}
                        {club.noOfFloors > 0 && (
                          <FloorDetails
                            floors={club.floors}
                            errors={errors}
                            handleChange={handleChange}
                            ventureType={formData.ventureType}
                            clubIndex={index}
                          />
                        )}
                      </div>
                    ))}
                </div>
              )}
            {((formData.ventureType === "Apartments" &&
              (formData.AbasementsApartments === "No" ||
                (formData.AbasementsApartments === "Yes" &&
                  formData.AbasementscommonTowers === "No"))) ||
              formData.ventureType === "Villas") && (
              <div style={{ marginTop: "20px" }}>
                {formData.clubs.length > 0 && (
                  <CommonClubHouseDetails
                    formData={formData}
                    handleChange={handleChange}
                    errors={errors}
                  />
                )}
              </div>
            )}
            {(formData.BOamenity === "Yes" ||
              formData.ventureType === "Apartments" ||
              formData.ventureType === "Villas") && (
              <AmenityContainer
                handleAddAmenity={handleAddAmenity}
                amenities={amenities}
                handleAmenityChange={handleAmenityChange}
                handleRemoveAmenity={handleRemoveAmenity}
              />
            )}
            <SubmitButton />
          </div>
        </>
      </form>
      <VillaTypeDialog
        typeCallBack={(rows) => {
          setRowsData(rows);
        }}
        open={openDialogIndex}
        onClose={handleCloseAddDialog}
        fvillaType={formData.VtypeHK ? formData.VtypeHK : ""}
        fnoOfCellar={formData.VnoOfCellar ? formData.VnoOfCellar : ""}
        fnoOfFloors={formData.VnoOfFloorVillas ? formData.VnoOfFloorVillas : ""}
      />
    </>
  );
};
export default AddSiteDetailsDialog;
